import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import { history, Link } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { RightCircleFilled } from '@ant-design/icons';
import { removeToken } from './utils/token';
// import LmeLogo from '@/assets/lme-logo-icon.png';
import shipRacerLogo from '@/assets/shipracer-logo.png';

import {
  addTokenToHeaderInterceptor,
  blockUserActionResponseInterceptor,
  requestErrorHandler,
} from './utils/request';
import { queryCurrentUser } from './services/api';
import type { LME_OMS_API } from './services/typings';
import defaultSettings from '@/../config/defaultSettings';
// import { Menu } from 'antd';

// const isDev = process.env.NODE_ENV === 'development';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

//
const PublicRoute: string[] = [
  '/login',
  '/privacy-policy',
  '/account/recovery',
  '/account/invitation',
  '/account/invitation/accept',
  '/account/invitation/result',
];

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: LME_OMS_API.CurrentUser;
  fetchUserInfo?: () => Promise<LME_OMS_API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const response = await queryCurrentUser();
      return response.data;
    } catch (error) {
      removeToken();
      // history.push('/login');
    }
    return undefined;
  };
  // 如果是登录页面，不执行
  if (!PublicRoute.includes(history.location.pathname)) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: {},
    };
  }
  return {
    fetchUserInfo,
    settings: {},
  };
}

/**
 * 异常处理程序
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    405: '请求方法不被允许。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
 //-----English
    200: The server successfully returned the requested data. ',
    201: New or modified data is successful. ',
    202: A request has entered the background queue (asynchronous task). ',
    204: Data deleted successfully. ',
    400: 'There was an error in the request sent, and the server did not create or modify data. ',
    401: The user does not have permission (token, username, password error). ',
    403: The user is authorized, but access is forbidden. ',
    404: The request sent was for a record that did not exist. ',
    405: The request method is not allowed. ',
    406: The requested format is not available. ',
    410':
        'The requested resource is permanently deleted and will no longer be available. ',
    422: When creating an object, a validation error occurred. ',
    500: An error occurred on the server, please check the server. ',
    502: Gateway error. ',
    503: The service is unavailable. ',
    504: The gateway timed out. ',
 * @see https://beta-pro.ant.design/docs/request-cn
 */
export const request: RequestConfig = {
  credentials: 'include', // 默认请求是否带上cookie
  timeout: 600000,
  headers: {
    'Accept-Language': 'en-US',
    Accept: 'application/json',
  },
  errorConfig: {},
  middlewares: [],
  requestInterceptors: [addTokenToHeaderInterceptor],
  responseInterceptors: [blockUserActionResponseInterceptor],

  errorHandler: requestErrorHandler, // 默认错误处理
};

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    // headerRender: (headerViewProps, defaultDom) =>
    //   headerViewProps.isMobile || headerViewProps.collapsed ? (
    //     defaultDom
    //   ) : (
    //     <>
    //       <div className="ant-pro-global-header ant-pro-global-header-layout-mix">
    //         <div className="ant-pro-global-header-logo">
    //           <a>
    //             <img src={headerViewProps.logo} />
    //           </a>
    //         </div>
    //         <div style={{ flex: '1 1 0%' }}>
    //           <Menu key={1} mode="horizontal">
    //             <Menu.Item>Create Single Shipment</Menu.Item>
    //             <Menu.Item>Create Single Shipment</Menu.Item>
    //             <Menu.Item>Create Single Shipment</Menu.Item>
    //           </Menu>
    //         </div>
    //         <RightContent />
    //       </div>
    //     </>
    //   ),
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    // waterMarkProps: {
    //   content: initialState?.currentUser?.name,
    // },
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && !PublicRoute.includes(location.pathname)) {
        history.push('/login');
      }
    },
    breadcrumbRender: (routers = []) => [
      {
        path: '/',
        breadcrumbName: 'Home',
      },
      ...routers,
    ],
    logo: window?.env && window.env.APP_LOGO !== '' ? window.env.APP_LOGO : shipRacerLogo,
    title: window?.env && window.env.APP_NAME !== '' ? window.env.APP_NAME : defaultSettings.title,
    links: [
      // <Link to="/api-integration">
      //   <BookOutlined />
      //   <span>API Integration</span>
      // </Link>,
      // <Link to="/terms-and-conditions">
      //   <ProfileOutlined />
      //   <span>Terms and Conditions</span>
      // </Link>,
    ],
    menuItemRender: (menuItemProps, defaultDom) => {
      if (menuItemProps.key === 'productManage' || menuItemProps.key === 'wmsManage') {
        return (
          <>
            <Link to={menuItemProps.path}>
              {defaultDom}
              <RightCircleFilled
                style={{
                  fontSize: 24,
                }}
                className="in_icon"
              />
            </Link>
          </>
        );
      }
      if (
        menuItemProps.path?.indexOf('http://') === 0 ||
        menuItemProps.path?.indexOf('https://') === 0
      ) {
        return (
          <a href={menuItemProps.path} target={menuItemProps.target}>
            {defaultDom}
          </a>
        );
      }
      return <Link to={menuItemProps.path}>{defaultDom}</Link>;
    },
    // menu: { type: 'group' },
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};
