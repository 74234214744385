import component from './en-US/component';
import globalHeader from './en-US/globalHeader';
import menu from './en-US/menu';
import pages from './en-US/pages';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';
import users from './en-US/users';
import general from './en-US/general';
import remark from './en-US/remark';
import userList from './en-US/userList';
import dashboard from './en-US/dashboard';
import quote from './en-US/quote';
import label from './en-US/label';
import admin from './en-US/admin';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  'form.please-select-status': 'Please select status',
  'common.action': 'Action',
  'common.created_at': 'Created At',
  'common.updated_at': 'Updated At',
  'common.created_by': 'Created By',
  'common.updated_by': 'Updated By',
  'action.create_item': 'Create Item',
  'action.settle': 'Settle',
  'action.add': 'Add',
  'action.show': 'Show',
  'action.submit': 'Submit',
  'action.save': 'Save',
  'common.record.in_total': '{total} Records in Total',
  'common.user_type.contact': 'Contact',
  'common.user_type.user': 'Supplier Portal User',
  'common.status.active': 'Active',
  'common.status': 'Status',
  'common.status.inactive': 'Inactive',
  'common.field.expandText': 'Expand',
  'common.field.collapseText': 'Collapse',
  'common.field.selectAllText': 'All',
  'common.field.email': 'Email',
  'common.field.status': 'Status',
  'common.field.active': 'Active',
  'common.field.inactive': 'Inactive',
  'common.field.created-at': 'Created At',
  'common.field.released-at': 'Released At',
  'common.field.shipped-at': 'Shipped At',
  'common.field.created-by': 'Created By',
  'common.field.updated-at': 'Updated At',
  'common.field.updated-by': 'Updated By',
  'common.field.action': 'Action',
  'common.field.action-by': 'Action By',
  'common.field.action-at': 'Action At',
  'common.field.from-date': 'From Date',
  'common.field.to-date': 'To Date',
  'common.field.errors': 'Error Messages',
  'common.filed.pending-for-my-approval': 'Pending For My Approval',
  'common.filed.pending-for-my-confirmation': 'Pending For My Confirmation',
  'common.idle.warning.title':
    'You have been logged out of the system due to inactivity, please login again to return to the last page of your session.',
  'common.idle.warning.content': '',
  'common.filed.rate-only.label': 'Rate Only',
  'common.loading': 'Loading...',
  'common.field.sort': 'Sort',
  'common.please_enter.primary_name': 'Please enter primary name',
  'common.please_enter.secondary_name': 'Please enter secondary name',
  'common.please_enter.address': 'Please enter address',
  'common.please_enter.postal_address': 'Please enter postal address',
  'common.please_enter.email': 'Please enter email',
  'common.please_enter.tel': 'Please enter tel',
  'common.please_enter.br': 'Please enter br',
  'common.please_enter.a_valid_email': 'Please enter a valid email',
  'common.please_enter.at_least_character': 'Please enter at least {num} characters',
  'common.please_enter.finance_code': 'Please enter finance code',
  'common.please_enter.bank_acc': 'Please enter bank account',
  'common.please_enter.contact_person': 'Please enter contact person',
  'common.please_enter.mobile': 'Please enter mobile no.',
  'common.please_enter.website': 'Please enter website',
  'common.please_enter.remark': 'Please enter remark',

  'button.preload': 'Add',
  'button.yes': 'Yes',
  'button.no': 'No',
  'button.submit': 'Submit',
  'button.submit.now': 'Submit Now',
  'button.cancel': 'Cancel',
  'button.complete': 'Complete',
  'button.activate': 'Activate',
  'button.close': 'Close',
  'button.exit': 'Exit',
  'button.search': 'Search',
  'button.delete': 'Delete',
  'button.reset': 'Reset',
  'button.create': 'Create',
  'button.update': 'Update',
  'button.download': 'Download',
  'button.save': 'Save',
  'button.edit': 'Edit',
  'button.open': 'Open',
  'button.clone': 'Clone',
  'button.update-info': 'Update Info',
  'button.confirm': 'Confirm',
  'button.setting.user-role-project': 'Setting',
  'button.setting.user-role': 'Setting',
  'button.more-info': 'More Info',
  'button.ok': 'OK',
  'button.personal-setting': 'Personal Setting',
  'button.export': 'Export',

  'action.options': 'More Options',
  'action.logout': 'Logout',
  'action.actions': 'Actions',
  'action.back': 'Back',
  'action.open': 'Open',
  'action.create': 'Create',
  'action.edit': 'Edit',
  'action.update': 'Update',
  'action.delete': 'Delete',
  'action.enable': 'Enable',
  'action.disable': 'Disable',
  'action.upload': 'Upload',
  'action.batch-delete': 'Batch Delete',
  'action.retry': 'Retry',
  'action.release': 'Release',
  'action.confirm': 'Confirm',
  'action.cancel': 'Cancel',
  'action.toggle-down': 'More options',
  'action.toggle-up': 'Less options',
  'action.batch-operation': 'Batch Operation',
  'action.download': 'Download',
  'action.confirm_download': 'Confirm to Download',
  'action.upload-pod': 'Upload POD',
  'action.preview': 'Preview',
  'action.check-all': 'Check All',
  'actions.select-all': 'Select All',
  'action.insert_qty_from_remain_qty': 'Insert Received Qty from Outstanding Qty',
  'action.send-content-request': 'Send Content Request',
  'action.show-relation': 'Show Relation',
  'action.selection': 'Selection',

  'message.error': 'Error',
  'message.by': 'by',
  'message.update-at': 'at',
  'message.warning': 'Warning message',
  'message.insufficient-permissions': 'Insufficient permissions to access the resources:',
  'message.system-error-message':
    'System Error!! Please contact system support,The following is the error details:',
  'message.error-occur': 'An Error Occurred!!',
  'message.create-success': 'Create Success',
  'message.update-success': 'Update Success',
  'message.delete-success': 'Delete Success',
  'message.please-select': 'Please Select ',
  'message.please-input': 'Please Input ',
  'message.please-select-file': 'Please Select File(s)',
  'message.select-file': 'Select File(s)',
  'message.select-single-file': 'Select File',
  'message.please-select-image': 'Please Select Image',
  'message.select-image': 'Select Image',
  'message.select-pdf': 'Select PDF',
  'message.uploading': 'Uploading',
  'message.start-upload': 'Start Upload',
  'message.valid-date': 'The input is not valid date!',
  'message.invalid-value': 'Invalid value ',
  'message.not_authorized': 'Sorry, you are not authorized to access this page.',
  'message.warning-browser': 'For better use of platform features, Chrome is recommended',
  'message.please-input.note': 'Please Input Note',
  'message.required': '{field} is required',
  'message.file.uploading': 'Uploading files...',
  'message.file.upload_failed': 'Failed to upload files, please try again!',
  'message.weight_gt_than_zero': 'Weight must be greater than 0',
  'message.request.request_permission': 'Request Permission',
  'message.request.pin_code': 'Permission PIN Code',
  'message.sorry': 'Sorry...',
  'switch.on.label': 'ON',
  'switch.off.label': 'OFF',
  'unavailable-choice': 'Choice is not available',
  'input.text-area.max.255': 'Maximum enter 255 characters',
  'ranking.1': 'First',
  'ranking.2': 'Second',
  'ranking.3': 'Third',
  'ranking.4': 'Fourth',
  'label.yes': 'Yes',
  'label.no': 'No',
  'label.need.yes': 'Yes',
  'label.need.no': 'No',
  'items.sort.title': 'Sort Item List',
  'items.sort.desc': 'Drag and drop the product to sort',
  'items.line-no': 'Line No.',
  'items.description': 'Product Description',
  'date.to.label': 'To',
  'button.files': 'Files',
  'form.submit': 'Submit',
  'message.please_input': 'Please Enter ',
  'general.message.updating': 'Updating...',
  'general.message.creating': 'Creating...',
  'wms.oms_company_name': 'Company Name',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...general,
  ...pages,
  ...dashboard,
  ...users,
  ...remark,
  ...userList,
  ...label,
  ...quote,
  ...admin,
};
