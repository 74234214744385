import component from './zh-TW/component';
import globalHeader from './zh-TW/globalHeader';
import menu from './zh-TW/menu';
import pwa from './zh-TW/pwa';
import settingDrawer from './zh-TW/settingDrawer';
import settings from './zh-TW/settings';
import users from './zh-TW/users';
import general from './zh-TW/general';
import pages from './zh-TW/pages';
import dashboard from './zh-TW/dashboard';
import remark from './zh-TW/remark';
import userList from './zh-TW/userList';
import quote from './zh-TW/quote';
import label from './zh-TW/label';
import admin from './zh-TW/admin';

export default {
  'navBar.lang': '語言',
  'layout.user.link.help': '幫助',
  'layout.user.link.privacy': '隱私',
  'layout.user.link.terms': '條款',
  'app.preview.down.block': '下載此頁面到本地項目',
  'form.please-select-status': '請選擇狀態',
  'common.action': '操作',
  'common.created_at': '創建時間',
  'common.updated_at': '修改時間',
  'common.created_by': '創建人',
  'common.updated_by': '修改人',
  'action.create_item': 'Create Item',
  'action.settle': 'Settle',
  'action.add': 'Add',
  'action.show': 'Show',
  'action.submit': '發送',
  'action.save': 'Save',
  'common.record.in_total': '共有 {total} 紀錄',
  'common.user_type.contact': '聯繫',
  'common.user_type.user': '供應商門戶網站用戶',
  'common.status': '狀態',
  'common.status.active': '有效',
  'common.status.inactive': '無效',
  'common.field.expandText': 'Expand',
  'common.field.collapseText': 'Collapse',
  'common.field.selectAllText': 'All',
  'common.field.email': '電郵',
  'common.field.status': '狀態',
  'common.field.active': '有效',
  'common.field.inactive': '無效',
  'common.field.created-at': '創建時間',
  'common.field.released-at': '釋放時間',
  'common.field.shipped-at': '出貨時間',
  'common.field.created-by': '創建人',
  'common.field.updated-at': '修改時間',
  'common.field.updated-by': '修改人',
  'common.field.action': '操作',
  'common.field.action-by': '操作人',
  'common.field.action-at': '操作时间',
  'common.field.from-date': '從日期',
  'common.field.to-date': '至日期',
  'common.field.errors': '錯誤信息',
  'common.filed.pending-for-my-approval': '待我審批',
  'common.filed.pending-for-my-confirmation': '待我確認',
  'common.idle.warning.title': '由於你已有一段時間沒有於系統中進行操作，所以你已被系統登出!',
  'common.idle.warning.content': '',
  'common.filed.rate-only.label': '僅供價',
  'common.loading': '加載中...',
  'common.field.sort': '排序',
  'common.please_enter.primary_name': '請輸入主要名稱',
  'common.please_enter.secondary_name': '請輸入副名',
  'common.please_enter.address': '請輸入地址',
  'common.please_enter.postal_address': '請輸入郵政地址',
  'common.please_enter.email': '請輸入電子郵件',
  'common.please_enter.tel': '請輸入電話',
  'common.please_enter.br': '請輸入br',
  'common.please_enter.a_valid_email': '請輸入有效電子郵件',
  'common.please_enter.at_least_character': '請輸入至少{num}個字符',
  'common.please_enter.finance_code': '請輸入財務代碼',
  'common.please_enter.bank_acc': '請輸入銀行帳號',
  'common.please_enter.contact_person': '請輸入聯繫人',
  'common.please_enter.mobile': '請輸入手機號碼',
  'common.please_enter.website': '請輸入網站',
  'common.please_enter.remark': '請輸入備註',

  'button.preload': '添加',
  'button.yes': '是',
  'button.no': '否',
  'button.submit': '發送',
  'button.submit.now': '現在發送',
  'button.activate': '啟用',
  'button.cancel': '取消',
  'button.complete': '結束',
  'button.search': '查詢',
  'button.delete': '刪除',
  'button.reset': '重置',
  'button.create': '新增',
  'button.update': '更新',
  'button.download': '下載',
  'button.open': '打開',
  'button.close': '關閉',
  'button.exit': '退出',
  'button.edit': '修改',
  'button.clone': '複製',
  'button.save': '儲存',
  'button.update-info': '更新信息',
  'button.confirm': '確認',
  'button.setting.user-role-project': '設置',
  'button.setting.user-role': '設置',
  'button.more-info': '更多信息',
  'button.ok': '確定',
  'button.personal-setting': '個人設置',
  'button.export': '匯出',

  'action.options': '更多操作',
  'action.logout': '登出',
  'action.actions': '操作',
  'action.back': '返回',
  'action.open': '打開',
  'action.create': '新增',
  'action.edit': '修改',
  'action.update': '更新',
  'action.delete': '刪除',
  'action.enable': 'Enable',
  'action.disable': 'Disable',
  'action.upload': '上載',
  'action.batch-delete': '批量刪除',
  'action.retry': '重試',
  'action.release': '釋放',
  'action.confirm': '確定',
  'action.cancel': '取消',
  'action.toggle-down': '更多選項',
  'action.toggle-up': '收起選項',
  'action.batch-operation': '批量操作',
  'action.download': '下載',
  'action.confirm_download': '確定下載',
  'action.upload-pod': '上傳簽收證明檔案',
  'action.preview': '預覽',
  'action.show-relation': '顯示文檔關係',
  'action.check-all': '全選',
  'actions.select-all': '全選',
  'action.insert_qty_from_remain_qty': '複製庫存數量',
  'action.send-content-request': '發送內容請求',
  'action.selection': '選擇區',

  'message.error': '錯誤',
  'message.by': '由',
  'message.update-at': '更新在',
  'message.warning': '警告信息',
  'message.insufficient-permissions': '權限不足，無法訪問資源:',
  'message.system-error-message': '系統錯誤！！， 請聯繫系統支持，以下是錯誤的詳細信息：',
  'message.error-occur': '發生錯誤！！',
  'message.create-success': '創建成功',
  'message.update-success': '更新成功',
  'message.delete-success': '刪除成功',
  'message.please-select': '請選擇',
  'message.please-input': '請輸入',
  'message.please-select-file': '請選擇文件',
  'message.select-file': '選擇文件',
  'message.please-select-image': '請選擇圖片',
  'message.select-image': '選擇圖片',
  'message.select-pdf': '選擇 PDF',
  'message.select-single-file': '選擇檔案',
  'message.uploading': '上載中',
  'message.start-upload': '開始上載',
  'message.valid-date': '輸入的日期無效',
  'message.not_authorized': '對不起，你沒有權限訪問此網頁',
  'message.warning-browser': '為了更好的使用平台功能， 建議使用Chrome瀏覽器',
  'message.please-input.note': '請輸入備註',
  'message.required': '{field}是必需的',
  'message.file.uploading': '正在上傳文件中...',
  'message.file.upload_failed': '文件上傳失敗，請重試!',
  'message.weight_gt_than_zero': 'Weight must be greater than 0',
  'message.request.request_permission': '須要權限',
  'message.request.pin_code': 'PIN 碼',
  'message.sorry': '抱歉...',
  'switch.on.label': '開啟',
  'switch.off.label': '關閉',
  'unavailable-choice': '無法選擇',
  'input.text-area.max.255': '最多輸入255個字符',
  'ranking.1': '首次',
  'ranking.2': '第2次',
  'ranking.3': '第3次',
  'ranking.4': '第4次',
  'label.yes': '是',
  'label.no': '否',
  'label.need.yes': '要',
  'label.need.no': '不要',
  'items.sort.title': '項目排序列表',
  'items.sort.desc': '將項目拖放以進行排序',
  'items.line-no': '行号',
  'items.description': '產品詳細',
  'date.to.label': '至',
  'button.files': '文件',
  'form.submit': 'Submit',
  'message.please_input': 'Please Enter ',
  'general.message.updating': '更新中',
  'general.message.creating': '新增中...',
  'wms.oms_company_name': '客戶',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...general,
  ...pages,
  ...dashboard,
  ...users,
  ...remark,
  ...userList,
  ...label,
  ...quote,
  ...admin,
};
