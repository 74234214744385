export default {
  'account.settings.menuMap.basic': 'User Profile',
  'account.settings.menuMap.password': 'Change Password',
  'account.settings.menuMap.company': 'Company Info',
  'account.settings.basic.email': 'Email',
  'account.settings.basic.displayname': 'Display Name',
  'account.settings.basic.first-name': 'First Name',
  'account.settings.basic.last-name': 'Last Name',
  'account.settings.basic.job_title': 'Job Title',
  'account.settings.basic.office_tel': 'Tel',
  'account.settings.basic.displayname-message': 'Please input your Name',
  'account.settings.basic.short-message': 'Please input your Initial',
  'account.settings.basic.tel': 'Telephone',
  'account.settings.basic.tel-message': 'Please input your phone!',
  'account.settings.basic.job-title': 'Please input your job title!',
  'account.settings.basic.update': 'Update Profile',
  'account.settings.password.old-password': 'Current Password',
  'account.settings.password.old-password-message': 'Please input current password',
  'account.settings.password.new-password': 'New Password',
  'account.settings.password.confirm-password': 'Confirm Password',
  'account.settings.password.update': 'Update Password',
  'account.settings.email.required': 'Please enter your email!',
  'account.settings.email.wrong-format': 'The email address is in the wrong format!',
  'account.settings.userName.required': 'Please enter your userName!',
  'account.settings.password.required': 'password is required',
  'account.settings.password.min.6': 'At least 6 characters long',
  'account.settings.password.one.number': 'At least 1 number',
  'account.settings.password.one.lowercase.letter': 'At least 1 lowercase letter',
  'account.settings.password.one.uppercase.letter': 'At least 1 uppercase letter',
  'account.settings.password.twice': 'The confirm password does not match the password!',
  'company.primary_name': 'Primary Name',
  'company.secondary_name': 'Secondary Name',
  'company.chop': 'Chop',
  'company.logo': 'Logo',
  'company.address': 'Address',
  'company.tel': 'Tel',
  'company.br': 'BR',

  'company.please_enter.primary_name': 'Please enter company primary name',
  'company.please_enter.secondary_name': 'Please enter company secondary name',
  'company.please_enter.address': 'Please enter company address',
};
