export default {
  'marketplace_pick_pack_vas.platform_code': 'Platform Code',
  'marketplace_pick_pack_vas.platform_name': 'Platform Name',
  'marketplace_pick_pack_vas.step_one_name': 'Step 1 Dispaly Name',
  'marketplace_pick_pack_vas.step_one_value': 'Step 1 Value',
  'marketplace_pick_pack_vas.step_two_name': 'Step 2 Dispaly Name',
  'marketplace_pick_pack_vas.step_two_value': 'Step 2 Value',
  'marketplace_pick_pack_vas.step_three_name': 'Step 3 Dispaly Name',
  'marketplace_pick_pack_vas.step_three_value': 'Step 3 Value',
  'marketplace_pick_pack_vas.step_four_name': 'Step 4 Dispaly Name',
  'marketplace_pick_pack_vas.step_four_value': 'Step 4 Value',
  'marketplace_pick_pack_vas.step_five_name': 'Step 5 Dispaly Name',
  'marketplace_pick_pack_vas.step_five_value': 'Step 5 Value',
  'marketplace_pick_pack_vas.vas_name': 'VAS Dispaly Name',
  'marketplace_pick_pack_vas.vas_value': 'VAS Value',
  'marketplace_pick_pack_vas.vas_label_name': 'VAS Dispaly Name (Include Label)',
  'marketplace_pick_pack_vas.vas_label_value': 'VAS Value (Include Label)',
  'marketplace_pick_pack_vas.create': 'Create marketplace Pick & Pack VAS',
  'marketplace_pick_pack_vas.create.fail': 'Fail to create marketplace Pick & Pack VAS',
  'marketplace_pick_pack_vas.create.success':
    'marketplace Pick & Pack VAS has been succesfully created',
  'marketplace_pick_pack_vas.update.fail': 'Fail to update warehouse bin',
  'marketplace_pick_pack_vas.update': 'Update {platform_code} Pick & Pack VAS',
  'marketplace_pick_pack_vas.update.success':
    'marketplace Pick & Pack VAS has been succesfully updated',
  'marketplace_pick_pack_vas.upload_pick_pack_vas': 'Upload To Update Pick & Pack VAS',
};
