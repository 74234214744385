export default {
  'marketplaces.name': 'Marketplace Name',
  'marketplaces.oms_platform': 'Shipracer Platform',
  'marketplaces.payment_gateway': 'Payment Gateway',
  'marketplaces.payment_gateway_commission': 'Payment Gateway Commission',
  'marketplaces.status': 'Status',
  'marketplaces.create': 'Create Marketplaces',
  'marketplaces.create.fail': 'Fail to create marketplaces',
  'marketplaces.create.success': 'Marketplaces has been succesfully created',
  'marketplaces.update.fail': 'Fail to update marketplaces',
  'marketplaces.update': 'Update',
  'marketplaces.update.success': 'Marketplaces has been succesfully updated',
};
