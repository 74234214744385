export default {
  'note-modal.title': '{document_no}的注意事項',
  'note-modal.close': '關',
  'note.creator': '創作者',
  'note.count': '查看備註',
  'note.note': '備註',
  'note.add': '加備註',
  'note.add-document': '新增備註 - {document_no}',
  'note.delete-confirm': '您確定要刪除此備註嗎？',
  'activity.title': '備註和活動',
  'activity.show.label': '顯示',
  'activity.note.label': '備註',
  'activity.notes.label': '備註',
  'activity.history.label': '產品歷史記錄',
  'activity.material.history.label': '物料歷史記錄',

  'place-holder.please-enter-title': '請輸入標題',
  'place-holder.please-display-title': '請輸入顯示標題',
  'file.uploading': '上載中...',
  'file.deleting': '刪除中...',
  'file.downloading': '下載中...',
  'file.download.success': '檔案下載成功',
  'file.loaded.success': '已載入檔案',
  'file.download.fail': '下載檔案失敗',
  'file.update.fail': '更新檔案資料失敗',
  'file.delete.fail': '刪除檔案失敗',
  'file.upload.fail': '上載檔案失敗',
  'file.creator': '創建者',
  'file.action': '操作',
  'file.upload-by': '上載人',
  'file.upload-at': '上載於',
  'file.count': '查看檔案',
  'file.add': '新增檔案',
  'file.edit': '編輯檔案',
  'file-modal.title': '{document_no}的檔案',
  'pod-file-modal.title': '{document_no} 的簽收証明檔案',
  'file-edit.info': '修改 - {file_name}',
  'file-modal.close': '關閉',
  'file.upload.modal.title': '上載檔案',
  'file.title': '檔案文件',
  'file.file': '檔案',
  'file.file-name': '檔案名稱',
  'file.file-type': '檔案類型',
  'file.type.placeholder': '選擇檔案類型',
  'file.remark': '備註',
  'file.attach': '郵件附件',
  'file.attach.validation.remark.empty': '電子郵件附件備註不能為空',
  'file.attach.limit': '文件超過郵箱支持附件文件的大小限制。請縮小附件的大小/數量，然後再次提交。',
  'file.extra-info': '額外的信息',
  'file.label-expiry-date': '到期日',
  'file.hint-required-expiry-date': '請選擇有效期',
  'file.remark.placeholder': '此文件的一般備註',
  'file.upload.leave': '有一些文件正在等待上載，您確定要離開嗎？',
  'file.button.leave': '離開',
  'file.button.stay': '留下',
  'file.title.remove': '如果要刪除此文件，請說明原因。',
  'file.button.remove': '清除',
  'file.validation.select.filetype': '需要為{文件名}選擇文件類型',
  'file.delete-confirm': '您確定要刪除此文件嗎？',
  'item-discount.negative-amount.warning': '產品折扣後不允許金額為負數',
  'common.invalid-discount-reasonable-value': '折扣不能超出範圍',
  'common.input-value-gt-min-val': '最小輸入值必須大於{value}',
  'common.input-value-le-max-val': '最大輸入值不能大於{value}',
  'document.items.order-discount': '折扣',
  'document.items.order-total-amount': '總金額',
  'document.items.order-total-cost': '總成本',
  'document.items.order-total-profit': '總利潤',
  'document.items.order-total-margin': '總差額',
  'document.items-group.add': '添加產品組',
  'document.items-group.add.title': '創建產品組',
  'document.items-group.label': '組別',
  'document.items-group-description.label': '組別描述',
  'document.items-group.product-option.warning':
    '（在組中只有價格最低的產品才會計入QRM成本比較中的報價總額）',
  'document.items-group.update.title': '更新组描述',
  'document.product.group-type.label': '組類型',
  'document.assignee.label': '處理者',

  'document.warning.title': '文檔狀態不是在進行中的狀態，是否是繼續進行操作？',
  'document.button.revise_now': '修改',
  'document.button.cancel': '取消',
  'document.button.preview': '預覽',
  'document.button.download': '下載',
  'document.button.download-files': '下載檔案',
  'document.button.download-pdf': '下載PDF',
  'document.button.download-excel': '下載Excel',
  'document.button.relation': '關聯文檔',
  'document.button.history': '歷史紀錄',
  'document.button.load_all': '載入全部',
  'document.relation': '關聯文檔',
  'document.progress': '進度',
  'document.history': '文檔歷史',
  'document.history.document-no': '文檔編號',
  'document.history.version': '版本',
  'document.history.created-at': '封存日期',
  'document.history.download': '下載',
  'document.go-to': '現在跳到 {document_no}',
  'document.document_no.label': '文檔編號',
  'document.version.label': '版本',
  'document.amount.label': '總金額 (HKD)',
  'document.status': '狀態',
  'document.status.label': '內部狀態',
  'document.project.label': '項目',
  'document.expiry-date.label': '到期日',
  'document.revised_by.label': '修訂人',
  'document.pdf.label': 'Pdf',
  'document.submit_to_contractor_date.label': '向承包商提交日期',
  'document.submit_to_contractor_by.label': '向承包商提交者',
  'document.none.label': '無',
  'document.last-updated-by': '最後更新者',
  'document.last-updated-at': '最後更新時間',
  'document.submit-for-confirmation': '提交確認',
  'document.add-items-before-submit': '提交審核前請先加入產品',
  'document.sap_document_parked': 'SAP文檔編號',
  'document.settlement_at': '結算日期',
  'document.last_updated': '最後更新',
  'document.last_updated.content': '由 { updater } 在 { datetime } 更新',
  'document.document_no': '文檔編號',
  'document.no-selected-item-warning': '請先選擇一些產品進行百分比折扣',

  'add-order-item.form.search': '搜尋編碼或名稱',
  'add-order-item.form.search_placeholder': '你可以根據搜尋结果添加或者添加新的產品',
  'add-order-item.form.search-required-message': '',
  'add-order-item.form.create-product': '新建產品',
  'add-order-item.form.no-products-found-message': '找不到你要添加的產品， 可以新建一個新的產品',
  'add-order-item.form.item_name': '產品名稱',
  'add-order-item.form.item_desc': '產品描述',
  'add-order-item.form.category': '類別',
  'add-order-item.form.unit': '單位',
  'add-order-item.form.supplier-item-code': '供應商編碼',
  'add-order-item.form.supplier': '供應商',
  'add-order-item.form.unit-cost': '單個費用',
  'add-order-item.form.unit-price': '單價',
  'add-order-item.form.unit-price-gt-0': '單價需要大於零',
  'add-order-item.form.markup': '利潤',
  'add-order-item.form.qty': '數量',
  'add-order-item.form.qty-gt-0': '數量需要大於零',
  'add-order-item.form.unit-discount': '單價折扣',
  'add-order-item.form.line-discount': '行總價折扣',
  'add-order-item.form.item-remark': '備註',
  'add-order-item.form.buyer-pr-no': '採購申請單號',
  'add-order-item.table.unit-price': '單價',
  'add-order-item.table.unit-discount': '折扣',
  'add-order-item.table.unit-price-after-discount': '折扣後單價',
  'add-order-item.table.qty': '數量',
  'add-order-item.table.subtotal': '小計',
  'add-order-item.table.line-discount': '產品折扣',
  'add-order-item.table.line-total': '產品金額',
  'add-order-item.table.total_cost': '總成本',
  'add-order-item.table.total_profit': '總利潤',
  'add-order-item.table.total_margin': '總差額',
  'products.unit': '單位',
  'asn.status.canceled': 'Canceled',
  'asn.status.processing': 'Processing',
  'asn.status.confirm': 'Warehouse Received',
  'asn.status.partially': 'Partially Warehouse Received',
  'asn.status.over': 'Over Warehouse Received',
  'common.message.generating': '生成中...',
  'common.client_webhook_status': 'Client Feedback Status',
};
