export default {
  'label.external-transaction-no': 'Trans. Ref.',
  'label.company': 'Company',
  'label.toup-up-type': 'Method',
  'topup-amount-place-holder': 'Please select a top-up amount',
  'topup-type-place-holder': 'Please select a top-up method',
  'topup-payment-proof': 'Payment Receipt',
  'topup-create-title': 'Initiate a Topup',
  'topup-edit-title': 'Edit - {document_no}',
  'topup-confirm-message-title': 'Are you sure you want to confirm this top-up?',
};
