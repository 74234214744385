export default {
  'warehouse_bin_size.name': '架位名稱',
  'warehouse_bin_size.length': '長',
  'warehouse_bin_size.width': '闊',
  'warehouse_bin_size.height': '高',
  'warehouse_bin_size.create': '新增架位體積',
  'warehouse_bin_size.create.fail': '建立架位體積失敗',
  'warehouse_bin_size.create.success': '成功建立新的架位體積',
  'warehouse_bin_size.update.fail': '更新架位體積失敗',
  'warehouse_bin_size.update.success': '成功更新架位體積',
};
