import { Space } from 'antd';
import React from 'react';
import { useModel, SelectLang } from 'umi';
import Avatar from './AvatarDropdown';
import styles from './index.less';
import EnvironmentReminder from './EnvironmentReminder';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  interface LocalData {
    lang: string;
    label?: string;
    icon?: string;
    title?: string;
  }

  return (
    <Space className={className}>
      <EnvironmentReminder />
      <Avatar menu />
      <SelectLang
        className={styles.action}
        postLocalesData={(locales: LocalData[]) => {
          return locales.filter((item) => {
            return true;
            // return item.lang === 'en-US';
          });
        }}
      />
    </Space>
  );
};
export default GlobalHeaderRight;
