export default {
  'outbound_invoice.document_no': 'Invoice No.',
  'outbound_invoice.received_date': 'Invoice Date',
  'outbound_invoice.settled_date': 'Settled Date',
  'outbound_invoice.payment_term': 'Payment Term',
  'outbound_invoice.amount': 'Amount',
  'outbound_invoice.date': 'Date',
  'outbound_invoice.date_from': 'Date From',
  'outbound_invoice.date_to': 'Date To',
  'outbound_invoice.currency_code': 'Currency',
  'outbound_invoice.form.please-select-currency': 'Please Select Currency',
};
