export default {
  'quote.search.title': 'Get Quotes',
  'quote.search': 'Get Quotes',
  'quote.action': 'Action',
  'quote.ship_from_hongkong': 'Ship From Hong Kong',
  'quote.ship_from': 'Ship From',
  'quote.ship_to': 'Ship To',
  'quote.warehouse': 'Warehouse',
  'quote.country': 'Country',
  'quote.country_state': 'Country State',
  'quote.postal_code': 'Postal Code',
  'quote.insurance': 'Insurance',
  'quote.item_category': 'Item Category',
  'quote.dimensions': 'Dimensions',
  'quote.height': 'Height',
  'quote.weight': 'Weight',
  'quote.length': 'Length',
  'quote.width': 'Width',
  'quote.customs_value': 'Customs Value',
  'quote.customs_currency': 'Customs Currency',
  'quote.delivery_time': 'Delivery Time',
  'quote.tracking': 'Tracking',
  'quote.rating': 'Rating',
  'quote.service_channel': 'Service Channel',
  'quote.service_code': 'Service Code',
  'quote.courier': 'Courier',
  'quote.remarks': 'Remark',
  'quote.tax_duty': 'Tax & Duty',
  'quote.freight_rate': 'Freight Rate',
  'quote.total_rate': 'Total Rate',
  'quote.other_fee': 'Other Fee',
  'quote.total_cost': 'Total Cost',
  'quote.message.warehouse': 'Please Select Warehouse',
  'quote.message.ship_to': 'Please Select Ship To',
  'quote.message.weight': 'Please Enter Weight',
  'quote.message.country_state': 'Please Select State',
  'quote.message.insurance': 'Please Select insurance',
  'quote.message.country': 'Please Select Country',
  'quote.message.item_category': 'Please Select Item Category',
  'quote.effective_date': 'Effective Date',
  'quote.effective_from': 'Effective From',
  'quote.effective_to': 'Effective To',
  'fee-trails.company_rates': 'Service {service_code} Company Rate Cards',
  'service-costs.upload-cost.title': 'Upload LME Service Costs',
  'service-costs.upload-cost.title_content':
    'LME system will generate company rate card by admin if you upload the LME service cost',
  'service-costs.upload-location-zone.title': 'Upload LME Service Country Zone',
  'company-services.effective_from': 'Effective From',
  'company-services.effective_to': 'Effective To',
};
