export default {
  'delivery-shippers.shipper_name': 'Shipper Name',
  'delivery-shippers.courier': 'Courier Name',
  'delivery-shippers.name': 'Name',
  'delivery-shippers.address1': 'Address 1',
  'delivery-shippers.address2': 'Address 2',
  'delivery-shippers.warehouse': 'Warehouse',
  'delivery-shippers.shipper_id': 'Shipper ID',
  'delivery-shippers.shipper_account': 'Shipper Account',
  'delivery-shippers.billing_account': 'Billing Account',
  'delivery-shippers.email': 'Email',
  'delivery-shippers.duty_account': 'Duty Account',
  'delivery-shippers.registered_account': 'Registered Account',
  'delivery-shippers.address_line1': 'Address Line1',
  'delivery-shippers.address_line2': 'Address Line2',
  'delivery-shippers.division_code': 'Division Code',
  'delivery-shippers.city': 'City',
  'delivery-shippers.division': 'Division',
  'delivery-shippers.postal_code': 'Postal Code',
  'delivery-shippers.country_code': 'Country Code',
  'delivery-shippers.country_name': 'Country Name',
  'delivery-shippers.contact_person_name': 'Contact Person',
  'delivery-shippers.contact_phone': 'Contact Phone',
  'delivery-shippers.create': 'Create Shipper',
  'delivery-shippers.create.fail': 'Fail to create warehouse',
  'delivery-shippers.create.success': 'Shipper has been succesfully created',
  'delivery-shippers.update.fail': 'Fail to update warehouse',
  'delivery-shippers.update': 'Update',
  'delivery-shippers.update.success': 'Shipper has been succesfully updated',
};
