export default {
  'company_wms_quotation.customer': 'Customer',
  'company_wms_quotation.rents': 'Storage Fee',
  'company_wms_quotation.warehouse': 'Warehouse',
  'company_wms_quotation.effective_from': 'Effective From',
  'company_wms_quotation.effective_to': 'Effective To',
  'company_wms_quotation.version': 'Version',
  'company_wms_quotation.quotation_code': 'Quotation Code',
  'company_wms_quotation.inbound_charges': 'Warehouse Charges(Inbound)',
  'company_wms_quotation.outbound_charges': 'Warehouse Charges(Outbound)',
  'company_wms_quotation.effective_period': 'Effetive Period',
  'company_wms_quotation.currency': 'Currency',
  'company_wms_quotation.status': 'Status',
  'company_wms_quotation.remark': 'Remark',
  'company_wms_quotation.create': 'Create Company WMS Quotation',
  'company_wms_quotation.create.fail': 'Fail to create WMS quotation rent',
  'company_wms_quotation.create.success': 'Company WMS quotation has been succesfully created',
  'company_wms_quotation.update.fail': 'Fail to update company WMS quotation',
  'company_wms_quotation.update': 'Update',
  'company_wms_quotation.update.success': 'Company WMS quotation has been succesfully updated',
  'company_wms_quotation.edit': 'Edit',
  'company_wms_quotation.confirm': 'Confirm',
  'company_wms_quotation.quotation-confirm':
    'Are you sure you want to confirm this {document_no} WMS quotation?',
  'company_wms_quotation.cancel': 'Cancel',
  'company_wms_quotation.quotation-cancel':
    'Are you sure you want to cancel this {document_no} WMS quotation?',
  'company_warehouse_rent.name': 'Name',
  'company_warehouse_rent.customer': 'Customer',
  'company_warehouse_rent.warehouse': 'Warehouse',
  'company_warehouse_rent.warehouse_code': 'Warehouse Code',
  'company_warehouse_rent.free_storage_days': 'Free Storage Days',
  'company_warehouse_rent.warehouse_rent_type': 'Warehouse Rent Type',
  'company_warehouse_rent.storage_fee': 'Storage Fee(Unstackable)',
  'company_warehouse_rent.currency': 'Currency',
  'company_warehouse_rent.create': 'Create Company Warehouse Rent',
  'company_warehouse_rent.create.fail': 'Fail to create company warehouse rent',
  'company_warehouse_rent.create.success': 'Company warehouse rent has been succesfully created',
  'company_warehouse_rent.update.fail': 'Fail to update company warehouse rent',
  'company_warehouse_rent.update': 'Update',
  'company_warehouse_rent.update.success': 'Company warehouse rent has been succesfully updated',
  'company_warehouse_charge.warehouse': 'Warehouse',
  'company_warehouse_charge.charge_name': 'Charge Name',
  'company_warehouse_charge.customer': 'Customer',
  'company_warehouse_charge.warehouse_charge_code': 'WMS Charge Code',
  'company_warehouse_charge.charge_category': 'Charge Category',
  'company_warehouse_charge.unit_price': 'Unit Price',
  'company_warehouse_charge.min_price': 'Min Price',
  'company_warehouse_charge.uom': 'UOM',
  'company_warehouse_charge.currency': 'Currency',
  'company_warehouse_charge.create': 'Create Company Warehouse Charge',
  'company_warehouse_charge.create.fail': 'Fail to create company warehouse charge',
  'company_warehouse_charge.create.success':
    'Company warehouse charge has been succesfully created',
};
