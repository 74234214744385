/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { stringify } from 'qs';
import { getIntl, getLocale, history } from 'umi';
import { Modal, Typography } from 'antd';
import type { ValidateDataType } from '@/components/Common/Validate';
import ValidateWarning from '@/components/Common/Validate';
import ResponseWarning from '@/components/Common/ResponseWarning';
import {
  buildAuthorization,
  checkTokenIsValid,
  getRefreshToken,
  getToken,
  removeToken,
  saveToken,
} from './token';
import { LOCALE } from '@/../config/const';

const {
  ACCOUNT_ACTIVATE_URL,
  ACCOUNT_INVITATION_VERIFY,
  ACCOUNT_INVITATION_CONFIRMATION,
  ACCOUNT_INVITATION_ACCEPTATION,
  LOGIN_URL,
  VERIFY_EMAIL_URL,
  RESET_EMAIL_URL,
  RESEND_REGISTER_EMAIL_URL,
  REFRESH_TOKEN_URL,
} = API;
const { Title } = Typography;

export interface ErrorData {
  errors?: {
    message: string;
    field: string;
    message_type: string;
  }[];
  error?: any;
  validations?: any;
  request_refresh?: boolean;
}

let isRefreshPending = false;

export function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return true;
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    return true;
  }
  return false;
}

export const blockUserActionResponseInterceptor = (response: any) => {
  if (isIE()) {
    sessionStorage.removeItem('blockUserAction');
    Modal.destroyAll();
  } else if (response && sessionStorage.getItem('blockUserAction') === response.url) {
    sessionStorage.removeItem('blockUserAction');
    Modal.destroyAll();
  }
  return response;
};

export const redirectAfterLogin = () => {
  const { pathname } = history.location;
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/login') {
    history.replace({
      pathname: '/login',
      search: stringify({
        redirect: pathname,
      }),
    });
  }
};

// refresh token && update local token
const refreshTokenAndUpdate = async (refreshToken: string) => {
  if (!isRefreshPending) {
    isRefreshPending = true;
    fetch(window.env?.API_URL + REFRESH_TOKEN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    })
      .then((response: { json: () => any }) => response.json())
      .then((json) => {
        isRefreshPending = false;
        if (!json.errors) {
          saveToken(json.data.access_token, json.data.refresh_token);
        } else {
          removeToken();
          redirectAfterLogin();
        }
      });
  }
};

const checkAndRefreshToken = async () => {
  const token = getToken();
  if (token !== '' && !checkTokenIsValid(token)) {
    const refreshToken = getRefreshToken();
    if (checkTokenIsValid(refreshToken)) {
      await refreshTokenAndUpdate(refreshToken);
    } else {
      removeToken();
      redirectAfterLogin();
      return;
    }
  }
  redirectAfterLogin();
};

/**
 * 异常处理程序
 */
export const requestErrorHandler = async (error: { response: Response; data: ErrorData }) => {
  const { response, data } = error;

  blockUserActionResponseInterceptor(response);
  if (response && response.status) {
    const errorText = response.statusText;
    const { status } = response;
    const intl = getIntl(getLocale());

    if (status === 401) {
      checkAndRefreshToken();
    } else if (status === 403) {
      Modal.error({
        content: `${intl.formatMessage({ id: 'message.insufficient-permissions' })} ${
          response.url
        }`,
        maskClosable: false,
        okText: intl.formatMessage({ id: 'button.ok' }),
      });
    } else if (status === 412 || status === 422) {
      if (data.validations) {
        ValidateWarning(data as ValidateDataType);
      } else if (data.errors && data.errors.length > 0) {
        if (!data.request_refresh) {
          ResponseWarning(data);
        }
        return data;
      }
    } else {
      if (data.errors && data.errors.length > 0) {
        ResponseWarning(data);
        return data;
      }
      if (status === 400) {
        if (data.validations) {
          ValidateWarning(data as ValidateDataType);
        } else {
          Modal.error({
            className: 'large-error',
            title: data?.message
              ? data?.message
              : intl.formatMessage({ id: 'message.system-error-message' }),
            okText: intl.formatMessage({ id: 'button.ok' }),
            maskClosable: false,
          });
        }

        return data;
      }
      if (status === 404) {
        if (data.status && data.status === 'error') {
          Modal.error({
            className: 'large-error',
            title: intl.formatMessage({ id: 'message.system-error-message' }),
            content: data?.message,
            maskClosable: false,
            okText: intl.formatMessage({ id: 'button.ok' }),
          });
        } else {
          Modal.error({
            className: 'large-error',
            title: intl.formatMessage({ id: 'message.system-error-message' }),
            content: `Can not found api: ${response.url}`,
            maskClosable: false,
            okText: intl.formatMessage({ id: 'button.ok' }),
          });
        }
        return null;
      }

      if (status >= 500) {
        Modal.error({
          className: 'large-error',
          title: intl.formatMessage({ id: 'message.system-error-message' }),
          content:
            data?.message && data?.message !== ''
              ? data?.message
              : `Call failed api: ${response.url}`,
          maskClosable: false,
          okText: intl.formatMessage({ id: 'button.ok' }),
        });
        return data;
      }

      Modal.error({
        className: 'large-error',
        title: intl.formatMessage({ id: 'message.system-error-message' }),
        content:
          intl.formatMessage(
            { id: 'request.error.with.status.url' },
            { status: response.status, url: response.url },
          ) + errorText,
        maskClosable: false,
        okText: intl.formatMessage({ id: 'button.ok' }),
      });
    }
  }

  return response;
};

const isCheckToken = (url: string): boolean => {
  const noCheckTokenUrlList = [
    ACCOUNT_ACTIVATE_URL,
    ACCOUNT_INVITATION_VERIFY,
    ACCOUNT_INVITATION_CONFIRMATION,
    ACCOUNT_INVITATION_ACCEPTATION,
    LOGIN_URL,
    VERIFY_EMAIL_URL,
    `${VERIFY_EMAIL_URL}/verify`,
    RESET_EMAIL_URL,
    RESEND_REGISTER_EMAIL_URL,
  ];
  const isCheck = noCheckTokenUrlList.indexOf(url) === -1;
  return isCheck;
};

export const addTokenToHeaderInterceptor = (url: string, options: any) => {
  const local = localStorage.getItem(LOCALE) || getLocale() || 'en-US';
  const isCheck = isCheckToken(url);

  if (window.env?.API_URL) {
    // eslint-disable-next-line no-param-reassign
    url = window.env.API_URL + url;
  }
  if (isCheck) {
    return {
      url,
      options: {
        ...options,
        interceptors: true,
        headers: {
          Authorization: buildAuthorization(),
          'Accept-Language': local,
        },
      },
    };
  }
  return {
    url,
    options: {
      ...options,
      headers: {
        'Accept-Language': local,
      },
    },
  };
};
