export default {
  'inventories.search_by_product_type': '以產品類別來檢示',
  'inventories.search_by_zone_product_type': '以區域及產品類別來檢示',
  'inventories.warehouse_zone_code': '區域碼',
  'inventories.product': 'Product',
  'inventories.product_sku': 'SKU',
  'inventories.product_total_qty': '倉庫庫存',
  'inventories.on_hand_stock': '已上架庫存 [On Hand]',
  'inventories.can_order_stock': '可下單庫存 [Available]',
  'inventories.can_allocate_stock': '可配貨庫存 [Available]',
  'inventories.ordered_stock': '新訂單 [New Ordered]',
  'inventories.inspect_stock': '質检中庫存',
  'inventories.hold_stock': '凍結庫存 [Hold]',
  'inventories.damage_stock': '壞貨庫存',
  'inventories.inbound_asn_stock': 'ASN 來貨數量',
  'inventories.outbound_dn_stock': 'DN Stock',
  'inventories.pending_put_away_qty': '待上架庫存',
  'inventories.put_away_qty': '已上架庫存',
  'inventories.pre_pick_stock': '準備揀貨庫存',
  'inventories.picked_stock': '已揀貨庫存',
  'inventories.pre_replenishment_qty': '待補貨庫存 (To AMR)',
  'inventories.back_order_stock': 'Back Order Stock',
  'inventories.download.warning_report': '下載 Dispatched Warning',
  'inventories.download.warning_report.title': '下載 Inventory Dispatch DN Warning Report',
  'inventories.remark': 'Remark',
  'inventories.status': 'Status',
  'inventories.create': 'Create Product',
  'inventories.create.success': 'Product has been succesfully created',
  'inventories.update.fail': 'Fail to update product',
  'inventories.update': 'Update',
  'inventories.update.success': 'Product has been succesfully updated',
  'stocktakes.stock_take_name': '盤點名稱',
  'stocktakes.informed_stocktake': '明盤',
  'stocktakes.blind_stocktake': '盲盤',
  'stocktakes.create_item_by_sku.title':
    '按產品盤點(當一次輸入多個產品編碼時，使用,進行分隔,如sku001,sku002)',
  'stocktakes.create_item_by_bin.title':
    '按貨位盤點(當一次輸入多個貨位編碼時，使用,進行分隔,如B000001F1A,C000002F1A)',
  'stocktakes.stock_take_code': '盤點單號',
  'stocktakes.type': '類別',
  'stocktakes.stock_take_command_type': '盤點指令類型',
  'stocktakes.creation_date': '建立日期',
  'stocktakes.workstation_no': '工作站號碼',
  'stocktakes.first_print_datetime': '首次列印時間',
  'stocktakes.last_print_datetime': '最後列印時間',
  'stocktakes.last_upload_time': '最後上傳時間',
  'stocktakes.confirm_result_time': '確認調整時間',
  'stocktakes.inventory_adjustment_code': '庫存調整單',
  'stocktakes.start_datetime': '開始時間',
  'stocktakes.end_datetime': '結束時間',
  'stocktakes.created_by_username': '創建人',
  'stocktakes.completed_by_username': '盤點完成',
  'stocktakes.uploaded_by_username': '上傳人',
  'stocktakes.confirm_result_by_username': '確認調整',
  'stocktakes.random_stocktake_amount': '隨機盤點數量',
  'stocktakes.remark': '備註',
  'stocktakes.create': '新增盤點單',
  'stocktakes.create.placeholder.bin': '架位 [通配符(*)搜尋]',
  'stocktakes.create.tab.search': '搜尋',
  'stocktakes.create.tab.items': '盤點項目 ({st_item_count})',
  'stocktakes.create.button.copyto': '加入草稿',
  'stocktakes.create.button.unselect-zero-can-allocate': '[取消選擇可以分配數量為零]',
  'stocktakes.create.button.clear-draft': '[清除草稿]',
  'stocktakes.create.button.create-st': '建立盤點單',
  'stocktakes.create.warning.no-item-insert': '請先選擇需要*盤點*的產品',
  'stocktakes.create.warning.pls-input-aisle-range': '請輸入通道範圍',
  'stocktakes.create.warning.pls-input-bay-range': '請輸入間隔範圍',
  'stocktakes.create.message.saved-to-draft': '{diff_count} 記錄已新增至草稿',
  'stocktakes.upload.button.upload-st-result': '上傳盤點結果',
  'stocktakes.upload.title.upload-st-result': '上傳盤點結果',
  'stocktakes.upload.warning.please-select-file-to-upload': '請先選擇excel文件',
  'stocktakes.upload.button.download-result': '下載結果',
  'stocktakes.upload.button.confirm-adjustment': '確認調整',
  'stocktakes.upload.button.complete-stocktake': '完成盤點',
  'stocktakes.upload.confirm.upload-with-cfm-complete': '您確定要完成盤點嗎？',
  'stocktakes.upload.confirm.upload-with-cfm-adjust': '您確定要根據此盤點Excel表格進行庫存調整嗎？',
  'stocktakes.label.warehouse_bin_zone': '區',
  'stocktakes.label.warehouse_bin_aisle_code': '通道',
  'stocktakes.label.warehouse_bin_bay_code': '間隔',
  'stocktakes.label.warehouse_bin_level_code': '層',
  'stocktakes.label.warehouse_bin_position_code': '位',
  'stocktakes.update': '更新盤點單',
  'stocktakes.stock_take_items': '盤點單詳情',
  'stocktakes.adjustments': '調整單',
  'stocktakes.adjustment_items': '調整單詳情',
  'stocktakes.complete-confirm': '真的想完成這個盤點單 - {document_no} 嗎?',
  'stocktakes.cancel-confirm': '真的想取消這個盤點單 - {document_no} 嗎?',
  'stocktakes.details.item_no': 'Item No.',
  'stocktakes.details.sku': 'SKU',
  'stocktakes.details.product_name': 'Product Name',
  'stocktakes.details.system_amount': '系統數量',
  'stocktakes.details.stocktake_amount': '盤點數量',
  'stocktakes.details.packing_spec': 'Packing Spec',
  'stocktakes.details.production_date': '生產日期',
  'stocktakes.details.expiry_date': '到期日期',
  'stocktakes.details.shelf_code': 'Shelf Code',
  'stocktakes.details.shelf_bin_code': 'Shelf Bin Code',
  'stocktakes.details.operator': '操作員',
  'stocktakes.details.sku_level': 'SKU Level',
  'stocktakes.details.amount': 'Amount',
  'inventory-adjustment.id': 'ID',
  'inventory-adjustment.add_adjusted_sku_details': 'Add Adjusted SKU Details',
  'inventory-adjustment.out_adjustment_code': '調整單號',
  'inventory-adjustment.create': '創建調整單',
  'inventory-adjustment.create.success': '成功新增調整單',
  'inventory-adjustment.create.fail': '新增調整單失敗',
  'inventory-adjustment.merchant': '貨主',
  'inventory-adjustment.warehouse': '倉庫',
  'inventory-adjustment.virtual_warehouse': '虛擬倉庫',
  'inventory-adjustment.warehouse_zone': 'Warehouse Zone',
  'inventory-adjustment.on_hand_stock': '已上架庫存 [On Hand]',
  'inventory-adjustment.item.no': 'Item No.',
  'inventory-adjustment.item.id': 'Item Id',
  'inventory-adjustment.product_name': 'Product Name',
  'inventory-adjustment.inventory_qty': 'Inventory Qty',
  'inventory-adjustment.can_allocate_stock': '可分配庫存',
  'inventory-adjustment.require_adjust_qty': '需調整數量',
  'inventory-adjustment.require_adjust_qty.tooltip':
    '如需要加一件, 請輸入1。如需要減一件, 請輸入-1',
  'inventory-adjustment.adjusted_qty': '已調整數量',
  'inventory-adjustment.system_amount': ' 調整前庫存',
  'inventory-adjustment.adjusted_system_amount': ' 調整後庫存',
  'inventory-adjustment.adjustment_reason': '調整原因',
  'inventory-adjustment.adjustment_reason_remark': '調整原因備註',
  'inventory-adjustment.production_date': '生產日期',
  'inventory-adjustment.expiry_date': '過期日期',
  'inventory-adjustment.system_expire_date': 'System Exp Date',
  'inventory-adjustment.production_batch_code': '產品批次號',
  'inventory-adjustment.picking_product_type': '貨品類別定義',
  'inventory-adjustment.warehouse_zone_code': '區域碼',
  'inventory-adjustment.warehouse_bin_code': '架位碼',
  'inventory-adjustment.completed_date_at': '調整時間',
  'inventory-adjustment.completed_by': '確認人',
  'inventory-adjustment.no_amr_inventory_adjustment': '人工區庫存調整',
  'inventory-adjustment.amr_inventory_adjustment': 'AMR區庫存調整',
  'inventory-adjustment.adjustment_code': '調整單號',
  'inventory-adjustment.wms_provider_code': 'WMS Provider Code',
  'inventory-adjustment.stock_take_code': '盤點單號',
  'inventory-adjustment.workstation_no': '工作站',
  'inventory-adjustment.pending_adjust_amount': '待調整數量',
  'inventory-adjustment.adjust_amount': '已調整數量',
  'inventory-adjustment.operator': '操作員',
  'inventory-adjustment.status': '狀態',
  'inventory-adjustment.creation_date': '創建時間',
  'inventory-adjustment.remark': '備註',
  'inventory-adjustment.no_item_warning': '請先添加需要調整的產品',
  'inventory-adjustment.button.complete': '完成调整',
  'inventory-adjustment.complete-confirm': '真的想完成這個庫存調整單 - {document_no} 嗎?',
  'inventory-adjustment.cancel-confirm': '真的想取消這個庫存調整單 - {document_no} 嗎?',
  'stockout_adjustment_item.amount': 'Amount',
  'stockout_adjustment_item.sku_level': 'SKU Level',
  'stockout_adjustment_item.production_date': '生產日期',
  'stockout_adjustment_item.expiry_date': '過期日期',
  'inventory-batch-adjusment.batch_adjustment_code': '特性調整單號',
  'inventory-batch-adjusment.batch_adjustment_type': '特性調整類型',
  'inventory-batch-adjusment.distribution_system': 'Distribution System',
  'inventory-batch-adjusment.manually_designated_bin': 'Manually Designated Bin',
  'inventory-batch-adjusment.remark': '備註',
  'inventory-batch-adjust.status': '狀態',
  'inventory-batch-adjust-item.product_sku': 'Product SKU',
  'inventory-batch-adjust-item.plan_adjustment_num': '計劃調整數量',
  'inventory-batch-adjust-item.actual_adjustment_num': '實際調整數量',
  'inventory-batch-adjust-item.merchant_code': '貨主',
  'inventory-batch-adjust-item.system_expire_date': 'System Expiry Date',
  'inventory-batch-adjust-item.asn_code': '入庫單號',
  'inventory-batch-adjust-item.picking_product_type': '貨品類別定義',
  'inventory-batch-adjust-item.target_system_expire_date': 'Target System Expiry Date',
  'inventory-batch-adjust-item.target_owner_code': 'Target Owner Code',
  'inventory-batch-adjust-item.target_picking_product_type': 'Target 貨品類別定義',
  'inventory-batch-adjust-item.repush-selected-confirm':
    'Are you sure you want to Re-Push selected adjustment item to external WMS?',
  'inventory-batch-adjust-item.repush_selected': 'Re-Push Selected',
  'inventory-batch-adjust-item.api_owner_code': 'API Owner Code',
  'inventory-batch-adjust-item.adjusted_qty': '已調整數量',
  'inventory-batch-adjust-item.exceptions': '調整原因',
  'inventory-batch-adjust-item.production_date': '生產日期',
  'inventory-batch-adjust-item.expiration_date': '過期日期',
  'inventory.tab.inventory': '庫存',
  'inventory.tab.inventory-by-product-type': '庫存 - 類別',
  'inventory.tab.inventory-by-bin': '庫存 - 架位',
  'inventory.tab.stock-take': '人工區 - 盤點',
  'inventory.tab.inventory-adjustment': '庫存 - 調整',
  'inventory.tab.inventory-batch-adjustment': '庫存 - 特性調整',
  'inventory.tab.temp-inventory': '暫存區',
  'inventory.tab.bin-lock-setting': '架位鎖設定',
  'inventory.tab.bin-lock-setting.title': '架位鎖設定',

  'inventory.tab.inventory.title': '總庫存',
  'inventory.tab.inventory-by-product-type.title': '以產品類別來看庫存',
  'inventory.tab.inventory-by-bin.title': '以架位類別來看庫存',
  'inventory.tab.stock-take.title': '人工區 - 盤點',
  'inventory.tab.inventory-adjustment.title': '調整庫存',
  'inventory.tab.inventory-batch-adjustment.title': '調整庫存特性',
  'inventory.tab.temp-inventory.title': '暫存區(已確認分揀 - 待上架)',
  'inventory.move-bin.fail': '庫存轉移架位失敗!',

  'inventories.in_transit_stock': '移動中的庫存',
  'inventories.allocated_stock': '已配單庫存',
  'inventories.show-allocated': '顯示已配單的資料',
  'inventories.delivery_note': '出貨單號',
  'inventories.expected_asn_code': '預期的 ASN',
  'inventories.allocated_qty': '已配單的數量',
  'inventories.allocated_title': '已配單的 {merchant} ({sku})',
  'inventories.create_stock_take.fail': '建立盤點單失敗',
  'inventory.remove.this.line': '要移除這一行嗎?',
  'inventory.adjustment.please-put-adjusted-qty': '請輸入調整數量',
  'inventory.adjustment.please-select-adjustment-reason-code': '請選擇一個調整的理由!',
  'inventory.adjustment.duplication': '這一行已經加入過了!',
};
