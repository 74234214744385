import type { Effect, Reducer } from 'umi';

import type { DeliveryNoteDataType, OutboundConsoleDataType } from './data';
import { getDeliveryNoteDetail, getOutboundConsoleDetail } from './service';

export interface StateType {
  deliveryNote?: DeliveryNoteDataType;
  outboundConsole?: OutboundConsoleDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    get: Effect;
    getConsole: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
    saveConsole: Reducer<StateType>;
    clear: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'outboundModel',
  state: {
    deliveryNote: undefined,
  },
  effects: {
    *get({ payload }, { call, put }) {
      const response = yield call(getDeliveryNoteDetail, payload);
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
    *getConsole({ payload }, { call, put }) {
      const response = yield call(getOutboundConsoleDetail, payload);
      yield put({
        type: 'saveConsole',
        payload: response.data,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        deliveryNote: action.payload,
      };
    },
    saveConsole(state, action) {
      return {
        ...state,
        outboundConsole: action.payload,
      };
    },
    clear() {
      return {
        deliveryNote: undefined,
        outboundConsole: undefined,
      };
    },
  },
};

export default Model;
