import { request } from 'umi';
import type {
  ConfirmPickedDeliveryNoteParams,
  DeliveryNoteItemTableListParams,
  OutboundPickItemTableListParams,
  DeliveryNoteTableListParams,
  DispatchReportTableListParams,
  WmsOutboundPickTableListParams,
  UserPickOutboundLogItem,
  WmsAmrPickingContainerTableListParams,
  OutboundConsoleTableListParams,
  OutboundConsoleOrderTableListParams,
  CreateOutboundConsoleFormValueType,
  ConfirmPackedDeliveryNoteParams,
  ConfirmPickedContainerItemParams,
  ConfirmPickedNoContainerItemParams,
  ReturnToCreateOutboundReturnFormType,
  WarehouseInfo,
  GenerateManifestRequest,
} from './data';
import type { DispatchConsoleFormValueType } from './components/console/DispatchConsoleForm';

const {
  JOR_A_WMS_DELIVERY_NOTE_URL,
  JOR_A_WMS_DELIVERY_NOTE_ITEM_URL,
  JOR_A_WMS_OUTBOUND_PICK_ITEM_URL,
  JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL,
  JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL,
  JOR_A_WMS_AMR_PICK_CONTAINER_URL,
  JOR_A_WMS_OUTBOUND_CONSOLE_URL,
  JOR_A_WMS_OUTBOUND_CONSOLE_ORDER_URL,
  JOR_A_WMS_OUTBOUND_WAVE_ORDER_URL,
  JOR_A_WMS_OUTBOUND_PICK_WORK_URL,
  JOR_A_WMS_OUTBOUND_CONTAINER_BOX_URL,
  JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL,
  JOR_A_WMS_CARRIER_URL,
} = API;

export async function getDeliveryNotes(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getNonAmrDeliveryNotes(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/nonamr`, {
    method: 'GET',
    params,
  });
}

export async function getNewDeliveryNotes(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/new`, {
    method: 'GET',
    params,
  });
}

export async function getAmrWmsOutboundPickOrders(
  params: WmsOutboundPickTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getDeliveryNoteOptions() {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/options`, {
    method: 'GET',
  });
}

export async function getDeliveryNoteDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getDeliveryNoteItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/items`, {
    method: 'GET',
  });
}

export async function immedateDispatchDN(params: {
  warehouse_id: number;
  oms_company_id: number;
  delivery_note_code_or_tracking: string;
  dispatched_pallet_id: string;
  wms_carrier_code: string;
  cutoff_time: string;
  previous_cutoff_time: string;
  dispatch_type: string;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/immediate/dispatch`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function dispatchShippingDeliveryNotes(params: {
  delivery_note_ids: number[];
  dispatched_pallet_id?: string;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/dispatch/shipping`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getCountPendingDispatchDeliveryNotes(params: { wms_carrier_code: string }) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/count/pending/dispatch`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updatePackedOutboundWeightDimension(params: {
  delivery_note_id: number;
  packed_weight: number;
  packed_length?: number;
  packed_width?: number;
  packed_height?: number;
  outbound_carton_no?: number;
  tracking_no?: string;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/update/packed/weight`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function confirmPickedAndPackedDeliveryNote(params: ConfirmPickedDeliveryNoteParams) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/confirm/pick/and/pack`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function confirmPickedContainerItem(params: ConfirmPickedContainerItemParams) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/confirm/picked/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function confirmPickedItemWithoutContainer(
  params: ConfirmPickedNoContainerItemParams,
) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/confirm/picked/scan/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function confirmPackedDeliveryNote(params: ConfirmPackedDeliveryNoteParams) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/confirm/packed`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkReprintAmrPackedOutbound(scanCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/packed/check/${scanCode}`, {
    method: 'GET',
  });
}

export async function adminCancelDeliveryNote(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/cancel/order`, {
    method: 'POST',
  });
}

export async function adminReturnDeliveryNote(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/return/order`, {
    method: 'POST',
  });
}

export async function checkPendingCancelOutboundProducts(code: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/check/pending/cancel/${code}`, {
    method: 'GET',
  });
}

//checkPendingCancelOutboundProducts
// export async function getOutboundReturnItemsByCode(params: { code: string }) {
//   const { code } = params;
//   return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${code}/picked/verify/return`, {
//     method: 'GET',
//   });
// }

export async function releaseDeliveryNoteToPickingList(params: {
  ids: number[];
  warehouse_id: number;
  warehouse_zone_ids?: number[];
  amr_zone_id?: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/release`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function releaseAllDeliveryNoteToPickingList(params: {
  warehouse_id: number;
  warehouse_zone_ids?: number[];
  amr_zone_id?: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/release/warehouse`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function pushDeliveryNoteToExternalWarehouse(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/push/warehouse`, {
    method: 'POST',
  });
}

export async function pushAllDeliveryNoteToExternalWarehouse() {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/all/push/warehouse`, {
    method: 'POST',
  });
}

export async function downloadDeliveryNotePDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsDeliveryNoteWaybill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/download/waybill`, {
    method: 'GET',
    responseType: 'blob',
  });
}

// export async function directDownloadPdfByUrl(params: { url: string }) {
//   const { url } = params;
//   return request(`${url}`, {
//     method: 'GET',
//     responseType: 'blob',
//   });
// }

export async function downloadJorWmsDeliveryNoteInvoice(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/download/invoice`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsDeliveryClientDgfPdf(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/download/client/dgf/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsClientDgCartonLabel(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/download/client/dg/carton/label`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsClientDnPdf(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${id}/download/client/dn/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getNewOrderDelieryNoteItems(
  params: DeliveryNoteItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_ITEM_URL}/neworder`, {
    method: 'GET',
    params,
  });
}

export async function getPickingWorkCreatedOutboundItems(
  params: DeliveryNoteItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_ITEM_URL}/picking/work/created`, {
    method: 'GET',
    params,
  });
}

export async function getPickedDelieryNoteItems(
  params: DeliveryNoteItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_ITEM_URL}/picked`, {
    method: 'GET',
    params,
  });
}

export async function getOutboundPickItemingList(
  params: OutboundPickItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_ITEM_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getCarrierListWithCutoffTime() {
  return request(`${JOR_A_WMS_CARRIER_URL}/with/cutoff`, {
    method: 'GET',
  });
}

export async function getCarrierListForOption() {
  return request(`${JOR_A_WMS_CARRIER_URL}/for/option`, {
    method: 'GET',
  });
}

export async function scanPicklistCode(picklistCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_ITEM_URL}/picklist/${picklistCode}`, {
    method: 'GET',
  });
}

export async function scanPacklistCode(deliveryNoteCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_ITEM_URL}/packlist/${deliveryNoteCode}`, {
    method: 'GET',
  });
}

export async function scanWmsDnCodeOrTrackingNo(params: {
  platform_code: string;
  wms_carrier_code: string;
  dn_or_tracking_no: string;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/check/dispatch`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function exportToWarehouseItems(warehouseId: number) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/export/warehouse/${warehouseId}/items`, {
    method: 'GET',
    responseType: 'blob',
    getResponse: true,
  });
}

export async function exportToWarehouseItemsSummary(warehouseId: number) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/export/warehouse/${warehouseId}/items/summary`, {
    method: 'GET',
    responseType: 'blob',
    getResponse: true,
  });
}

export async function dispatchB2BDeliveryNote(params: {
  delivery_note_id: number;
  tracking_no: string;
  booking_no: string;
  truck_detail: string;
  remark: string;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/dispatch/delivery`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateB2BDeliveryNoteTrackingInfo(params: {
  delivery_note_id: number;
  tracking_no: string;
  booking_no: string;
  truck_detail: string;
  remark: string;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/update/tracking/info`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkDeliveryNoteReadyToPack(deliveryNoteCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/pack/check/${deliveryNoteCode}`, {
    method: 'GET',
  });
}

export async function checkDeliveryNoteReadyToPickAndPack(deliveryNoteCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/pickpack/check/${deliveryNoteCode}`, {
    method: 'GET',
  });
}

export async function checkDeliveryNotePackedWaybill(deliveryNoteCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/pack/waybill/check/${deliveryNoteCode}`, {
    method: 'GET',
  });
}

export async function verifyDeliveryNoteDesktopPrinting(deliveryNoteCode: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/reprint/check/${deliveryNoteCode}`, {
    method: 'GET',
  });
}

export async function readyToPackDeliveryNote(params: {
  delivery_note_id: number;
  tracking_no: string;
  bar_code: string;
  packed_weight?: number;
  packed_length?: number;
  packed_width?: number;
  packed_height?: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/ready/tracking/pack`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getUploadedDispatchReports(
  params: DispatchReportTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getDispatchReporOptions() {
  return request(`${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/options`, {
    method: 'GET',
  });
}

export async function downloadDispatchReportTemplate() {
  return request(`${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/download/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function uploadDispatchedReport(formData: FormData) {
  return request(`${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/upload`, {
    method: 'POST',
    data: formData,
  });
}

export async function confirmDispatchReportToShipping(params: { dispatchReportId: number }) {
  const { dispatchReportId } = params;
  return request(
    `${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/confirm/${dispatchReportId}/dispatch`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function confirmToUpdatePackWeightProcess(params: {
  delivery_note_id: number;
  packed_weight: number;
  packed_length?: number;
  packed_width?: number;
  packed_height?: number;
  outbound_carton_no?: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/update/outbound/weight`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function holdDispatchReportItem(
  dispatchReportId: number,
  params: { delivery_note_code: string },
) {
  return request(`${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/hold/${dispatchReportId}/dispatch`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function recoveryDispatchReportItem(
  dispatchReportId: number,
  params: { delivery_note_code: string },
) {
  return request(
    `${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/recovery/${dispatchReportId}/dispatch`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function getDispatchReportItems(params: { dispatchReportId: number }) {
  const { dispatchReportId } = params;
  return request(`${JOR_A_WMS_INTERFACE_DISPATCH_REPORT_URL}/${dispatchReportId}/items`, {
    method: 'GET',
  });
}

export async function getDeliveryNoteShippedSerialNumbers(params: { deliveryNoteId: number }) {
  const { deliveryNoteId } = params;
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/${deliveryNoteId}/shipped/serial/numbers`, {
    method: 'GET',
  });
}

export async function getSavedUserOutboundPackLogs(delvieryNoteCode: string) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_ITEM_URL}/save/${delvieryNoteCode}/pack/logs`, {
    method: 'GET',
  });
}

export async function saveUserOutboundPackLogs(params: {
  delivery_note_code: string;
  picked_items: UserPickOutboundLogItem[];
}) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_ITEM_URL}/save/pack/logs`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function releaseAmrOutboundOrderToPickingList(params: { ids: number[] }) {
  return request(`${JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL}/release`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function releaseAllAmrOutboundToPickingList(warehouseId: number) {
  return request(`${JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL}/release/warehouse/${warehouseId}`, {
    method: 'POST',
  });
}

export async function scanWmsAmrOutboundContainerCode(containerCode: string) {
  return request(`${JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL}/${containerCode}/packlist`, {
    method: 'GET',
  });
}

export async function downloadContainerDeliveryNotePDF(params: { container_code: string }) {
  const { container_code } = params;
  return request(`${JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL}/download/packlist/${container_code}`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadAmrDeliveryNotePDF(params: {
  container_code: string;
  delivery_note_id: number;
}) {
  const { delivery_note_id, container_code } = params;
  return request(
    `${JOR_A_WMS_AMR_OUTBOUND_PICK_ORDER_URL}/download/dn/${delivery_note_id}/${container_code}`,
    {
      method: 'GET',
      responseType: 'blob',
    },
  );
}

export async function getAmrPickingContainers(
  params: WmsAmrPickingContainerTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_AMR_PICK_CONTAINER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getAmrPickingContainerOptions() {
  return request(`${JOR_A_WMS_AMR_PICK_CONTAINER_URL}/options`, {
    method: 'GET',
  });
}

export async function getAmrPickingContainerItems(
  id: number,
  params: WmsAmrPickingContainerTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_AMR_PICK_CONTAINER_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function releaseAmrPickingContainer(params: { ids: number[] }) {
  return request(`${JOR_A_WMS_AMR_PICK_CONTAINER_URL}/release`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function reconfirmAmrPickingContainer(params: { id: number }) {
  return request(`${JOR_A_WMS_AMR_PICK_CONTAINER_URL}/reconfirm`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getOutboundConsoles(params: OutboundConsoleTableListParams | undefined) {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}`, {
    method: 'GET',
    params,
  });
}

export async function fetchAllOutboundConsoleOrders(
  params: OutboundConsoleOrderTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getOutboundConsoleOptions() {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/options`, {
    method: 'GET',
  });
}

export async function getOutboundConsoleDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getOutboundConsoleOrders(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/${id}/orders`, {
    method: 'GET',
  });
}

export async function checkDispatchByCodeOrTrackingNo(codeOrTrackingNo: string) {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/check/dispatch/${codeOrTrackingNo}`, {
    method: 'GET',
  });
}

export async function createOutboundConsole(params: CreateOutboundConsoleFormValueType) {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function downloadOutboundConsolePDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function dispatchOutboundConsole(params: DispatchConsoleFormValueType) {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/dispatch`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function adminCancelOutboundConsole(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function adminCancelOutboundConsoleOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_ORDER_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function downloadJorWmsOutboundConsoleWaybill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/${id}/download/waybill`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsOutboundConsoleInvoice(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/${id}/download/invoice`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function checkDeliveryNoteCodeToConsole(params: { note_code_or_tracking_no: string }) {
  return request(`${JOR_A_WMS_OUTBOUND_CONSOLE_URL}/check/available/console`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkOutboundContainerCode(params: { container_box_code: string }) {
  return request(`${JOR_A_WMS_OUTBOUND_CONTAINER_BOX_URL}/check/code`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getAmrOutboundPickedOrderItems(
  deliveryNoteCode: string,
  params: DeliveryNoteTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_AMR_PICK_CONTAINER_URL}/picked/${deliveryNoteCode}/items`, {
    method: 'GET',
    params,
  });
}

export async function verifyOutboundPackedWeightByCarrier(params: {
  delivery_note_id: number;
  packed_weight: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/check/carrier/weight`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getOutboundWaveOrders(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_OUTBOUND_WAVE_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getOutboundPickWorks(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_WORK_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getOutboundPickWorkOptions() {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_WORK_URL}/options`, {
    method: 'GET',
  });
}

export async function getOutboundPickWorkItems(
  id: number,
  params: DeliveryNoteTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_WORK_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function printOutboundPickWorkDn(params: { outbound_pick_work_ids: number[] }) {
  return request(`${JOR_A_WMS_OUTBOUND_PICK_WORK_URL}/print/delivery/note/pdf`, {
    method: 'POST',
    responseType: 'blob',
    data: {
      ...params,
    },
  });
}

export async function printNewPicklistByWarehouse(warehouseId: number) {
  return request(
    `${JOR_A_WMS_OUTBOUND_PICK_WORK_URL}/download/warehouse/${warehouseId}/newpicklist`,
    {
      method: 'GET',
      responseType: 'blob',
    },
  );
}

export async function exportToWarehouseNewPicklistItems(warehouseId: number) {
  return request(
    `${JOR_A_WMS_OUTBOUND_PICK_WORK_URL}/export/warehouse/${warehouseId}/prepick/items`,
    {
      method: 'GET',
      responseType: 'blob',
      getResponse: true,
    },
  );
}

export async function getTransferPendingOutbounds(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/pending/outbounds`, {
    method: 'GET',
    params,
  });
}

export async function getOutboundStatics() {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/outbound/statistic`, {
    method: 'GET',
  });
}

export async function createOutboundReturnOrderToCancel(
  params: ReturnToCreateOutboundReturnFormType,
) {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function scanAvailablePutAwayReturnByContainer(params: {
  container_box_code: string;
  zone_or_bin_id: number;
}) {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}/check/putaway/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function submitReturnToPutAwayContainer(params: {
  container_box_codes: string[];
  zone_or_bin_id: number;
}) {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}/putaway/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getOutboundReturnOrders(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getDnBeforeCutoff(params: {
  warehouse_id: number;
  oms_company_id: number;
  wms_carrier_id: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/orders/before/cutoff`, {
    method: 'GET',
    params,
  });
}

export async function getOutboundReturnOrderOptions() {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function printOutboundReturnOrderCartonLabel(params: {
  outbound_return_order_ids: number[];
}) {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}/print/carton`, {
    method: 'POST',
    responseType: 'blob',
    data: {
      ...params,
    },
  });
}

export async function updateDeliveryNoteWhInfo(params: WarehouseInfo) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/update/warehouse/info`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getOutboundReturnOrderItems(
  id: number,
  params: DeliveryNoteTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_OUTBOUND_RETURN_ORDER_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function generateManifest(params: GenerateManifestRequest) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/generate/manifest`, {
    method: 'POST',
    data: {
      ...params,
    },
    getResponse: true,
    responseType: 'blob',
  });
}

export async function updatePackedInfo(params: {
  delivery_note_id: number;
  packed_weight: number;
  packed_length?: number;
  packed_width?: number;
  packed_height?: number;
}) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/update/packed/info`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function resetDeliveryNoteWebhookToPending(dnId: number) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/reset/dn/webhook/pending/${dnId}`, {
    method: 'POST',
  });
}
