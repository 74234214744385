import _ from 'lodash';
import atob from 'atob';
import { REFRESH_TOKEN_NAME, TOKEN_NAME, TOKEN_PAYLOAD_NAME } from '@/../config/const';

export interface LoginUser {
  admin?: boolean;
  exp?: number;
  iat?: number;
  scp?: string[];
  sub?: string;
  client?: string;
  uid: number;
  user_type?: string;
}

export interface Client {
  client_id: number;
}

export function saveToken(token: string, refreshToken: string): void {
  if (token) {
    localStorage.setItem(TOKEN_NAME, token);
    localStorage.setItem(TOKEN_PAYLOAD_NAME, JSON.stringify(parseToken(token)));
  }
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  }
}

export function removeToken(): void {
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  localStorage.removeItem(TOKEN_PAYLOAD_NAME);
  localStorage.removeItem('antd-pro-authority');
  sessionStorage.clear();
}

export function getCurrentUser(): LoginUser {
  const payload = localStorage.getItem(TOKEN_PAYLOAD_NAME) || '';
  if (payload !== '') {
    return JSON.parse(payload);
  }
  return { uid: 0 };
}

export function isInternalUser(): boolean {
  return (getCurrentUser()?.user_type || '') === 'internal_user';
}

export function getClient(): Client {
  const user = getCurrentUser();
  if (user.uid > 0 && user.client) {
    return JSON.parse(atob(user.client));
  }
  return {
    client_id: -1,
  };
}

export function getScopes(): string[] {
  const tokenObj = parseToken(getToken());
  const scopes = tokenObj && tokenObj.scp ? tokenObj.scp : [];
  return scopes.filter((s: string, i: number) => s !== '' && scopes.indexOf(s) === i);
}

export function getTokenType(): string {
  const tokenObj = parseToken(getToken());
  return tokenObj.type;
}

export function getToken(): string {
  return localStorage.getItem(TOKEN_NAME) || '';
}

export function parseToken(token: string) {
  try {
    const arr = token !== '' ? token.split('.') : [];
    return arr.length === 3 ? JSON.parse(atob(arr[1])) : {};
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function checkToken(token: string): boolean {
  try {
    return !_.isEmpty(parseToken(token));
  } catch (error) {
    removeToken();
    return false;
  }
}

export function checkTokenIsValid(token: string): boolean {
  try {
    if (checkToken(token)) {
      const tokenObj = parseToken(token);
      // 10000 only for Test
      if (tokenObj.exp > new Date().getTime() / 1000 + 300) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function getRefreshToken(): string {
  return localStorage.getItem(REFRESH_TOKEN_NAME) || '';
}

export function getAuthoritiesFromToken(token: string): string[] {
  const decodedToken = parseToken(token);
  const permissions: string[] = decodedToken.scp ? decodedToken.scp : [];
  return permissions;
}

export function buildAuthorization(): string {
  const token = getToken();
  return token !== '' ? `Bearer ${token}` : '';
}

export function isResourceOwner(createdBy: string | undefined | number): boolean {
  const user = getCurrentUser();

  if (!createdBy || !user) {
    return false;
  }

  if (user.uid === (createdBy ? Number(createdBy) : '')) {
    return true;
  }

  return false;
}

export function enableFullPickingMethod() {
  sessionStorage.setItem('picking_method', 'full');
}

export function disableFullPickingMethod() {
  sessionStorage.setItem('picking_method', 'piece');
}

export function checkSessionPickingMethod(): boolean {
  const pickingMethod = sessionStorage.getItem('picking_method');
  if (pickingMethod && pickingMethod === 'full') {
    return true;
  }
  return false;
}
