import { request } from 'umi';
import type { DisableSupplierCostParam, ProductSupplierCostTableListParams } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';

const { LME_PRODUCT_SUPPLIER_COST_URL } = API;

export async function getProductSupplierCosts(
  params: ProductSupplierCostTableListParams | undefined,
) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getProductSupplierCostsByProductId(params: { productId: number }) {
  const { productId } = params;

  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/product/${productId}`, {
    method: 'GET',
  });
}

export async function getProductSupplierCostEditOption(id: number) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}/edit`, {
    method: 'GET',
  });
}

export async function getProductSupplierCostCreateOption() {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/create`, {
    method: 'GET',
  });
}

export async function getProductSupplierCostDetail(id: number) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createProductSupplierCost(params: CreateFormValueType) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateProductSupplierCost(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function downloadProductSupplierCostTemplate() {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/download/cost/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function uploadProductSupplierCosts(formData: FormData) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/upload/costs`, {
    method: 'POST',
    data: formData,
  });
}

export async function getProductSupplierCostHistory(id: number) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}/history`, {
    method: 'GET',
  });
}

export async function disableSupplierCost(params: DisableSupplierCostParam) {
  const { id, ...rest } = params;

  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}/disable`, {
    method: 'POST',
    data: {
      ...rest,
    },
  });
}
export async function disableSupplierCostCheck(id: number) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}/disable/check`, {
    method: 'POST',
  });
}

export async function enableSupplierCost(id: number) {
  return request(`${LME_PRODUCT_SUPPLIER_COST_URL}/${id}/enable`, {
    method: 'POST',
  });
}
