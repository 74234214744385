export default {
  'warehouse_zone.warehouse_zone_name': 'Zone Name',
  'warehouse_zone.warehouse_zone_code': 'Zone Code',
  'warehouse_zone.zone_property': 'Zone Property',
  'warehouse_zone.zone_type': 'Zone Type',
  'warehouse_zone.picking_priority': 'Picking Priority',
  'warehouse_zone.create': 'Create Warehouse zone',
  'warehouse_zone.create.fail': 'Fail to create warehouse zone',
  'warehouse_zone.create.success': 'Warehouse zone has been succesfully created',
  'warehouse_zone.update.fail': 'Fail to update warehouse zone',
  'warehouse_zone.update.success': 'Warehouse zone has been succesfully updated',
  'warehouse_zone.download_selected': 'Downlaod selected',
  'warehouse_zone.download-selected-confirm':
    'Are you sure you want to download selected warehouse zone barcode?',
};
