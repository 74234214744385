export default {
  'label.document-number': 'Doc No.',
  'label.pending.new': 'Create Single Shipment',
  'label.pending.upload': 'Create Multi Shipments',
  'label.create.title': 'Create Single Shipment',
  'label.create.title_content': 'Enter the full infomation you are sending your items to',
  'label.create_multi.title': 'Create Multi Shipment',
  'label.create_multi.title_content': 'Please upload the shipment details',
  'label.create.basic-info': 'Basic Information',
  'label.create.receiver-info': 'Receiver Information',
  'label.create.declared-info': 'Declared Information',
  'label.create.item.title': 'Add Declared Item',
  'label.edit.item.title': 'Edit Declared Item',
  'label.id': 'ID',
  'label.courier': 'Courier',
  'label.wms_outbound': 'Dispatch From W/H',
  'label.wms_merchant': 'Merchant',
  'label.wms_warehouse': 'Warehouse',
  'label.wms_warehouse_code': 'Warehouse Code',
  'label.wms_merchant_code': 'Merchant Code',
  'label.wms_delivery_note_code': 'DN No.',
  'label.wms_preorder.delete-confirm': 'Are you sure to remove this order?',
  'label.cancel-wms-preorder': 'Remove Pre Order',
  'label.service_name': 'Service Name',
  'label.service_code': 'Service Code',
  'label.service_type': 'Service Type',
  'label.self_pick_location_code': 'Self Pick (Store / LOCKER)',
  'label.sr_direct_line': 'SR Direct Line',
  'label.sr_tracking_no': 'SR Tracking No.',
  'label.reference_no': 'Reference No.',
  'label.vat_number': 'VAT Number',
  'label.ioss_number': 'IOSS Number',
  'label.eori_number': 'EORI Number',
  'label.po_number': 'Po Number',
  'label.invoice_number': 'Invoice Number',
  'label.department_reference': 'Dept Ref No.',
  'label.duty_third_party_account': 'D/T: O',
  'label.company': 'Company',
  'label.address_book': 'Saved Addresses',
  'label.email': '電郵',
  'label.shipment_content': 'Shipment Content',
  'label.delivery_name': 'Delivery Name',
  'label.all_address': 'Address',
  'label.address': 'Address Line 1',
  'label.address1': 'Address1 Line 2',
  'label.address2': 'Address2 Line 3',
  'label.country': 'Country',
  'label.district': 'District',
  'label.city': 'City',
  'label.state': 'State',
  'label.postal': 'Postal',
  'label.phone': 'Phone',
  'label.create.shipper-info': 'Shipper Info',
  'label.shipper_name': 'Shipper Name',
  'label.shipper_company': 'Shipper Company',
  'label.shipper_email': 'Shipper E-mail',
  'label.shipper_all_address': 'Shipper Address',
  'label.shipper_address': 'Shipper Address Line 1',
  'label.shipper_address1': 'Shipper Address Line 2',
  'label.shipper_address2': 'Shipper Address Line 3',
  'label.shipper_country': 'Shipper Country',
  'label.shipper_district': 'Shipper District',
  'label.shipper_city': 'Shipper City',
  'label.shipper_state': 'Shipper State',
  'label.shipper_postal': 'Shipper Postal',
  'label.shipper_phone': 'Shipper Phone',
  'label.battery': 'Battery',
  'label.product_type': 'Product Type',
  'label.package_num': 'Package Num',
  'label.courier_reference_id': 'Courier Reference ID',
  'label.incoterm': 'Incoterm',
  'label.box': 'Box',
  'label.message.box': 'Box',
  'label.create.package-title': 'Enter your package information',
  'label.package-info': 'Package information',
  'label.declared_value': 'Declared Value',
  'label.declared_currency': 'Declared Currency',
  'label.insurance': 'Insurance',
  'label.package_no': 'Package No.',
  'label.insurance_value': 'Insurance Value',
  'label.insurance_currency': 'Insurance Currency',
  'label.product_code': 'Product Code',
  'label.weight': 'Weight',
  'label.weight_unit': 'Weight Unit',
  'label.height': 'Height',
  'label.width': 'Width',
  'label.dimension_uom': 'Dimension Uom',
  'label.length': 'Length',
  'label.remark': '備註',
  'label.warehouse_remark': '倉庫備註',
  'label.sku': 'SKU',
  'label.sku_asn': 'SKU / ASN',
  'label.product_name': 'Product Name',
  'label.request_asn_code': 'Request ASN / RMA',
  'label.product_weight': 'Product Weight',
  'label.battery_type': 'Battery',
  'label.description_export': 'Description Export',
  'label.description_import': 'Description Import',
  'label.country_of_origin': 'Origin',
  'label.quantity': 'Quantity',
  'label.request_qty': 'Request Qty',
  'label.item_declared_value': 'Declared Value(Per Item)',
  'label.hscode': 'HsCode',
  'label.hsdescription': 'HsDescription',
  'label.estimated-volume': 'Estimated Volume',
  'label.action': 'Action',
  'label.upload.title': 'Upload To Create Multi Shipments',
  'label.upload.title_content': 'Enter the full infomation you are sending your items to',
  'label.upload.file': 'Upload File',
  'label.batch_request_id': 'Batch Id',
  'label.batch_request_number': 'Batch Number',
  'label.waybill_number': 'Waybill Number',
  'label.barcode': 'Barcode',
  'label.tracking_no': 'Tracking No.',
  'label.return_tracking_no': 'Return Tracking No.',
  'label.ship_date': 'Ship Date',
  'label.upload_date': 'Uploaded Date',
  'label.pending.title': 'Pending Label',
  'label.rejected.title': 'Rejected Label',
  'label.to-download.title': 'Download Label',
  'label.wms-shipped.title': 'Shipped (WMS)',
  'label.wms-in-progress.title': 'In Progress (WMS)',
  'label.wms-preorder.title': 'Send Delivery Order',
  'label.pieces': 'Pieces',
  'label.search': 'Search',
  'label.from_date': 'Created From',
  'label.to_date': 'Created To',
  'label.reset': 'Reset',
  'label.view': 'View',
  'label.edit': 'Edit',
  'label.edit.title': 'Edit Label Detail',
  'label.collapse': 'Collapse',
  'label.system_status': 'LME system status',
  'label.status': 'Status',
  'label.api_failed_message': 'Failed Message',
  'label.print_label': 'Print Label',
  'label.pick_up': 'Picked Up',
  'label.batch_print': 'Print By Batch',
  'label.download_max_tooltips': 'System Can only support download Max 2000 items',
  'label.button.download_delivery_summary': 'Download Summary',
  'label.confirm.download_delivery_summary': 'Are you sure you want to download the delivery summary by your filter parameter?',
  'label.button.download_shipment_trace': 'Download Trace',
  'label.confirm.download_shipment_trace': 'Are you sure you want to download the shipment trace summary by your filter parameter?',
  'label.batch_download': 'Batch Summary',
  'label.batch_print_waybill': 'Waybill By Batch',
  'label.batch_print_invoice': 'Invoice By Batch',
  'label.download_pdf': 'Download PDF',
  'label.upload-to-download': 'Selected Download',
  'label.upload-to-download-waybill': 'Waybill By Excel',
  'label.upload-to-download-invoice': 'Invoice By Excel',
  'label.upload_to_download.waybill-title': 'Selected Download Waybill',
  'label.batch_to_download.waybill-title': 'Batch Print Waybill',
  'label.upload_to_download.invoice-title': 'Selected Download Invoice',
  'label.batch_to_download.invoice-title': 'Batch Print Invoice',
  'label.batch_to_download.excel': 'Batch To Download Excel',
  'label.to_download.batch_number': 'To Download By Batch',
  'label.message.batch_number': 'Please Select Batch',
  'label.download_order': 'Download Order',
  'label.more_action': 'More Actions',
  'label.delete': 'Delete',
  'label.delete-confirm': 'Are you sure you want to delete?',
  'label.updated_at': 'Updated At',
  'label.captcha.second': 'sec',
  'label.optional': ' (optional) ',
  'label.submit': 'Submit',
  'label.cancel': 'Cancel',
  'label.complete': 'Complete',
  'label.save': 'Save',
  'label.email.placeholder': 'Email',
  'label.password.placeholder': 'Password',
  'label.confirm-password.placeholder': 'Confirm password',
  'label.phone-number.placeholder': 'Phone number',
  'label.verification-code.placeholder': 'Verification code',
  'label.title.label': 'Title',
  'label.title.placeholder': 'Give the target a name',
  'label.date.label': 'Start and end date',
  'label.date.placeholder.start': 'Start date',
  'label.date.placeholder.end': 'End date',
  'label.create-item.create': 'Add Item',
  'label.create.submit': '發送',
  'label.edit.update': '更新',
  'label.shipping_method': 'Shipping Method',
  'label.message.courier': 'Please Select Courier',
  'label.message.service_name': 'Please Select Service Name',
  'label.message.service_code': 'Please Select Service Code',
  'label.message.service_type': 'Please Select Service Type',
  'label.message.self_pick_location_code': 'Please Select Self Pick (Store / LOCKER)',
  'label.message.shipping_code': 'Please Select Shipping Method',
  'label.message.shipment_content': 'Please Enter Shipment Description',
  'label.message.reference_no': 'Please Enter Reference No.',
  'label.message.package_num': 'Please Enter Package Num',
  'label.message.delivery_name': 'Please Enter Delivery Name',
  'label.message.address_book': 'Select Your Saved Addresses',
  'label.message.company': 'Please Enter Company',
  'label.message.email': 'Please Enter Email',
  'label.message.email_format': 'The input is not valid E-mail!',
  'label.message.phone': 'Please Enter Phone Number',
  'label.message.upload_file': 'Please select upload file！',
  'label.message.country': 'Please Enter Country',
  'label.message.city': 'Please Enter City',
  'label.message.postal': 'Please Enter Postal',
  'label.message.address': 'Please Enter Address Line 1',
  'label.message.address1': 'Please Enter Address Line 2',
  'label.message.address2': 'Please Enter ddress Line 3',
  'label.message.shipper_name': 'Please Enter Shipper Name',
  'label.message.shipper_email': 'Please Enter Shipper Email',
  'label.message.shipper_phone': 'Please Enter Shipper Phone Number',
  'label.message.shipper_country': 'Please Enter Shipper Country',
  'label.message.shipper_city': 'Please Enter Shipper City',
  'label.message.shipper_postal': 'Please Enter Shipper Postal',
  'label.message.shipper_address': 'Please Enter Shipper Address Line 1',
  'label.message.shipper_address1': 'Please Enter Shipper Address Line 2',
  'label.message.shipper_address2': 'Please Enter Shipper Address Line 3',
  'label.message.declared_value': 'Please Enter Declared Value',
  'label.message.currency': 'Please Select Currency',
  'label.message.battery_type': 'Please Select Battery',
  'label.message.incoterm': 'Please Select Incoterm',
  'label.message.sku': 'Please Enter SKU',
  'label.message.product_name': 'Please Enter Product Name',
  'label.message.description_export': 'Please Enter Description Export',
  'label.message.description_import': 'Please Enter Description Import',
  'label.message.country_of_origin': 'Please Enter Origin',
  'label.message.quantity': 'Please Enter Quantity',
  'label.message.weight': 'Please Enter Weight',
  'label.message.item_declared_value': 'Please Enter Declared Value',
  'label.message.product_weight': 'Please Enter Product Weight',
  'label.detail.title': 'Label Detail',
  'label.detail.item.title': 'Label Items',
  'label.status.cancel': 'Cancel',
  'label.status.valid_in_progress': 'Valid In Progress',
  'label.status.pending_valid': 'Pending Valid',
  'label.status.valid_success': 'Valid Success',
  'label.status.valid_failed': 'Valid Failed',
  'label.status.created_failed': 'Created Failed',
  'label.status.resave_label': 'ReSave Label',
  'label.status.created_success': 'Created Success',
  'label.status.requested': 'Requested',
  'label.status.pick_up': 'Picked Up',
  'label.status.audit': 'Audit',
  'label.status.not_audit': 'Not Audit',
  'label.status.inbound': 'Inbound',
  'label.status.outbound': 'Outbound',
  'label.status.frozen': 'Frozen',
  'label.status.exception': 'Exception',
  'label.status.in_progress': 'In Progress',
  'label.status.not_known': 'Not Known',
  'label.status.not_exit': 'Not Exit',
  'label.status.transporting': 'Transporting',
  'label.status.submited': 'Submited',
  'label.status.collect': 'Collect',
  'label.status.deleted': 'Deleted',
  'label.status.refunded': 'Refunded',
  'label.status.pending_transfer': 'Pending transfer',
  'label.status.refund_in_warehouse': 'Refund in warehouse',
  'label.status.signed': 'Signed',
  'label.button.download': 'Download',
  'label.button.download-invoice': 'Invoice',
  'label.shipper_account': 'Shipper Account',
  'label.print_selected_label': 'Print Selected Label',
  'label.print_selected_invoices': 'Print Selected Invoices',
  'label.print_selected_reference': 'Print Selected Reference',
  'shipping.title.route': 'Tracking List',
  'shipping.tracking': 'Tracking',
  'shipping.tracking_number': 'Tracking number',
  'shipping.search_placeholder': 'Enter the tracking number to query',
  'shipping.audited': 'Audited',
  'shipping.collect': 'Collect',
  'shipping.out_warehouse': 'Out Warehouse',
  'shipping.transporting': 'Transporting',
  'shipping.signing': 'Signing',
  'shipping.signed': 'Signed',
  'shipping.message.duplicate': 'The tracking number is wrong, please check.',
  'shipping.message.retry_later': 'Please retry it later.',
  'shipping.message.add_tracking_number': 'Please add tracking number.',
  'label.amount': '金額',
  'label.related_inventories': 'Reference No. {delivery_order_no} Available Inventories',
  'label.create_return_label.title': 'Create Reference No. {reference_no} Return Shipment ',
  'label.require_return_label': 'Require Return Label',
  'label.return_service_code': 'Return Service Code',
  'label.message.return_service_code': 'Return Service Code',
  'label.title.return_infomartion': 'Return Information'
};
