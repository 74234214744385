import { queryJorWmsNote } from '@/services/wms/note';

import type { Effect, Reducer } from 'umi';
import type { NoteTableListData } from './note.d';

export interface StateType {
  notes: NoteTableListData;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getNotes: Effect;
    clear: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'wmsNoteModel',

  state: {
    notes: {
      list: [],
      pagination: {},
    },
  },

  effects: {
    *getNotes({ payload }, { call, put }) {
      const response = yield call(queryJorWmsNote, payload);
      yield put({
        type: 'save',
        payload: {
          list: response.data,
          pagination: {
            total: response?.meta ? response.meta.total_record : 0,
            page_size: response.meta ? response.meta.per_page : 0,
            current: response.meta ? response.meta.current_page : 0,
          },
        },
      });
    },
    *clear(_, { put }) {
      yield put({
        type: 'save',
        payload: {
          list: [],
          pagination: {},
        },
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        notes: action.payload,
      };
    },
  },
};

export default Model;
