export default {
  'exchange_rate.from_currency': 'From Currency',
  'exchange_rate.to_currency': 'To Currency',
  'exchange_rate.rate': 'Rate',
  'exchange_rate.create': 'Create Exhange Rate',
  'exchange_rate.create.fail': 'Fail to create exchange rate',
  'exchange_rate.create.success': 'Exhange rate has been succesfully created',
  'exchange_rate.update.fail': 'Fail to update exchange rate',
  'exchange_rate.update': 'Update',
  'exchange_rate.update.success': 'Exhange rate has been succesfully updated',
};
