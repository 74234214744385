export default {
  'delivery_notes.batch': 'Batch',
  'delivery_notes.total_weight': 'Total Weight(KG)',
  'delivery_notes.total_volume': 'Total Volume(CBM)',
  'delivery_notes.total_cost': 'Total Cost',
  'delivery_notes.packing_gift_card': 'Packing Gift Card',
  'delivery_notes.packing_gift_information': 'Packing Gift Information',
  'delivery_notes.packing_wrapping': 'Packing Wrapping',
  'delivery_notes.packing_special_information': 'Packing Special Information',
  'delivery_notes.packing_natural_beauty': 'Packing Natural Beauty',
  'delivery_notes.reference_no': 'Reference No.',
  'delivery_notes.back_order': 'Back Order',
  'delivery_notes.cross_zone_pick': 'Cross Zone Pick',
  'delivery_notes.pending_non_amr_pick': 'Go To Non-AMR Picking',
  'delivery_notes.create': 'Create Outbound Order',
  'delivery_notes.create.fail': 'Fail to create outbound order',
  'delivery_notes.create.success': 'Delivery Note has been succesfully created',
  'delivery_notes.download_oms_label_failed': 'Download label error',
  'delivery_notes.update.fail': 'Fail to update outbound order',
  'delivery_notes.update': 'Update',
  'delivery_notes.update.success': 'Delivery Note has been succesfully updated',
  'delivery_notes.form_item.title': 'Item Details',
  'delivery_notes.product': 'Product',
  'delivery_notes.pick_type': 'Allocated Picking Zone',
  'delivery_notes.search_product': 'Search Product',
  'delivery_notes.item.no': 'No.',
  'delivery_notes.item.qty': 'Item Qty',
  'delivery_notes.item.order_qty': 'Order Qty',
  'delivery_notes.item.pre_pick_qty': 'PrePick Qty',
  'delivery_notes.item.remain_pick_qty': 'Remain Pick Qty',
  'delivery_notes.item.picked_qty': 'Picked Qty',
  'delivery_notes.item.declared_price': 'Declared Value',
  'delivery_notes.item.asn_code': 'ASN',
  'delivery_notes.item.currency': 'Currency',
  'delivery_notes.item.allocate_inv_product_type': 'Allocated Product Type',
  'delivery_notes.item.outbound_pick_items': 'Non-AMR Picklist',

  'delivery_notes.auto-release-dn-title': 'Auto Allocate DN',
  'delivery_notes.release-selected-confirm':
    'Are you sure you want to release selected DN for picking?',
  'delivery_notes.push-external-confirm':
    'Are you sure you want to push all DN to external warehouse?',
  'delivery_notes.release_selected': 'Allocate Selected',
  'delivery_notes.allocate_order': 'Allocating Orders...',
  'delivery_notes.allocate_order.fail': 'Fail to allocate orders',
  'delivery_notes.print_picklist.fail': 'Fail to print the picklist',
  'delivery_notes.print_picklist.all':
    'Download all the picklist that have not been downloaded yet',
  'delivery_notes.confirm_to_new': 'Confirm To New',
  'delivery_notes.confirm-to-new-confirm':
    'Are you sure you want to confirm this {document_no} to new order?',
  'delivery_notes.release_to_picklist': 'Allocate To Picklist',
  'delivery_notes.release-confirm':
    'Are you sure you want to release this {document_no} to picking list?',
  'delivery_notes.push-to-warehouse': 'Push To External WMS',
  'delivery_notes.push-warehouse-confirm':
    'Are you sure you want to push this {document_no} to External WMS (Upload DN to FTP)?',
  'delivery_notes.confirm_picked': 'Confirm Picked',
  'delivery_notes.confirm-picked-confirm':
    'Are you sure you want to confirm this {document_no} picked?',
  'delivery_notes.dispatch_shipping': 'Dispatch Shipping',
  'delivery_notes.dispatch-shipping-confirm':
    'Are you sure you want to dispatch this {document_no} shpping?',
  'delivery_notes.scan_picklist_code': 'Scan Picklist',
  'delivery_notes.product_sku': 'Product SKU',
  'delivery_notes.serial_number': 'Serial Number',
  'delivery_notes.serial_numbers_list': '{product_name} Serial Numbers',
  'delivery_notes.show_serial_number': 'Show Serial Numbers',
  'delivery_notes.scanned_qty': 'Scanned Qty',
  'delivery_notes.pack_handling': 'Packing DN',
  'delivery_notes.pick_handling': 'Picking DN',
  'delivery_notes.picked_success': 'The {scanned_code} scanned {qty} pieces',
  'delivery_notes.search': 'Search',
  'delivery_notes.warehouse_bin': 'Warehouse Bin',
  'delivery_notes.allocated_asn_or_rma': 'Allocated ASN / RMA',
  'delivery_notes.user_picked.load_all_confirm':
    'System has auto saved your previously scanned barcode(s), do you want to load all the barcode from the save?',
  'delivery_notes.save_current_scanned_dn': 'Save Current Scanned List',
  'delivery_notes.scan_bar_codes': 'Bar Codes (Scan)',
  'delivery_notes.ordered_qty': 'QTY',
  'wms.common.scan.success': 'Scan succesfully',
  'delivery_notes.scan_tracking_no': 'Scan Tracking No.',
  'delivery_notes.scan_delivery_note': 'Scan Delivery Note (DN)',
  'delivery_notes.scan_container_code': 'Scan Container Note',
  'delivery_notes.scan_amr_container_code': 'Scan AMR Container Note',
  'delivery_notes.booking_no': 'BL No.',
  'delivery_notes.platform_code': 'Platform Code',
  'delivery_notes.wms_carrier_code': 'Carrier Code',
  'delivery_notes.shipped_at': 'Shipped At',
  'delivery_notes.truck_detail': 'Truck Details',
  'delivery_notes.courier_service_code': 'SR Service Code',
  'delivery_notes.tracking_no': 'Tracking No.',
  'delivery_notes.priority': 'Priority',
  'delivery_notes.packed_dimension': 'Packed Dimension (CM)',
  'delivery_notes.packed_weight': 'Packed Weight',
  'delivery_notes.packed_length': 'Packed Length',
  'delivery_notes.packed_width': 'Packed Width',
  'delivery_notes.item.amount': 'Amount',
  'delivery_notes.item.packing_spec': 'Packed Spec',
  'delivery_notes.wms_container_code': 'WMS Container Code',
  'delivery_notes.packed_height': 'Packed Height',
  'delivery_notes.shipped.success': 'Delivery note has been mark as shipped successful',
  'delivery_notes.update_tracking.fail': 'Fail to update the tracking information',
  'delivery_notes.download.documents': 'Download all required documents',
  'delivery_notes.picklist_no': 'Picklist No.',
  'delivery_notes.amr_picked_items': 'AMR {document_no} Picked Items',
  'delivery_notes.regenerate-picklist-confirm':
    'Are you sure want to regenerate all available picklist No.',
  'delivery_notes.scan_picklist.no_data':
    'The Picklist No. - {picklist_no} seems not a valid picklist number',
  'delivery_notes.scan_delivery_note.no_data':
    'The {delivery_note_code} has no packing information',
  'delivery_notes.item.scan_method': 'Scan Method',
  'delivery_notes.disptach_update_tracking': 'Update {document_no} tracking information',
  'delivery_notes.pack_update_tracking': 'Update {document_no} packing information',
  'delivery_notes.bar_code': 'Bar Code',
  'delivery_notes.upload_packed_report': 'Upload Packed Report',
  'delivery_notes.upload_dispatched_report': 'Upload Dispatched Report',
  'delivery_notes.upload_packed_items': 'Packed Report Items',
  'delivery_notes.upload_dispatched_items': 'Dispatched Report Items',
  'delivery_notes.download-delivery-notes': 'Download DN',
  'delivery_notes.download-summary': 'Download Summary',
  'delivery_notes.upload_file_name': 'Upload File Name',
  'delivery_notes.upload_dispatch_title': 'Upload Dispatch Report Items',
  'delivery_notes.confirm-dispatch': 'Pending Confirm Upload Dispatch Report To Ship',
  'delivery_notes.show-dispatch-items': 'Show Dispatch Report Items',
  'delivery_notes.dispatch.hold': 'Hold the DN to dispatch',
  'delivery_notes.dispatch.recovery': 'Recovery the DN to dispatch',
  'delivery_notes.download_packlist': 'Download Packing List',
  'delivery_notes.print_all_new_picklist': 'Print all new Picklist',
  'delivery_notes.download_new_packlist_items': 'Download New Picklist Items(Excel)',
  'delivery_notes.packlist.total': 'Total',
  'delivery_notes.packlist.total.dn': 'DN',
  'delivery_notes.scan_dn_or_tracking': 'Scan DN or Tracking No.',
  'delivery_notes.packed-at': 'Packed At',
  'delivery_notes.scan_dn_or_tracking_no': 'DN or Tracking No.',
  'delivery_notes.scan_code_or_tracking_no': 'Scan Console Code / Tracking No.',
  'delivery_notes.ean_or_upc_code': 'EAN / UPC',
  'wms_picking_container.wms_container_code': 'WMS Container Code',
  'wms_picking_container.show_container_detail': 'Show WMS Container Picked Items',
  'wms_picking_container.pallet_code': 'Pallet Code',
  'wms_picking_container.sku_type_amount': 'No. of SKU(s)',
  'wms_picking_container.sku_amount': 'QTY',
  'wms_picking_container.wms_provider_code': 'WMS Provider',
  'wms_picking_container.picker': 'Picker',
  'wms_picking_container.item.outbound_type': 'Outbound Type',
  'wms_picking_container.item.transfer_order_code': 'Transfer Order Code',
  'wms_picking_container.item.asn_code': 'ASN Code',
  'wms_picking_container.item.amount': 'Picked Qty',
  'wms_picking_container.item.picking_product_type': 'Picking Product Type',
  'wms_picking_container.item.country_code_of_origin': 'Country Of Origin',
  'wms_picking_container.item.container_amount': 'Container Amount',
  'wms_picking_container.item.expiration_date': 'Expiration Date',
  'wms_picking_container.item.is_last_container': 'Is Last Container',
  'wms_picking_container.pick-container-item-title':
    'WMS Container {wms_container_code} Picked Items',
  'wms_picking_container.item.finish_time': 'Finish Time',
  'wms_picking_container.release-selected': 'Release Selected Container',
  'wms_picking_container.release-selected-confirm':
    'Are you sure you want to release selected AMR container?',
  'wms_picking_container.release-confirm':
    'Are you sure you want to release AMR container {container_code}?',
  'wms_picking_container.retry-confirm':
    'Are you sure you want to re-try AMR container {container_code}?',
  'delivery_notes.delivery_note_code': 'DN No.',
  'delivery_notes.require_courier_invoice': 'Require Invoice',
  'delivery_notes.upload_waybill_title': 'Upload {document_no} Waybill File',
  'delivery_notes.upload_waybill': 'Upload Waybill',
  'delivery_notes.download_waybill': 'Download Waybill',
  'delivery_notes.upload_invoice_title': 'Upload {document_no} Invoice File',
  'delivery_notes.upload_invoice': 'Upload Invoice',
  'delivery_notes.download_invoice': 'Download Invoice',
  'delivery_notes.cancel-confirm': 'Are you sure to cancel this {document_no} delivery note?',
  'delivery_notes.return': 'Return',
  'delivery_notes.return-confirm': 'Are you sure to return this {document_no} delivery note?',
  'delivery_notes.download-selected-confrim': 'Are you sure to download selected outbound orders?',
  'delivery_notes.download_selected': 'Download Selected',
  'delivery_notes.export_to_excel': 'Export your data to excel',
  'delivery_notes.download': 'Download',
  'delivery_notes.show_tracking': 'Show Tracking',
  'delivery_notes.asn': 'ASN',
  'delivery_notes.delivery_info': 'Delivery Info',
  'delivery_notes.delivery_company': 'Company Name',
  'delivery_notes.delivery_name': 'Contact Name',
  'delivery_notes.delivery_email': 'Contact Email',
  'delivery_notes.address': 'Address',
  'delivery_notes.address1': 'Address1',
  'delivery_notes.address2': 'Address2',
  'delivery_notes.country': 'Country',
  'delivery_notes.city': 'City',
  'delivery_notes.state': 'State',
  'delivery_notes.postal': 'Postal',
  'delivery_notes.phone': 'Phone',
  'delivery_notes.require_transfer': 'Require Replenish To AMR',
  'delivery_notes.require_cancel': 'Require Cancel Order',
  'delivery_notes.item.request_asn_code': 'Request ASN / RMA',
  'delivery_notes.download_pack_label_alert_title': 'Pack {document_no} Require Labels',
  'delivery_notes.pack.scan_more_fail':
    'The {document_no} EAN / UPC / (SKU) {code} only allow scan {scanned_qty}, please check',
  'delivery_notes.pack_box_number.title': 'Carton No. {carton_no}',
  'delivery_notes.outbound_pack_boxes.list': 'Warehoue Outbound Carton List',
  'delivery_notes.no_amr.picked_success_title': 'Picked outbound order succesfully, Please check',
  'delivery_notes.no_amr.total_picked_qty': 'Totally Picked: {picked_qty} PCS',
  'outbound_consoles.outbound_console_code': 'Console Code',
  'outbound_consoles.scan_dn_or_tracking': 'Scan DN or Tracking No.',
  'outbound_consoles.scan_container_box_code': 'Scan Container Box(Outbound)',
  'outbound_consoles.scan_container_code.success': 'Scan (Outbound) container box code Succesfully',
  'outbound_consoles.update_packed_weight': 'Update {document_no} Packed Weight',
  'outbound_consoles.scan_code_or_tracking': 'Scan Console Code or Tracking No.',
  'outbound.release-picking-title': 'Allocate All DN ',
  'outbound.document.name.long.name': 'Delivery Note',
  'outbound.index.tab.dn': 'DN',
  'outbound.index.tab.dn.title': 'Delivery Orders',
  'outbound.index.tab.new-order': 'New Order',
  'outbound.index.tab.new-order.title': 'New Orders',
  'outbound.index.tab.amr-pick-order': 'AMR Pick Order',
  'outbound.index.tab.amr-pick-order.title': 'AMR Pick Order',
  'outbound.index.tab.no-amr-pick-order': 'Non-AMR Pick Order',
  'outbound.index.tab.no-amr-pick-order.title': 'Non-AMR Pick Order',
  'outbound.index.tab.upload-dispatch': 'Upload Dispatch',
  'outbound.index.tab.upload-dispatch.title': 'Upload Dispatch',
  'outbound.index.tab.transfer-pending-outbound': 'Pending Order',
  'outbound.index.tab.transfer-pending-outbound.title': 'Pending Order',
  'outbound.amr.index.tab.pack-list': 'AMR Pack List (Print)',
  'outbound.amr.index.tab.pack-list.title': 'AMR Pack List',
  'outbound.amr.index.tab.pack': 'AMR Pack',
  'outbound.amr.index.tab.pack.title': 'AMR Pack',
  'outbound.amr.index.tab.dispatch': 'AMR Dispatch',
  'outbound.amr.index.tab.dispatch.title': 'AMR Dispatch',
  'outbound.amr.index.tab.release-container': 'AMR Pick Container (Feedback)',
  'outbound.amr.index.tab.release-container.title': 'AMR Pick Container (Feedback)',
  'outbound.pick.tab.amr-picklist': 'AMR Picklist',
  'outbound.pick.tab.amr-picklist.title': 'AMR Picklist',
  'outbound.pick.tab.no-amr-picklist': 'Non-AMR Picklist',
  'outbound.pick.tab.no-amr-picklist.title': 'Non-AMR Picklist',
  'outbound.pick.tab.no-amr-pick': 'Non-AMR Pick',
  'outbound.pick.tab.no-amr-pick.title': 'Non-AMR Pick',
  'outbound.pick.tab.no-amr-container-pick': 'Non-AMR Container Pick',
  'outbound.pick.tab.no-amr-container-pick.title': 'Non-AMR Container Pick',
  'outbound.pack.tab.pack-order': 'Pack Order',
  'outbound.pack.tab.pack-order.title': 'Pack Order',
  'outbound.pack.tab.no-amr-pick-pack': 'Non-AMR Pick & Pack',
  'outbound.pack.tab.no-amr-pick-pack.title': 'Non-AMR Pick & Pack',
  'outbound.pack.tab.scan-to-pack': 'Scan To Verify Product',
  'outbound.pack.tab.scan-to-pack.title': 'Scan To Verify Product',
  'outbound.pack.tab.pack-waybill': 'Print Pack Label',
  'outbound.pack.tab.pack-waybill.title': 'Print Pack Label',
  'outbound.pack.tab.reprint': 'Re-Print',
  'outbound.pack.tab.reprint.title': 'Re-Print',
  'outbound.pack.tab.reinput-weight': 'Re-Input Weight & Dimension',

  'outbound.dispatch.tab.amr-dispatch': 'AMR dispatch',
  'outbound.dispatch.dispatch': 'Dispatch',
  'outbound.dispatch.tab.amr-dispatch.title': 'AMR dispatch',
  'outbound.dispatch.tab.dispatch.title': 'Dispatch',
  'outbound.dispatch.tab.dispatch_v2.title': 'Dispatch (v2)',
  'outbound.dispatch.tab.manifest.title': 'Manifest',
  'outbound.dispatch.tab.no-amr-dispatch': 'Non-AMR dispatch',
  'outbound.dispatch.tab.no-amr-dispatch.title': 'Non-AMR dispatch',
  'outbound.pick.scan.ean-or-sku':
    '{scanned_code} is not set as the permitted scanning barcode for this product',
  'outbound.pick.scan.ean-or-sku.finish_scanning': 'Scanning of {scanned_code} had been finished',
  'outbound.return.tab.return': 'Return DN to Non-AMR',
  'outbound.return.tab.return.title': 'Return DN to Non-AMR',
  'outbound.return.tab.put-away': 'Put Away',
  'outbound.return.tab.put-away.title': 'Put Away',
  'outbound.return.tab.return-orders': 'Return Orders',
  'outbound.return.tab.return-orders.title': 'Return Orders',
  'outbound.return.tab.new-item': 'Return Items',
  'outbound.return.tab.new-item.title': 'Return Items',
  'outbound.release.message': 'Allocate Delivery Note to create picking task ...',
  'outbound.release.fail.message': 'Fail to all new order for picking!',
  'outbound.release.all.message': 'Allocate all new order to create picking task',
  'outbound.release.all.button': 'Allocate all new order',
  'outbound.release.selected.button': 'Allocate selected order',
  'outbound.release.selected.dn.confirmation':
    'Are you sure you want to allocate the selected order to create picking task?',

  'outbound.amr.packlist.download': 'There is no packing list for download',
  'outbound.amr.packlist.single.dn.download.fail': 'Fail to download delivery note!',
  'outbound.amr.pack.item.table.title': 'Item(s) to Pack',
  'outbound.amr.pack.confirm.pack.fail': 'Fail to confirm your packing order',
  'outbound.amr.pack.download.courier-label.fail': 'Fail to download the courier label',
  'outbound.amr.pack.download.waybill.fail': 'Fail to download the waybill',
  'outbound.amr.pack.download.client-dn.fail': 'Fail to download the DN',
  'outbound.amr.pack.download.client-dg-form.fail': 'Fail to download the DG goods form',
  'outbound.amr.pack.download.client-dg-carton.fail': 'Fail to download the DG carton box label',
  'outbound.amr.pack.download.client-invoice.fail': 'Fail to download the invoice',
  'outbound.amr.pack.download.client-courier-invoice.fail': 'Fail to download the courier invoice',
  'outbound.amr.pack.download.sr-dn.fail': 'Fail to download the delivery note',
  'outbound.amr.pack.download.fail': 'Fail to download file(s)',
  'outbound.amr.pack.reprint_type': 'Re-Print Type',
  'outbound.amr.pack.dispatch.duplicate.scan': 'The {scanned_dn} has been scanned before',
  'outbound.amr.pack.dispatch.require.scan.tracking':
    'Scan the tracking no. instead of the DN no. - {delivery_note_code}',
  'outbound.amr.gtn.container': 'GTN Container',
  'outbound.packed.reprint': 'AMR Re-Print',
  'outbound.reprint-amr-outbound-title': 'Re-Print AMR Outbound Label',
  'outbound.amr.packed.reprint.message': 'Re-Print...',
  'outbound.amr.packed.reprint.fail.message': 'Fail to re-print AMR outbound label',
  'outbound.picking.dn_status': 'DN Status',
  'outbound.dispatch.load_time': 'Orders Loaded at: {when}',
  'outbound.dispatch.number_of_packed_order_before_cutoff':
    'Number of Orders for Dispatch before Cutoff',
  'outbound.dispatch.number_of_packed_orders': 'Number of Packed Orders',
  'outbound.dispatch.number_of_packed_order_dispatched': 'Number of Orders Dispatched',
  'outbound.dispatch.cutoff_time': '{courier_name} Cutoff time',
  'outbound.dispatch.time_remaining_before_cutoff': 'Time remaining',
  'outbound.dispatch.awaiting_dispatch': 'DN - Awaiting Dispatch',
  'outbound.dispatch.dispatched': 'DN - Dispatched',
  'outbound.dispatch.dispatch_date': 'Dispatch Date',
  'outbound.dispatch.problem_dn': 'DN - Invalid',
  'outbound.dispatch.extra_dn': 'DN - Extra',
  'outbound.dispatch.button.change_pallet_id': 'Change Pallet ID',
  'outbound.dispatch.shipped_at_message': 'Dispatch At: {shipped_at}',
  'outbound.dispatch.requested_cancel_at_message': 'Requested Cancel At: {request_cancel_at}',
  'outbound.dispatch.manifest.no_basic_info':
    'Loading of basic information fail, please contact IT support',
  'outbound.dispatch.plesae-select-carriers': 'Please select at least 1 carrier',

  'delivery_note.pick-work-item-title': 'Picking Task No. {picklist_task_no} Details',
  'delivery_notes.picking_task_no': 'Picking Task No.',
  'delivery_notes.show_picking_task_detail': 'Show Picking Task Detail',
  'delivery_notes.wave_order_code': 'Wave Order Code',
  'delivery_notes.wave_type': 'Wave Type',
  'delivery_notes.wave_structure': 'Wave Structure',
  'delivery_notes.dispatched_pallet_id': 'Pallet ID',
  'delivery_notes.is_printed': 'Is Printed',
  'delivery_notes.plan_number_of_items': 'Plan Number Of Items',
  'delivery_notes.plan_number_of_sku_qty': 'Plan Pick Qty',
  'delivery_notes.print-picklist-selected-confirm':
    'Are you sure you want to print selected picklist DN?',
  'delivery_notes.print_picklist_selected': 'Print Picklist Selected',
  'delivery_notes.select_warehouse_first': 'Please Select Warehouse First',
  'delivery_notes.picklist.item': 'Picklist Items',
  'delivery_notes.print_picklist.title': 'Print picklist',
  'delivery_notes.print_picklist.button': 'Print All New Picklist',
  'delivery_notes.placeholder-allcation-zone-property':
    'Select Zone Property(if you want to allocate by selected zone)',
  'delivery_notes.step.created_at': 'Received',
  'delivery_notes.step.released_at': 'Released',
  'delivery_notes.step.non_amr_picked_at': 'Pick',
  'delivery_notes.step.amr_picked_at': 'AMR Pick',
  'delivery_notes.step.packed_at': 'Pack',
  'delivery_notes.step.shipped_at': 'Dispatch',
  'delivery_notes.step.request_cancel_at': 'Request Cancel',
  'delivery_notes.step.cancelled_at': 'Cancel',
  'delivery_notes.step.handled_by': 'by {name} ',
  'delivery_notes.return.item': 'Return Items',
  'delivery_notes.outbound_return_code': 'Return Code',
  'delivery_notes.print-return-order-selected-confirm':
    'Are you sure you want to print selected return order?',
  'delivery_notes.print_return_order_selected': 'Print Selected Return Orders',
  'delivery_notes.show_outbound_return_detail': 'Show Return Order Detail',
  'delivery_notes.order_total_qty': 'Order Total Qty',
  'delivery_notes.button.confirm': 'Confirm',
  'delivery_note.return-order-item-title': 'Return Order Code {return_order_code} Details',
  'outbound_return_item.from_zone_property': 'From Zone Property',
  'outbound_return_item.from_bin_property': 'From Bin Property',
  'outbound_return_item.from_container_box_code': 'From Container Box Code',
  'outbound_return_item.to_zone_property': 'To Zone Property',
  'outbound_return_item.to_bin_property': 'To Bin Property',
  'outbound_return_item.to_container_box_code': 'To Container Box Code',
  'outbound_return_item.to_warehouse_bin': 'To Warehouse Bin',
  'outbound_return_item.product_qty': 'Product Qty',
  'outbound_return_item.put_away_qty': 'PutAway Qty',
  'outbound_return_item.pending_put_away_qty': 'Pending PutAway Qty',
  'delivery_notes.update.warehouse_info': 'Update DN Warehouse Info',
  'delivery_notes.details.items': 'Item Details',
  'delivery_notes.details.packing_list': 'Packed Information',
  'delivery_notes.packed_at': 'Packed Date',
};
