export default {
  'courier_spot_cost.courier_service': 'Courier Service',
  'courier_spot_cost.company': 'Company',
  'courier_spot_cost.companies': 'Companies',
  'courier_spot_cost.package_type': 'Package Type',
  'courier_spot_cost.pickup_mode': 'Pickup Mode',
  'courier_spot_cost.document': 'Document',
  'courier_spot_cost.non_document': 'Non Document',
  'courier_spot_cost.weight': 'Weight(KG)',
  'courier_spot_cost.min_weight': 'Min Weight',
  'courier_spot_cost.max_weight': 'Max Weight',
  'courier_spot_cost.destination_country_from': 'From',
  'courier_spot_cost.from': 'From',
  'courier_spot_cost.destination': 'Destination',
  'courier_spot_cost.dimension_denominator': 'Dimension Denominator',
  'courier_spot_cost.freight_cost': 'Freight Cost',
  'courier_spot_cost.effective_date': 'Effective Date',
  'courier_spot_cost.ddp_surcharge': 'DDP Surcharge',
  'courier_spot_cost.peak_season_surcharge': 'Peak Season Surcharge',
  'courier_spot_cost.fuel_surcharge': 'Fuel Surcharge',
  'courier_spot_cost.pod': 'Proof Of Delivery',
  'courier_spot_cost.message.effective_date': 'Please select set effactive date',
  'courier_spot_cost.create': 'Create Spot Cost',
  'courier_spot_cost.create.fail': 'Fail to create spot cost',
  'courier_spot_cost.create.success': 'Spot Cost has been succesfully created',
  'courier_spot_cost.update.fail': 'Fail to update spot cost',
  'courier_spot_cost.update': 'Update',
  'courier_spot_cost.update.success': 'Spot Cost has been succesfully updated',
  'courier_spot_cost.status': 'Status',
  'courier_spot_cost.spot_rate_title': '{courier_name} Spot Rates',
};
