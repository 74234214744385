import { List, Modal, Typography } from 'antd';
import { getIntl, getLocale } from 'umi';
import type { ErrorData } from '@/utils/request';
const { Title } = Typography;

const ResponseWarning = (data: ErrorData, closeCallback?: Function) => {
  const intl = getIntl(getLocale());

  const content = (
    <List
      split={false}
      size="small"
      dataSource={data.errors}
      renderItem={(item: { message: string }, index: number) => (
        <List.Item>
          <Title level={3}>
            {index + 1}. {item.message}
          </Title>
        </List.Item>
      )}
    />
  );

  let modalType = 'error';
  if (data && data.errors && data.errors.length > 0) {
    data.errors.forEach((x) => {
      if (x.message_type === 'warning') {
        modalType = x.message_type;
      }
    });
  }

  if (modalType === 'warning') {
    Modal.warning({
      title: intl.formatMessage({ id: 'message.warning' }),
      content,
      okText: intl.formatMessage({ id: 'button.ok' }),
      afterClose: () => {
        if (closeCallback) {
          closeCallback();
        }
      },
    });
  } else {
    Modal.error({
      title: intl.formatMessage({ id: 'message.error' }),
      content,
      okText: intl.formatMessage({ id: 'button.ok' }),
      afterClose: () => {
        if (closeCallback) {
          closeCallback();
        }
      },
    });
  }
};

export default ResponseWarning;
