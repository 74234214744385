export default {
  'shipment.pickup.title': 'Pickups',
  'shipment.title': 'Shipment Tracking',
  'shipment.reference_no': 'Reference No.',
  'shipment.tracking_no': 'Tracking No.',
  'shipment.pickup_note': 'Pickup Note',
  'shipment.signed_by': 'Signed By',
  'shipment.platform_lead_time': 'Lead Time',
  'shipment.courier_name': 'Courier Name',
  'shipment.courier_slug': 'Courier Slug',
  'shipment.last_checkpoint': 'Last Checkpoint',
  'shipment.delivery_from': 'From',
  'shipment.delivery_to': 'To',
  'shipment.status': 'Status',
  'shipment.action': 'Operation',
  'shipment.from_date': 'From Date',
  'shipment.to_date': 'To Date',
  'shipment.button.markas': 'Mark as',
  'shipment.status.requested': 'Requested',
  'shipment.status.info_received': 'Info Received',
  'shipment.status.in_transit': 'In Transit',
  'shipment.status.delivered': 'Delivered',
  'shipment.status.expired': 'Expired',
  'shipment.status.pending': 'Pending',
  'shipment.status.exception': 'Exception',
  'shipment.status.other': 'Other',
};
