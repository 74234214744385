export default {
  'warehouses.name': 'Warehouse Name',
  'warehouses.location': 'Location',
  'warehouses.address': 'Address',
  'warehouses.postal_code': 'Postal Code',
  'warehouses.email': 'E-mail',
  'warehouses.contact_person_name': 'Contact Person',
  'warehouses.contact_phone': 'Contact Phone',
  'warehouses.status': 'Status',
  'warehouses.create': 'Create Warehouse',
  'warehouses.create.fail': 'Fail to create warehouse',
  'warehouses.create.success': 'Warehouse has been succesfully created',
  'warehouses.update.fail': 'Fail to update warehouse',
  'warehouses.update': 'Update',
  'warehouses.update.success': 'Warehouse has been succesfully updated',
};
