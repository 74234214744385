export default {
  'transfer_pick_order.tab.new-transfer': 'New Transfer',
  'transfer_pick_order.tab.no-amr-pick': 'Non AMR Pick',
  'transfer_pick_order.tab.no-amr-pick.title': 'Non AMR Pick',
  'transfer_pick_order.tab.sorting': 'Sorting',
  'transfer_pick_order.tab.put-away': 'Put Away',
  'transfer_pick_order.scan_bin': 'Scan Bin Code',
  'transfer_pick_order.scan_container_box_code': 'Scan Container Box Code',
  'transfer_pick_order.scan_transfer_order_code': 'Scan Transfer Order Code',
  'transfer_pick_order.scan_sku_barcode': 'Scan SKU / Barcode',
  'transfer_pick_order.scan_barcode': 'Scan Barcode',
  'transfer_pick_order.scan_transfer_order_code.no_data':
    'The {transfer_order_code} is not valid for sorting, please verify the Transfer Order Code first',
  'transfer_pick_order.scan_transfer_order_code.no_data_putaway':
    'The {transfer_order_code} has no information for put away',
  'transfer_pick_order.ready_sorting_product': 'Product for Sorting',
  'transfer_pick_order.sorting.not_match_sku':
    'The input SKU - {scan_sku} does not matching with the SKU - {require_sku}',
  'transfer_pick_order.confirm-sorting-items':
    'Are you sure you want to confirm the {document_no} sorting report, and send receiving report to customer?',
  'transfer_pick_order.scan_transfer_order_code.success': 'Scan Transfer Order succesfully',
  'transfer_pick_order.scan_container_code.success':
    'Verification of the container box has been done!',
  'transfer_pick_order.tab.items': 'Item Details',
  'transfer_pick_order.tab.sorting_items': 'Sorting Items',
  'transfer_pick_order.tab.sorting_report': 'Sorting Report',
  'transfer_pick_order.tab.inventory_adjustments': 'Inventory Adjustment Items',
  'transfer_pick_order.tab.inv_batch_adjustments': 'INV - Batch Adjustment Items',
  'transfer_pick_order.details.container_box_code': 'Container Box Code',
  'transfer_pick_order.details.transfer_item_type': 'Transfer Item Type',
  'transfer_pick_order.details.pending_pick_qty': 'Pending Pick Qty',
  'transfer_pick_order.details.scanned_qty': 'No. of Boxes Scanned',
  'transfer_pick_order.details.picked_qty': 'Picked Qty',
  'transfer_pick_order.details.length': 'Length',
  'transfer_pick_order.details.width': 'Width',
  'transfer_pick_order.details.height': 'Height',
  'transfer_pick_order.details.total_scanned_qty': 'Scanned Qty',
  'transfer_pick_order.details.sku': 'SKU Code',
  'transfer_pick_order.details.serial_number': 'Serial Number',
  'transfer_pick_order.details.serial_numbers': 'Serial Numbers',
  'transfer_pick_order.details.sorting_report': 'Sorting Report',
  'transfer_pick_order.details.product_name': 'Product Name',
  'transfer_pick_order.details.product_category': 'Product Category',
  'transfer_pick_order.details.product_qty': 'Expected Qty',
  'transfer_pick_order.details.expected_qty': 'Expected Qty',
  'transfer_pick_order.details.product_actual_qty': 'Actual Arrival Qty',
  'transfer_pick_order.details.product_normal_qty': 'Actual Normal Qty',
  'transfer_pick_order.details.put_away_qty': 'Put Away Qty',
  'transfer_pick_order.details.production_date': 'MFG Date',
  'transfer_pick_order.details.expiry_date': 'Expiry Date',
  'transfer_pick_order.details.system_expire_date': 'System Expire Date',
  'transfer_pick_order.details.country_of_origin': 'Country Of Origin',
  'transfer_pick_order.details.product_damage_qty': 'Damage Qty',
  'transfer_pick_order.details.difference': 'Difference',
  'transfer_pick_order.details.product_shortage_qty': 'Arrival Shortage Qty',
  'transfer_pick_order.details.product_more_qty': 'Arrival Additional Qty',
  'transfer_pick_order.details.production_batch_code': 'Production Batch Code',
  'transfer_pick_order.details.pending_put_away_qty': 'Pending Put Away Qty',
  'transfer_pick_order.details.packing_list': 'Packing Details',
  'transfer_pick_order.details.pallet_no': 'Pallet No.',
  'transfer_pick_order.details.carton_no': 'Carton No.',
  'transfer_pick_order.details.print_normal_qty': 'Print Normal Qty',
  'transfer_pick_order.details.print_damage_qty': 'Print Damage Qty',
  'transfer_pick_order.details.month': 'Month',
  'transfer_pick_order.last_put_away_at': 'Last Put Away At',
  'transfer_pick_order.last_put_away_by': 'Last Put Away By',

  'transfer_pick_order.sorting.sorted_items': 'Sorted Items',
  'transfer_pick_order.sorting.inbound-items': 'Items on Transfer Order',
  'transfer_pick_order.sorting.complete-sorting': 'Complete the sorting',
  'transfer_pick_order.sorting.un-complete-sorting': 'Reverse the completion',
  'transfer_pick_order.sorting.un-receive-product': 'Un-Recevie the product',
  'transfer_pick_order.sorting.current_box_scanned': 'Product Scanned (Box) - Subtotal',
  'transfer_pick_order.sorting.total_product_scanned': 'Product Scanned - Grand Total',
  'transfer_pick_order.put-away.pending-item-table.title': 'Pending Put Away - Items',
  'transfer_pick_order.put-away.pending-container-table.title': 'Pending Put Away - Containers',
  'transfer_pick_order.confirm-and-send-sorting-report-message':
    'Confirm and send the sorting report to customer successfully!',
  'transfer_pick_order.force-complete-picking-success-message':
    'Force complete transfer order picking successfully!',
  'transfer_pick_order.go-to.button': 'Go to - {transfer_order_code}',
  'transfer_pick_order.sorting.message.please-scan-the-product': 'Please scan the product bar code',
  'transfer_pick_order.cancel-confirm': 'Are you sure to cancel the {document_no} transfer order?',
  'transfer_pick_order.export_to_excel': 'Export to Excel',
  'transfer_pick_order.update_tracking_info': 'Update Tracking Info',
  'transfer_pick_order.button.edit_tracking': 'Edit Tracking',
  'transfer_pick_order.select_carton': 'Select Carton No. List To Associate Pallet No.',
  'transfer_pick_order.confirm-sorting-confirm':
    'Are you sure you want to confirm this {document_no} sorting report?',
  'transfer_pick_order.confirm-sorting.button': 'Confirm Sorting',
  'transfer_pick_order.sorting-item.confirm.message': 'Confirming the sorting items...',
  'transfer_pick_order.sorting-item.confirm.fail': 'Fail to confirm the sorting!',
  'transfer_pick_order.sorting-item.confirm.tooltips': 'Confirm the sorting',
  'transfer_pick_order.putaway.success': 'Goods have been put away successfully',
  'transfer_pick_order.sorting.verifying_pin': 'Verifying Pin...',
  'transfer_pick_order.sorting.fail_to_do_pin_exchange': 'Fail to do Pin exchange',

  'transfer_pick_order.id': 'Id',
  'transfer_pick_order.add_transfer_sku_details': 'Add Transfer SKU Details',
  'transfer_pick_order.transfer_order_code': 'Transfer Order',
  'transfer_pick_order.create': 'Create Transfer Order',
  'transfer_pick_order.merchant': 'Merchant',
  'transfer_pick_order.warehouse': 'Warehouse',
  'transfer_pick_order.virtual_warehouse': 'Virtual Warehouse',
  'transfer_pick_order.warehouse_zone': 'Warehouse Zone',
  'transfer_pick_order.on_hand_stock': 'On Hand Stock',
  'transfer_pick_order.can_transfer_stock': 'Can Transfer Qty',
  'transfer_pick_order.item.no': 'Item No.',
  'transfer_pick_order.item.id': 'Item Id',
  'transfer_pick_order.product_sku': 'Product SKU',
  'transfer_pick_order.product_name': 'Product Name',
  'transfer_pick_order.inventory_qty': 'Inventory Qty',
  'transfer_pick_order.can_allocate_stock': 'Can Allocate Qty',
  'transfer_pick_order.transfer_qty': 'Transfer Qty',
  'transfer_pick_order.production_date': 'MFG Date',
  'transfer_pick_order.released_date_at': 'Allocate Date',
  'transfer_pick_order.expiry_date': 'Exp Date',
  'transfer_pick_order.system_expire_date': 'System Exp Date',
  'transfer_pick_order.production_batch_code': 'Production Batch Code',
  'transfer_pick_order.picking_product_type': 'Picking Product Type',
  'transfer_pick_order.warehouse_zone_code': 'Zone Code',
  'transfer_pick_order.warehouse_bin_code': 'Bin Code',
  'transfer_pick_order.confirmed_date_at': 'Confirm Date At',
  'transfer_pick_order.wms_provider_code': 'WMS Provider Code',
  'transfer_pick_order.workstation_no': 'Workstation No.',
  'transfer_pick_order.pending_transfer_qty': 'Pending Transfer Qty',
  'transfer_pick_order.picked_qty': 'AMR Picked Qty',
  'transfer_pick_order.transferred_qty': 'Transferred Qty',
  'transfer_pick_order.operator': 'Operator',
  'transfer_pick_order.status': 'Status',
  'transfer_pick_order.creation_date': 'Creation Date',
  'transfer_pick_order.completed_date_at': 'Completed Date At',
  'transfer_pick_order.remark': 'Remark',
  'transfer_pick_order.sku_add_before': 'The selected products have been added.',
  'transfer_pick_order.sku_add_same_product_type':
    'The selected products should add the same product type',
  'transfer_pick_order.sku_add_same_product_type_before':
    'The selected products {sku} had add {product_type}, please check',
  'transfer_pick_order.please-select-item': 'Please select the item',
  'transfer_pick_order.please-put-transfer-qty': 'Please put the transfer Qty',
  'transfer_pick_order.duplication': 'The item has been added before!',
  'transfer_pick_order.submit-to-wms': 'Submit To AMR',
  'transfer_pick_order.submit-confirm': 'Are you sure you want to submit the {document_no} to AMR?',
  'transfer_pick_order.fail-submit-confirm': 'Fail to submit the {document_no} to AMR',
  'transfer_pick_order.force_complete_picking': 'Force Complete Picking',
  'transfer_pick_order.submit-picked-confirm':
    'Are you sure you want to force complete the {document_no} picking?',
  'transfer_pick_order.fail-force-complete-picking':
    'Fail to force complete the {document_no} picking',
  'transfer_pick_order.picklist.item': 'Picklist Items',
  'transfer_pick_order.no_item_warning': 'Please add adjutment items first!',
  'transfer_pick_order.no_amr.picked_success_title':
    'Picked transfer order succesfully, Please check',
  'transfer_pick_order.require_sorting_qty': 'Awaiting Sorting Qty',
  'transfer_pick_order.actual_sorting_qty': 'Actual Sorting Qty',
  'transfer_pick_order.sorting_item': 'Sorting',
  'transfer_pick_order.print.inbound_label_title': 'Print Carton Label - Sealed',
  'transfer_pick_order.sorting_print_label': 'Seal Container Box',
  'transfer_pick_order.scan_picklist.no_data':
    'The picklist No. {picklist_no} has no data to pack, please check it',
  'transfer_pick_order.sorting.cancel': 'Cancel',
  'transfer_pick_order.sorting.submit': 'Submit',
  'transfer_pick_order.sorting.item_title': 'Sorting SKU / Bar Code {sku}',
  'transfer_pick_order.show_sorting_items_title': '{document_no} Sorting Report Items',
  'transfer-sorting-report.confirm_sorted_date_at': 'Confirmed Date',
  'transfer-sorting-report.confirm_sorted_by': 'Confirmed By',
  'transfer-sorting-report.remark': 'Remark',
  'transfer-sorting-report.show-sorting-items': 'Show Sorting Items',
  'transfer_pick_order.scan.code_not_match':
    'Transfer Order - {document_no} does not match with the one that you scanned before, please refresh the page and scan it again',
  'transfer_pick_order.only_add_one_sku': 'You can only select one SKU at a time',
  'transfer_pick_order.amr_expiry_product_cannot_mix_with_other_type':
    'Expired product cannot mix with other product type item that putting into the same transfer order',
};
