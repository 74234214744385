export default {
  'transfer_pick_order.tab.new-transfer': '下架單',
  'transfer_pick_order.tab.no-amr-pick': '人工區 - 揀貨(下架)',
  'transfer_pick_order.tab.no-amr-pick.title': '人工區 - 揀貨(下架)',
  'transfer_pick_order.tab.sorting': '重新分揀',
  'transfer_pick_order.tab.put-away': '重新上架',
  'transfer_pick_order.scan_bin': '掃描架位',
  'transfer_pick_order.scan_container_box_code': '掃描箱號',
  'transfer_pick_order.ready_sorting_product': '分揀的產品',
  'transfer_pick_order.scan_transfer_order_code': '掃描下架單號碼',
  'transfer_pick_order.scan_sku_barcode': '掃描 SKU 或 其他條碼',
  'transfer_pick_order.scan_barcode': '掃描條碼',
  'transfer_pick_order.scan_transfer_order_code.no_data':
    '這個 {transfer_order_code} 未能做分揀，請檢查你揀選的下架單號碼',
  'transfer_pick_order.scan_transfer_order_code.no_data_putaway':
    '入庫單 - {transfer_order_code} 沒有可上架的資料',
  'transfer_pick_order.sorting.not_match_sku':
    '你所掃描的 SKU - {scan_sku} 與所需要的 SKU - {require_sku} 對不上',
  'transfer_pick_order.confirm-sorting-items': '要確認下架單 - {document_no} 的分揀報表嗎?',

  'transfer_pick_order.scan_transfer_order_code.success': '成功驗證下架單號碼',
  'transfer_pick_order.scan_container_code.success': '檢測箱號完成',
  'transfer_pick_order.tab.items': '貨物資料',
  'transfer_pick_order.tab.sorting_items': '分揀箱',
  'transfer_pick_order.tab.sorting_report': '重新分揀報告',
  'transfer_pick_order.tab.inventory_adjustments': '庫存調整',
  'transfer_pick_order.tab.inv_batch_adjustments': '產品屬性調整',
  'transfer_pick_order.details.container_box_code': '箱號',
  'transfer_pick_order.details.transfer_item_type': '下架產品方式',
  'transfer_pick_order.details.scanned_qty': '已掃描的箱數',
  'transfer_pick_order.details.pending_pick_qty': '待下架數量',
  'transfer_pick_order.details.picked_qty': '已下架數量',
  'transfer_pick_order.details.length': '長',
  'transfer_pick_order.details.width': '闊',
  'transfer_pick_order.details.height': '高',
  'transfer_pick_order.details.total_scanned_qty': '已分揀數量',
  'transfer_pick_order.details.sku': '產品號碼 SKU',
  'transfer_pick_order.details.serial_number': '序列號 S/N',
  'transfer_pick_order.details.serial_numbers': '序列號 S/N(s)',
  'transfer_pick_order.details.sorting_report': '重新分揀報告',
  'transfer_pick_order.details.product_name': '產品名稱',
  'transfer_pick_order.details.product_category': '產品類別',
  'transfer_pick_order.details.product_qty': '預期數量',
  'transfer_pick_order.details.expected_qty': '預期數量',
  'transfer_pick_order.details.product_actual_qty': '實數數量',
  'transfer_pick_order.details.product_normal_qty': '好貨數量',
  'transfer_pick_order.details.put_away_qty': '上架數量',
  'transfer_pick_order.details.production_date': '生產日期',
  'transfer_pick_order.details.expiry_date': '到期日期',
  'transfer_pick_order.details.system_expire_date': '系統假定的過期日',
  'transfer_pick_order.details.country_of_origin': '產地 (COO)',
  'transfer_pick_order.details.product_damage_qty': '壞貨數量',
  'transfer_pick_order.details.difference': '相差',
  'transfer_pick_order.details.product_shortage_qty': '少收數量',
  'transfer_pick_order.details.product_more_qty': '多收數量',
  'transfer_pick_order.details.production_batch_code': '批次號',
  'transfer_pick_order.details.pending_put_away_qty': '待上架數量',
  'transfer_pick_order.details.packing_list': '包裝資料',
  'transfer_pick_order.details.pallet_no': '板數',
  'transfer_pick_order.details.carton_no': '箱數',
  'transfer_pick_order.details.print_normal_qty': '列印正貨數量',
  'transfer_pick_order.details.print_damage_qty': '列印壞貨數量',
  'transfer_pick_order.details.month': '月',
  'transfer_pick_order.last_put_away_at': '最後上架時間',
  'transfer_pick_order.last_put_away_by': '最後上架處理人',

  'transfer_pick_order.sorting.sorted_items': '已分揀的產品',
  'transfer_pick_order.sorting.inbound-items': '下架單產品列表',
  'transfer_pick_order.sorting.complete-sorting': '完成分揀',
  'transfer_pick_order.sorting.un-complete-sorting': '撤銷完成了的分揀',
  'transfer_pick_order.sorting.un-receive-product': '未到貨',
  'transfer_pick_order.sorting.current_box_scanned': '箱子中已掃描的數量 - Subtotal',
  'transfer_pick_order.sorting.total_product_scanned': '產品已掃描的總數量 - Grand Total',
  'transfer_pick_order.put-away.pending-item-table.title': '待上架 - 單件',
  'transfer_pick_order.put-away.pending-container-table.title': '待上架 - 箱',
  'transfer_pick_order.confirm-and-send-sorting-report-message': '已分揀完成，請安排上架',
  'transfer_pick_order.go-to.button': '轉到 - {transfer_order_code} 頁面',
  'transfer_pick_order.force-complete-picking-success-message': '已強制完成揀貨，請安排重新分揀',
  'transfer_pick_order.sorting.message.please-scan-the-product': '請掃描產品條碼',

  'transfer_pick_order.cancel-confirm': '真的要取消下架單 - {document_no} 嗎?',
  'transfer_pick_order.export_to_excel': '匯出至 Excel',
  'transfer_pick_order.update_tracking_info': '更新追蹤訊息',
  'transfer_pick_order.button.edit_tracking': '修改追蹤訊息',
  'transfer_pick_order.select_carton': '選取箱號去關聯板號',
  'transfer_pick_order.confirm-sorting-confirm': '要確認下架單 - {document_no} 的分揀報表?',
  'transfer_pick_order.confirm-sorting.button': '確認分揀',
  'transfer_pick_order.sorting-item.confirm.message': '正在確認分揀 ...',
  'transfer_pick_order.sorting-item.confirm.fail': '確認分揀失敗',
  'transfer_pick_order.sorting-item.confirm.tooltips': '確認分揀',
  'transfer_pick_order.putaway.success': '已成功上架',
  'transfer_pick_order.sorting.verifying_pin': '驗証 Pin 碼中...',
  'transfer_pick_order.sorting.fail_to_do_pin_exchange': 'Pin 碼轉換失敗',
  'transfer_pick_order.id': 'Id',
  'transfer_pick_order.add_transfer_sku_details': '添加下架單SKU詳情',
  'transfer_pick_order.transfer_order_code': '下架單號碼',
  'transfer_pick_order.create': '創建新下架單',
  'transfer_pick_order.merchant': 'Merchant',
  'transfer_pick_order.warehouse': 'Warehouse',
  'transfer_pick_order.virtual_warehouse': 'Virtual Warehouse',
  'transfer_pick_order.warehouse_zone': 'Warehouse Zone',
  'transfer_pick_order.on_hand_stock': '已上架庫存 [On Hand]',
  'transfer_pick_order.can_transfer_stock': '可下架庫存數',
  'transfer_pick_order.item.no': 'Item No.',
  'transfer_pick_order.item.id': 'Item Id',
  'transfer_pick_order.product_sku': 'Product SKU',
  'transfer_pick_order.product_name': 'Product Name',
  'transfer_pick_order.inventory_qty': 'Inventory Qty',
  'transfer_pick_order.can_allocate_stock': '可分配庫存',
  'transfer_pick_order.transfer_qty': '可下架庫存',
  'transfer_pick_order.production_date': '生產日期',
  'transfer_pick_order.released_date_at': '釋放時間',
  'transfer_pick_order.expiry_date': '到期日期',
  'transfer_pick_order.system_expire_date': 'System Exp Date',
  'transfer_pick_order.production_batch_code': '批次號',
  'transfer_pick_order.picking_product_type': '貨品類別定義',
  'transfer_pick_order.warehouse_zone_code': 'Zone Code',
  'transfer_pick_order.warehouse_bin_code': 'Bin Code',
  'transfer_pick_order.confirmed_date_at': '完成時間',
  'transfer_pick_order.wms_provider_code': 'WMS Provider Code',
  'transfer_pick_order.workstation_no': 'Workstation No.',
  'transfer_pick_order.pending_transfer_qty': 'Pending Transfer Qty',
  'transfer_pick_order.picked_qty': 'AMR 已揀貨數量',
  'transfer_pick_order.transferred_qty': 'Transferred Qty',
  'transfer_pick_order.operator': '操作員',
  'transfer_pick_order.status': '狀態',
  'transfer_pick_order.creation_date': '創建時間',
  'transfer_pick_order.completed_date_at': '完成時間',
  'transfer_pick_order.remark': '備註',
  'transfer_pick_order.sku_add_before': '所選擇的SKU之前已經選擇過',
  'transfer_pick_order.sku_add_same_product_type': '所選擇的SKU需要相同的貨品類別定義',
  'transfer_pick_order.sku_add_same_product_type_before':
    '所選擇的SKU {sku} 貨品類別定義 - {product_type}已經添加, 只允許添加相同的貨品類別定義，請檢查',
  'transfer_pick_order.please-select-item': '請先選擇需要調整的SKU',
  'transfer_pick_order.please-put-transfer-qty': '請輸入調整數量',
  'transfer_pick_order.duplication': '這一行已經加入過了!',
  'transfer_pick_order.submit-to-wms': '提交到AMR',
  'transfer_pick_order.submit-confirm': '真的想確認提交這個單號 - {document_no} 到AMR?',
  'transfer_pick_order.fail-submit-confirm': '提交這個單號 {document_no}到AMR失敗',
  'transfer_pick_order.force_complete_picking': '強制完成揀貨',
  'transfer_pick_order.submit-picked-confirm': '真的想對這個單號 - {document_no} 強制完成揀貨?',
  'transfer_pick_order.fail-force-complete-picking': '訂單號 - {document_no} 強制完成揀貨失敗 ',
  'transfer_pick_order.picklist.item': '揀貨的產品(下架)',
  'transfer_pick_order.no_item_warning': '請先添加需要下架的產品',
  'transfer_pick_order.no_amr.picked_success_title': '揀貨成功，請查看',
  'transfer_pick_order.require_sorting_qty': '待分揀數量',
  'transfer_pick_order.actual_sorting_qty': '已分揀數量',
  'transfer_pick_order.sorting_item': '分揀',
  'transfer_pick_order.print.inbound_label_title': '列印封箱標籤',
  'transfer_pick_order.sorting_print_label': '封箱',
  'transfer_pick_order.scan_picklist.no_data':
    'The picklist No. {picklist_no} has no data to pack, please check it',
  'transfer_pick_order.sorting.cancel': '取消',
  'transfer_pick_order.sorting.submit': '發送',
  'transfer_pick_order.sorting.item_title': '分揀 SKU / Barcode {sku}',
  'transfer_pick_order.show_sorting_items_title': '{document_no} 重新分揀報告',
  'transfer-sorting-report.confirm_sorted_date_at': '確認日期',
  'transfer-sorting-report.confirm_sorted_by': '確認人',
  'transfer-sorting-report.remark': '備註',
  'transfer-sorting-report.show-sorting-items': '顯示重新分揀報告',
  'transfer_pick_order.scan.code_not_match':
    '下架單號 - {document_no} 和先前的單號有所出入，請重新載入面頁並重新掃描號碼',
  'transfer_pick_order.only_add_one_sku': '每次只能選取一個 SKU',
  'transfer_pick_order.amr_expiry_product_cannot_mix_with_other_type':
    '過期的產品不能與其他產品類形的產品加入在同一個下架單中',
};
