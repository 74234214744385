export default {
  'inbound_warehouse_charge.merchant': 'Merchant',
  'inbound_warehouse_charge.warehouse_name': 'Warehouse Name',
  'inbound_warehouse_charge.asn_code': 'ASN',
  'inbound_warehouse_charge.bill_code': 'Bill Code',
  'inbound_warehouse_charge.receive_date': 'Receive Date',
  'inbound_warehouse_charge.total_amount': 'Total Amount',
  'inbound_warehouse_charge.total_inbound_qty': 'Total Inbound Qty',
  'inbound_warehouse_charge.total_scanned_qty': 'Total Scanned S/N',
  'inbound_warehouse_charge.handling_in_out_fee': 'Handling In & Out Fee',
  'inbound_warehouse_charge.handling_in_fee': 'Handling In Fee',
  'inbound_warehouse_charge.handling_out_fee': 'Handling Out Fee',
  'inbound_warehouse_charge.asn_doc_handling_fee': 'ASN Doc Handling Fee',
  'inbound_warehouse_charge.barcode_scanning_fee': 'Barcode Scanning Fee',
  'inbound_warehouse_charge.sorting_fee': 'Sorting Fee',
  'inbound_warehouse_charge.loading_unloading_fee': 'Loading/Unloading Fee',
  'inbound_warehouse_charge.labeling_fee': 'Labeling Fee',
  'inbound_warehouse_charge.currency': 'Currency',
  'inbound_warehouse_charge.status': 'Status',
};
