export default {
  'warehouse_rent_bill_item.merchant': 'Merchant',
  'warehouse_rent_bill_item.bill_code': 'Bill Code',
  'warehouse_rent_bill_item.document_no': 'Document No.',
  'warehouse_rent_bill_item.sku': 'SKU',
  'warehouse_rent_bill_item.charge_qty': 'Charge Qty(CBM)',
  'warehouse_rent_bill_item.charge_date': 'Charge Date',
  'warehouse_rent_bill_item.total_volume': 'Total Volume(CBM)',
  'warehouse_rent_bill_item.warehouse_rent_type': 'Warehouse Rent Type',
  'warehouse_rent_bill_item.unit_amount': 'Unit Amount',
  'warehouse_rent_bill_item.total_amount': 'Total Amount',
  'warehouse_rent_bill_item.bill_start_date': 'Bill Start Date',
  'warehouse_rent_bill_item.bill_end_date': 'Bill End Date',
  'warehouse_rent_bill_item.status': 'Status',
};
