export default {
  'quote.search.title': 'Get Quotes',
  'quote.search': 'Get Quotes',
  'quote.action': 'Action',
  'quote.ship_from_hongkong': 'Ship From Hong Kong',
  'quote.ship_from': 'Ship From',
  'quote.ship_to': 'Ship To',
  'quote.warehouse': 'Warehouse',
  'quote.country': 'Country',
  'quote.country_state': 'Country State',
  'quote.postal_code': 'Postal Code',
  'quote.insurance': 'Insurance',
  'quote.item_category': 'Item Category',
  'quote.dimensions': 'Dimensions',
  'quote.height': 'Height',
  'quote.weight': 'Weight',
  'quote.weight_kg': 'Weight(KG)',
  'quote.length': 'Length',
  'quote.width': 'Width',
  'quote.customs_value': 'Customs Value',
  'quote.customs_currency': 'Customs Currency',
  'quote.delivery_time': 'Delivery Time',
  'quote.tracking': 'Tracking',
  'quote.rating': 'Rating',
  'quote.service_channel': 'Service Channel',
  'quote.service_code': 'Service Code',
  'quote.drop_off_or_pickup': 'Drop-off/Pickup',
  'quote.courier': 'Courier',
  'quote.remarks': 'Remark',
  'quote.tax_duty': 'Tax & Duty',
  'quote.freight_rate': 'Freight Rate',
  'quote.total_rate': 'Total Rate',
  'quote.other_fee': 'Other Fee',
  'quote.total_cost': 'Total Cost',
  'quote.message.warehouse': 'Please Select Warehouse',
  'quote.message.ship_to': 'Please Select Ship To',
  'quote.message.weight': 'Please Enter Weight',
  'quote.message.country_state': 'Please Select State',
  'quote.message.insurance': 'Please Select insurance',
  'quote.message.country': 'Please Select Country',
  'quote.message.item_category': 'Please Select Item Category',
  'quote.currency': 'Currency',
  'quote.freight_cost': 'Freight Cost',
  'quote.selected': 'Selected',
  'quote.selected.tooltip': 'Select this service chanel to create shipment',
  'quote.get_quote.success': 'Get Quotes Successful',
  'quote.get_quote.fail': 'Get Quotes Fail',
  'quote.company_name': 'Company Name',
  'quote.destination': 'Destination',
  'quote.to_country': 'To Country',
  'quote.dimension_weight': 'Dimension Weight (KG)',
  'quote.working_days': 'Working Days',
  'quote.ddp_surcharge': 'DDP Surcharge',
  'quote.operating_fee': 'Operating Fee',
  'quote.register_fee': 'Register Fee',
  'quote.item_fee': 'Item Fee',
  'quote.super_size_fee': 'Super Size Fee',
  'quote.fuel_surcharge': 'Fuel Surcharge',
  'quote.freight_collect_fee': 'Freight Collect Fee',
  'quote.clean_customs_fee': 'Clean Customs Fee',
  'quote.special_service_charge': 'Special Service Charge',
  'quote.high_risk_area_surcharge': 'High Risk Area Surcharge',
  'quote.limit_destination_surcharge': 'Limit estination Surcharge',
  'quote.transfer_agency_clean_fee': 'Transfer Agency Clean Fee',
  'quote.peak_season_surcharge': 'Peak Season Surcharge',
};
