export default {
  'inbound_warehouse_charge.merchant': 'Merchant',
  'inbound_warehouse_charge.warehouse_name': 'Warehouse Name',
  'inbound_warehouse_charge.asn_code': 'ASN',
  'inbound_warehouse_charge.bill_code': 'Bill Code',
  'inbound_warehouse_charge.receive_date': 'Receive Date',
  'inbound_warehouse_charge.total_amount': 'Total Amount',
  'inbound_warehouse_charge.total_inbound_qty': 'Total Inbound Qty',
  'inbound_warehouse_charge.total_scanned_qty': 'Total Scanned S/N',
  'inbound_warehouse_charge.handling_in_out_fee': 'Handling In & Out Fee',
  'inbound_warehouse_charge.asn_doc_handling_fee': 'ASN Doc Handling Fee',
  'inbound_warehouse_charge.barcode_scanning_fee': 'Barcode Scanning Fee',
  'inbound_warehouse_charge.sorting_fee': 'Sorting Fee',
  'inbound_warehouse_charge.loading_unloading_fee': 'Loading/Unloading Fee',
  'inbound_warehouse_charge.labeling_fee': 'Labeling Fee',
  'inbound_warehouse_charge.currency': 'Currency',
  'inbound_warehouse_charge.status': 'Status',
  'outbound_warehouse_charge.merchant': 'Merchant',
  'outbound_warehouse_charge.warehouse_name': 'Warehouse Name',
  'outbound_warehouse_charge.bill_code': 'Bill Code',
  'outbound_warehouse_charge.delivery_note_code': 'DN Code',
  'outbound_warehouse_charge.pickup_date': 'Pick Date',
  'outbound_warehouse_charge.total_amount': 'Total Amount',
  'outbound_warehouse_charge.total_outbound_qty': 'Total Outbound Qty',
  'outbound_warehouse_charge.total_scanned_qty': 'Total Scanned Qty',
  'outbound_warehouse_charge.picking_fee': 'Picking Fee',
  'outbound_warehouse_charge.packing_fee': 'Packing Fee',
  'outbound_warehouse_charge.labeling_fee': 'Labeling Fee',
  'outbound_warehouse_charge.barcode_scanning_fee': 'Barcode Scanning Fee',
  'outbound_warehouse_charge.palletization_fee': 'Palletization Fee',
  'outbound_warehouse_charge.currency': 'Currency',
  'outbound_warehouse_charge.status': 'Status',
  'warehouse_bill.merchant': 'Merchant',
  'warehouse_bill.customer': 'Customer',
  'warehouse_bill.warehouse': 'Warehouse',
  'warehouse_bill.bill_code': 'Bill Code',
  'warehouse_bill.bill_date': 'Bill Date',
  'warehouse_bill.total_amount': 'Total Amount',
  'warehouse_bill.bill_date_month': 'Bill Month',
  'warehouse_bill.bill_start_date': 'Bill Start Date',
  'warehouse_bill.bill_end_date': 'Bill End Date',
  'warehouse_bill.remark': 'Remark',
  'warehouse_bill.submitted_at': 'Submitted At',
  'warehouse_bill.submitted_by': 'Submitted By',
  'warehouse_bill.confirmed_date_at': 'Confirmed At',
  'warehouse_bill.completed_date_at': 'Completed At',
  'warehouse_bill.status': 'Status',
  'warehouse_bill.push': 'Push to Re-Genreate',
  'warehouse_bill.push-confirm': 'Re-Genreate {charge_title}?',
  'warehouse_bill.create': 'Create Warehouse Bill',
  'warehouse_bill.create.fail': 'Fail to create warehouse bill',
  'warehouse_bill.create.success': 'Warehouse bill has been succesfully created',
  'warehouse_bill.update.fail': 'Fail to update warehouse bill',
  'warehouse_bill.update': 'Update',
  'warehouse_bill.update.success': 'Warehouse bill has been succesfully updated',
  'warehouse_bill.charge_title': 'Warehouse Chareges',
  'warehouse_bills.confirm.bill-confirm': 'Are you sure want to confirm the bill {document_no}?',
  'warehouse_bills.complete.bill-confirm': 'Are you sure want to complete the bill {document_no}?',
  'warehouse_bills.cancel-confirm': 'Are you sure want to cancle the bill {document_no}?',
  'warehouse_rent_bill.warehouse': 'Warehouse',
  'warehouse_rent_bill.bill_code': 'Bill Code',
  'warehouse_rent_bill.total_amount': 'Total Amount',
  'warehouse_rent_bill.bill_start_date': 'Bill Start Date',
  'warehouse_rent_bill.bill_end_date': 'Bill End Date',
  'warehouse_rent_bill.remark': 'Remark',
  'warehouse_rent_bill.submitted_at': 'Submitted At',
  'warehouse_rent_bill.submitted_by': 'Submitted By',
  'warehouse_rent_bill.status': 'Status',
  'warehouse_rent_bill.create': 'Create Warehouse Rent Bill',
  'warehouse_rent_bill.create.fail': 'Fail to create warehouse rent bill',
  'warehouse_rent_bill.create.success': 'Warehouse rent bill has been succesfully created',
  'warehouse_rent_bill.update.fail': 'Fail to update warehouse rent bill',
  'warehouse_rent_bill.update': 'Update',
  'warehouse_rent_bill.update.success': 'Warehouse rent bill has been succesfully updated',
  'warehouse_rent_bill_item.merchant': 'Merchant',
  'warehouse_rent_bill_item.bill_code': 'Bill Code',
  'warehouse_rent_bill_item.document_no': 'Document No.',
  'warehouse_rent_bill_item.warehouse': 'Warehouse',
  'warehouse_rent_bill_item.sku': 'SKU',
  'warehouse_rent_bill_item.product_qty': 'Product Qty',
  'warehouse_rent_bill_item.total_outbound_qty': 'Outbound Qty',
  'warehouse_rent_bill_item.charge_qty': 'Charge Qty(CBM)',
  'warehouse_rent_bill_item.charge_date': 'Charge Date',
  'warehouse_rent_bill_item.total_volume': 'Total Volume(CBM)',
  'warehouse_rent_bill_item.warehouse_rent_type': 'Warehouse Rent Type',
  'warehouse_rent_bill_item.unit_amount': 'Unit Amount',
  'warehouse_rent_bill_item.total_amount': 'Total Amount',
  'warehouse_rent_bill_item.bill_date': 'Bill Date',
  'warehouse_rent_bill_item.download-error-sku': 'Download No Dimension SKU',
  'warehouse_rent_bill_item.status': 'Status',
  'warehouse_rent_bill_item.create': 'Create Warehouse Rent Bill Item',
  'warehouse_rent_bill_item.create.fail': 'Fail to create warehouse rent bill item',
  'warehouse_rent_bill_item.create.success':
    'Warehouse rent bill item has been succesfully created',
  'warehouse_rent_bill_item.update.fail': 'Fail to update warehouse rent bill item',
  'warehouse_rent_bill_item.update': 'Update',
  'warehouse_rent_bill_item.update.success':
    'Warehouse rent bill item has been succesfully updated',
  'warehouse_rent_bill_item.inventory_ageing_item': 'Warehouse {document_no} Inventory Ageing',
  'warehouse_rent_bill_item.show_inventory_ageing_list': 'Show Inventory Ageing Items',
  'warehouse_rent_bill_item.upload_rent_bill_item': 'Upload Rent Items',
};
