export default {
  'merchants.primary_name': 'Primary Name',
  'merchants.secondary_name': 'Secondary Name',
  'merchants.merchant_code': 'Merchant Code',
  'merchants.tel': 'Tel',
  'merchants.address': 'Address',
  'merchants.email': 'E-mail',
  'merchants.remark': 'Remark',
  'merchants.status': 'Status',
  'merchants.create': 'Create Merchant',
  'merchants.create.fail': 'Fail to create merchant',
  'merchants.create.success': 'Merchant has been succesfully created',
  'merchants.update.fail': 'Fail to update merchant',
  'merchants.update': 'Update',
  'merchants.update.success': 'Merchant has been succesfully updated',
  'merchants.reference_code': 'Reference Code',
};
