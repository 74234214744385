// @ts-ignore
/* eslint-disable */
import { request } from 'umi';
import { LME_OMS_API } from './typings';
const {
  REFRESH_TOKEN_URL,
  USER_URL,
  USER_PROFILE_URL,
  LME_OMS_COUNTRY_URL,
  LME_OMS_WAREHOUSE_URL,
  LME_OMS_DASHBOARD_URL,
  LME_OMS_COURIER_PACKAGE_URL,
  LME_OMS_COURIER_BATTERY_URL,
  LME_OMS_MARKETPLACE_PLATFORM_URL,
  LME_OMS_AFTERSHIP_COURIER_URL,
  LME_A_OMS_COURIER_URL,
  LME_A_OMS_COMPANY_URL,
  LME_A_OMS_COURIER_COST_URL,
  LME_A_OMS_SHIPPER_URL,
  LME_A_OMS_COURIER_SERVICE_URL,
  LME_A_OMS_WAREHOUSE_URL,
  LME_A_OMS_DELIVERY_ACCOUNT_URL,
  JOR_A_WMS_OMS_COMPANY_URL,
} = API;

export async function query(): Promise<any> {
  return request(USER_URL);
}

/** 获取当前的用户 GET /api/currentUser */
export async function queryCurrentUser(): Promise<any> {
  return request(`${USER_PROFILE_URL}/info`, {
    method: 'GET',
  });
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<LME_OMS_API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

// refresh token
export async function refreshToken(): Promise<any> {
  return request(REFRESH_TOKEN_URL);
}

export async function queryCountry() {
  return request(`${LME_OMS_COUNTRY_URL}`, {
    method: 'GET',
    useCache: true,
    ttl: 60000,
  });
}

export async function queryCourierRateTypes() {
  return request(`${LME_A_OMS_COURIER_COST_URL}/rate-types`, {
    method: 'GET',
    useCache: true,
    ttl: 60000,
  });
}

export async function queryCourierBatteryTypes() {
  return request(`${LME_OMS_COURIER_BATTERY_URL}/types`, {
    method: 'GET',
    useCache: true,
    ttl: 60000,
  });
}

export async function queryCountryStates(country_code: string) {
  return request(`${LME_OMS_COUNTRY_URL}/${country_code}/states`, {
    method: 'GET',
  });
}

export async function queryCountryCityList(country_code: string) {
  return request(`${LME_OMS_COUNTRY_URL}/${country_code}/cities`, {
    method: 'GET',
  });
}

export async function queryWarehouse() {
  return request(`${LME_OMS_WAREHOUSE_URL}`, {
    method: 'GET',
    useCache: true,
    ttl: 60000,
  });
}

export async function queryLabelDashboard() {
  return request(`${LME_OMS_DASHBOARD_URL}`, {
    method: 'GET',
  });
}
export async function fetchShipmentsStatics() {
  return request(`${LME_OMS_DASHBOARD_URL}/shipments-statistic`, {
    method: 'GET',
  });
}

export async function fetchShipmentsCard(params: { date_from: any; date_to: any }) {
  return request(`${LME_OMS_DASHBOARD_URL}/shipments-card`, {
    method: 'GET',
    params,
  });
}

export async function queryServiceChannelDashboard() {
  return request(`${LME_OMS_DASHBOARD_URL}/services-channel`, {
    method: 'GET',
  });
}
export async function queryAsnLatestlDashboard() {
  return request(`${LME_OMS_DASHBOARD_URL}/asn-latest`, {
    method: 'GET',
  });
}

export async function queryAllForApiCourierList() {
  return request(`${LME_A_OMS_COURIER_URL}/all-api-options`, {
    method: 'GET',
  });
}

export async function queryAllActiveWarehouseList() {
  return request(`${LME_A_OMS_WAREHOUSE_URL}/all-active`, {
    method: 'GET',
  });
}

export async function queryAllCourierService() {
  return request(`${LME_A_OMS_COURIER_SERVICE_URL}/all-active`, {
    method: 'GET',
  });
}

export async function queryAllCourierList() {
  return request(`${LME_A_OMS_COURIER_URL}`, {
    method: 'GET',
  });
}

export async function queryOMSCompanies() {
  return request(`${LME_A_OMS_COMPANY_URL}`, {
    method: 'GET',
  });
}

export async function queryOMSMarketplacePlatforms() {
  return request(`${LME_OMS_MARKETPLACE_PLATFORM_URL}`, {
    method: 'GET',
  });
}

export async function queryCourierShippinginfo(courier_id: number) {
  return request(`${LME_A_OMS_COURIER_URL}/${courier_id}/shipping-info`, {
    method: 'GET',
  });
}

export async function getAllCompanyListForOption() {
  return request(`${LME_A_OMS_COMPANY_URL}`, {
    method: 'GET',
  });
}

export async function getAllActiveCompanyList() {
  return request(`${LME_A_OMS_COMPANY_URL}/all-active`, {
    method: 'GET',
  });
}


export async function getAllActiveDeliveryAccounts() {
  return request(`${LME_A_OMS_DELIVERY_ACCOUNT_URL}/all-active`, {
    method: 'GET',
  });
}

export async function getAllShipperListForOption() {
  return request(`${LME_A_OMS_SHIPPER_URL}`, {
    method: 'GET',
  });
}

export async function queryCourierPackageFlatBoxes() {
  return request(`${LME_OMS_COURIER_PACKAGE_URL}/flat-boxes`, {
    method: 'GET',
  });
}

export async function queryAfterShipCouries() {
  return request(`${LME_OMS_AFTERSHIP_COURIER_URL}`, {
    method: 'GET',
  });
}

export async function queryJorWmsOmsCompanies() {
  return request(`${JOR_A_WMS_OMS_COMPANY_URL}`, {
    method: 'GET',
  });
}
