export default {
  'marketplace.platform_code': 'Platform Code',
  'marketplace.reference_code': 'Reference Code',
  'marketplace.platform_name': 'Platform Name',
  'marketplace.order_priority': 'WMS Order Priority',
  'marketplace.pick_product_type_priority': '貨品類別定義優先級',
  'marketplace.email': 'Email',
  'marketplace.first_pick_product_type': 'First Pick Product Type',
  'marketplace.second_pick_product_type': 'Second Pick Product Type',
  'marketplace.third_pick_product_type': 'Third Pick Product Type',
};
