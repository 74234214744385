export default {
  'marketplace.store.id': 'ID',
  'marketplace.store.marketplace_id': 'marketplace_id',
  'marketplace.store.platform': 'Platform',
  'marketplace.store.remark': 'Remark',
  'marketplace.store.store_account': 'Store Account',
  'marketplace.store.store_currency': 'Store Currency',
  'marketplace.store.store_email': 'Store Email',
  'marketplace.store.store_token': 'Store Token',
  'marketplace.store.store_user_id': 'Store User',
  'marketplace.store.time_schedule': 'Time Schedule',
  'marketplace.store.debug': 'Debug',
  'marketplace.store.action': 'Action',
  'marketplace.store.auth.title': 'Authorization',
};
