import { request } from 'umi';
import type { InventoryTableListParams, StockTakeItemTableListParams } from './data';

const { JOR_WMS_STOCK_TAKE_URL } = API;

export async function getStockTakes(params: InventoryTableListParams | undefined) {
  return request(`${JOR_WMS_STOCK_TAKE_URL}`, {
    method: 'GET',
    params,
  });
}
export async function getStockTakeDetails(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_STOCK_TAKE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getStockTakeItems(id: number) {
  return request(`${JOR_WMS_STOCK_TAKE_URL}/${id}/items`, {
    method: 'GET',
  });
}

export async function getStockTakeAdjustmentItems(
  stock_take_id: number,
  params: StockTakeItemTableListParams | undefined,
) {
  return request(`${JOR_WMS_STOCK_TAKE_URL}/${stock_take_id}/adjustment/items`, {
    method: 'GET',
    params,
  });
}

export async function getStockTakeOptions() {
  return request(`${JOR_WMS_STOCK_TAKE_URL}/options`, {
    method: 'GET',
  });
}
