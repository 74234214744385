import type { Effect, Reducer } from 'umi';

import type { InboundOrderDataType } from './data';
import { getInboundOrderDetail } from './service';

export interface StateType {
  inboundOrder?: InboundOrderDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    get: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
    clear: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'wmsInboundModel',
  state: {
    inboundOrder: undefined,
  },
  effects: {
    *get({ payload }, { call, put }) {
      const response = yield call(getInboundOrderDetail, payload);
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        inboundOrder: action.payload,
      };
    },
    clear() {
      return {
        inboundOrder: undefined,
      };
    },
  },
};

export default Model;
