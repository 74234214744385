import { request } from 'umi';
import type {
  NoteTableListParams,
  CreateNoteDataType,
  UpdateNoteDataType,
} from '@/models/wms/note.d';

const { JOR_WMS_NOTE_URL, JOR_A_WMS_NOTE_URL } = API;

export async function queryJorWmsNote(params: NoteTableListParams | undefined) {
  return request(JOR_WMS_NOTE_URL, {
    method: 'GET',
    params,
  });
}

export async function getJorWmsNote(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_WMS_NOTE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createJorWmsNote(params: CreateNoteDataType) {
  return request(JOR_WMS_NOTE_URL, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateJorWmsNote(params: UpdateNoteDataType) {
  const { id } = params;
  return request(`${JOR_WMS_NOTE_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function removeJorWmsNote(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_WMS_NOTE_URL}/${id}`, {
    method: 'DELETE',
  });
}

export async function queryJorAdminWmsNote(params: NoteTableListParams | undefined) {
  return request(JOR_A_WMS_NOTE_URL, {
    method: 'GET',
    params,
  });
}
