export default {
  'warehouses.warehouse-asn.title': 'Advance Shipment Notifications',
  'warehouses.asn.id': 'ID',
  'warehouses.asn.asn_number': 'ASN Number',
  'warehouses.asn.batch_id': 'Batch ID',
  'warehouses.asn.batch_request_number': 'Batch Number',
  'warehouses.asn.delivery_type': 'Type',
  'warehouses.asn.pickup_address': 'Pickup Address',
  'warehouses.asn.company_name': 'Company Name',
  'warehouses.asn.country_code': 'Country Code',
  'warehouses.asn.city': 'City',
  'warehouses.asn.state': 'State',
  'warehouses.asn.contact_person_name': 'Contact Person',
  'warehouses.asn.contact_phone': 'Contact Phone',
  'warehouses.asn.postal_code': 'Postal Code',
  'warehouses.asn.request_packages': 'Request Packages',
  'warehouses.asn.request_collection_date': 'Request Pickup Date',
  'warehouses.asn.pickup_note': 'Pickup Note',
  'warehouses.asn.shipment_scan_date': 'Scanned Date',
  'warehouses.asn.scanned_packages': 'Scanned Packages',
  'warehouses.asn.action': 'Action',
  'warehouses.type.pickup': 'Request LME Pickup',
  'warehouses.type.delivery': 'Delivery to Warehouse',
  'warehouses.asn.confirm_shipment': 'Create Tracking & Trace',
  'warehouses.warehouse-asn.scan_status': 'Scan Status',
  'warehouses.status.scanned': 'Scanned',
  'warehouses.status.unscan': 'Unscan',
  'warehouses.warehouse-asn.scan_to_warehouse_date': 'Scanned Date',
  'warehouses.asn.show_scanned_packages': 'Show Scanned Pacakges',
  'warehouses.asn.show_request_packages': 'Show Request Pacakges',
  'warehouses.warehouse-asn.request_package_title': '{asn_no} Request Packages',
  'warehouses.warehouse-asn.request_package_list': 'Request Ppacakges',
  'warehouses.warehouse-asn.scanned_package_title': '{asn_no} Scanned Packages',
  'warehouses.warehouse-asn.scanned_package_list': 'Scanned Pacakges',
};
