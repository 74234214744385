export default {
  'courier-services.company.title': 'Services List',
  'courier-services.service_code': 'Service Code',
  'courier-services.service_channel': 'Service Channel',
  'courier-services.country': 'Country / Region',
  'courier-services.destination_country': 'Destination Country / Region',
  'courier-services.export_import_label': 'Export / Import',
  'courier-services.weight_break_from': 'Weight Break From',
  'courier-services.weight_break_to': 'Weight Break To',
  'courier-services.warehouse_address': 'Warehouse Address',
};
