export default {
  'drivers.name': 'Name',
  'drivers.license_plate': 'License Plate',
  'drivers.contract': 'Contract',
  'drivers.create': 'Create Driver',
  'drivers.create.fail': 'Fail to create driver',
  'drivers.create.success': 'Driver has been succesfully created',
  'drivers.update.fail': 'Fail to update driver',
  'drivers.update': 'Update',
  'drivers.update.success': 'Driver has been succesfully updated',
};
