export default {
  'company-services.company_name': 'Company Name',
  'company-services.courier_service': 'Courier Service',
  'company-services.program_name': 'Program Name',
  'company-services.currency_code': 'Currency Code',
  'courier-services.markup': 'Markup',
  'courier-services.shipper': 'Shipper',
  'company-services.service_type': 'Service Type',
  'company-services.valid_till': 'Valid Till',
  'company-services.aftership_courier': 'Tracking By Aftership',
  'company-services.update': 'Update',
  'company-services.status': 'Status',
  'company-services.disable-confirm': 'Are you sure you want to disable this services?',
  'company-services.enable-confirm': 'Are you sure you want to enable this services?',
  'courier-services.disable.success': 'Service has been successfully disabled',
  'courier-services.enable.success': 'Service has been successfully enabled',
  'courier-services.disable.fail': 'Fail to disable service',
  'company-services.message.aftership_courier': 'Please select aftership courier',
  'courier-services.enable.fail': 'Fail to enable service',
  'company-services.update.success': 'Service has been succesfully updated',
};
