export default {
  'tools.menu.offline_inbound': '收貨箱面(Offline)',
  'tools.menu.offline_inbound.tooltip': '收貨箱面Label打印(Offline)',
  'tools.print_contianer.print_inbound_box': '列印分揀箱條碼',
  'tools.print_contianer.print_outbound_box': 'Print Outbound Box',
  'tools.print_contianer.generate_qty': '箱數',
  'tools.print_contianer.generate-container': '新增箱號',
  'tools.print_contianer.only_one_sku': '1 箱 1 SKU',
  'tools.print_trolley.generate_qty': 'Generate Qty',
  'tools.print_trolley.generate-trolley': 'Generate Trolley Code',
  'tools.print_contianer.unsealed_inbound_box': '未使用分揀箱',
  'tools.print_contianer.sealed_inbound_box': '已使用分揀箱',
  'tools.please-input': '請輸入',
  'tools.please-select': '請選擇',
  'tools.offline_inbound.title': '收貨箱面Label打印(Offline)',
  'tools.print_label': '打印箱面Label',
  'tools.offline_inbound.box_id': '箱號',
  'tools.offline_inbound.use_type': '箱面Label用途',
  'tools.offline_inbound.warehouse': '收貨倉庫',
  'tools.offline_inbound.customer_order_no': 'PO No.',
  'tools.offline_inbound.product': '產品名稱',
  'tools.offline_inbound.product_category': '產品類型',
  'tools.offline_inbound.production_batch_code': '產品批次號',
  'tools.offline_inbound.production_date': '出廠日期',
  'tools.offline_inbound.expiry_date': '到期日期',
  'tools.offline_inbound.product_qty': '數量',
  'tools.offline_inbound.scan_barcode': '掃描(產品條碼)',
  'tools.use_type_normal': '正品',
  'tools.use_type_damage': '殘品',
  'tools.offline-inbound.box_id': 'Box ID',
  'tools.offline_inbound.month': '月',
  'tools.offline_inbound.net_weight': '淨重',
  'tools.offline_inbound.gross_weight': '毛重',
  'tools.offline_inbound.gross_weight_valid': '毛重必須大於0, 請重試',
  'tools.offline_inbound.require_add_product':
    '未找到對應產品條碼{bar_code}產品, 請查看產品是否已經創建？',
  'tools.offline_inbound.length': '長',
  'tools.offline_inbound.width': '闊',
  'tools.offline_inbound.height': '高',
  'tools.offline_inbound.valid_month': '保質期(月)',
  'tools.print_contianer.print_container_code': '列印分揀箱條碼',
  'tools.download-selected-container-confirm': '真的要下載所選取箱子的條碼嗎?',
  'tools.download-selected-container-button': '下載選取箱子的條碼',
};
