export default {
  'note-modal.title': 'Note For {document_no}',
  'note-modal.close': 'Close',
  'note.creator': 'Creator',
  'note.count': 'View notes',
  'note.note': 'Note',
  'note.add': 'Add Note',
  'note.add-document': 'Add Note - {document_no}',
  'note.delete-confirm': 'Are you sure to delete this note?',
  'activity.title': 'Notes & Activities',
  'activity.show.label': 'Show',
  'activity.note.label': 'Note',
  'activity.notes.label': 'Notes',
  'activity.history.label': 'Item History',
  'activity.material.history.label': 'Material History',

  'place-holder.please-enter-title': 'Please enter title',
  'place-holder.please-display-title': 'Please enter display title',
  'file.uploading': 'Uploading...',
  'file.deleting': 'Deleting...',
  'file.downloading': 'Downloading...',
  'file.download.success': 'File has been downloaded',
  'file.loaded.success': 'File has been loaded',
  'file.download.fail': 'Fail to download',
  'file.update.fail': 'Fail to update file info',
  'file.delete.fail': 'Fail to delete file',
  'file.upload.fail': 'Fail to upload file(s)',
  'file.creator': 'Creator',
  'file.action': 'Action',
  'file.upload-by': 'Uploaded By',
  'file.upload-at': 'Uploaded At',
  'file.count': 'View files',
  'file.add': 'Add files',
  'file.edit': 'Edit file',
  'file-modal.title': 'File for {document_no}',
  'pod-file-modal.title': 'POD File for {document_no}',
  'file-edit.info': 'Edit - {file_name}',
  'file-modal.close': 'Close',
  'file.upload.modal.title': 'Upload Files',
  'file.title': 'Files',
  'file.file': 'File',
  'file.file-name': 'File Name',
  'file.file-type': 'File Type',
  'file.type.placeholder': 'Select File Type',
  'file.remark': 'Remark',
  'file.attach': 'Mail Attachment',
  'file.attach.validation.remark.empty': 'Email attachment remark cannot be empty',
  'file.attach.limit':
    'Files are existed most mailbox support limit on attachment file size. Please reduce the size/number of attachment then submit again.',
  'file.extra-info': 'Extra Information',
  'file.label-expiry-date': 'Expiry Date',
  'file.hint-required-expiry-date': 'Please select expiry date',
  'file.remark.placeholder': 'General remark for this file',
  'file.upload.leave': 'There are some files pending for upload, are you sure you want to leave?',
  'file.button.leave': 'Leave',
  'file.button.stay': 'Stay',
  'file.title.remove': 'Please put a reason if you want to remove this file.',
  'file.button.remove': 'Remove',
  'file.validation.select.filetype': 'Need to select a file type for {filename}',
  'file.delete-confirm': 'Are you sure to delete this file?',
  'item-discount.negative-amount.warning': 'Item line total cannot be negative after discount',
  'common.invalid-discount-reasonable-value': "Discount can't exceed the range",
  'common.input-value-gt-min-val': 'Min input value needs to be greater than {value}',
  'common.input-value-le-max-val': 'Max input value cannot be greater than {value}',
  'document.items.order-discount': 'Discount',
  'document.items.order-total-amount': 'Total Amount',
  'document.items.order-total-cost': 'Total Cost',
  'document.items.order-total-profit': 'Total Profit',
  'document.items.order-total-margin': 'Total Margin',
  'document.items-group.add': 'Create Product Group',
  'document.items-group.add.title': 'Create Product Group',
  'document.items-group.label': 'Group',
  'document.items-group-description.label': 'Group Description',
  'document.items-group.product-option.warning':
    ' (Only the product with a minimum price in the group will count to the Quoted Total in the Cost Comparison of the QRM.)',
  'document.items-group.update.title': 'Update Product Group',
  'document.product.group-type.label': 'Group Type',
  'document.assignee.label': 'Assignee',
  'document.edit-order-discount': 'Edit Document Order Level Discount',
  'document.order-items.discount-type': 'Discount Type',
  'document.order-items.order-discount': 'Discount',
  'document.order-items.order-total-amount': 'Total Amount',
  'document.warning.title':
    'Document status is not work in progress, do you want to revise it now?',
  'document.button.revise_now': 'Revise Now',
  'document.button.cancel': 'Cancel',
  'document.button.preview': 'Preview',
  'document.button.download': 'Download',
  'document.button.download-files': 'Download Files',
  'document.button.download-pdf': 'Download PDF',
  'document.button.download-excel': 'Download Excel',
  'document.button.relation': 'Show Relation',
  'document.button.history': 'History',
  'document.button.load_all': 'Load All',
  'document.relation': 'Document Relation',
  'document.progress': 'Progress',
  'document.history': 'Document History',
  'document.history.document-no': 'Document No',
  'document.history.version': 'Version',
  'document.history.created-at': 'Archive Date',
  'document.history.download': 'Download',
  'document.go-to': 'Go to {document_no}',
  'document.external-reference': 'Ref PO No.',
  'document.document_no.label': 'Document No.',
  'document.version.label': 'Version',
  'document.amount.label': 'Amount (HKD)',
  'document.status': 'Status',
  'document.status.label': 'Internal Status',
  'document.project.label': 'Project',
  'document.expiry-date.label': 'Expiry Date',
  'document.revised_by.label': 'Revised By',
  'document.pdf.label': 'Pdf',
  'document.submit_to_contractor_date.label': 'Submit To Contractor Date',
  'document.submit_to_contractor_by.label': 'Submit To Contractor By',
  'document.none.label': 'None',
  'document.last-updated-by': 'Last Updated by',
  'document.last-updated-at': 'Last Updated At',
  'document.add-items-before-submit': 'Please add some items before Submit',
  'document.sap_document_parked': 'SAP Document No.',
  'document.settlement_at': 'Settlement Date',
  'document.last_updated': 'Last Updated',
  'document.last_updated.content': 'by { updater } at { datetime }',
  'document.document_no': 'Document No.',
  'document.no-selected-item-warning':
    'Please select some items before update order level discount',
  'add-order-item.form.search': 'Search Product',
  'add-order-item.form.search_placeholder': 'You Can Add Item By Search Result Or Add a new item',
  'add-order-item.form.search-required-message': '',
  'add-order-item.form.create-product': 'Create Product',
  'add-order-item.form.no-products-found-message':
    'Can not find the product you want, you can create a new',
  'add-order-item.form.item_name': 'Product Name',
  'add-order-item.form.item_desc': 'Product Description',
  'add-order-item.form.category': 'Category',
  'add-order-item.form.unit': 'Unit',
  'add-order-item.form.supplier-item-code': 'Supplier Product Code',
  'add-order-item.form.supplier': 'Supplier',
  'add-order-item.form.unit-cost': 'Unit Cost',
  'add-order-item.form.unit-price': 'Unit Rate',
  'add-order-item.form.unit-price-gt-0': 'Unit Rate needs to be greater than 0',
  'add-order-item.form.markup': 'MarkUp',
  'add-order-item.form.qty': 'Quantity',
  'add-order-item.form.qty-gt-0': 'Quantity needs to be greater than 0',
  'add-order-item.form.unit-discount': 'Unit Rate Discount',
  'add-order-item.form.rate-discount': 'Rate Discount',
  'add-order-item.form.line-discount': 'Line Amount Discount',
  'add-order-item.form.item-remark': 'Item remark',
  'add-order-item.form.buyer-pr-no': 'Ref PR No.',
  'add-order-item.form.free-product': 'Free Product',
  'add-order-item.form.budget_code': 'Budget Code',
  'add-order-item.table.unit-price': 'Unit Rate',
  'add-order-item.table.unit-discount': 'Unit Discount',
  'add-order-item.table.unit-price-after-discount': 'Unit Rate After Discount',
  'add-order-item.table.qty': 'Quantity',
  'add-order-item.table.rental-qty': 'Rental Qty',
  'add-order-item.table.subtotal': 'Subtotal',
  'add-order-item.table.line-discount': 'Line Discount',
  'add-order-item.table.line-total': 'Line Total',
  'add-order-item.table.total_cost': 'Total Cost',
  'add-order-item.table.total_profit': 'Total Profit',
  'add-order-item.table.total_margin': 'Total Margin',
  'products.unit': 'Unit',
  'asn.status.canceled': 'Canceled',
  'asn.status.processing': 'Processing',
  'asn.status.confirm': 'Warehouse Received',
  'asn.status.partially': 'Partially Warehouse Received',
  'asn.status.over': 'Over Warehouse Received',
  'common.message.generating': 'Generating...',
  'common.client_webhook_status': 'Client Feedback Status',
};
