import { List, Modal, Typography } from 'antd';

const { Title } = Typography;

export interface ValidateDataType {
  validations: AuthValidationMessage[] | ValidationOmsLabelAddress | ValidationOMSMessage;
  message?: string;
  status?: string;
}
type AuthValidationMessage = {
  message: string;
  data?: any;
};

export type ValidationOMSMessage = Record<string, string[]>;

export type ValidationOmsLabelAddress = {
  address_validate: LabelAddressValidate;
};
type LabelAddressValidate = {
  warning: string;
  recommend_addresses: LabelRecommendAddress[];
};

export type LabelRecommendAddress = {
  city: string;
  postal_code: string;
  country_code: string;
  suburb: string | null;
};

const ValidateWarning = (data: ValidateDataType) => {
  const showOMSMessage = (validations: ValidationOMSMessage) => {
    return Object.keys(validations).map((key, index) => {
      return (
        <List
          split={false}
          size="small"
          dataSource={validations[key]}
          renderItem={(item: string) => (
            <List.Item>
              <Title level={3}>
                {index + 1}. {item}
              </Title>
            </List.Item>
          )}
        />
      );
    });
  };

  const showOMSLabelAddressMessage = (validations: ValidationOmsLabelAddress) => {
    return (
      <List
        split={false}
        size="small"
        header={<div>Recommend Addresses:</div>}
        dataSource={validations.address_validate.recommend_addresses}
        renderItem={(item: LabelRecommendAddress, index: number) => (
          <List.Item>
            {index + 1}.<span style={{ padding: 10 }}>City: {item.city}</span>
            <span style={{ padding: 10 }}>Country Code: {item.country_code}</span>
            <span style={{ padding: 10 }}>Postal Code: {item.postal_code}</span>
            <span style={{ padding: 10 }}>Suburb: {item.suburb}</span>
          </List.Item>
        )}
      />
    );
  };

  const content = (validations: AuthValidationMessage[]) => (
    <List
      split={false}
      size="small"
      dataSource={validations}
      renderItem={(item: { message: string }, index: number) => (
        <List.Item>
          <Title level={3}>
            {index + 1}. {item.message}
          </Title>
        </List.Item>
      )}
    />
  );

  const showContent = (
    validations: AuthValidationMessage[] | ValidationOMSMessage | ValidationOmsLabelAddress,
  ) => {
    if (Array.isArray(validations)) {
      return content(validations as AuthValidationMessage[]);
    }
    if (validations.address_validate) {
      return showOMSLabelAddressMessage(validations as ValidationOmsLabelAddress);
    }
    return showOMSMessage(validations as ValidationOMSMessage);
  };

  Modal.warning({
    title: data.message ? data.message : data?.status,
    content: showContent(data.validations),
    width: 720,
  });
};

export default ValidateWarning;
