export default {
  'menu.welcome': '歡迎',
  'menu.home': '首頁',
  'menu.back_to_home': '返回首頁',
  'menu.login': '登錄',
  'menu.dashboard': '儀表板',
  'menu.api-integration': 'API Integration',
  'menu.terms-and-conditions': 'Terms and Conditions',
  'menu.register': '註冊',
  'menu.register.result': '註冊結果',
  'menu.account': '個人頁',
  'menu.account.center': '個人中心',
  'menu.account.settings': '個人設置',
  'menu.account.trigger': '觸發報錯',
  'menu.account.logout': '退出登錄',
  'menu.account.account-recovery': '帳號恢復',
  'menu.reset-password': '重置密碼',
  'menu.settings': '設置',
  'menu.privacy-policy': 'Privacy Policy',
  'menu.shipment.settings.courier-services': 'Services',
  'menu.shipment.settings.courier-services.service_edit': 'Edit Service',
  'menu.shipment.settings.customer-rate-service': 'Customer Service',
  'menu.shipment.settings.couriers': 'Couriers',
  'menu.shipment.settings.courier-platform-methods': 'Platform Service',
  'menu.shipment.settings.suppliers': 'Suppliers',
  'menu.shipment.settings.warehouses': 'Warehouses',
  'menu.shipment.settings.shipper-accounts': 'Shipper Accounts',
  'menu.shipment.quote': 'Get Quotes',
  'menu.shipment.quote.quoteImport': 'Import Quote',
  'menu.shipment.quote.quoteExport': 'Export Quote',
  'menu.shipment.quote.quoteDocument': 'Document Quote',
  'menu.shipment.quote.hongkongLocalTruck': 'Hong Kong Truck',
  'menu.shipment.download_price_list': 'Rate Card',
  'menu.shipment.download_announcement': 'Notices',
  'menu.shipment.label': 'Create Labels',
  'menu.shipment.labelCreate': 'Create Single Shipment',
  'menu.shipment.labelUpload': 'Create Multi Shipments',
  'menu.shipment.labelManage': 'Shipment Summary',
  'menu.shipment.labelManage.labelEdit': 'Edit Label',
  'menu.shipment.labelManage.pendingLabel': 'Pending',
  'menu.shipment.labelManage.rejectedLabel': 'Rejected',
  'menu.shipment.labelManage.toDownload': 'Download',
  'menu.shipment.labelManage.labelDetail': 'Label Detail',
  'menu.shipment.labelManage.wmsPreorder': 'Pre Order(WMS)',
  'menu.shipment.labelManage.wmsInProgress': 'Work In Porgress(WMS)',
  'menu.shipment.labelManage.wmsShipped': 'Shipped (WMS)',
  'menu.shipment': 'Shipment',
  'menu.shipment.settings': '設置',
  'menu.shipment.label.labelCreate': 'Basic Create',
  'menu.shipment.label.labelUpload': 'Bulk Upload',
  'menu.shipment.labelsStatus': 'Labels Status',
  'menu.shipment.track-trace': 'Track & Trace',
  'menu.shipment.pickup-request': 'Pick Up Requests',
  'menu.shipment.delivery-booking': 'Booking Appointment',
  'menu.shipment.delivery-booking.booking_edit': 'Edit Booking',
  'menu.shipment.shipment': 'Shipments',
  'menu.shipment.shipment.shipmentSummary': 'Shipment Summary',
  'menu.shipment.shipment.shipmentSummary.all-shipments': 'All Shipments',
  'menu.shipment.shipment.shipmentSummary.assign-shipment': 'Assign Shipment',
  'menu.shipment.shipment.shipmentSummary.upload-lastmile': 'Upload Lastmile',
  'menu.shipment.shipment.shipmentSummary.send-manifest': 'Send Manifest',
  'menu.shipment.shipment.shipmentSummary.manual-shipments': 'Manual Shipments',
  'menu.shipment.shipment.shipmentTracking': 'Shipment Tracking',
  'menu.shipment.shipment.delivery-booking': 'Booking (Re-Label) ',
  'menu.shipment.shipment.delivery-booking.booking-orders': 'Booking Orders',
  'menu.shipment.shipment.delivery-booking.prepare': 'Prepare',
  'menu.shipment.shipment.delivery-booking.scan': 'Scan',
  'menu.shipment.shipment.delivery-booking.dispatch': 'Dispatch',
  'menu.shipment.shipment.delivery-booking.upload-lastmile': 'Upload Lastmile',
  'menu.shipment.shipment.delivery-booking.send-manifest': 'Send Manifest',
  'menu.shipment.shipment.delivery-booking.booking_edit': 'Edit Booking',
  'menu.shipment.manage-service': 'Manage Service',
  'menu.shipment.manage-service.courier-services': 'Courier Service',
  'menu.shipment.manage-service.courier-services.service_detail': 'Service Details',
  'menu.shipment.manage-service.customer-rate-service': 'Customer Service',
  'menu.shipment.warehouses': 'Warehouses',
  'menu.shipment.service-channel': 'Service Channel',
  'menu.shipment.warehouses.warehouse-scan': 'Warehouse Scan',
  'menu.shipment.warehouses.advance-shipment-notice': 'ASN',
  'menu.shipment.warehouses.gate-charge': 'Gate Charge',
  'menu.warehouse-bills': 'Warehouse Bill',
  'menu.warehouse-bills.edit': 'Edit Warehouse Bill',
  'menu.shipment.courier-services': 'Services Channel',
  'menu.shipment.service-costs': 'System Costs',
  'menu.shipment.service-costs.services': 'System Costs',
  'menu.shipment.service-spots.customer-spot-rates': 'Spot Quote',
  'menu.shipment.service-costs.effectives': 'Effectives',
  'menu.shipment.service-costs.fee-trails': 'Rates Matrix',
  'menu.shipment.service-costs.upload': 'Upload Costs',
  'menu.shipment.service-costs.upload-location-zone': 'Upload Location Zone',
  'menu.shipment.service-spots': 'Spots',
  'menu.shipment.service-spots.service-spot-costs': 'Spot Costs',
  'menu.shipment.service-spots.comstomer-spot-rates': 'Spot Quote',
  'menu.shipment.address-books': 'Address Book',
  'menu.marketplaceManage': 'Marketplace',
  'menu.marketplaceManage.order': 'Orders',
  'menu.marketplaceManage.order-fulfillment': 'Order Fulfillment',
  'menu.marketplaceManage.order-fulfillment.platform-order': 'Platform Order(All)',
  'menu.marketplaceManage.order-fulfillment.new-order': 'New Order',
  'menu.marketplaceManage.order-fulfillment.upload-to-create-label': 'Upload Order',
  'menu.marketplaceManage.order-fulfillment.ready-to-ship': 'Ready To Ship',
  'menu.marketplaceManage.order-fulfillment.shipped-order': 'Shipped Order',
  'menu.marketplaceManage.order-fulfillment.orderDetail': 'Order Detail',
  'menu.marketplaceManage.order-fulfillment.rejected-label': 'Rejected Label',
  'menu.marketplaceManage.store': 'Stores',
  'menu.marketplaceManage.marketplace-commission': 'Commission',
  'menu.marketplaceManage.marketplace-category': 'Marketplace Category',
  'menu.marketplaceManage.payment-gateway': 'Payment Gateway',
  'menu.warehouses': 'Warehouses',
  'menu.warehouses.warehouse-scan': 'Warehouse Scan',
  'menu.warehouses.advance-shipment-notice': 'ASN',
  'menu.warehouses.gate-charge': 'Gate Charge',
  'menu.service-channel': 'Service Channel',
  'menu.address-books': 'Address Book',
  'menu.productManage': 'Product Management',
  'menu.productManage.products': '產品',
  'menu.productManage.products.edit': '修改產品',
  'menu.productManage.product-prices': 'Product Prices',
  'menu.productManage.inbound': 'Inbound',
  'menu.productManage.inbound.edit': 'Edit Inbound',
  'menu.productManage.rma-order': 'RMA Management',
  'menu.productManage.rma-order.edit': 'RMA Management Edit',
  'menu.productManage.outbound': 'Outbound',
  'menu.productManage.outbound.edit': 'Edit Outbound',
  'menu.productManage.inventory': 'Inventory',
  'menu.productManage.warehouse-bills': 'Warehouse Bill',
  'menu.productManage.warehouse-bills.edit': 'Edit Warehouse Bill',
  'menu.productManage.product-supplier-costs': 'Product Supplier Costs',
  'menu.productManage.report': '報表',
  'menu.productManage.calculator-profit-jobs': 'Calculator Profit Jobs',
  'menu.productManage.calculator-profit-jobs.upload-supplier-cost-jobs':
    'Upload Supplier Cost Jobs',
  'menu.productManage.calculator-profit-jobs.update-category-commission-jobs':
    'Update Category Commission Jobs',
  'menu.productManage.calculator-profit-jobs.update-payment-commssion-jobs':
    'Update Payment Gateway Commission Jobs',
  'menu.productManage.product-category': 'Product Category',
  'menu.productManage.suppliers': 'Supplier',
  'menu.productManage.currency': 'Currency',
  'menu.productManage.exchange-rate': 'Exchange Rate',
  'menu.productManage.setting': 'Setting',
  'menu.productManage.setting.product-category': 'Product Category',
  'menu.productManage.setting.suppliers': 'Supplier',
  'menu.productManage.setting.currency': 'Currency',
  'menu.productManage.setting.merchant': 'Merchant',
  'menu.productManage.setting.exchange-rate': 'Exchange Rate',
  'menu.productManage.setting.marketplace-commission': 'Commission',
  'menu.productManage.setting.marketplace-category': 'Marketplace Category',
  'menu.productManage.setting.payment-gateway': 'Payment Gateway',
  'menu.productManage.wmsManage': 'WMS',
  'menu.productManage.line': 'Line',
  'menu.products': '產品',
  'menu.products.edit': '修改產品',
  'menu.product-prices': 'Product Prices',
  'menu.product-supplier-costs': 'Product Supplier Costs',
  'menu.wms-dashboard': '儀表板',
  'menu.report': '報表',
  'menu.calculator-profit-jobs': 'Calculator Profit Jobs',
  'menu.calculator-profit-jobs.upload-supplier-cost-jobs': 'Upload Supplier Cost Jobs',
  'menu.calculator-profit-jobs.update-category-commission-jobs': 'Update Category Commission Jobs',
  'menu.calculator-profit-jobs.update-payment-commssion-jobs':
    'Update Payment Gateway Commission Jobs',
  'menu.setting': 'Setting',
  'menu.setting.product-category': 'Product Category',
  'menu.setting.merchant': 'Merchant',
  'menu.setting.suppliers': 'Supplier',
  'menu.setting.currency': 'Currency',
  'menu.setting.exchange-rate': 'Exchange Rate',
  'menu.setting.marketplace-commission': 'Commission',
  'menu.setting.marketplace-category': 'Marketplace Category',
  'menu.setting.payment-gateway': 'Payment Gateway',
  'menu.product-category': 'Product Category',
  'menu.suppliers': 'Supplier',
  'menu.currency': 'Currency',
  'menu.exchange-rate': 'Exchange Rate',
  'menu.marketplace-commission': 'Commission',
  'menu.marketplace-category': 'Marketplace Category',
  'menu.payment-gateway': 'Payment Gateway',

  'menu.wmsManage': 'WMS',
  'menu.wmsManage.wms-dashboard': '儀表板',
  'menu.wmsManage.inbound.asn': 'ASN',
  'menu.wmsManage.inbound.inspection': '質量檢查',
  'menu.wmsManage.inbound.sorting': '分揀',
  'menu.wmsManage.inbound.print-label': '打印Label',
  'menu.wmsManage.inbound.sorting-report': '分揀報表',
  'menu.wmsManage.inbound.put-away': '上架',
  'menu.wmsManage.inbound.upload-receiving': '上載收貨結果',
  'menu.wmsManage.inventoryManage.edit-stocktake': '編輯人工區 - 盤點單',
  'menu.wmsManage.inbound': '入庫單',
  'menu.wmsManage.inbound.edit': '編輯入庫單',
  'menu.wmsManage.amr-replenishment': 'AMR 補貨',
  'menu.wmsManage.amr-replenishment.create': '建立 AMR 補貨單',
  'menu.wmsManage.amr-replenishment.products': 'AMR 補貨產品',
  'menu.wmsManage.amr-replenishment.movement-temp-zone': '從臨時區域移庫',
  'menu.wmsManage.amr-replenishment.edit': 'AMR 補貨',
  'menu.wmsManage.amr-replenishment.picking': '揀貨到 AMR',
  'menu.wmsManage.amr-replenishment.received': 'AMR 區域接收',
  'menu.wmsManage.amr-replenishment.request-items': '準備補貨的產品',
  'menu.wmsManage.amr-replenishment.return': '回單到 AMR',
  'menu.wmsManage.amr-replenishment.replenishment-orders': '補貨單',
  'menu.wmsManage.tools': '工具',
  'menu.wmsManage.report': '報表',
  'menu.wmsManage.tools.print-container': '列印分揀箱',
  'menu.wmsManage.tools.print-offline-inbound': 'Print Offline Inbound',
  'menu.wmsManage.tools.print-outbound-carton': 'Print Outbound Carton',
  'menu.wmsManage.tools.print-warehouse-trolley': 'Print Warehouse Trolley',
  'menu.wmsManage.outbound.dashboard': 'Outbound Dashboard',
  'menu.wmsManage.outbound': '出貨單',
  'menu.wmsManage.outbound.edit': '編輯出貨單資料',
  'menu.wmsManage.outbound.amr-pick-order': 'AMR區出貨單',
  'menu.wmsManage.outbound.no-amr-pick-order': '人工區出貨單',
  'menu.wmsManage.outbound.deliveryNote': '出貨單',
  'menu.wmsManage.outbound.packlist': 'Packlist',
  'menu.wmsManage.outbound.dispatch': '訂單 - 出庫',
  'menu.wmsManage.outbound.upload-dispatch': 'Upload Dispatch',
  'menu.wmsManage.outbound.new-order': '新出貨單',
  'menu.wmsManage.outbound.amr-release-container': 'AMR揀貨箱(回傳)',
  'menu.wmsManage.outbound-pick': '訂單-揀貨',
  'menu.wmsManage.outbound-pick.amr-picklist': 'AMR-揀貨任務',
  'menu.wmsManage.outbound-pick.no-amr-picklist': '人工區-揀貨任務',
  'menu.wmsManage.outbound-pick.no-amr-pick': '人工區-揀貨',
  'menu.wmsManage.outbound-pick.no-amr-container-pick': '人工區(掃箱號) 揀貨',
  'menu.wmsManage.outbound-pack': '訂單-打包',
  'menu.wmsManage.outbound-pack.pack-order': '打包',
  'menu.wmsManage.outbound-pack.scan-to-pack': '掃描訂單',
  'menu.wmsManage.outbound-pack.pack-waybill': '打印包裝Label',
  'menu.wmsManage.outbound-pack.no-amr-pick-pack': '人工區-揀貨&包裝',
  'menu.wmsManage.outbound-pack.reprint': '重新打印',
  'menu.wmsManage.outbound-pack.reinput-weight': '重新磅度',
  'menu.wmsManage.outbound-dispatch': '訂單-出庫',
  'menu.wmsManage.outbound-dispatch.amr-dispatch': '訂單-出庫',
  'menu.wmsManage.outbound-dispatch.no-amr-dispatch': '人工區-出庫',
  'menu.wmsManage.outbound-return': '訂單-回貨',
  'menu.wmsManage.outbound-return.return': '回單到人工區',
  'menu.wmsManage.outbound-return.put-away': '重新上架',
  'menu.wmsManage.outbound-return.return-orders': '回貨單',
  'menu.wmsManage.outbound-return.new-item': '回貨單列表',
  'menu.wmsManage.outbound-dispatch.common-dispatch': '出貨',
  'menu.wmsManage.outbound-dispatch.manifest': '載貨清單',

  'menu.wmsManage.amr-outbound': 'AMR 出貨',
  'menu.wmsManage.amr-outbound.amr-dispatch': 'AMR 出貨',
  'menu.wmsManage.amr-outbound.amr-picking': 'AMR 揀貨單(DN)',
  'menu.wmsManage.amr-outbound.amr-packlist': 'AMR Packlist',
  'menu.wmsManage.amr-outbound.pack-order': 'Pack Order',

  'menu.wmsManage.pickPackVas': 'Pick & Pack VAS',
  'menu.wmsManage.pickPackVas.marketplace': 'Marketplace',
  'menu.wmsManage.pickPackVas.pick-pack-vas-flow': 'Pick & Pack VAS Flow',
  'menu.wmsManage.pickPackVas.marketplace-pick-pack-vas': 'Marketplace Pick & Pack VAS',
  'menu.wmsManage.outbound-console': 'Outbound Console',
  'menu.wmsManage.outbound-console.console': 'Console',
  'menu.wmsManage.outbound-console.create': 'Create Console',
  'menu.wmsManage.outbound-console.edit': 'Edit Outbound Console',
  'menu.wmsManage.outbound-console.dispatch': 'Dispatch Console',
  'menu.wmsManage.outbound-console.console-order': 'Console Order',
  'menu.wmsManage.transfer-pick-order': '下架單',
  'menu.wmsManage.transfer-pick-order.new-transfer': '下架單',
  'menu.wmsManage.transfer-pick-order.no-amr-pick': '人工區 - 揀貨(下架)',
  'menu.wmsManage.transfer-pick-order.sorting': '重新分揀 ',
  'menu.wmsManage.transfer-pick-order.put-away': '重新上架 ',
  'menu.wmsManage.transfer-pick-order.sorting-report': '分揀報告',
  'menu.wmsManage.transfer-pick-order.edit': '編輯下架單',
  'menu.wmsManage.inventory': 'Inventory',
  'menu.wmsManage.products': 'Products',
  'menu.wmsManage.products.wms-provider-products': 'WMS Provider Products',
  'menu.wmsManage.products.edit': '修改產品',
  'menu.wmsManage.warehouseSetting': '倉庫設置',
  'menu.wmsManage.warehouseSetting.warehouses': '倉庫',
  'menu.wmsManage.warehouseSetting.warehouses.warehouse_edit': 'Warehouse Edit',
  'menu.wmsManage.warehouseSetting.virtual-warehouses': '虛擬倉庫',
  'menu.wmsManage.warehouseSetting.warehouse-zone': '倉庫區域',
  'menu.wmsManage.warehouseSetting.warehouse-bin': '倉庫架位',
  'menu.wmsManage.warehouseSetting.warehouse-bin-size': '架位體積',
  'menu.wmsManage.rma-order': 'RMA Order',
  'menu.wmsManage.rma-order.edit': 'RMA Order Edit',
  'menu.settlementCenter': 'Settlement Center',
  'menu.wmsManage.settlementCenter': 'Settlement Center',
  'menu.wmsManage.settlementCenter.company-wms-quotations': 'WMS Quotation',
  'menu.wmsManage.settlementCenter.company-wms-quotations.edit': 'Edit WMS Quotation',
  'menu.wmsManage.settlementCenter.bill-manage': 'Bill Management',
  'menu.wmsManage.settlementCenter.bill-manage.edit': 'Edit Warehouse Bill',
  'menu.wmsManage.settlementCenter.bill-manage.warehouse-bills': 'Warehouse Bill',
  'menu.wmsManage.settlementCenter.bill-manage.warehouse-bills.edit': 'Edit Warehouse Bill',
  'menu.wmsManage.settlementCenter.bill-manage.warehouse-rent-bills': 'Warehouse Rent Bill',
  'menu.wmsManage.settlementCenter.bill-manage.warehouse-rent-bill-items': 'Warehouse Rent',
  'menu.wmsManage.settlementCenter.bill-manage.inbound-charges': 'Inbound Charges',
  'menu.wmsManage.settlementCenter.bill-manage.outbound-charges': 'Outbound Charges',
  'menu.settlementCenter.company-wms-quotations': 'WMS Quotation',
  'menu.settlementCenter.company-wms-quotations.edit': 'Edit Wms Quotation',
  'menu.settlementCenter.bill-manage': 'Bill Management',
  'menu.settlementCenter.bill-manage.warehouse-bills': 'Bill Management',
  'menu.settlementCenter.bill-manage.warehouse-bills.edit': 'Edit Bill Management',
  'menu.settlementCenter.bill-manage.warehouse-rent-bills': 'Warehouse Rent Bill',
  'menu.settlementCenter.bill-manage.warehouse-rent-bill-items': 'Warehouse Rent',
  'menu.settlementCenter.bill-manage.inbound-charges': 'Inbound Charges',
  'menu.settlementCenter.bill-manage.outbound-charges': 'Outbound Charges',

  'menu.client-webhooks': 'Client Webhooks',
  'menu.wmsManage.drivers': 'Drivers',
  'menu.wmsManage.merchants': 'Merchants',
  'menu.wmsManage.line': 'Line',
  'menu.wmsManage.client-webhooks': 'Client Webhooks',
  'menu.wmsManage.productManage': 'Product Management',
  'menu.inbound': '入庫',
  'menu.inbound.edit': '入庫單資料',
  'menu.inbound.asn': '入庫清單 (ASN)',
  'menu.inbound.inspection': 'Inspection',
  'menu.inbound.sorting': '分揀',
  'menu.inbound.print-label': '列印標籤',
  'menu.inbound.sorting-report': '分揀報告',
  'menu.inbound.put-away': '上架',
  'menu.inbound.upload-receiving': '上載收貨結果',
  'menu.amr-replenishment': 'AMR 補貨',
  'menu.amr-replenishment.create': '建立新的補貨單',
  'menu.amr-replenishment.movement-temp-zone': '從臨時區域移動',
  'menu.amr-replenishment.products': 'AMR 補貨產品',
  'menu.amr-replenishment.return': '退回 DN',
  'menu.amr-replenishment.edit': 'AMR 補貨',
  'menu.amr-replenishment.picking': '揀貨到 AMR',
  'menu.amr-replenishment.received': 'AMR 區域接收',
  'menu.amr-replenishment.request-items': '準備補貨的產品',
  'menu.amr-replenishment.replenishment-orders': '補貨單',
  'menu.tools': '工具',
  'menu.tools.print-container': '列印分揀箱',
  'menu.tools.print-offline-inbound': 'Print Offline Inbound',
  'menu.tools.print-warehouse-trolley': 'Print Warehouse Trolley',
  'menu.rma-order': 'RMA Management',
  'menu.rma-order.edit': 'RMA Management Edit',
  'menu.outbound.dashboard': 'Outbound Dashboard',
  'menu.outbound': '出貨單',
  'menu.outbound.edit': '編輯出貨單資料',
  'menu.outbound.amr-pick-order': 'AMR區出貨單',
  'menu.outbound.no-amr-pick-order': '人工區出貨單',
  'menu.outbound.deliveryNote': '出貨單',
  'menu.outbound.packlist': 'Packlist',
  'menu.outbound.dispatch': '訂單 - 出庫',
  'menu.outbound.upload-dispatch': 'Upload Dispatch',
  'menu.outbound.new-order': '新出貨單',
  'menu.outbound.amr-release-container': '釋放週轉箱',
  'menu.outbound.transfer-pending-outbound': '等待下架單完成(訂單)',
  'menu.outbound-pick': '訂單-揀貨',
  'menu.outbound-pick.amr-picklist': 'AMR-揀貨任務',
  'menu.outbound-pick.no-amr-picklist': '人工區-揀貨任務',
  'menu.outbound-pick.no-amr-pick': '人工區-揀貨',
  'menu.outbound-pick.no-amr-container-pick': '人工區(掃箱號) 揀貨',
  'menu.outbound-pack': '訂單-打包',
  'menu.outbound-pack.pack-order': '打包',
  'menu.outbound-pack.scan-to-pack': '掃描訂單',
  'menu.outbound-pack.pack-waybill': '打印包裝Label',
  'menu.outbound-pack.no-amr-pick-pack': '人工區-揀貨&包裝',
  'menu.outbound-pack.reprint': '重新打印',
  'menu.outbound-pack.reinput-weight': '重新磅度',
  'menu.outbound-dispatch': '訂單-出庫',
  'menu.outbound-dispatch.amr-dispatch': '訂單-出庫',
  'menu.outbound-dispatch.no-amr-dispatch': '人工區-出庫',
  'menu.outbound-return': '訂單-回貨',
  'menu.outbound-return.return': '回單到人工區',
  'menu.outbound-return.put-away': '重新上架',
  'menu.outbound-return.return-orders': '回貨單',
  'menu.outbound-return.new-item': '回貨單列表',
  'menu.outbound-dispatch.common-dispatch': '出貨',
  'menu.outbound-dispatch.manifest': '載貨清單',
  'menu.pickPackVas': 'Pick & Pack VAS',
  'menu.pickPackVas.marketplace': 'Marketplace',
  'menu.pickPackVas.pick-pack-vas-flow': 'Pick & Pack VAS Flow',
  'menu.pickPackVas.marketplace-pick-pack-vas': 'Marketplace Pick & Pack VAS',
  'menu.outbound-console': 'Outbound Console',
  'menu.outbound-console.console': 'Console',
  'menu.outbound-console.create': 'Create Console',
  'menu.outbound-console.dispatch': 'Dispatch Console',
  'menu.outbound-console.console-order': 'Console Order',
  'menu.transfer-pick-order': '下架單',
  'menu.transfer-pick-order.new-transfer': '下架單',
  'menu.transfer-pick-order.no-amr-pick': '人工區 - 揀貨(下架)',
  'menu.transfer-pick-order.sorting': '重新分揀 ',
  'menu.transfer-pick-order.put-away': '重新上架 ',
  'menu.transfer-pick-order.sorting-report': '分揀報告',
  'menu.transfer-pick-order.edit': '編輯下架單',
  'menu.inventory': 'Inventory',
  'menu.stocktake': '人工區 - 盤點',
  'menu.inventory-batch-adjustment': '庫存 - 批次屬性調整',
  'menu.inventoryManage': '庫存管理',
  'menu.inventoryManage.edit-stocktake': '編輯人工區 - 盤點單',
  'menu.inventoryManage.stock': '庫存',
  'menu.inventoryManage.stock-product-type': '庫存 - 產品類別',
  'menu.inventoryManage.stocktake': '人工區 - 盤點',
  'menu.inventoryManage.inventory-adjustments': '調整庫存',
  'menu.inventoryManage.edit-inventory-adjustment': '編輯人工區-庫存調整單',
  'menu.inventoryManage.batch-property-adjustment': '特性調整',
  'menu.inventoryManage.edit-batch-adjustment': '編輯批次屬性調整單',
  'menu.inventoryManage.edit-batch-property-adjustment-item': '編輯批次屬性調整單詳情',
  'menu.inventoryManage.batch-property-adjustment-item': '批次屬性調整單詳情',
  'menu.inventoryManage.inventory': '庫存 - 列表',
  'menu.inventoryManage.inventory-bin': '庫存 - 架位',
  'menu.inventoryManage.inventory-cycle-count': 'Cycle Count',
  'menu.inventoryManage.temp-inventory': '暫存區庫存',
  'menu.inventoryManage.bin-lock-setting': '架位鎖設定',
  'menu.wmsManage.inventoryManage': '庫存管理',
  'menu.wmsManage.inventoryManage.inventory': '庫存 - 列表',
  'menu.wmsManage.inventoryManage.inventory-bin': '庫存 - 架位',
  'menu.wmsManage.inventoryManage.inventory-cycle-count': 'Cycle Count',
  'menu.wmsManage.inventoryManage.stock': '庫存',
  'menu.wmsManage.inventoryManage.stock-product-type': '庫存 - 產品類別',
  'menu.wmsManage.inventoryManage.stocktake': '人工區 - 盤點',
  'menu.wmsManage.inventoryManage.inventory-adjustments': '調整庫存',
  'menu.wmsManage.inventoryManage.edit-inventory-adjustment': '庫存調整',
  'menu.wmsManage.inventoryManage.edit-batch-adjustment': '特性調整',
  'menu.wmsManage.inventoryManage.batch-property-adjustment': '庫存 - 批次屬性調整',
  'menu.wmsManage.inventoryManage.batch-property-adjustment-item':
    'Batch Property Adjustment Items',
  'menu.warehouseSetting': '倉庫設置',
  'menu.warehouseSetting.warehouses': '倉庫',
  'menu.warehouseSetting.warehouses.warehouse_edit': '編輯倉庫',
  'menu.warehouseSetting.virtual-warehouses': '虛擬倉庫',
  'menu.warehouseSetting.warehouse-zone': '倉庫區域',
  'menu.warehouseSetting.warehouse-bin': '倉庫架位',
  'menu.warehouseSetting.warehouse-bin-size': '架位體積',
  'menu.drivers': 'Drivers',
  'menu.merchants': 'Merchants',
  'menu.line': 'Line',
  'menu.administration': 'Administration',
  'menu.administration.role': 'Role',
  'menu.administration.user': 'User',
  'menu.administration.subsidiary': 'Subsidiary',
  'menu.payment': 'Billing & Payment',
  'menu.payment.topup': 'Top-Up',
  'menu.payment.invoice': 'Invoices',
  'menu.payment.customer-payment-record': 'Payment',
  'menu.payment.create-customer-payment-record': 'Create Payment',
};
