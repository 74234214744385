import type { Effect, Reducer } from 'umi';

import { queryFinanceFile } from '@/services/finance/file';
import type { FileTableListData } from './file.d';

export interface StateType {
  files?: FileTableListData;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getFiles: Effect;
    clear: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'fileModel',

  state: {
    files: undefined,
  },

  effects: {
    *getFiles({ payload }, { call, put }) {
      const response = yield call(queryFinanceFile, payload);
      yield put({
        type: 'save',
        payload: {
          list: response.data,
          pagination: {
            total: response?.meta ? response.meta.total_record : 0,
            page_size: response.meta ? response.meta.per_page : 0,
            current: response.meta ? response.meta.current_page : 0,
          },
        },
      });
    },
    *clear(_, { put }) {
      yield put({
        type: 'save',
        payload: {
          list: [],
          pagination: {},
        },
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        files: action.payload,
      };
    },
  },
};

export default Model;
