import { request } from 'umi';
import type { PutAwayProductFormValueType } from './components/details/PutAwayProductForm';
import type { InspectionFormValueType } from './components/details/InspectionForm';
import type {
  CreateRmaProductFileDataType,
  RmaOrderTableListParams,
  RmaProductFileTableListParams,
} from './data';
import type { InspectToPutAwayFormValueType } from './components/details/InspectionForm/InspectToPutAwayForm';
import type { InspectUnknownProductFormValueType } from './components/details/InspectionForm/InspectUnknownProductForm';

const { JOR_A_WMS_RMA_ORDER_URL, JOR_A_WMS_RMA_PRODUCT_FILE_URL } = API;

export async function getRmaOrders(params: RmaOrderTableListParams | undefined) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getRmaOrderOptions() {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function getRmaOrderDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getRmaOrderItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/items`, {
    method: 'GET',
  });
}

export async function getRmaOrderItemProducts(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/item/products`, {
    method: 'GET',
  });
}

export async function getRmaReceiveWarehouseBins(params: { warehouse_id: number }) {
  const { warehouse_id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/receive/warehouse/${warehouse_id}/bins`, {
    method: 'GET',
  });
}

export async function getRmaAvailableReceiveItems(params: { id: number; sku: string }) {
  const { id, sku } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/available/receive/${sku}`, {
    method: 'GET',
  });
}

export async function getRmaPutawayWarehouseBins(params: { warehouse_id: number }) {
  const { warehouse_id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/putaway/warehouse/${warehouse_id}/bins`, {
    method: 'GET',
  });
}

export async function exportRmaOrderWarehouseItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/export/warehouse/items`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function receiveRmaOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/receive`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function completeRequireNote(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/require/note`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function completeRmaOrder(params: { id: number; note: string }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/complete`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function downloadRmaOrderPDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getRmaOrderSerialNumbers(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/serial/numbers`, {
    method: 'GET',
  });
}

export async function checkRmaReceiveProductScannedlNumber(params: {
  rma_order_id: number;
  product_id: number;
  scanned_number: string;
}) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/check/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkRmaPutawayProductScannedlNumber(params: {
  rma_order_item_product_id: number;
  scanned_number: string;
}) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/check/product`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function inspectToPutAwayRmaOrderItemProduct(
  id: number,
  params: InspectToPutAwayFormValueType,
) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/inspect/putaway`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function inspectToPutAwayRmaUnknownProduct(
  id: number,
  params: InspectUnknownProductFormValueType,
) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/inspect/new/product`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function inspectToReceiveRmaOrderItemProduct(
  id: number,
  params: InspectionFormValueType,
) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/inspect/receive`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function putAwayPendingInspectRmaProduct(
  id: number,
  params: PutAwayProductFormValueType,
) {
  return request(`${JOR_A_WMS_RMA_ORDER_URL}/${id}/putaway`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function queryJorAdminRmaProductFiles(
  params: RmaProductFileTableListParams | undefined,
) {
  return request(JOR_A_WMS_RMA_PRODUCT_FILE_URL, {
    method: 'GET',
    params,
  });
}

export async function getJorAdminRmaProductFile(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_PRODUCT_FILE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createJorAdminRmaProductFile(params: CreateRmaProductFileDataType) {
  return request(JOR_A_WMS_RMA_PRODUCT_FILE_URL, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function removeJorAdminRmaProductFile(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_A_WMS_RMA_PRODUCT_FILE_URL}/${id}`, {
    method: 'DELETE',
  });
}

export async function downloadJorAdminRmaProductFile(params: { id: string }) {
  return request(`${JOR_A_WMS_RMA_PRODUCT_FILE_URL}/${params.id}/download`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function previewJorAdminRmaProductFile(params: { id: string }) {
  const res = await request(`${JOR_A_WMS_RMA_PRODUCT_FILE_URL}/${params.id}/download`, {
    responseType: 'blob',
  });
  return res;
}
