export default {
  'warehouse_rent_bill_item.merchant': 'Merchant',
  'warehouse_rent_bill_item.bill_code': 'Bill Code',
  'warehouse_rent_bill_item.document_no': 'Document No.',
  'warehouse_rent_bill_item.warehouse': 'Warehouse',
  'warehouse_rent_bill_item.sku': 'SKU',
  'warehouse_rent_bill_item.product_qty': 'Product Qty',
  'warehouse_rent_bill_item.total_outbound_qty': 'Outbound Qty',
  'warehouse_rent_bill_item.charge_qty': 'Charge Qty(CBM)',
  'warehouse_rent_bill_item.charge_date': 'Charge Date',
  'warehouse_rent_bill_item.total_volume': 'Total Volume(CBM)',
  'warehouse_rent_bill_item.warehouse_rent_type': 'Warehouse Rent Type',
  'warehouse_rent_bill_item.currency': 'Currency',
  'warehouse_rent_bill_item.unit_amount': 'Unit Amount',
  'warehouse_rent_bill_item.total_amount': 'Total Amount',
  'warehouse_rent_bill_item.bill_date': 'Bill Date',
  'warehouse_rent_bill_item.download-error-sku': 'Download No Dimension SKU',
  'warehouse_rent_bill_item.status': 'Status',
  'warehouse_rent_bill_item.create': 'Create Warehouse Rent Bill Item',
  'warehouse_rent_bill_item.create.fail': 'Fail to create warehouse rent bill item',
  'warehouse_rent_bill_item.create.success':
    'Warehouse rent bill item has been succesfully created',
  'warehouse_rent_bill_item.update.fail': 'Fail to update warehouse rent bill item',
  'warehouse_rent_bill_item.update': 'Update',
  'warehouse_rent_bill_item.update.success':
    'Warehouse rent bill item has been succesfully updated',
  'warehouse_rent_bill_item.inventory_ageing_item': 'Warehouse {document_no} Inventory Ageing',
  'warehouse_rent_bill_item.show_inventory_ageing_list': 'Show Inventory Ageing Items',
  'warehouse_rent_bill_item.upload_rent_bill_item': 'Upload Rent Items',
};
