export default {
  'wms.common.go_back': '返回',
  'wms.common.status': '狀態',
  'wms.common.amr_pick_status': '揀貨單狀態',
  'wms.common.warehouse': '倉庫',
  'wms.common.merchant': '貨主',
  'wms.common.platform_code': '電商平台',
  'wms.common.external_wms_status': '外部 WMS 狀態',
  'wms.common.external_client_status': '外部客戶狀態',
  'wms.common.container_box': '箱號',
  'wms.common.sku': '產品號碼 SKU',
  'wms.common.bin': '架位',
  'wms.common.bar_code': '產品條碼',
  'wms.common.product_name': '產品名稱',
  'wms.common.ean': 'EAN / UPC 條碼',
  'wms.common.normal_qty': '好貨數量',
  'wms.common.damage_qty': '壞貨數量',
  'wms.common.quantity': '數量',
  'wms.common.put_away.quantity': '上架數',
  'wms.common.picking_product_type': '貨品類別定義',
  'wms.common.origin_picking_product_type': '原貨品類別定義',
  'wms.common.expected_qty': '預期數量',
  'wms.common.virtual_warehouse': '虛擬倉庫',
  'wms.common.carrier': '快遞商',
  'wms.common.zone': '區域',
  'wms.common.warehouse.bin_zone': '架區',
  'wms.common.warehouse.bin': '架位',
  'wms.common.warehouse.aisle': '通道',
  'wms.common.warehouse.bay': '間隔',
  'wms.common.inventory_status': '庫存狀態',
  'wms.common.waybill': '貨運單',
  'wms.common.invoice': '發票',
  'wms.common.processing.message': '處理中 ...',
  'wms.common.scan.success': '驗證成功',
  'wms.common.scan.fail': '驗證失敗',
  'wms.common.scan_ean_or_upc_code': '掃描 EAN / UPC / (SKU) 編碼',
  'wms.common.zone-property': '倉庫區域類型',
  'wms.common.zone-property-amr': 'AMR - 區域',
  'wms.common.zone-property-non-amr': 'Non-AMR - 區域',
  'wms.common.message.download.successfully': '已成功下載',
};
