import type { Effect, Reducer } from 'umi';

import type { InventoryBatchAdjustmentTableList } from './data';
import { getInventoryBatchAdjustmentDetail } from './service';

export interface StateType {
  batchAdjustment?: InventoryBatchAdjustmentTableList;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    get: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
    clear: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'batchAdjustmentModel',
  state: {
    batchAdjustment: undefined,
  },
  effects: {
    *get({ payload }, { call, put }) {
      const response = yield call(getInventoryBatchAdjustmentDetail, payload);
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        batchAdjustment: action.payload,
      };
    },
    clear() {
      return {
        batchAdjustment: undefined,
      };
    },
  },
};

export default Model;
