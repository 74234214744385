import { request } from 'umi';
import type {
  InboundOrderItemFormDataType,
  InboundOrderTableListParams,
  InboundSortingReportTableListParams,
  OperationSortingProductFormValue,
  ReceivingReportTableListParams,
} from './data';
import type { WmsReceivedFormItemDataType } from './components/details/PackingList';
import type { PutAwayFormValueType } from './components/details/PutAwayForm';
import type { UpdateWarehouseProductFormValueType } from './components/details/UpdateWarehouseProductForm';
import type { PrintInboundItemFormValueType } from './components/sorting/PrintForm';
import type { RePrintInboundItemFormValueType } from './print-label';
import type { SortingNormalInboundItemFormValue } from './components/sorting/SortingNormalForm';
import type { SortingDamageInboundItemFormValue } from './components/sorting/SortingDamageForm';

const {
  JOR_A_WMS_INBOUND_ORDER_URL,
  JOR_A_WMS_INBOUND_SORTING_REPORT_URL,
  JOR_A_WMS_INTERFACE_RECEIVING_REPORT_URL,
  JOR_A_WMS_INBOUND_CONTAINER_BOX_URL,
  JOR_A_WMS_PRODUCT_CHECK_FRESH_URL,
} = API;

export async function getInboundOrders(params: InboundOrderTableListParams | undefined) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getInboundOrderOptions() {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function getInboundOrderDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getInboundOrderGroupItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/group/items`, {
    method: 'GET',
  });
}

export async function getInboundOrderGroupSortingItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/group/sorting/items`, {
    method: 'GET',
  });
}

export async function getInboundOrderPackingList(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/packing/list`, {
    method: 'GET',
  });
}

export async function getInboundAllSortingItemList(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/sorting/report/items`, {
    method: 'GET',
  });
}

export async function getInboundOrderBoxItems(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/box/items`, {
    method: 'GET',
  });
}

export async function getInboundOrderPalletBoxes(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/pallet/boxes`, {
    method: 'GET',
  });
}

export async function createInboundOrderItems(id: number, params: InboundOrderItemFormDataType) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function receivedInboundOrder(
  id: number,
  params: { items: WmsReceivedFormItemDataType[] },
) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/receive`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function dispatchInboundOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/dispatch`, {
    method: 'POST',
  });
}

export async function completeAllInboundSorting(id: number) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/complete`, {
    method: 'POST',
  });
}

export async function completeInboundSortingItem(params: OperationSortingProductFormValue) {
  const { inbound_order_id, product_id } = params;
  return request(
    `${JOR_A_WMS_INBOUND_ORDER_URL}/${inbound_order_id}/product/${product_id}/complete`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function unCompleteInboundSortingItem(params: OperationSortingProductFormValue) {
  const { inbound_order_id, product_id } = params;
  return request(
    `${JOR_A_WMS_INBOUND_ORDER_URL}/${inbound_order_id}/product/${product_id}/uncomplete`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function unReceiveInboundSortingItem(params: OperationSortingProductFormValue) {
  const { inbound_order_id, product_id } = params;
  return request(
    `${JOR_A_WMS_INBOUND_ORDER_URL}/${inbound_order_id}/product/${product_id}/unreceive`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function sortingNormalInboundOrderItem(
  id: number,
  params: SortingNormalInboundItemFormValue,
) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/sorting/normal`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function sortingDamageInboundOrderItem(
  id: number,
  params: SortingDamageInboundItemFormValue,
) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/sorting/damage`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function printInboundItemBarcodePdf(
  inboundOrderId: number,
  params: PrintInboundItemFormValueType,
) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${inboundOrderId}/print/item`, {
    method: 'POST',
    responseType: 'blob',
    data: {
      ...params,
    },
  });
}

export async function printInboundRegistrationPdf(inboundOrderId: number) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${inboundOrderId}/print/registration`, {
    method: 'POST',
    responseType: 'blob',
  });
}

export async function rePrintInboundItemBarcode(params: RePrintInboundItemFormValueType) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/reprint/code`, {
    method: 'POST',
    responseType: 'blob',
    data: {
      ...params,
    },
  });
}

export async function putAwayInboundOrderItem(id: number, params: PutAwayFormValueType) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/putaway`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function scanAvailablePutAwayInboundByAsnCode(asnCode: string) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/putaway/asn/${asnCode}`, {
    method: 'GET',
  });
}

export async function getInboundAvailablePutawayItems(params: { id: number; sku: string }) {
  const { id, sku } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/available/putaway/${sku}`, {
    method: 'GET',
  });
}

export async function scanAvailableSortingInboundByAsnCode(asnCode: string) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/sorting/asn/${asnCode}`, {
    method: 'GET',
  });
}

export async function checkInboundAvailableSortingProduct(params: {
  asn_code: string;
  scan_sku_barcode: string;
}) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/sorting/check/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInboundAvailablePutAwayProduct(params: {
  asn_code: string;
  product_sku: string;
}) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/putaway/check/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function submitInboundSortingItemsToConfirm(inboundOrderId: number) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${inboundOrderId}/submit/confirm`, {
    method: 'POST',
  });
}

export async function exportInboundOrderWarehouseItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/export/warehouse/items`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadInboundOrderFiles(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/download/files`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadInboundOrderPod(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/download/pod`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getInboundOrderSerialNumbers(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/serial/numbers`, {
    method: 'GET',
  });
}

export async function checkPutAwayProductScannedlNumber(params: {
  inbound_order_id: number;
  product_id: number;
  scanned_number: string;
}) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/check/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function uploadReceivingeport(id: number, formData: FormData) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/upload/receiving`, {
    method: 'POST',
    data: formData,
  });
}

export async function downloadReceivingReportTemplate() {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/download/receiving/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getPendingReceivingInboundOrders(params: { asn_code?: string }) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/pending/receiving`, {
    method: 'GET',
    params,
  });
}

export async function getPendingReceivingReportItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/pending/receiving/report`, {
    method: 'GET',
  });
}

export async function confirmReceivingToPutAwayItem(id: number) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/confirm/receiving/report`, {
    method: 'POST',
  });
}

export async function updateInboundOrderItemDimension(
  productId: number,
  params: UpdateWarehouseProductFormValueType,
) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/update/${productId}/dimension`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getUploadedReceivingReports(
  params: ReceivingReportTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INTERFACE_RECEIVING_REPORT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getReceivingReporOptions() {
  return request(`${JOR_A_WMS_INTERFACE_RECEIVING_REPORT_URL}/options`, {
    method: 'GET',
  });
}

export async function getInboundSortingReports(
  params: InboundSortingReportTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INBOUND_SORTING_REPORT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getInboundSortingReportOptions() {
  return request(`${JOR_A_WMS_INBOUND_SORTING_REPORT_URL}/options`, {
    method: 'GET',
  });
}

export async function getInboundSortingReportDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_SORTING_REPORT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getInboundSortingReportItemsByPages(
  inboundOrderId: number,
  params: InboundOrderTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INBOUND_SORTING_REPORT_URL}/inbound/${inboundOrderId}/items`, {
    method: 'GET',
    params,
  });
}

export async function generateAndPrintContainerCode(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${id}/generate/container`, {
    method: 'POST',
    responseType: 'blob',
    getResponse: true,
    data: {
      ...params,
    },
  });
}

export async function scanAvailablePutAwayInboundByContainer(params: {
  container_box_code: string;
  zone_or_bin_id: number;
  type: number;
}) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/check/putaway/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// export async function submitInboundPutAwayContainer(params: {
//   container_box_code: number;
//   zone_or_bin_id: number;
// }) {
//   return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/putaway/container`, {
//     method: 'POST',
//     data: {
//       ...params,
//     },
//   });
// }

export async function submitInboundPutAwayContainer(params: {
  container_box_codes: string[];
  zone_or_bin_id: number;
  type: number;
}) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/putaway/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInboundContainerCode(params: {
  container_box_code: string;
  inbound_order_id?: number;
  use_type: number;
  product_id: number;
}) {
  return request(`${JOR_A_WMS_INBOUND_CONTAINER_BOX_URL}/check/code`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function printInboundCartonLabel(params: { container_box_code: string }) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/print/carton`, {
    method: 'POST',
    data: {
      ...params,
    },
    getResponse: true,
    responseType: 'blob',
  });
}

export async function searchProductCheckFresh(params: { batch_code: string; product_id: number }) {
  return request(`${JOR_A_WMS_PRODUCT_CHECK_FRESH_URL}/check/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function exportToAsnSortingReport(inboundOrderId: number) {
  return request(`${JOR_A_WMS_INBOUND_ORDER_URL}/${inboundOrderId}/export/sorting/report`, {
    method: 'GET',
    responseType: 'blob',
    getResponse: true,
  });
}

export async function deleteInboundContainerBoxSorting(params: { container_box_code: string }) {
  const { container_box_code } = params;
  return request(`${JOR_A_WMS_INBOUND_CONTAINER_BOX_URL}/${container_box_code}/inbound/sorting`, {
    method: 'DELETE',
  });
}
