export default {
  'client_webhook.company_name': 'Company Name',
  'client_webhook.webhook_url': 'Webhook URL',
  'client_webhook.webhook_secret': 'Webhook Secret',
  'client_webhook.status': 'Status',
  'client_webhook.status_label': 'Status Label',
  'client_webhook.create': 'Create Client Webhook',
  'client_webhook.create.fail': 'Fail to create client webhook',
  'client_webhook.create.success': 'Client webhook has been succesfully created',
  'client_webhook.update.fail': 'Fail to update client webhook',
  'client_webhook.update': 'Update',
  'client_webhook.update.success': 'Client webhook has been succesfully updated',
};
