export default {
  'user.name': '用戶名',
  'user.short-name': '別名',
  'user.email': '電郵',
  'user.email-address': '電郵地址',
  'user.initial': '簡稱',
  'user.job_title': '職位',
  'user.office_tel': '辦公室號碼',
  'user.mobile_tel': '手機號碼',
  'user.password': '密碼',
  'user.email-language': '電郵中所用的語言',
  'user.invite': '邀請',
  'user.resend-invitation-email': '重發邀請郵件',
  'user.resend-success': '重發成功',
  'user.confirm-password': '確認密碼',
  'user.role-label': '角色',
  'user.email-confirmed-status': '激活電郵',
  'user.batch-operation': '批量操作',
  'user.invite-user': '邀請用戶',
  'user.setting': '設置 -',
  'user.please-input-email': '請輸入電郵',
  'user.please-input-username': '請輸入用戶名',
  'user.please-input-user-short-name': '請輸入別名',
  'user.user-title': '稱謂',
  'user.user-title.mr': 'Mr',
  'user.user-title.ms': 'Ms',
  'user.user-title.mrs': 'Mrs',
  'user.user-job-title': '職位',
  'user.telephone': '電話',
  'user.mobile-phone': '移動電話號碼',
  'user.office-telephone': '辦公室電話號碼',
  'user.project': '項目',
  'user.please-select-project': '請選擇項目',
  'user.Role': '角色',
  'user.client': '客戶',
  'user.role.view-permission': '檢查用戶權限',
  'user.view-permission': '用戶權限',
  'user.please-select-role': '請選擇角色',
  'user.please-select-functional-groups': '請選擇職能組',
  'user.identification-no': '識別號',
  'user.please-input-identification-no': '請輸入識別號',
  'user.status': '狀態',
  'user.please-input-tel': '請輸入公司電話號碼',
  'user.please-input-mobile-tel': '請輸入手機號碼',
  'user.please-input-password-more-than': '請輸入至少六位字符密碼',
  'user.delete-confirm': '確定取消關聯該用戶 ？',
  'user.email-confirmed-status.active': '激活',
  'user.email-confirmed-status.inactive': '待激活',
  'user.status.inactive': '無效',
  'user.status.active': '有效',
  'user.tab.project-setting': '項目設置',
  'user.exists-warning-title': '邀請用戶',
  'user.exists-warning-ok-button-text': '是，邀請',
  'user.suppliers': '供應商',
  'user.create.fail': '創建用戶失敗',
  'user.create.success': '已成功邀請用戶',
  'user.update.fail': '無法更新用戶',
  'user.update.success': '用戶已成功更新',
};
