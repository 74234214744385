import React, { useCallback } from 'react';
import {
  LogoutOutlined,
  ProfileOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, Spin } from 'antd';
import { useIntl, history, useModel } from 'umi';
import { stringify } from 'querystring';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { removeToken } from '@/utils/token';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = async () => {
  removeToken();
  const { query = {}, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/login' && !redirect) {
    history.replace({
      pathname: '/login',
      search: stringify({
        redirect: pathname,
      }),
    });
  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const intl = useIntl();
  const onMenuClick = useCallback(
    (event: {
      key: React.Key;
      keyPath: React.Key[];
      item: React.ReactInstance;
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      const { key } = event;
      if (key === 'logout' && initialState) {
        setInitialState({ ...initialState, currentUser: undefined });
        loginOut();
        return;
      }
      if (key === 'address-books') {
        history.push(`/shipment/address-books`);
        return;
      }
      if (key === 'service-channel') {
        history.push(`/shipment/service-channel`);
        return;
      }
      history.push(`/profile/${key}`);
    },
    [initialState, setInitialState],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.id) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {menu && (
        <>
          <Menu.Item key="settings">
            <SettingOutlined />
            {intl.formatMessage({ id: 'button.personal-setting' })}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="address-books">
            <UnorderedListOutlined />
            {intl.formatMessage({ id: 'menu.address-books' })}
          </Menu.Item>
          <Menu.Item key="service-channel">
            <ProfileOutlined />
            {intl.formatMessage({ id: 'menu.service-channel' })}
          </Menu.Item>
        </>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="logout">
        <LogoutOutlined />
        {intl.formatMessage({ id: 'action.logout' })}
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`} style={{ color: '#000' }}>
        <UserOutlined
          style={{
            color: '#f56a00',
            backgroundColor: '#fde3cf',
            borderRadius: '30px',
            padding: '5px',
            marginRight: '15px',
          }}
        />
        {currentUser.name}
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
