export default {
  'product_category.name': 'Name',
  'product_category.description': 'Description',
  'product_category.parent': 'Parent Category',
  'product_category.remark': 'Remark',
  'product_category.status': 'Status',
  'product_category.create': 'Create Product Category',
  'product_category.create.fail': 'Fail to create product category',
  'product_category.create.success': 'Product Category has been succesfully created',
  'product_category.update.fail': 'Fail to update product category',
  'product_category.update': 'Update',
  'product_category.update.success': 'Product Category has been succesfully updated',
};
