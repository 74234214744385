export default {
  'wms.common.go_back': 'Go Back',
  'wms.common.status': 'Status',
  'wms.common.amr_pick_status': 'Picking Order Status',
  'wms.common.warehouse': 'Warehouse',
  'wms.common.merchant': 'Merchant',
  'wms.common.platform_code': 'Platform Code',
  'wms.common.external_wms_status': 'External WMS Status',
  'wms.common.external_client_status': 'External Client Status',
  'wms.common.container_box': 'Container Box',
  'wms.common.sku': 'SKU',
  'wms.common.bin': 'Bin',
  'wms.common.bar_code': 'Bar Code',
  'wms.common.product_name': 'Product name',
  'wms.common.ean': 'EAN / UPC',
  'wms.common.normal_qty': 'Normal QTY',
  'wms.common.damage_qty': 'Damage QTY',
  'wms.common.quantity': 'QTY',
  'wms.common.put_away.quantity': 'Put Away QTY',
  'wms.common.picking_product_type': 'Picking Product Type',
  'wms.common.origin_picking_product_type': 'Origin Picking Product Type',
  'wms.common.expected_qty': 'Expected QTY',
  'wms.common.virtual_warehouse': 'Virtual Warehouse',
  'wms.common.carrier': 'Carrier',
  'wms.common.zone': 'Zone',
  'wms.common.warehouse.bin': 'Bin',
  'wms.common.warehouse.bin_zone': 'Bin Zone',
  'wms.common.warehouse.aisle': 'Aisle',
  'wms.common.warehouse.bay': 'Bay',
  'wms.common.inventory_status': 'Inventory Status',
  'wms.common.waybill': 'Waybill',
  'wms.common.invoice': 'Invoice',
  'wms.common.processing.message': 'Processing ...',
  'wms.common.scan.success': 'Scan Successfuly',
  'wms.common.scan.fail': 'Scan Faild...',
  'wms.common.scan_ean_or_upc_code': 'Scan EAN / UPC / (SKU)',
  'wms.common.zone-property': 'Zone Property',
  'wms.common.zone-property-amr': 'AMR Zone',
  'wms.common.zone-property-non-amr': 'Non-AMR Zone',
  'wms.common.message.download.successfully': 'Download Successfully',
};
