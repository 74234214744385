export default {
  'products.name': '產品名稱',
  'products.merchant': '貨主',
  'products.description': '產品描述',
  'products.scan_barcode_type': '揀貨掃描方式',
  'products.product_code': '產品號碼',
  'products.sku': 'SKU',
  'products.product_category': '類別',
  'products.weight_unit': '單位',
  'products.weight': '重量',
  'products.dimension': '三邊碼 (CM)',
  'products.length': '長',
  'products.width': '闊',
  'products.height': '高',
  'products.replenishment.safety.qty': '少於這個數便補貨',
  'products.supplier': '供應商',
  'products.oversize_large_type': '大碼 (Large)',
  'products.oversize_type': '產品大小',
  'products.ean_code': 'EAN / UPC 碼',
  'products.require_serial_number': '需要掃描 S/N',
  'products.serial_number_length': 'S/N 字元長度',
  'products.remark': '備註',
  'products.declared_info_title': '報關資料',
  'products.declared_chinese_name': '報關的產品名稱 (中文)',
  'products.declared_english_name': '報關的產品名稱 (英文)',
  'products.declared_price': '報關貨價',
  'products.hscode': 'HS 碼',
  'products.country_code_of_origin': '產地 (COO)',
  'products.battery_section_type': '選取類別',
  'products.battery': '電池 ',
  'products.battery_un_number': '聯合國危險貨物編號(UN No.)',
  'products.battery_type': '電池類型',
  'products.battery_pi_type': 'P.I. Type',
  'products.battery_pack_qty': '包裝數量',
  'products.battery_pack_capacity_kilowatt': 'Battery Pack Capacity(WH)',
  'products.battery_cell_capacity_kilowatt': 'Battery Cell Capacity(WH)',
  'products.battery_pack_lithium_content_gram': 'Battery Pack Lithium Content(G)',
  'products.battery_cell_lithium_content_gram': 'Battery Cell Lithium Content(G)',
  'products.battery_net_weight': '淨重 (KG)',
  'products.status': 'Status',
  'products.create': 'Create Product',
  'products.create.fail': 'Fail to create product',
  'products.create.success': 'Product has been succesfully created',
  'products.update.fail': 'Fail to update product',
  'products.update': 'Update',
  'products.update.success': 'Product has been succesfully updated',
  'products.export_to_excel': '匯出產品至 excel',
  'products.download': 'Download',
  'products.upload': 'Upload Products',
  'products.expiry_date': 'Expiry Date',
  'products.qty_alert_threshold': 'Qty Alert Threshold',
  'products.require_gift_card': '需要禮品卡',
  'products.sku_id': 'SKU ID',
  'products.shelf_life': 'Shelf Life',
  'products.sku_abc': 'SKU ABC',
  'products.sku_price': 'SKU Price',
  'products.volume': 'Volume',
  'products.net_weight': 'Net Weight',
  'products.min_count': 'Min Count',
  'products.max_count': 'Max Count',
  'products.production_location': 'Product Location',
  'products.specification': 'Specification',
  'products.sku_brand': '品牌',
  'products.item_size': '產品大小',
  'products.item_color': '產品顏色',
  'products.item_style': 'Item Style',
  'products.pic_url': 'Picture URL',
  'products.is_sequence_sku': 'Is Sequence SKU',
  'products.is_breakable': 'Is Breakable',
  'products.is_dangerous': 'Is Dangerous',
  'products.is_precious': 'Is Precious',
  'products.is_abnormity': 'Is Abnormity',
  'products.is_need_product_batch_manage': 'Is Need Product Batch Manage',
  'products.is_need_exp_manage': 'Is Need Exp Manage',
  'products.is_material': 'Is Material',
  'products.additional_info': 'Additional Info',

  'products.download_selected': '下載已選擇的',
  'products.download-selected-confirm': '真的要下載所選取產品的條碼嗎？',
  'products.print_sku': '列印 SKU 條碼',
  'products.update_warehouse_product': '更新 WH 產品資料',
  'products.cbm': '立方米 (CBM)',
  'products.upload_warehouse_product': '上載 (WH) 產品資料',
  'products.warehouse_product_title': 'WH 產品資料',
  'products.push_to_wms_tooltip': '傳送到 Ext. WMS',
  'products.push_to_wms_confirm': '真的要傳送產品資料到外部 WMS 嗎?',

  'products.fail-to-update': '更新失敗!',
};
