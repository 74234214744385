export default {
  'user.list.user-name': 'Name',
  'user.list.user-short-name': 'Initial',
  'user.list.user-email': 'Email Address',
  'user.list.password': 'Password',
  'user.list.email-language': 'Invitation Email Language',
  'user.list.invite': 'Invite',
  'user.list.resend-invitation-email': 'Resend Invitation Email',
  'user.list.resend-success': 'Resend Success',
  'user.list.email-confirmed-status': 'Email Confirmed',
  'user.list.confirm-password': 'Confirm Password',
  'user.list.role-label': 'Roles',
  'user.list.invite-user': 'Invite User',
  'user.list.setting': 'Setting for -',
  'user.list.please-input-email': 'Please Input Email',
  'user.list.please-input-username': 'Please Input UserName',
  'user.list.please-input-user-short-name': 'Please Input Initial',
  'user.list.user-title': 'Title',
  'user.list.user-title.mr': 'Mr',
  'user.list.user-title.ms': 'Ms',
  'user.list.user-title.mrs': 'Mrs',
  'user.list.user-job-title': 'Job Title',
  'user.list.user-tel': 'Tel',
  'user.list.project': 'Project',
  'user.list.please-select-project': 'Please Select Project',
  'user.list.Role': 'Role',
  'user.list.role.view-permission': 'Check User Permissions',
  'user.list.view-permission': 'User Permissions',
  'user.list.please-select-role': 'Please Select Role',
  'user.list.please-select-functional-groups': 'Please Select Functional Groups',
  'user.list.identification-no': 'Identification No.',
  'user.list.status': 'Status',
  'user.list.default_module': 'Default Module',
  'user.list.please-input-tel': 'Please Input Tel.',
  'user.list.please-input-identification-no': 'Please Input Identification No.',
  'user.list.please-input-password-more-than': 'Please Input Password at least 6 character',
  'user.list.delete-confirm': 'Are you sure to disconnect this user?',
  'user.list.email-confirmed-status.active': 'Confirmed',
  'user.list.email-confirmed-status.inactive': 'Pending',
  'user.status.inactive': 'Inactive',
  'user.status.active': 'Active',
  'user.tab.project-setting': 'Project Settings',
  'user.tab.preferences': 'Preferences',
  'user.list.exists-warning-title': 'Invite user',
  'user.list.exists-warning-ok-button-text': 'Yes, Invite',
};
