export default {
  'gate_charge.document_no': 'Receipt No.',
  'gate_charge.asn_number': 'ASN No.',
  'gate_charge.description': 'Description',
  'gate_charge.gate_type': 'Gate Type',
  'gate_charge.gate_type.gate_in': 'Gate In',
  'gate_charge.gate_type.gate_out': 'Gate Out',
  'gate_charge.amount': 'Amount',
  'gate_charge.date': 'Date',
  'gate_charge.date_from': 'Date From',
  'gate_charge.date_to': 'Date To',
  'gate_charge.form.please-select-gate-type': 'Please Select Gate Type',
  'gate_charge.subsidiary': 'Subsidiary',
  'gate_charge.form.please-select-subsidiary': 'Please Select Subsidiary',
  'gate_charge.currency': 'Currency',
  'gate_charge.warehouse': 'Warehouse',
  'gate_charge.form.please-select-currency': 'Please Select Currency',
  'gate_charge.delete-confirm': 'Are you sure to delete this gate charge?',
  'gate_charge.paid-confirm': 'Are you sure to paid this gate charge {document_no}?',
  'gate_charge.form.please-select-warehouse': 'Please Select Warehouse',
  'document.remark': 'Document Remarks',
};
