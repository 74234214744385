export default {
  'virtual_warehouses.name': 'Name',
  'virtual_warehouses.merchant': 'Merchant',
  'virtual_warehouses.virtual_warehouse_code': 'Virtual Warehouse Code',
  'virtual_warehouses.remark': 'Remark',
  'virtual_warehouses.status': 'Status',
  'virtual_warehouses.create': 'Create Virtual Warehouse',
  'virtual_warehouses.create.fail': 'Fail to create virtual warehouse',
  'virtual_warehouses.create.success': 'Virtual warehouse has been succesfully created',
  'virtual_warehouses.update.fail': 'Fail to update virtual warehouse',
  'virtual_warehouses.update': 'Update',
  'virtual_warehouses.picklist_method': 'Picklist Method',
  'virtual_warehouses.update.success': 'Virtual warehouse has been succesfully updated',
};
