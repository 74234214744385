export default {
  'label.external-transaction-no': '其他參考編號',
  'label.company': '公司',
  'label.toup-up-type': '增值方式',
  'topup-amount-place-holder': '請選擇一個增值的金額',
  'topup-type-place-holder': '請選擇一個增值的方式',
  'topup-payment-proof': '付款單據',
  'topup-edit-title': '修改 - {document_no}',
  'topup-confirm-message-title': '你是否確認這個充值?',
};
