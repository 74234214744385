export default {
  'rma_orders.return_warehouse': 'Return Warehouse',
  'rma_orders.merchant': 'Merchant',
  'rma_orders.return_order_type': 'Return Order Type',
  'rma_orders.rma_order_code': 'RMA Order Code',
  'rma_orders.delivery_note': 'Delivery Note',
  'rma_orders.delivery_note_or_reference_no': 'DN Code / Ref No.',
  'rma_orders.search_delivery_note_or_reference_no': 'Search WMS DN Code Or Reference No.',
  'rma_orders.return_reason': 'Return Reason',
  'rma_orders.warehouse_remark': 'Warehouse  Remark',
  'rma_orders.processing_method': 'Processing Method',
  'rma_orders.return_mode': 'Return Mode',
  'rma_orders.delivery_note_code': 'Delivery Note Code',
  'rma_orders.reference_no': 'Reference No.',
  'rma_orders.return_courier_service': 'Return Courier Service',
  'rma_orders.product_sku': 'Product SKU',
  'rma_orders.condition': 'Condition',
  'rma_orders.company': 'Company',
  'rma_orders.email': 'Email',
  'rma_orders.shipper_name': 'Shipper Name',
  'delivery_notes.message.shipper_name': 'Please Enter Shipper Name',
  'delivery_notes.message.country': 'Please Select Country',
  'rma_orders.shipper_company': 'Company Name',
  'rma_orders.shipper_email': 'Contact Email',
  'rma_orders.address': 'Address',
  'rma_orders.address1': 'Address1',
  'rma_orders.address2': 'Address2',
  'rma_orders.country': 'Country',
  'rma_orders.district': 'District',
  'rma_orders.city': 'City',
  'rma_orders.state': 'State',
  'rma_orders.postal': 'Postal',
  'rma_orders.phone': 'Phone',
  'rma_orders.status': 'Status',
  'rma_orders.create': 'Create RMA Order',
  'rma_orders.create.fail': 'Fail to create product',
  'rma_orders.create.success': 'RMA Order has been succesfully created',
  'rma_orders.update.fail': 'Fail to update product',
  'rma_orders.update': 'Update',
  'rma_orders.update.success': 'RMA Order has been succesfully updated',
  'rma_orders.form_item.title': 'Product details ',
  'rma_orders.item.no': 'No.',
  'rma_orders.product': 'Product',
  'rma_orders.search_product': 'Search Product',
  'rma_orders.sku': 'SKU',
  'rma_orders.product_qty': 'Product Qty',
  'rma_orders.product_name': 'Product Name',
  'rma_orders.item.rma_qty': 'Expected RMA Qty',
  'rma_orders.item.condition': 'Condition',
  'rma_orders.details.items': 'Item Details',
  'rma_orders.details.sku': 'SKU Code',
  'rma_orders.details.product_name': 'Product Name',
  'rma_orders.details.product_qty': 'Expected Qty',

  'rma_orders.complete-rma-confirm': 'Are you sure you want to complete this {document_no}',
  'rma_orders.receive-rma-confirm': 'Are you sure you want to receive this {document_no}',
  'rma_orders.receive': 'Receive',
  'rma_orders.update_title': 'Update ({merchant}) {asn_number} ',
  'rma_orders.cancel-confirm': 'Are you sure to cancel this {document_no} rma order?',
  'rma_orders.upload_items': 'Upload Box Items',
  'rma_orders.export_to_excel': 'Export your data to excel',
  'rma_orders.download': 'Download',
  'rma_orders.warehouse': 'Warehouse',
  'rma_orders.custom_return_carrier': 'Custom Return Carrier',
  'rma_orders.return_courier_service_code': 'Courier Service Code',
  'rma_orders.return_tracking_no': 'Return Tracking No.',
  'delivery_notes.shipper_name': 'Shipper Name',
  'delivery_notes.shipper_email': 'Shipper Email',
  'delivery_notes.shipper_company': 'Company',
};
