export default {
  'marketplace.order.id': 'ID',
  'marketplace.order.store_account': 'Store Account',
  'marketplace.order.title': 'Marketplace Order List',
  'marketplace.order.platform': 'Platform',
  'marketplace.order.platform_order_id': 'Platform Order ID',
  'marketplace.order.platform_order_no': 'Platform Order No.',
  'marketplace.order.purchase_date': 'Purchase Date',
  'marketplace.order.purchase_date_from': 'From Purchase Date',
  'marketplace.order.currency': 'Currency',
  'marketplace.order.platform_order_status': 'Platform Order Status',
  'marketplace.order.total_commission': 'Total Commission',
  'marketplace.order.label_status': 'Label Status',
  'marketplace.order.download-all-to-label': 'Download All',
  'marketplace.order.status': 'Status',
  'marketplace.order.action': 'Action',
  'marketplace.order.basic-info': 'Order Basic Info',
  'marketplace.order.marketplace_store': 'Marketplace Store',
  'marketplace.order.total_amount': 'Total Amount',
  'marketplace.order.bill-info': 'Order Bill Info',
  'marketplace.order.bill_name': 'Bill Name ',
  'marketplace.order.bill_phone': 'Bill Phones Number',
  'marketplace.order.bill_postal_code': 'Bill Postal Code ',
  'marketplace.order.bill_country': 'Bill Country',
  'marketplace.order.bill_city': 'Bill City',
  'marketplace.order.bill_district': 'Bill District',
  'marketplace.order.bill_address': 'Bill Address',
  'marketplace.order.shipping-info': 'Order Shipping Info',
  'marketplace.order.shipping_name': 'Shipping Name',
  'marketplace.order.shipping_phone': 'Shipping Phones Number',
  'marketplace.order.shipping_postal_code': 'Shipping Postal Code',
  'marketplace.order.shipping_country': 'Shipping Country ',
  'marketplace.order.shipping_city': 'Shipping City',
  'marketplace.order.shipping_district': 'Shipping District',
  'marketplace.order.shipping_address': 'Shipping Address',
  'marketplace.order.order_item_id': 'Order Item Id',
  'marketplace.order.product_name': 'Product Name',
  'marketplace.order.seller_sku': 'Seller SKU',
  'marketplace.order.quantity_ordered': 'Quantity Ordered',
  'marketplace.order.shipping_type': 'Shipping Type',
  'marketplace.order.item_price': 'Items Price',
  'marketplace.order.shipping_price': 'Shipping Price',
  'marketplace.order.item-info': 'Order Item Details',
  'marketplace.order.detail.title': 'Order Details',
  'marketplace.order.to_country': 'To Country',
  'marketplace.order.declared_value': 'Declared Value',
  'marketplace.order.sync_orders': 'Sync Platform Orders',
  'marketplace.order.sync_orders.tooltip': 'Sync Platform Orders',
  'marketplace.order.button.sync_orders': 'Sync Platform Orders',
  'marketplace.order.item.no': 'No.',
  'marketplace.order.weight.tooltip': 'Weight Unit (G)',
  'marketplace.order.item_declared_value.tooltip': 'Declared Value (Per Item)',
  'marketplace.order.create_label_success_title':
    'Create shipment label successfully, Please check',
  'marketplace.order.create_shipment.title': 'Create Order {document_no} Shipment Label',
  'marketplace.order.re_create_shipment.title': 'Re-Generate Order {document_no} Shipment Label',
  'marketplace.order.edit_shipment.title': 'Edit Order {document_no} Shipment Label',
  'marketplace.button.create_label': 'Create Shipment Label',
  'marketplace.order.create_label.fail': 'Create shipment label failed, please check',
  'marketplace.order.create_label.success': 'Create shipment label successfully, please check',
  'marketplace.order.shipped.success': 'Shipped order successfully',
  'marketplace.order.dispatch-shipping-confirm': 'Are you sure you want to ship selected orders?',
  'marketplace.order.shipment-info': 'Order Shipment',
  'marketplace.order.shipment_reference_no': 'Shipment Ref No.',
  'marketplace.order.service_code': 'Service Code',
  'marketplace.order.incoterm': 'Incoterm',
  'marketplace.order.shipment_content': 'Shipment Content',
  'marketplace.order.tracking_no': 'Tracking No.',
  'marketplace.order.delivery_email': 'Delivery E-mail',
  'marketplace.order.delivery_name': 'Delivery Name',
  'marketplace.order.delivery_company': 'Delivery Company',
  'marketplace.order.delivery_address': 'Delivery Address',
  'marketplace.order.delivery_state': 'Delivery State',
  'marketplace.order.delivery_postal': 'Delivery Postal',
  'marketplace.order.delivery_country': 'Delivery Country',
  'marketplace.order.delivery_phone': 'Delivery Phone',
  'marketplace.order.shipped_at': 'Shipped At',
  'marketplace.button.re_create_label': 'Re-Generate Shipment Label',
  'marketplace.order.shipment_by_system': 'Create Shipment By',
  'marketplace.sync-shipping': 'Sync Shipping Info',
  'marketplace.sync-shipping-info-confirm': 'Are you sure you want to sync order shipping info?',
  'marketplace.order.external_fulfillment_status': 'External Fulfill Status',
};
