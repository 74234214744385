export default {
  'replenishment_orders.menu.picking': 'Picking',
  'replenishment_orders.replenishment_code': 'Replenishment Batch Code',
  'replenishment_orders.scan_replenishment_code': 'Scan Replenishment Batch Code',
  'replenishment_orders.wms_receipt_code': 'Replenishment Code',
  'replenishment_orders.scan_container_box_code': 'Scan Container Code',
  'replenishment_orders.container_box_or_dn_code': 'Container Code / DN No.',
  'replenishment_orders.from_warehouse_zone': 'Picking from - Zone',
  'replenishment_orders.from_warehouse_bin': 'Picking from - Bin',
  'replenishment_orders.to_warehouse_zone': 'To Zone',
  'replenishment_orders.to_zone_property': 'Zone Property',
  'replenishment_orders.details.pallets': 'Replenishment Pallets',
  'replenishment_orders.details.items': 'Replenishment Product(s)',
  'replenishment_orders.details.request-items': 'Replenishment Request Items',
  'replenishment_orders.scan_sku_barcode': 'Scan SKU',
  'replenishment_orders.replenishment_type': 'Replenishment Type',
  'replenishment_orders.product_container_title': 'SKU - {product_sku} Container List',
  'replenishment_orders.product_type_title': 'SKU - {product_sku} Picking Product Type List',
  'replenishment_orders.menu.picking.tooltip': '(Handheld) Picking the product for replenishment',
  'replenishment_orders.details.sku_type_amount': 'No. of SKU(s)',
  'replenishment_orders.details.planned_receive_qty': 'Planned Replenishment Qty',
  'replenishment_orders.details.intransit_qty': 'In-transit Qty',
  'replenishment_orders.details.put_away_date_at': 'Put Away Date',
  'replenishment_orders.scan_amr_zone': 'Scan AMR Zone',
  'replenishment_orders.details.request_qty': 'Request Qty',
  'replenishment_orders.details.allocated_qty': 'Allocated Qty',
  'replenishment_orders.details.replenishment_date': 'Replenishment Date',
  'replenishment_orders.create-replenishment-request-item-confirm':
    'Are you sure that you want to request the above items for AMR replenishment?',
  'replenishment_orders.create-replenishment-request-item-confirm-and-create':
    'Are you sure that you want create replenishment order immediately with the above item(s) for AMR replenishment?',
  'replenishment_orders.remove-container-confirm':
    'Are you sure you want to remove this container box {container_box_code}?',
  'replenishment_orders.details.delivery_note_code': 'DN No.',
  'replenishment_orders.print_picked_item_pdf': 'Print Picked Request Item PDF',
  'replenishment_orders.details.max_request_qty': 'Max Request Qty',
  'replenishment_orders.movement.success': 'Movement of products between zone has been done',
  'replenishment_orders.title.replenishment_order': 'Replenishment Orders',
  'replenishment_orders.title.move_temp_zone': 'Move From Temp Zone',
  'replenishment_orders.title.create_replenishment_order_from_normal_zone.title':
    'Create Replenishment Order From Normal Inventory Zone',
  'replenishment_orders.title.create_replenishment_order_from_normal_zone': 'Create Replen. Item',
  'replenishment_orders.title.immediate_return.title': 'Immediate return the cancelled DN to AMR',
  'replenishment_orders.title.immediate_return': 'Return DN to AMR',
  'replenishment_orders.scan.checking.success': 'DN is verified successfully',

  'replenishment.tab.receive_to_amr.title': 'Receive in AMR Zone',
  'replenishment.tab.receive_to_amr': 'Receive in AMR Zone',
  'replenishment.tab.requested_item.title': 'Rquest a new Replenishment Order',
  'replenishment.tab.requested_item': 'Rquest a new Repl.',
  'replenishment.tab.products': 'Products',
  'replenishment.to_amr_zone': 'To AMR Zone',
  'replenishment.pending_movement_table_title': 'Pending for Movement',
  'replenishment.container_box_scanned_duplicate': '{container_box} has been scanned before',
  'replenishment_orders.create.success': 'Replenishment item(s) have been created successfully',
  'replenishment_orders.return_cancel_dn_table.title': 'Return cancelled DN to AMR',
  'replenishment_orders.return_dn_duplicate_scan': '{dn_number} has been scanned before',
  'replenishment_orders.picking_item.table.title': 'Picking Product(s)',
  'replenishment_orders.picking.success': 'Orders have been picked successfully',
  'replenishment_orders.scan.container.success': 'Verify the container successfully',
  'replenishment_orders.amr.awaiting_receive.title': 'Awaiting Receiving Container(s)',
  'replenishment_orders.submit_receiving_to_amr.success':
    'Submit the container(s) for receiving has been done successfully',
  'replenishment_orders.amr.select_item_to_replenish': 'Replenishment orders is creating',
  'replenishment_orders.amr.request_replenishment_in_different_zone':
    'The requested item is not belong to the same warehouse zone',
  'replenishment_orders.amr.request_item_to_replenish_fail':
    'Fail to pick the requested item for replenishment',
  'replenishment_orders.cancel-confirm':
    'Are you sure you want to cancel the replenishment order - {document_no}?',
  'replenishment_orders.allocate-confirm':
    'Are you sure you want to allocate the replenishment order - {document_no}?',
  'replenishment_orders.release-confirm':
    'Are you sure you want to release the items that is not picked on replenishment order - {document_no}?',
  'replenishment_orders.cancellation.fail': 'Fail to cancel the replenishment order',
  'replenishment_orders.allocation.fail': 'Fail to allocate for the replenishment order',
  'replenishment_orders.release.fail': 'Fail to release the items in the replenishment order',
  'replenishment_orders.request_item_picked': 'Create Replenishment',
  'replenishment_orders.request-item-picked-confirm':
    'Are you sure you want to create a new replenishment order with the selected items?',
  'replenishment_orders.return.success': 'Return order submission has been done successfully',
  'replenishment_orders.create.all_valid_sku_is_added':
    'The SKU with specific product type has been added below, please remove the record and added again to adjust the requested QTY',
  'replenishment_orders.details.request_qty.is_required':
    'Replenishment requesting QTY is required',
  'replenishment_orders.details.request_qty.more_than_zero':
    'Replenishment requesting QTY should be larger than 0',
  'replenishment.remove-requested-item': 'Are you sure that you want to remove the selected items?',
  'button.replenishment.allocate': 'Allocate',
  'button.replenishment.release': 'Release',
  'button.replenishment.request_item_create_only': 'Move to Request Item',
  'button.replenishment.immedate_create_replenishment_order':
    'Immediate Create Replenishment Order',
  'replenishment_orders.allocation.info.label': 'Allocation Info.',
  'replenishment_orders.possible.blocking.allocation.dn.label': 'DN that is Allocated (Hints)',
  'replenishment_orders.number_of_skus_label':
    'There are {number_of_skus} SKU in total (Different product type will be count as another SKU)',
};
