// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/www/chunsing-app-ui/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/var/www/chunsing-app-ui/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/var/www/chunsing-app-ui/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/var/www/chunsing-app-ui/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/var/www/chunsing-app-ui/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/var/www/chunsing-app-ui/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1629111480000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "Environment Prepare",
                  "heading": "environment-prepare"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 3,
                  "value": "Build project",
                  "heading": "build-project"
                },
                {
                  "depth": 3,
                  "value": "Check code style",
                  "heading": "check-code-style"
                },
                {
                  "depth": 3,
                  "value": "Test code",
                  "heading": "test-code"
                },
                {
                  "depth": 2,
                  "value": "More",
                  "heading": "more"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/var/www/chunsing-app-ui/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1629111480000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/account",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__ForgetPasswordLayout' */'/var/www/chunsing-app-ui/src/layouts/ForgetPasswordLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/account/recovery",
            "hideInMenu": true,
            "name": "reset-password",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login__recovery' */'/var/www/chunsing-app-ui/src/pages/User/login/recovery'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/login",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__LoginLayout' */'/var/www/chunsing-app-ui/src/layouts/LoginLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/login",
            "hideInMenu": true,
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/var/www/chunsing-app-ui/src/pages/User/login'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/privacy-policy",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__TextOnlyLayout' */'/var/www/chunsing-app-ui/src/layouts/TextOnlyLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/privacy-policy",
            "hideInMenu": true,
            "name": "privacy-policy",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__no-auth__privacyPolicy' */'/var/www/chunsing-app-ui/src/pages/no-auth/privacyPolicy'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "dashboard",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard' */'/var/www/chunsing-app-ui/src/pages/dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/pms",
        "name": "productManage",
        "icon": "project",
        "routes": [
          {
            "path": "/pms",
            "redirect": "/pms/products",
            "exact": true
          },
          {
            "path": "/pms/products",
            "name": "products",
            "icon": "FileZip",
            "access": "canEditProduct",
            "hideChildrenInMenu": true,
            "routes": [
              {
                "path": "/pms/products/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__products' */'/var/www/chunsing-app-ui/src/pages/product-manage/products'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/pms/products/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__products__edit' */'/var/www/chunsing-app-ui/src/pages/product-manage/products/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/product-prices",
            "name": "product-prices",
            "icon": "transaction",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__product-price' */'/var/www/chunsing-app-ui/src/pages/product-manage/product-price'), loading: LoadingComponent}),
            "access": "canManageProductPrice",
            "exact": true
          },
          {
            "path": "/pms/product-supplier-costs",
            "name": "product-supplier-costs",
            "icon": "OneToOneOutlined",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__product-supplier-cost' */'/var/www/chunsing-app-ui/src/pages/product-manage/product-supplier-cost'), loading: LoadingComponent}),
            "access": "canManageProductPrice",
            "exact": true
          },
          {
            "path": "/pms/inbound",
            "name": "inbound",
            "hideChildrenInMenu": true,
            "access": "canEditWmsInbound",
            "icon": "Inbox",
            "routes": [
              {
                "path": "/pms/inbound/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__inbound' */'/var/www/chunsing-app-ui/src/pages/inbound'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/pms/inbound/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__inbound__edit' */'/var/www/chunsing-app-ui/src/pages/inbound/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/outbound",
            "name": "outbound",
            "hideChildrenInMenu": true,
            "access": "canEditWmsOutbound",
            "icon": "send",
            "routes": [
              {
                "path": "/pms/outbound/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__delivery-note' */'/var/www/chunsing-app-ui/src/pages/delivery-note'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/pms/outbound/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__delivery-note__edit' */'/var/www/chunsing-app-ui/src/pages/delivery-note/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/outbound-console",
            "name": "outbound-console",
            "hideChildrenInMenu": true,
            "access": "canEditWmsOutbound",
            "icon": "send",
            "routes": [
              {
                "path": "/pms/outbound-console/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__outbound-console' */'/var/www/chunsing-app-ui/src/pages/outbound-console'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/pms/outbound-console/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__outbound-console__edit' */'/var/www/chunsing-app-ui/src/pages/outbound-console/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/inventory",
            "name": "inventory",
            "icon": "FileZip",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__inventory' */'/var/www/chunsing-app-ui/src/pages/inventory'), loading: LoadingComponent}),
            "access": "canViewInventory",
            "hideChildrenInMenu": true,
            "exact": true
          },
          {
            "path": "/pms/stocktake",
            "name": "stocktake",
            "hideChildrenInMenu": true,
            "access": "canViewInventory",
            "icon": "FileZip",
            "routes": [
              {
                "path": "/pms/stocktake/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stocktake' */'/var/www/chunsing-app-ui/src/pages/stocktake'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "details",
                "path": "/pms/stocktake/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stocktake__details' */'/var/www/chunsing-app-ui/src/pages/stocktake/details'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/inventory-batch-adjustment",
            "name": "inventory-batch-adjustment",
            "hideChildrenInMenu": true,
            "access": "canViewInventory",
            "icon": "UngroupOutlined",
            "routes": [
              {
                "path": "/pms/inventory-batch-adjustment/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__inventory-batch-adjustment' */'/var/www/chunsing-app-ui/src/pages/inventory-batch-adjustment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/pms/inventory-batch-adjustment/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__inventory-batch-adjustment__edit' */'/var/www/chunsing-app-ui/src/pages/inventory-batch-adjustment/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/report",
            "name": "report",
            "hideChildrenInMenu": true,
            "icon": "FolderOpen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__report' */'/var/www/chunsing-app-ui/src/pages/product-manage/report'), loading: LoadingComponent}),
            "access": "canManageReport",
            "exact": true
          },
          {
            "path": "/pms/warehouse-bills",
            "name": "warehouse-bills",
            "hideChildrenInMenu": true,
            "access": "canViewWmsBill",
            "icon": "PullRequestOutlined",
            "routes": [
              {
                "path": "/pms/warehouse-bills/",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__warehouse-bill' */'/var/www/chunsing-app-ui/src/pages/warehouse-bill'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/pms/warehouse-bills/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__warehouse-bill__edit' */'/var/www/chunsing-app-ui/src/pages/warehouse-bill/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/calculator-profit-job",
            "name": "calculator-profit-jobs",
            "icon": "schedule",
            "disable": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__calculator-profit-job' */'/var/www/chunsing-app-ui/src/pages/product-manage/calculator-profit-job'), loading: LoadingComponent}),
            "hideChildrenInMenu": true,
            "access": "canManageProductPrice",
            "routes": [
              {
                "path": "/pms/calculator-profit-job/",
                "redirect": "/pms/calculator-profit-job/upload-supplier-cost",
                "exact": true
              },
              {
                "path": "/pms/calculator-profit-job/upload-supplier-cost",
                "name": "upload-supplier-cost-jobs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__calculator-profit-job__upload-supplier-cost' */'/var/www/chunsing-app-ui/src/pages/product-manage/calculator-profit-job/upload-supplier-cost'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/pms/calculator-profit-job/update-category-commission",
                "name": "update-category-commission-jobs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__calculator-profit-job__update-category-commission' */'/var/www/chunsing-app-ui/src/pages/product-manage/calculator-profit-job/update-category-commission'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/pms/calculator-profit-job/update-payment-commssion",
                "name": "update-payment-commssion-jobs",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__calculator-profit-job__update-payment-commssion' */'/var/www/chunsing-app-ui/src/pages/product-manage/calculator-profit-job/update-payment-commssion'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/pms/product-categories",
            "name": "product-category",
            "icon": "project",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__product-category' */'/var/www/chunsing-app-ui/src/pages/product-manage/product-category'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "setting",
            "routes": [
              {
                "path": "/pms/merchant",
                "name": "merchant",
                "icon": "user",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__merchant' */'/var/www/chunsing-app-ui/src/pages/merchant'), loading: LoadingComponent}),
                "access": "canEditMerchant",
                "exact": true
              },
              {
                "path": "/pms/marketpalce-categories",
                "name": "marketplace-category",
                "icon": "profile",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marketplace__marketplace-category' */'/var/www/chunsing-app-ui/src/pages/marketplace/marketplace-category'), loading: LoadingComponent}),
                "access": "canManageProductPrice",
                "exact": true
              },
              {
                "path": "/pms/marketpalce-payment-gateway",
                "name": "payment-gateway",
                "icon": "transaction",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__marketplace__marketplace-payment' */'/var/www/chunsing-app-ui/src/pages/marketplace/marketplace-payment'), loading: LoadingComponent}),
                "access": "canManageProductPrice",
                "exact": true
              },
              {
                "path": "/pms/suppliers",
                "name": "suppliers",
                "icon": "solution",
                "access": "canManageProductPrice",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__suppliers' */'/var/www/chunsing-app-ui/src/pages/product-manage/suppliers'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/pms/currency",
                "name": "currency",
                "icon": "DollarOutlined",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__currency' */'/var/www/chunsing-app-ui/src/pages/product-manage/currency'), loading: LoadingComponent}),
                "access": "canManageProductPrice",
                "exact": true
              },
              {
                "path": "/pms/exchange-rate",
                "name": "exchange-rate",
                "icon": "block",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product-manage__exchange-rate' */'/var/www/chunsing-app-ui/src/pages/product-manage/exchange-rate'), loading: LoadingComponent}),
                "access": "canManageProductPrice",
                "exact": true
              }
            ]
          }
        ],
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/var/www/chunsing-app-ui/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "key": "productManage",
        "hideChildrenInMenu": true,
        "access": "canAccessPms"
      },
      {
        "path": "/wms",
        "name": "wmsManage",
        "icon": "layout",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__WmsBasicLayout' */'/var/www/chunsing-app-ui/src/layouts/WmsBasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/wms",
            "redirect": "/wms/inbound/inbound-order",
            "exact": true
          },
          {
            "path": "/wms/inbound",
            "name": "inbound",
            "icon": "Inbox",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound' */'/var/www/chunsing-app-ui/src/pages/wms/inbound'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/wms/inbound",
                "redirect": "/wms/inbound/inbound-order",
                "exact": true
              },
              {
                "path": "/wms/inbound/inbound-order",
                "name": "asn",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__inbound-order' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/inbound-order.tsx'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/wms/inbound/inbound-order/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__edit' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/edit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inbound/inspection",
                "name": "inspection",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__inspection' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/inspection'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inbound/sorting",
                "name": "sorting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__sorting' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/sorting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inbound/print-label",
                "name": "print-label",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__print-label' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/print-label'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inbound/sorting-report",
                "name": "sorting-report",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__sorting-report' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/sorting-report'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inbound/put-away",
                "name": "put-away",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__put-away' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/put-away'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inbound/upload-receiving",
                "name": "upload-receiving",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inbound__upload-receiving' */'/var/www/chunsing-app-ui/src/pages/wms/inbound/upload-receiving'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/amr-replenishment",
            "name": "amr-replenishment",
            "icon": "scan",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment'), loading: LoadingComponent}),
            "access": "canViewWmsReplenishment",
            "routes": [
              {
                "path": "/wms/amr-replenishment",
                "redirect": "/wms/amr-replenishment/replenishment-orders",
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/products",
                "name": "products",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__products' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/products'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/replenishment-orders",
                "name": "replenishment-orders",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__replenishment-order' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/replenishment-order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/replenishment-orders/:id",
                "name": "edit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__edit' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/edit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/movement-temp-zone",
                "name": "movement-temp-zone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__movement-temp-zone' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/movement-temp-zone'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/return",
                "name": "return",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__return' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/return'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/picking",
                "name": "picking",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__picking' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/picking'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/received",
                "name": "received",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__received' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/received'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/amr-replenishment/request-items",
                "name": "request-items",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__amr-replenishment__request-item' */'/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/request-item'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/outbound",
            "name": "outbound",
            "icon": "send",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order'), loading: LoadingComponent}),
            "access": "canViewWmsOutbound",
            "routes": [
              {
                "path": "/wms/outbound",
                "redirect": "/wms/outbound/delivery-note",
                "exact": true
              },
              {
                "path": "/wms/outbound/delivery-note",
                "name": "deliveryNote",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__delivery-note' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/delivery-note.tsx'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/wms/outbound/delivery-note/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__edit' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/edit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound/new-order",
                "name": "new-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__new-order' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/new-order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound/amr-pick-order",
                "name": "amr-pick-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__amr-pick-order' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/amr-pick-order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound/no-amr-pick-order",
                "name": "no-amr-pick-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__no-amr-pick-order' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/no-amr-pick-order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound/amr-release-container",
                "access": "canReleaseContainer",
                "name": "amr-release-container",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__amr-release-container' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/amr-release-container'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound/transfer-pending-outbound",
                "name": "transfer-pending-outbound",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__transfer-pending-outbound' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/transfer-pending-outbound'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound/dashboard",
                "name": "dashboard",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-order__dashboard' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-order/dashboard.tsx'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/outbound-pick",
            "name": "outbound-pick",
            "icon": "ShoppingCartOutlined",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pick' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pick'), loading: LoadingComponent}),
            "access": "canManageWmsOutbound",
            "routes": [
              {
                "path": "/wms/outbound-pick",
                "redirect": "/wms/outbound-pick/amr-picklist",
                "exact": true
              },
              {
                "path": "/wms/outbound-pick/amr-picklist",
                "name": "amr-picklist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pick__amr-picklist' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pick/amr-picklist'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pick/no-amr-picklist",
                "name": "no-amr-picklist",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pick__no-amr-picklist' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pick/no-amr-picklist'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pick/no-amr-pick",
                "name": "no-amr-pick",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pick__no-amr-pick' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pick/no-amr-pick'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pick/no-amr-container-pick",
                "name": "no-amr-container-pick",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pick__no-amr-container-pick' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pick/no-amr-container-pick'), loading: LoadingComponent}),
                "access": "canAdminWms",
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/outbound-pack",
            "name": "outbound-pack",
            "icon": "SelectOutlined",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack'), loading: LoadingComponent}),
            "access": "canManageWmsOutbound",
            "routes": [
              {
                "path": "/wms/outbound-pack",
                "redirect": "/wms/outbound-pack/pack-order",
                "exact": true
              },
              {
                "path": "/wms/outbound-pack/pack-order",
                "name": "pack-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack__pack-order' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack/pack-order'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pack/reprint",
                "name": "reprint",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack__reprint' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack/reprint'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pack/reinput-weight",
                "name": "reinput-weight",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack__reinput-weight' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack/reinput-weight'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pack/scan-to-pack",
                "name": "scan-to-pack",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack__scan-to-pack' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack/scan-to-pack'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pack/pack-waybill",
                "name": "pack-waybill",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack__pack-waybill' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack/pack-waybill'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-pack/no-amr-pick-pack",
                "name": "no-amr-pick-pack",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__pack__no-amr-pick-pack' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/pack/no-amr-pick-pack'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/outbound-dispatch",
            "name": "outbound-dispatch",
            "icon": "SendOutlined",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__dispatch' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/dispatch'), loading: LoadingComponent}),
            "access": "canManageWmsOutbound",
            "routes": [
              {
                "path": "/wms/outbound-dispatch",
                "redirect": "/wms/outbound-dispatch/amr-dispatch",
                "exact": true
              },
              {
                "path": "/wms/outbound-dispatch/amr-dispatch",
                "name": "amr-dispatch",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__dispatch__amr-dispatch' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/dispatch/amr-dispatch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-dispatch/no-amr-dispatch",
                "name": "no-amr-dispatch",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__dispatch__no-amr-dispatch' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/dispatch/no-amr-dispatch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-dispatch/common-dispatch",
                "name": "common-dispatch",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__dispatch__common-dispatch' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/dispatch/common-dispatch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-dispatch/manifest",
                "name": "manifest",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__dispatch__manifest' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/dispatch/manifest'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/outbound-return",
            "name": "outbound-return",
            "icon": "SyncOutlined",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__return' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/return'), loading: LoadingComponent}),
            "access": "canManageWmsOutbound",
            "routes": [
              {
                "path": "/wms/outbound-return",
                "redirect": "/wms/outbound-return/return",
                "exact": true
              },
              {
                "path": "/wms/outbound-return/return",
                "name": "return",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__return__return' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/return/return'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-return/put-away",
                "name": "put-away",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__return__put-away' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/return/put-away'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-return/return-orders",
                "name": "return-orders",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__return__return-orders' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/return/return-orders'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/outbound-console",
            "name": "outbound-console",
            "icon": "AppstoreOutlined",
            "hideInMenu": true,
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-console' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-console'), loading: LoadingComponent}),
            "access": "canManageWmsOutbound",
            "routes": [
              {
                "path": "/wms/outbound-console",
                "redirect": "/wms/outbound-console/console",
                "exact": true
              },
              {
                "path": "/wms/outbound-console/console",
                "name": "console",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-console__console' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-console/console'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/wms/outbound-console/console/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-console__edit' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-console/edit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-console/create",
                "name": "create",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-console__create' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-console/create'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-console/dispatch",
                "name": "dispatch",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-console__dispatch' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-console/dispatch'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/outbound-console/console-order",
                "name": "console-order",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__outbound__outbound-console__console-order' */'/var/www/chunsing-app-ui/src/pages/wms/outbound/outbound-console/console-order'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/tools",
            "name": "tools",
            "icon": "tool",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__tools' */'/var/www/chunsing-app-ui/src/pages/wms/tools'), loading: LoadingComponent}),
            "access": "canManageWmsInbound",
            "routes": [
              {
                "path": "/wms/tools",
                "redirect": "/wms/tools/print-container",
                "exact": true
              },
              {
                "path": "/wms/tools/print-container",
                "name": "print-container",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__tools__print-container' */'/var/www/chunsing-app-ui/src/pages/wms/tools/print-container'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/tools/print-warehouse-trolley",
                "name": "print-warehouse-trolley",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__tools__print-warehouse-trolley' */'/var/www/chunsing-app-ui/src/pages/wms/tools/print-warehouse-trolley'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/tools/print-offline-inbound",
                "name": "print-offline-inbound",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__tools__print-offline-inbound' */'/var/www/chunsing-app-ui/src/pages/wms/tools/print-offline-inbound'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/tools/print-outbound-carton",
                "name": "print-outbound-carton",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__tools__print-outbound-carton' */'/var/www/chunsing-app-ui/src/pages/wms/tools/print-outbound-carton'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/transfer-pick-order",
            "name": "transfer-pick-order",
            "hideChildrenInMenu": true,
            "icon": "RetweetOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order'), loading: LoadingComponent}),
            "access": "canViewWmsTransferPick",
            "routes": [
              {
                "path": "/wms/transfer-pick-order",
                "redirect": "/wms/transfer-pick-order/new-transfer",
                "exact": true
              },
              {
                "path": "/wms/transfer-pick-order/new-transfer",
                "name": "new-transfer",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order__new-transfer' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/new-transfer'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/transfer-pick-order/no-amr-pick",
                "name": "no-amr-pick",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order__no-amr-pick' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/no-amr-pick'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/transfer-pick-order/sorting",
                "name": "sorting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order__sorting' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/sorting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/transfer-pick-order/put-away",
                "name": "put-away",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order__put-away' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/put-away'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/transfer-pick-order/sorting-report",
                "name": "sorting-report",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order__sorting-report' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/sorting-report'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/wms/transfer-pick-order/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__transfer-pick-order__edit-transfer' */'/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/edit-transfer'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/report",
            "name": "report",
            "hideChildrenInMenu": true,
            "access": "canViewWmsReport",
            "icon": "FolderOpen",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__report' */'/var/www/chunsing-app-ui/src/pages/wms/report'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/wms/inventory-management",
            "name": "inventoryManage",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory' */'/var/www/chunsing-app-ui/src/pages/wms/inventory'), loading: LoadingComponent}),
            "icon": "database",
            "access": "canViewWmsInventory",
            "routes": [
              {
                "path": "/wms/inventory-management/",
                "redirect": "/wms/inventory-management/stock",
                "exact": true
              },
              {
                "path": "/wms/inventory-management/stock",
                "name": "stock",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__stock' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/stock'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/stock-product-type",
                "name": "stock-product-type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__stock-product-type' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/stock-product-type'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/inventory-bin",
                "name": "inventory-bin",
                "icon": "FileText",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory-bin' */'/var/www/chunsing-app-ui/src/pages/wms/inventory-bin'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/stocktake",
                "name": "stocktake",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__stocktake' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/stocktake'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit-stocktake",
                "path": "/wms/inventory-management/stocktake/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__edit-stocktake' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/edit-stocktake'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/inventory-adjustments",
                "name": "inventory-adjustments",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__inventory-adjustments' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/inventory-adjustments'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit-inventory-adjustment",
                "path": "/wms/inventory-management/inventory-adjustments/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__edit-inventory-adjustment' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/edit-inventory-adjustment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/batch-property-adjustment",
                "name": "batch-property-adjustment",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__batch-property-adjustment' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/batch-property-adjustment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit-batch-adjustment",
                "path": "/wms/inventory-management/batch-property-adjustment/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__edit-batch-property-adjustment' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/edit-batch-property-adjustment'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/temp-inventory",
                "name": "temp-inventory",
                "icon": "FileText",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory__temp-inventory' */'/var/www/chunsing-app-ui/src/pages/wms/inventory/temp-inventory'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/bin-lock-setting",
                "name": "bin-lock-setting",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__bin-lock-setting' */'/var/www/chunsing-app-ui/src/pages/wms/bin-lock-setting'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/bin-lock-setting/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__bin-lock-setting__edit' */'/var/www/chunsing-app-ui/src/pages/wms/bin-lock-setting/edit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/inventory-management/inventory-cycle-count",
                "name": "inventory-cycle-count",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__inventory-cycle-count' */'/var/www/chunsing-app-ui/src/pages/wms/inventory-cycle-count'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/warehouse-setting",
            "name": "warehouseSetting",
            "access": "canAccessWarehouseSetting",
            "routes": [
              {
                "path": "/wms/warehouse-setting/",
                "redirect": "/wms/warehouse-setting/warehouses",
                "exact": true
              },
              {
                "path": "/wms/warehouse-setting/warehouses",
                "name": "warehouses",
                "access": "canAdminWms",
                "icon": "home",
                "showMenuIcon": true,
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/wms/warehouse-setting/warehouses/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__warehouses' */'/var/www/chunsing-app-ui/src/pages/wms/admin/warehouses'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "warehouse_edit",
                    "path": "/wms/warehouse-setting/warehouses/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__warehouses__edit' */'/var/www/chunsing-app-ui/src/pages/wms/admin/warehouses/edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/wms/warehouse-setting/virtual-warehouses",
                "name": "virtual-warehouses",
                "access": "canAdminWms",
                "icon": "bank",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__virtual-warehouse' */'/var/www/chunsing-app-ui/src/pages/wms/virtual-warehouse'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/warehouse-setting/warehouse-zone",
                "name": "warehouse-zone",
                "access": "canAdminWms",
                "icon": "layout",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__warehouse-zone' */'/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-zone'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/warehouse-setting/warehouse-bin",
                "name": "warehouse-bin",
                "access": "canManageWarehouseBin",
                "icon": "layout",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__warehouse-bin' */'/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-bin'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/warehouse-setting/warehouse-bin-size",
                "icon": "block",
                "name": "warehouse-bin-size",
                "access": "canManageWarehouseBin",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__warehouse-bin-size' */'/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-bin-size'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/pick-pack-vas",
            "name": "pickPackVas",
            "access": "canAdminWms",
            "icon": "CodeSandboxOutlined",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__pick-pack-vas' */'/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/wms/pick-pack-vas/",
                "redirect": "/wms/pick-pack-vas/pick-pack-vas-flow",
                "exact": true
              },
              {
                "path": "/wms/pick-pack-vas/pick-pack-vas-flow",
                "name": "pick-pack-vas-flow",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__pick-pack-vas__pick-pack-vas-flow' */'/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/pick-pack-vas-flow'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/pick-pack-vas/marketplace",
                "name": "marketplace",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__pick-pack-vas__marketplace' */'/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/marketplace'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/pick-pack-vas/marketplace-pick-pack-vas",
                "name": "marketplace-pick-pack-vas",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__pick-pack-vas__marketplace-pick-pack-vas' */'/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/marketplace-pick-pack-vas'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/settlement-center",
            "name": "settlementCenter",
            "access": "canAdminWms",
            "routes": [
              {
                "path": "/wms/settlement-center/company-quotations",
                "name": "company-wms-quotations",
                "access": "canManageWmsQuotation",
                "icon": "SolutionOutlined",
                "showMenuIcon": true,
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/wms/settlement-center/company-quotations/",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__company-wms-quotation' */'/var/www/chunsing-app-ui/src/pages/wms/admin/company-wms-quotation'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "edit",
                    "path": "/wms/settlement-center/company-quotations/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__company-wms-quotation__edit' */'/var/www/chunsing-app-ui/src/pages/wms/admin/company-wms-quotation/edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/wms/settlement-center/bill-manage",
                "name": "bill-manage",
                "access": "canManageWmsBill",
                "icon": "dollar",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage'), loading: LoadingComponent}),
                "showMenuIcon": true,
                "hideChildrenInMenu": true,
                "routes": [
                  {
                    "path": "/wms/settlement-center/bill-manage",
                    "redirect": "/wms/settlement-center/bill-manage/warehouse-bill",
                    "exact": true
                  },
                  {
                    "path": "/wms/settlement-center/bill-manage/warehouse-bill",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage__warehouse-bill' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/warehouse-bill'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "name": "edit",
                    "path": "/wms/settlement-center/bill-manage/warehouse-bill/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage__warehouse-bill__edit' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/warehouse-bill/edit'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/wms/settlement-center/bill-manage/warehouse-rent-bills",
                    "name": "warehouse-rent-bills",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage__warehouse-rent-bill' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/warehouse-rent-bill'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/wms/settlement-center/bill-manage/warehouse-rent-bill-items",
                    "name": "warehouse-rent-bill-items",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage__warehouse-rent-bill-item' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/warehouse-rent-bill-item'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/wms/settlement-center/bill-manage/inbound-charges",
                    "name": "inbound-charges",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage__inbound-charge' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/inbound-charge'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/wms/settlement-center/bill-manage/outbound-charges",
                    "name": "outbound-charges",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__bill-manage__outbound-charge' */'/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/outbound-charge'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "path": "/wms/merchants",
            "name": "merchants",
            "icon": "user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__merchant' */'/var/www/chunsing-app-ui/src/pages/wms/merchant'), loading: LoadingComponent}),
            "hideChildrenInMenu": true,
            "access": "canEditMerchant",
            "exact": true
          },
          {
            "path": "/wms/client-webhooks",
            "name": "client-webhooks",
            "icon": "user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__admin__client-webhook' */'/var/www/chunsing-app-ui/src/pages/wms/admin/client-webhook'), loading: LoadingComponent}),
            "hideChildrenInMenu": true,
            "access": "canAdminWms",
            "exact": true
          },
          {
            "path": "/wms/products",
            "name": "products",
            "icon": "FileZip",
            "hideChildrenInMenu": true,
            "access": "canViewProduct",
            "routes": [
              {
                "path": "/wms/products",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__products' */'/var/www/chunsing-app-ui/src/pages/wms/products'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/wms/products/wms-provider-products",
                "name": "wms-provider-products",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__products__wms-provider-product' */'/var/www/chunsing-app-ui/src/pages/wms/products/wms-provider-product'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "edit",
                "path": "/wms/products/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__products__edit' */'/var/www/chunsing-app-ui/src/pages/wms/products/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/wms/drivers",
            "name": "drivers",
            "icon": "car",
            "hideChildrenInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wms__drivers' */'/var/www/chunsing-app-ui/src/pages/wms/drivers'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "line",
            "path": "/",
            "key": "line",
            "disabled": true,
            "exact": true
          }
        ],
        "key": "wmsManage",
        "hideChildrenInMenu": true,
        "access": "canAccessWms"
      },
      {
        "name": "settings",
        "icon": "smile",
        "hideInMenu": true,
        "path": "/profile/settings",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__profile__settings' */'/var/www/chunsing-app-ui/src/pages/profile/settings'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "administration",
        "path": "/administration",
        "icon": "user",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/administration/user",
            "name": "user",
            "icon": "UserSwitchOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__user' */'/var/www/chunsing-app-ui/src/pages/admin/user'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/administration/role",
            "name": "role",
            "icon": "UsergroupAddOutlined",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__role' */'/var/www/chunsing-app-ui/src/pages/admin/role'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/administration/subsidiary",
            "name": "subsidiary",
            "icon": "control",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__subsidiary' */'/var/www/chunsing-app-ui/src/pages/admin/subsidiary'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/chunsing-app-ui/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
