export default {
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'shippers.shipper_name': 'Shipper Name',
  'shippers.warehouse': 'Warehouse Name',
  'shippers.warehouse_address': 'Warehouse Address',
  'shippers.shipper_account': 'Shipper Account',
  'shippers.billing_account': 'Billing Account',
  'shippers.duty_account': 'Duty Account',
  'shippers.registered_account': 'Registered Account',
  'shippers.address': 'Address',
  'shippers.city': 'City',
  'shippers.division': 'Division',
  'shippers.postal_code': 'Postal Code',
  'shippers.country_name': 'Country',
  'shippers.contact_person_name': 'Contact Person',
  'shippers.contact_phone': 'Contact Phone',
};
