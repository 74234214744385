export default {
  'dashboard.currency.title': 'Currency Exchange',
  'dashboard.currency.from_currency': 'From Currency',
  'dashboard.currency.to_currency': 'To Currency',
  'dashboard.currency.rate': 'Rate',
  'dashboard.currency.updated_at': 'Updated At',
  'dashboard.services.title': 'Services Summary',
  'dashboard.services.service_code': 'Service Channel',
  'dashboard.services.total_packages': 'Total Packages',
  'dashboard.services.total_scan_packages': 'Total Scanned Pacakges',
  'dashboard.asn.title': 'Latest Pickup Requests',
  'dashboard.asn.asn_number': 'ASN Number',
  'dashboard.asn.delivery_type': 'Type',
  'dashboard.asn.request_collection_date': 'Delivery / Collection Date',
  'dashboard.asn.request_packages': 'Request Packages',
  'dashboard.asn.scanned_packages': 'Scanned Packages',
  'dashboard.asn.type.pickup': 'Pickup',
  'dashboard.asn.type.delivery': 'Delivery',
};
