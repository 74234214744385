export default {
  'warehouse_bill.merchant': 'Merchant',
  'warehouse_bill.customer': 'Customer',
  'warehouse_bill.warehouse': 'Warehouse',
  'warehouse_bill.bill_code': 'Bill Code',
  'warehouse_bill.bill_date': 'Bill Date',
  'warehouse_bill.total_amount': 'Total Amount',
  'warehouse_bill.bill_date_month': 'Bill Month',
  'warehouse_bill.bill_start_date': 'Bill Start Date',
  'warehouse_bill.bill_end_date': 'Bill End Date',
  'warehouse_bill.remark': 'Remark',
  'warehouse_bill.submitted_at': 'Submitted At',
  'warehouse_bill.submitted_by': 'Submitted By',
  'warehouse_bill.status': 'Status',
};
