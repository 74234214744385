// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/var/www/chunsing-app-ui/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelFile0 from '/var/www/chunsing-app-ui/src/models/finance/file.ts';
import ModelNote1 from '/var/www/chunsing-app-ui/src/models/finance/note.ts';
import ModelFile2 from '/var/www/chunsing-app-ui/src/models/wms/file.ts';
import ModelNote3 from '/var/www/chunsing-app-ui/src/models/wms/note.ts';
import ModelModel4 from '/var/www/chunsing-app-ui/src/pages/admin-shipment/delivery-booking/model.ts';
import ModelModel5 from '/var/www/chunsing-app-ui/src/pages/delivery-booking/model.ts';
import ModelModel6 from '/var/www/chunsing-app-ui/src/pages/delivery-note/model.ts';
import ModelModel7 from '/var/www/chunsing-app-ui/src/pages/inbound/model.ts';
import ModelModel8 from '/var/www/chunsing-app-ui/src/pages/inventory-batch-adjustment/model.ts';
import ModelModel9 from '/var/www/chunsing-app-ui/src/pages/label-manage/model.ts';
import ModelModel10 from '/var/www/chunsing-app-ui/src/pages/outbound-console/model.ts';
import ModelModel11 from '/var/www/chunsing-app-ui/src/pages/product-manage/products/model.ts';
import ModelModel12 from '/var/www/chunsing-app-ui/src/pages/product-manage/suppliers/model.ts';
import ModelModel13 from '/var/www/chunsing-app-ui/src/pages/rma-order/model.ts';
import ModelModel14 from '/var/www/chunsing-app-ui/src/pages/stocktake/model.ts';
import ModelModel15 from '/var/www/chunsing-app-ui/src/pages/warehouse-bill/model.ts';
import ModelModel16 from '/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/warehouse-bill/model.ts';
import ModelModel17 from '/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/model.ts';
import ModelModel18 from '/var/www/chunsing-app-ui/src/pages/wms/bin-lock-setting/model.ts';
import ModelModel19 from '/var/www/chunsing-app-ui/src/pages/wms/inbound/model.ts';
import ModelModel20 from '/var/www/chunsing-app-ui/src/pages/wms/inventory/model.ts';
import ModelModel21 from '/var/www/chunsing-app-ui/src/pages/wms/outbound/model.ts';
import ModelModel22 from '/var/www/chunsing-app-ui/src/pages/wms/products/model.ts';
import ModelModel23 from '/var/www/chunsing-app-ui/src/pages/wms/rma-order/model.ts';
import ModelModel24 from '/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/model.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'file', ...ModelFile0 });
app.model({ namespace: 'note', ...ModelNote1 });
app.model({ namespace: 'file', ...ModelFile2 });
app.model({ namespace: 'note', ...ModelNote3 });
app.model({ namespace: 'model', ...ModelModel4 });
app.model({ namespace: 'model', ...ModelModel5 });
app.model({ namespace: 'model', ...ModelModel6 });
app.model({ namespace: 'model', ...ModelModel7 });
app.model({ namespace: 'model', ...ModelModel8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
app.model({ namespace: 'model', ...ModelModel11 });
app.model({ namespace: 'model', ...ModelModel12 });
app.model({ namespace: 'model', ...ModelModel13 });
app.model({ namespace: 'model', ...ModelModel14 });
app.model({ namespace: 'model', ...ModelModel15 });
app.model({ namespace: 'model', ...ModelModel16 });
app.model({ namespace: 'model', ...ModelModel17 });
app.model({ namespace: 'model', ...ModelModel18 });
app.model({ namespace: 'model', ...ModelModel19 });
app.model({ namespace: 'model', ...ModelModel20 });
app.model({ namespace: 'model', ...ModelModel21 });
app.model({ namespace: 'model', ...ModelModel22 });
app.model({ namespace: 'model', ...ModelModel23 });
app.model({ namespace: 'model', ...ModelModel24 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
