export default {
  'remark.title': 'Document Remark',
  'remark.title.edit': 'Set Document Remark',
  'remark.title.custom': 'Custom Document Remark',
  'remark.btn.custom': 'Custom',
  'remark.carry-next': 'Carry to next document',
  'remark.carry-all': 'Carry All Remarks',
  'remark.transfer.source-title': 'Remark Template List',
  'remark.transfer.target-title': 'Selected',
  'remark.search.title.placeholder': 'Search here',
  'remark.search.document.placeholder': 'Select Remark Label',
};
