import type {
  SearchQuoteTableListParams,
  PendingLabelTableListParams,
  ToDownloadLabelTableListParams,
  WmsInProgressLabelTableListParams,
  WmsShipedLabelTableListParams,
  LabelDetailParams,
} from '@/models/delivery';
import type {
  CreateLabelDataType,
  CreateReturnLabelDataType,
  CreateReturnLabelFormValueType,
  UpdateLabelDataType,
} from '@/pages/label-manage/data.d';
import { request } from 'umi';

const {
  LME_OMS_DELIVERY_URL,
  LME_OMS_COURIER_SERVICE_URL,
  LME_OMS_OPEN_SHIPMENT_URL,
  LME_A_OMS_DELIVERY_DOWNLOAD_LABEL_URL,
} = API;

export async function queryQuote(params: SearchQuoteTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_URL}/quote`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function queryDeliveryLabelStatistic() {
  return request(`${LME_OMS_DELIVERY_URL}/pending/statistic`, {
    method: 'GET',
  });
}

export async function queryPendingLabel(params: PendingLabelTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/jobs`, {
    method: 'GET',
    params,
  });
}

export async function queryRejectedLabel(params: PendingLabelTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/rejected`, {
    method: 'GET',
    params,
  });
}

export async function deleteRejectDeliveryLabels(params: { ids: number[] }) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/delete-rejected`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function deleteRejectedLabel(id: number) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${id}`, {
    method: 'DELETE',
  });
}

export async function retryPendingLabel(id: number) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/retry/${id}`, {
    method: 'POST',
  });
}

export async function queryCreateLabelOption() {
  return request(`${LME_OMS_DELIVERY_URL}/pending/create`, {
    method: 'GET',
  });
}

export async function getEditPendingLabel(id: number) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${id}/edit`, {
    method: 'GET',
  });
}

export async function queryUploadLabelOption() {
  return request(`${LME_OMS_DELIVERY_URL}/upload/order`, {
    method: 'GET',
  });
}

export async function queryLabelServiceOption(service_code: string) {
  return request(`${LME_OMS_COURIER_SERVICE_URL}/${service_code}/options`, {
    method: 'GET',
  });
}

export async function getImportCourierServices() {
  return request(`${LME_OMS_COURIER_SERVICE_URL}/import-services`, {
    method: 'GET',
  });
}

export async function getExportCourierServices() {
  return request(`${LME_OMS_COURIER_SERVICE_URL}/export-services`, {
    method: 'GET',
  });
}

export async function queryPendingLabelDetail(id: number) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${id}`, {
    method: 'GET',
  });
}

export async function queryToDownloadLabel(params: ToDownloadLabelTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_URL}/order/to-download-label`, {
    method: 'GET',
    params,
  });
}

export async function getDeliveryLabelOptions() {
  return request(`${LME_OMS_DELIVERY_URL}/order/options`, {
    method: 'GET',
  });
}

export async function queryWmsPreOrderLabel(params: WmsInProgressLabelTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_URL}/order/wms-preorder`, {
    method: 'GET',
    params,
  });
}

export async function queryWmsInProgressLabel(
  params: WmsInProgressLabelTableListParams | undefined,
) {
  return request(`${LME_OMS_DELIVERY_URL}/order/wms-in-progress`, {
    method: 'GET',
    params,
  });
}

export async function queryWmsShippedLabel(params: WmsShipedLabelTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_URL}/order/wms-shipped`, {
    method: 'GET',
    params,
  });
}

// export async function queryOutboundLabel(params: NoteTableListParams | undefined) {
//   return request(`${LME_OMS_DELIVERY_URL}/order/out-warehouse`, {
//     method: 'GET',
//     params,
//   });
// }

export async function queryLabelDetail(params: LabelDetailParams) {
  const { id, ship } = params;
  if (ship === 'pending') {
    return request(`${LME_OMS_DELIVERY_URL}/pending/${id}`, {
      method: 'GET',
    });
  }
  return request(`${LME_OMS_DELIVERY_URL}/order/${id}`, {
    method: 'GET',
  });
}

export async function addLabel(params: CreateLabelDataType) {
  return request(`${LME_OMS_DELIVERY_URL}/pending`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createReturnLabel(
  delieryOrderId: number,
  params: CreateReturnLabelFormValueType,
) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${delieryOrderId}/return`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateLabel(params: UpdateLabelDataType) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${params.id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function deleteDeliveryOrderItem(id: number, itemId: number) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${id}/delete-item/${itemId}`, {
    method: 'POST',
  });
}

export async function downloadDeliveryOrdersReference(params: { reference_no_list: string[] }) {
  return request(`${LME_OMS_DELIVERY_URL}/download/order/reference`, {
    method: 'POST',
    data: {
      ...params,
    },
    responseType: 'blob',
  });
}

export async function downloadDeliveryOrdersLabel(params: { reference_no_list: string[] }) {
  return request(`${LME_OMS_DELIVERY_URL}/download/order/label`, {
    method: 'POST',
    data: {
      ...params,
    },
    responseType: 'blob',
  });
}

export async function downloadDeliveryOrdersInvoices(params: { reference_no_list: string[] }) {
  return request(`${LME_OMS_DELIVERY_URL}/download/order/invoice`, {
    method: 'POST',
    data: {
      ...params,
    },
    responseType: 'blob',
  });
}

export async function downloadDeliveryLabel(params: { reference_no: string }) {
  return request(`${LME_OMS_DELIVERY_URL}/download/order/label`, {
    method: 'GET',
    responseType: 'blob',
    params,
  });
}

export async function downloadDeliveryInvoiceLabel(params: { reference_no: string }) {
  return request(`${LME_OMS_DELIVERY_URL}/download/order/invoice`, {
    method: 'GET',
    responseType: 'blob',
    params,
  });
}

export async function queryLabelBatchLatest() {
  return request(`${LME_OMS_DELIVERY_URL}/batch/latest`, {
    method: 'GET',
  });
}

export async function queryAsnOptionsByBatch(id: number) {
  return request(`${LME_OMS_DELIVERY_URL}/batch/${id}/asn-options`, {
    method: 'GET',
  });
}

export async function queryLabelBatchReadyPickup() {
  return request(`${LME_OMS_DELIVERY_URL}/batch/ready-pickup`, {
    method: 'GET',
  });
}

export async function downloadBatchDeliveryLabel(batch_id: string) {
  return request(`${LME_OMS_DELIVERY_URL}/download/batch/${batch_id}/label`, {
    method: 'GET',
    responseType: 'blob',
  });
}
export async function downloadBatchDeliveryInvoice(batch_id: string) {
  return request(`${LME_OMS_DELIVERY_URL}/download/batch/${batch_id}/invoice`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadBatchDeliverySheet(batch_id: string) {
  return request(`${LME_OMS_DELIVERY_URL}/download/batch/${batch_id}/sheet`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function queryDeliveryShipmentRoutes(params: { tracking_numbers: string[] }) {
  return request(`${LME_OMS_OPEN_SHIPMENT_URL}/routes`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createDeliveryReturnLabel(id: number, params: CreateReturnLabelDataType) {
  return request(`${LME_OMS_DELIVERY_URL}/pending/${id}/return`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function adminDownloadDeliveryPackWaybill(params: {
  delivery_order_id: number;
  reference_no: string;
  tracking_no: string;
}) {
  return request(`${LME_A_OMS_DELIVERY_DOWNLOAD_LABEL_URL}/pack-waybill`, {
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export async function adminDownloadDeliveryWaybill(params: {
  delivery_order_id: number;
  reference_no: string;
  tracking_no: string;
}) {
  return request(`${LME_A_OMS_DELIVERY_DOWNLOAD_LABEL_URL}/waybill`, {
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export async function adminDownloadOmsDeliveryInvoice(params: {
  delivery_order_id: number;
  reference_no: string;
  tracking_no: string;
}) {
  return request(`${LME_A_OMS_DELIVERY_DOWNLOAD_LABEL_URL}/invoice`, {
    method: 'POST',
    responseType: 'blob',
    params,
  });
}

export async function cancelDeliveryToWmsPreOrder(params: { id: number }) {
  const { id } = params;

  return request(`${LME_OMS_DELIVERY_URL}/order/${id}/cancel-wms-preorder`, {
    method: 'POST',
  });
}

export async function downloadDeliveryOrders(
  params: ToDownloadLabelTableListParams | undefined,
) {
  return request(`${LME_OMS_DELIVERY_URL}/download/orders`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}
