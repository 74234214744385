export default {
  'suppliers.primary_name': 'Primary Name',
  'suppliers.secondary_name': 'Secondary Name',
  'suppliers.address': 'Address',
  'suppliers.default_currency': 'Default Currency',
  'suppliers.tel': 'Tel',
  'suppliers.fax': 'Fax',
  'suppliers.br': 'BR',
  'suppliers.website': 'Website',
  'suppliers.remark': 'Remark',
  'suppliers.status': 'Status',
  'suppliers.create': 'Create Supplier',
  'suppliers.create.fail': 'Fail to create supplier',
  'suppliers.create.success': 'Supplier has been succesfully created',
  'suppliers.update.fail': 'Fail to update supplier',
  'suppliers.update': 'Update',
  'suppliers.update.success': 'Supplier has been succesfully updated',
  'suppliers.button.show-contact': 'Show Contact',
  'suppliers.button.add-contact': 'Add Company Contact',
  'suppliers.contact-modal.title': 'Contacts of {company_name}',
  'suppliers.contact-modal.create-title': 'Create Contact for {company_name}',
  'suppliers.contact_person': 'Contact Person',
  'suppliers.button.show-bank': 'Show Bank',
  'suppliers.button.add-bank': 'Add Company Bank',
  'suppliers.bank-modal.title': 'Banks of {company_name}',
  'suppliers.bank-modal.create-title': 'Create Bank for {company_name}',
  'suppliers.default_payment_term': 'Default Payment Term',
  'suppliers.delete-confirm': 'Are you sure to delete this company?',
  'suppliers.contacts': 'Contacts',
  'suppliers.banks': 'Banks',
  'suppliers.contact.email': 'Email',
  'suppliers.contact.job_title': 'Job Title',
  'suppliers.contact.name': 'Display Name',
  'suppliers.contact.initial': 'Initial',
  'suppliers.contact.office_tel': 'Office Tel',
  'suppliers.contact.mobile_tel': 'Mobile Tel',
  'suppliers.contact.department': 'Department',
  'suppliers.contact.status': 'Status',
  'suppliers.contact.delete-confirm': 'Are you sure to delete this contact?',
  'suppliers.bank.email': 'Email',
  'suppliers.bank.job_title': 'Job Title',
  'suppliers.bank.name': 'Bank Name',
  'suppliers.bank.account': 'Account',
  'suppliers.bank.bic': 'BIC',
  'suppliers.bank.currency': 'Currency',
  'suppliers.bank.remark': 'Remark',
  'suppliers.bank.status': 'Status',
  'suppliers.bank.delete-confirm': 'Are you sure to delete this bank?',
};
