import { request } from 'umi';
import type { BinLockItemTableListParams, BinLockTableListParams, CheckBinForLock } from './data';

const { JOR_A_WMS_BIN_LOCK_URL } = API;

export async function getBinLockDetails(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_BIN_LOCK_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getBinLockItems(
  binlock_id: number,
  params: BinLockItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_BIN_LOCK_URL}/${binlock_id}/items`, {
    method: 'GET',
    params,
  });
}

export async function getBinLocks(params: BinLockTableListParams | undefined) {
  return request(`${JOR_A_WMS_BIN_LOCK_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getBinLockOptions() {
  return request(`${JOR_A_WMS_BIN_LOCK_URL}/options`, {
    method: 'GET',
  });
}

export async function loadBinForLock(params: CheckBinForLock) {
  return request(`${JOR_A_WMS_BIN_LOCK_URL}/bin`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createBinLock(params: any) {
  return request(`${JOR_A_WMS_BIN_LOCK_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function adminActivateBinLock(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_BIN_LOCK_URL}/${id}/activate`, {
    method: 'POST',
  });
}

export async function adminCloseBinLock(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_BIN_LOCK_URL}/${id}/close`, {
    method: 'POST',
  });
}
