export default {
  'company-spot-rates.company_name': 'Company Name',
  'company-spot-rates.courier_service': 'Courier Service',
  'company-spot-rates.from': 'From',
  'company-spot-rates.destination': 'Destination',
  'company-spot-rates.effective_date': 'Effective Date',
  'company-spot-rates.weight': 'Weight',
  'company-spot-rates.dimension_denominator': 'Dimension Denominator',
  'company-spot-rates.cost': 'Freight Cost',
  'company-spot-rates.rate': 'Freight Rate',
  'company-spot-rates.ddp_surcharge': 'DDP Surcharge',
  'company-spot-rates.peak_season_surcharge': 'Peak Season Surcharge',
  'company-spot-rates.fuel_surcharge': 'Fuel Surcharge',
  'company-spot-rates.markup': 'Markup',
  'company-spot-rates.update': 'Update',
  'company-spot-rates.status': 'Status',
  'company-spot-rates.disable-confirm': 'Are you sure you want to disable this services?',
  'company-spot-rates.enable-confirm': 'Are you sure you want to enable this services?',
  'company-spot-rates.disable.success': 'Service has been successfully disabled',
  'company-spot-rates.enable.success': 'Service has been successfully enabled',
  'company-spot-rates.disable.fail': 'Fail to disable service',
  'company-spot-rates.enable.fail': 'Fail to enable service',
  'company-spot-rates.update.success': 'Service has been succesfully updated',
};
