export default {
  'product_price.marketplace': 'Marketplace',
  'product_price.product': 'Product',
  'product_price.history': '{product_name} Price Histories',
  'product_price.show_history': 'Show Price Histories',
  'product_price.product_name': 'Product Name',
  'product_price.selling_price_hk': 'Selling Price(HKD)',
  'product_price.freight_cost_hk': 'Freight Cost(HKD)',
  'product_price.total_commission_cost_hk': 'Total Commission Cost(HKD)',
  'product_price.search_product': 'Search Product',
  'product_price.product_code': 'Product Code',
  'product_price.marketplace_category': 'Marketplace Category',
  'product_price.supplier_cost_info': 'Supplier Cost Info',
  'product_price.category_commission_info': 'Category Commission Info',
  'product_price.marketplace_commission': 'Commission',
  'product_price.selling_price': 'Selling Price',
  'product_price.sku': 'SKU',
  'product_price.currency': 'Currency',
  'product_price.lme_service_code': 'LME Service Code',
  'product_price.freight': 'Freight',
  'product_price.freight_currency': 'Freight Currency',
  'product_price.freight_rate_to_hkd': 'Freight Rate To HKD',
  'product_price.rate_to_hkd': 'Rate To HKD',
  'product_price.supplier': 'Supplier',
  'product_price.payment_gateway': 'Payment Gateway',
  'product_price.payment_gateway_commission': 'Payment Gateway Commission',
  'product_price.duty_and_tax_commission': 'VAT Commission',
  'product_price.management_fee_commission': 'Platform Management Fee',
  'product_price.commission': 'Commission',
  'product_price.refurbished_commission': 'Refurbished Commission',
  'product_price.rebate_commission': 'Rebate Commission',
  'product_price.product_supplier': 'Product Supplier',
  'product_price.supplier_cost_hk': 'Product Cost(HKD)',
  'product_price.total_cost_hk': 'Total Cost(HKD)',
  'product_price.total_commission': 'Total Commission',
  'product_price.net_profit_hk': 'Net Profit(HKD)',
  'product_price.net_profit_margin': 'Net Profit Margin',
  'product_price.profit_percentage': 'Profit Percentage',
  'product_price.create': 'Create Product Price',
  'product_price.create.fail': 'Fail to create product price',
  'product_price.create.success': 'Product Price has been succesfully created',
  'product_price.update.fail': 'Fail to update product price',
  'product_price.update': 'Update',
  'product_price.update.success': 'Product Price has been succesfully updated',
  'product_price.status': 'Status',
  'product_price.remark': 'Remark',
  'product_price.disable-confirm':
    'Are you sure to disable this product {product} in {marketpalce} price?',
  'product_price.enable-confirm':
    'Are you sure to enable this product {product} in {marketpalce} price?',
};
