import { request } from 'umi';
import type { CreateFormValueType } from './components/CreateForm';
import type { CreateItemFormValueType } from './components/CreateItemForm';
import type { UpdateFormValueType } from './components/UpdateForm';
import type { UpdateItemFormValueType } from './components/UpdateItemForm';
import type { BatchAdjustmentItemFormDataType, InventoryTableListParams } from './data';

const { JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL } = API;

export async function getInventoryBatchAdjustments(params: InventoryTableListParams | undefined) {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getInventoryBatchAdjustmentDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getAllocatedInventory(id: number) {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}/allocated`, {
    method: 'GET',
  });
}

export async function createInventoryBatchAdjustment(params: CreateFormValueType) {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateInventoryBatchAdjustment(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function getInventoryBatchAdjustmentOptions() {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/options`, {
    method: 'GET',
  });
}

export async function getInventoryBatchAdjustmentItemOptions() {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/item/options`, {
    method: 'GET',
  });
}

export async function dispatchInventoryBatchAdjustment(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}/dispatch`, {
    method: 'POST',
  });
}

export async function cancelInventoryBatchAdjustment(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function getInventoryBatchAdjustmentItems(
  id: number,
  params: InventoryTableListParams | undefined,
) {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function createInventoryBatchAdjustmentItems(
  id: number,
  params: BatchAdjustmentItemFormDataType,
) {
  return request(`${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}/items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createInventoryBatchAdjustmentItem(params: CreateItemFormValueType) {
  const { inventory_batch_adjustment_id } = params;
  return request(
    `${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${inventory_batch_adjustment_id}/item`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function updateInventoryBatchAdjustmentItem(params: UpdateItemFormValueType) {
  const { inventory_batch_adjustment_id } = params;
  return request(
    `${JOR_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${inventory_batch_adjustment_id}/item`,
    {
      method: 'PUT',
      data: {
        ...params,
      },
    },
  );
}
