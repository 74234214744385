import { request } from 'umi';
import type {
  ConfirmPickedContainerItemParams,
  OperationSortingTransferProduct,
  TransferPickOrderItemTableListParams,
} from './data';
import type { CreateTransferPickOrderFormValueType } from './components/CreateItemForm';
import type { SortingNormalTransferItemFormValue } from './components/sorting/SortingNormalForm';
import type { SortingDamageTransferItemFormValue } from './components/sorting/SortingDamageForm';
import type { UserPickOutboundLogItem } from '../outbound/data';

const {
  JOR_A_WMS_TRANSFER_PICK_ORDER_URL,
  JOR_A_WMS_INBOUND_CONTAINER_BOX_URL,
  JOR_A_WMS_TRANSFER_SORTING_ITEM_URL,
} = API;

export async function getTransferPickOrders(
  params: TransferPickOrderItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getTransferPickOrderOptions() {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function getTransferPickOrderDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getTransferPickOrderItems(
  id: number,
  params: TransferPickOrderItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function getTransferInvAdjustmentItems(
  id: number,
  params: TransferPickOrderItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/inventory/adjustments`, {
    method: 'GET',
    params,
  });
}

export async function getTransferInvBatchAdjustmentItems(
  id: number,
  params: TransferPickOrderItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/inventory/batch/adjustments`, {
    method: 'GET',
    params,
  });
}

export async function createTransferPickOrder(params: CreateTransferPickOrderFormValueType) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function forceCompleteTransferOrderPicking(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/force/complete/picking`, {
    method: 'POST',
  });
}

export async function adminCancelTransferPickOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function confirmPickedTransferContainerItem(params: ConfirmPickedContainerItemParams) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/confirm/picked/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function scanTransferPicklistByCode(transferOrderCode: string) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/picklist/${transferOrderCode}`, {
    method: 'GET',
  });
}

export async function checkTransferAvailablePickItem(params: {
  serial_number: string;
  picklist_no: string;
  update_user_pack_log?: { transfer_order_code: string; picked_items: UserPickOutboundLogItem[] };
}) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/available/pick/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function downloadTransferPickOrderPDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function confirmTransferPickOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/confirm`, {
    method: 'POST',
  });
}

export async function cancelTransferPickOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/cancel/order`, {
    method: 'POST',
  });
}

export async function submitTransferPutAwayContainer(params: {
  container_box_codes: string[];
  zone_or_bin_id: number;
  type: number;
}) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/putaway/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function scanAvailablePutAwayTransferByContainer(params: {
  container_box_code: string;
  zone_or_bin_id: number;
  type: number;
}) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/check/putaway/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkTransferAvailableSortingProduct(params: {
  transfer_order_code: string;
  scan_sku_barcode: string;
}) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/sorting/check/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function submitTransferSortingItemsToConfirm(transferOrderId: number) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${transferOrderId}/confirm/sorting`, {
    method: 'POST',
  });
}

export async function getTransferAllSortingItemList(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/sorting/report/items`, {
    method: 'GET',
  });
}

export async function scanAvailableSortingTransferByCode(transferCode: string) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/sorting/code/${transferCode}`, {
    method: 'GET',
  });
}

export async function getTransferPickOrderGroupSortingItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/group/sorting/items`, {
    method: 'GET',
  });
}

// export async function completeAllTransferSorting(id: number) {
//   return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/complete/sorting`, {
//     method: 'POST',
//   });
// }

export async function completeTransferSortingItem(params: OperationSortingTransferProduct) {
  const { transfer_pick_order_id, product_id } = params;
  return request(
    `${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${transfer_pick_order_id}/product/${product_id}/complete`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function unCompleteTransferSortingItem(params: OperationSortingTransferProduct) {
  const { transfer_pick_order_id, product_id } = params;
  return request(
    `${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${transfer_pick_order_id}/product/${product_id}/uncomplete`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function unReceiveTransferSortingItem(params: OperationSortingTransferProduct) {
  const { transfer_pick_order_id, product_id } = params;
  return request(
    `${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${transfer_pick_order_id}/product/${product_id}/unreceive`,
    {
      method: 'POST',
      data: {
        ...params,
      },
    },
  );
}

export async function sortingNormalTransferOrderItem(
  id: number,
  params: SortingNormalTransferItemFormValue,
) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/sorting/normal`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function sortingDamageTransferOrderItem(
  id: number,
  params: SortingDamageTransferItemFormValue,
) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${id}/sorting/damage`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function printTransferCartonLabel(params: { container_box_code: string }) {
  return request(`${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/print/carton`, {
    method: 'POST',
    data: {
      ...params,
    },
    getResponse: true,
    responseType: 'blob',
  });
}

export async function checkTransferContainerCode(params: {
  container_box_code: string;
  transfer_pick_order_id?: number;
  use_type: number;
  product_id: number;
}) {
  return request(`${JOR_A_WMS_INBOUND_CONTAINER_BOX_URL}/check/transfer/code`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getTransferSortingItemsByPages(
  transferOrderId: number,
  params: TransferPickOrderItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_TRANSFER_SORTING_ITEM_URL}/transfer/${transferOrderId}/items`, {
    method: 'GET',
    params,
  });
}

export async function getTransferSortingItemOptions() {
  return request(`${JOR_A_WMS_TRANSFER_SORTING_ITEM_URL}/options`, {
    method: 'GET',
  });
}

export async function getTransferSortingReportItems(
  params: TransferPickOrderItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_TRANSFER_SORTING_ITEM_URL}`, {
    method: 'GET',
    params,
  });
}

export async function exportToTransferSortingReport(transferPickOrderId: number) {
  return request(
    `${JOR_A_WMS_TRANSFER_PICK_ORDER_URL}/${transferPickOrderId}/export/sorting/report`,
    {
      method: 'GET',
      responseType: 'blob',
      getResponse: true,
    },
  );
}

export async function deleteTransferContainerBoxSorting(params: { container_box_code: string }) {
  const { container_box_code } = params;
  return request(`${JOR_A_WMS_INBOUND_CONTAINER_BOX_URL}/${container_box_code}/transfer/sorting`, {
    method: 'DELETE',
  });
}
