import { request } from 'umi';
import type {
  ProductCategoryTableListParams,
  ProductTableListParams,
  UpdateProductDeclarationDataType,
  WmsProviderProductTableListParams,
} from './data';
import type { UpdateFormValueType } from './components/UpdateForm';
import type { UpdateWarehouseProductFormValueType } from './components/warehouse-product/UpdateWarehouseForm';

const { JOR_A_WMS_PRODUCT_URL, JOR_A_WMS_PRODUCT_CATEGORY_URL, JOR_A_WMS_PROVIDER_PRODUCT_URL } =
  API;

export async function fetchProducts(params: ProductTableListParams | undefined) {
  return request(`${JOR_A_WMS_PRODUCT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function fetchProductsByBarcode(barCode: string) {
  return request(`${JOR_A_WMS_PRODUCT_URL}/barcode/${barCode}`, {
    method: 'GET',
  });
}

export async function getProductDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_PRODUCT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function adminUpdateProduct(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_A_WMS_PRODUCT_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function adminUpdateWarehouseProduct(params: UpdateWarehouseProductFormValueType) {
  const { id } = params;
  return request(`${JOR_A_WMS_PRODUCT_URL}/${id}/warehouse`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function adminUpdateProductDeclaration(params: UpdateProductDeclarationDataType) {
  const { id } = params;
  return request(`${JOR_A_WMS_PRODUCT_URL}/${id}/declaration`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function queryJorWmsProductOptions() {
  return request(`${JOR_A_WMS_PRODUCT_URL}/options`, {
    method: 'GET',
  });
}

export async function fetchAllProductCategories(
  params: ProductCategoryTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_PRODUCT_CATEGORY_URL}`, {
    method: 'GET',
    params,
  });
}

export async function updateProductSerialNumberLength(params: {
  serial_number_length: number;
  product_id: number;
}) {
  const { product_id, ...rest } = params;
  return request(`${JOR_A_WMS_PRODUCT_URL}/${product_id}/serial/number/length`, {
    method: 'POST',
    data: {
      ...rest,
    },
  });
}

export async function downloadProductBarcode(params: { ids: number[] }) {
  return request(`${JOR_A_WMS_PRODUCT_URL}/download/barcode`, {
    method: 'POST',
    responseType: 'blob',
    data: {
      ...params,
    },
  });
}

export async function exportJorWmsProducts(params: ProductTableListParams | undefined) {
  return request(`${JOR_A_WMS_PRODUCT_URL}/export`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}

export async function fetchJorWmsWarehouseProduct(productId: number) {
  return request(`${JOR_A_WMS_PRODUCT_URL}/${productId}`, {
    method: 'GET',
  });
}

export async function uploadWarehouseProduct(formData: FormData) {
  return request(`${JOR_A_WMS_PRODUCT_URL}/upload/warehouse`, {
    method: 'POST',
    data: formData,
  });
}

export async function downloadWarehouseProductTemplate() {
  return request(`${JOR_A_WMS_PRODUCT_URL}/download/warehouse/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function fetchWmsProviderProducts(
  params: WmsProviderProductTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_PROVIDER_PRODUCT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function postWmsProviderProductsPushWms(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_PROVIDER_PRODUCT_URL}/${id}/push/wms`, {
    method: 'POST',
  });
}

export async function adminRemoveBarcode(params: { id: number; barcode: string }) {
  const { id, barcode } = params;
  return request(`${JOR_A_WMS_PRODUCT_URL}/${id}/warehouse/remove/barcode`, {
    method: 'POST',
    data: {
      barcode: barcode,
    },
  });
}
