export default {
  'label.create-payment': 'Create Payment',
  'label.external-transaction-no': 'Trans. Ref.',
  'label.amount': 'Amount',
  'label.invoices': 'Invoice(s)',
  'title.invoices': 'Invoices of {document_no}',
  'label.number-of-invoices': 'Number of Invoices: {invoice_count}',
  'action.button.clear-selection': 'Clear Selection',
  'label.selected-invoices': '{number} Invoice(s) Selected',
  'title.please-select-invoice': 'Please select invoice(s) below to create your payment',
  'label.remark': 'Remark',
  'title.review-and-create': 'Review and create your payment',
  'edit.payment': 'Edit Payment',
  'action.button.review-and-create': 'Review and Create',
  'error.multiple-currencies-is-not-allow':
    'You are not allow to create payment with multiple currencies!',
  'action.creating-payment': 'Payment record is creating ...',
  'action.updating-payment': 'Payment record is updating ...',
  'payment-proof-has-been-uploaded': 'Payment proof has been uploaded',
  'payment-record-proof': 'Payment Proof',
  'upload-payment-proof-reminder': 'Please upload your payment proof',
  'button.show-invoices': 'Show Invoices',
  'cancel-cus-payment-warning': 'Are you sure you want to cancel this payment record?',
};
