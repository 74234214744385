export default {
  'inventory_cycle_counts.warehouse': 'Warehouse',
  'inventory_cycle_counts.warehouse_bin': 'Warehouse Bin',
  'inventory_cycle_counts.product_sku': 'SKU',
  'inventory_cycle_counts.on_hand_stock': '已上架庫存 [On Hand]',
  'inventory_cycle_counts.physical_stock': 'Physical Stock',
  'inventory_cycle_counts.difference': 'Difference',
  'inventory_cycle_counts.transaction_code': 'Transaction Code',
  'inventory_cycle_counts.status': 'Status',
  'inventory_cycle_counts.create': 'Create Inventory Cycle Count',
  'inventory_cycle_counts.create.fail': 'Fail to create inventory cycle count',
  'inventory_cycle_counts.create.success': 'Inventory cycle count has been succesfully created',
  'inventory_cycle_counts.update.fail': 'Fail to update inventory cycle count',
  'inventory_cycle_counts.update': 'Update',
  'inventory_cycle_counts.update.success': 'Inventory cycle count has been succesfully updated',
};
