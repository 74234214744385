/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
import { getScopes } from './utils/token';
import {
  ADMIN,
  PERMISSION_MANAGE_SHIPMENT,
  PERMISSION_MANAGE_SERVICE,
  PERMISSION_MANAGE_ASN,
  PERMISSION_SETUP_SERVICE,
  PERMISSION_MANAGE_LABEL,
  PERMISSION_MANAGE_PICKUP,
  PERMISSION_VIEW_QUOTE,
  PERMISSION_MANAGE_MARKETPLACE,
  PERMISSION_GATE_CHARGE,
  PERMISSION_MANAGE_PAYMENT,
  PERMISSION_VIEW_PAYMENT,
  PERMISSION_MANAGE_INVOICE,
  PERMISSION_MANAGE_PRODUCT_PRICE,
  CUSTOMER_PERMISSION_EDIT_PRODUCT,
  CUSTOMER_PERMISSION_EDIT_INBOUND,
  CUSTOMER_PERMISSION_EDIT_OUTBOUND,
  CUSTOMER_PERMISSION_EDIT_MERCHANT,
  CUSTOMER_PERMISSION_VIEW_INVENTORY,
  PERMISSION_MANAGE_WMS,
  PERMISSION_MANAGE_WMS_INBOUND,
  PERMISSION_TOOLS_WMS_OFFLINE_INBOUND,
  PERMISSION_MANAGE_WMS_OUTBOUND,
  PERMISSION_MANAGE_WMS_MERCHANT,
  PERMISSION_MANAGE_WMS_INVENTORY,
  PERMISSION_RELEASE_WMS_OUTBOUND,
  CUSTOMER_PERMISSION_EDIT_WMS_RMA,
  PERMISSION_MANAGE_WMS_RMA,
  CUSTOMER_PERMISSION_VIEW_WMS_BILL,
  PERMISSION_MANAGE_WMS_BILL,
  PERMISSION_MANAGE_WMS_QUOTATION,
  PERMISSION_MANAGE_WAREHOUSE_BIN,
  PERMISSION_RELEASE_CONTAINER,
  PERMISSION_VIEW_PRODUCT,
  PERMISSION_EDIT_PRODUCT_DIMENSION,
  PERMISSION_UPLOAD_PRODUCT,
  PERMISSION_EDIT_PRODUCT_INFO,
  PERMISSION_VIEW_WMS_INBOUND,
  PERMISSION_VIEW_WMS_INVENTORY,
  PERMISSION_VIEW_WMS_REPORT,
  PERMISSION_VIEW_WMS_OUTBOUND,
  PERMISSION_VIEW_WMS_REPLENISHMENT,
  PERMISSION_MANAGE_WMS_TRANSFER_ORDER,
  PERMISSION_VIEW_WMS_TRANSFER_ORDER,
  CUSTOMER_PERMISSION_EDIT_OUTBOUND_WAREHOUSE_INFO,
} from '@/../config/const';

const canAccessWms = (scopes: string[]) => {
  for (let i = 0; i < scopes.length; i++) {
    if (scopes[i] === PERMISSION_MANAGE_WMS_INBOUND) {
      return true;
    }
    if (scopes[i] === PERMISSION_MANAGE_WMS_OUTBOUND) {
      return true;
    }
    if (scopes[i] === PERMISSION_MANAGE_WMS_MERCHANT) {
      return true;
    }
    if (scopes[i] === PERMISSION_MANAGE_WMS_INVENTORY) {
      return true;
    }
    if (scopes[i] === PERMISSION_VIEW_WMS_INBOUND) {
      return true;
    }
    if (scopes[i] === PERMISSION_VIEW_WMS_INVENTORY) {
      return true;
    }
    if (scopes[i] === PERMISSION_VIEW_WMS_REPORT) {
      return true;
    }
  }
  return false;
};

const canAccessPmsByScopes = (scopes: string[]) => {
  for (let i = 0; i < scopes.length; i++) {
    if (scopes[i] === CUSTOMER_PERMISSION_EDIT_PRODUCT) {
      return true;
    }
    if (scopes[i] === CUSTOMER_PERMISSION_EDIT_INBOUND) {
      return true;
    }
    if (scopes[i] === CUSTOMER_PERMISSION_EDIT_OUTBOUND) {
      return true;
    }
    if (scopes[i] === CUSTOMER_PERMISSION_EDIT_MERCHANT) {
      return true;
    }
    if (scopes[i] === CUSTOMER_PERMISSION_VIEW_INVENTORY) {
      return true;
    }
    if (scopes[i] === PERMISSION_MANAGE_PRODUCT_PRICE) {
      return true;
    }
  }
  return false;
};

const canAccessReport = (scopes: string[]) => {
  for (let i = 0; i < scopes.length; i++) {
    if (scopes[i] === PERMISSION_MANAGE_PRODUCT_PRICE) {
      return true;
    }
    if (scopes[i] === CUSTOMER_PERMISSION_VIEW_INVENTORY) {
      return true;
    }
  }
  return false;
};

export default function access() {
  // const { currentUser } = initialState || {};
  const scopes = getScopes();
  return {
    canAccessPms: canAccessPmsByScopes(scopes),
    canEditProduct:
      scopes.includes(CUSTOMER_PERMISSION_EDIT_PRODUCT) ||
      scopes.includes(CUSTOMER_PERMISSION_VIEW_INVENTORY),
    canEditOutboundWarheouseInfo: scopes.includes(CUSTOMER_PERMISSION_EDIT_OUTBOUND_WAREHOUSE_INFO),
    canEditWmsInbound:
      scopes.includes(CUSTOMER_PERMISSION_EDIT_INBOUND) ||
      scopes.includes(CUSTOMER_PERMISSION_VIEW_INVENTORY),
    canEditWmsOutbound:
      scopes.includes(CUSTOMER_PERMISSION_EDIT_OUTBOUND) ||
      scopes.includes(CUSTOMER_PERMISSION_VIEW_INVENTORY),
    canEditWmsRma:
      scopes.includes(CUSTOMER_PERMISSION_EDIT_WMS_RMA) ||
      scopes.includes(CUSTOMER_PERMISSION_VIEW_INVENTORY),
    canViewWmsBill: scopes.includes(CUSTOMER_PERMISSION_VIEW_WMS_BILL),
    canEditMerchant: scopes.includes(CUSTOMER_PERMISSION_EDIT_MERCHANT),
    canViewInventory: scopes.includes(CUSTOMER_PERMISSION_VIEW_INVENTORY),
    canViewQuote: scopes.includes(PERMISSION_VIEW_QUOTE),
    canManageLabel: scopes.includes(PERMISSION_MANAGE_LABEL),

    canAdmin: scopes.includes(ADMIN),
    canManageShipment: scopes.includes(PERMISSION_MANAGE_SHIPMENT),
    canManageService: scopes.includes(PERMISSION_MANAGE_SERVICE),
    canManageASN: scopes.includes(PERMISSION_MANAGE_ASN),
    canSetupService: scopes.includes(PERMISSION_SETUP_SERVICE),
    canManagePickup: scopes.includes(PERMISSION_MANAGE_PICKUP),
    canManageInvoice: scopes.includes(PERMISSION_MANAGE_INVOICE),
    canManageBilling: scopes.includes(PERMISSION_MANAGE_INVOICE),
    canManageMarketplace: scopes.includes(PERMISSION_MANAGE_MARKETPLACE),
    canManageGateCharge: scopes.includes(PERMISSION_GATE_CHARGE),
    canManagePayment: scopes.includes(PERMISSION_MANAGE_PAYMENT),
    canManageProductPrice: scopes.includes(PERMISSION_MANAGE_PRODUCT_PRICE),
    canManageReport: canAccessReport(scopes),

    canAccessWms: canAccessWms(scopes),
    canAdminWms: scopes.includes(PERMISSION_MANAGE_WMS),
    canManageWarehouseBin: scopes.includes(PERMISSION_MANAGE_WAREHOUSE_BIN),
    canManageWmsInbound: scopes.includes(PERMISSION_MANAGE_WMS_INBOUND),
    canManageWmsOutbound: scopes.includes(PERMISSION_MANAGE_WMS_OUTBOUND),
    canReleaseContainer: scopes.includes(PERMISSION_RELEASE_CONTAINER),

    canToolsWmsOfflineInbound: scopes.includes(PERMISSION_TOOLS_WMS_OFFLINE_INBOUND),
    canViewWmsInbound:
      scopes.includes(PERMISSION_MANAGE_WMS_INBOUND) ||
      scopes.includes(PERMISSION_VIEW_WMS_INBOUND),
    canViewWmsOutbound:
      scopes.includes(PERMISSION_MANAGE_WMS_OUTBOUND) ||
      scopes.includes(PERMISSION_VIEW_WMS_OUTBOUND),
    canViewWmsInventory:
      scopes.includes(PERMISSION_MANAGE_WMS_INVENTORY) ||
      scopes.includes(PERMISSION_VIEW_WMS_INVENTORY),
    canViewWmsReport:
      scopes.includes(PERMISSION_VIEW_WMS_REPORT) || scopes.includes(PERMISSION_MANAGE_WMS),
    canViewAmrOutbound:
      scopes.includes(PERMISSION_MANAGE_WMS_OUTBOUND) ||
      scopes.includes(PERMISSION_RELEASE_CONTAINER),
    canViewWmsReplenishment:
      scopes.includes(PERMISSION_MANAGE_WMS_INBOUND) ||
      scopes.includes(PERMISSION_VIEW_WMS_REPLENISHMENT),
    canViewWmsTransferPick:
      scopes.includes(PERMISSION_MANAGE_WMS_TRANSFER_ORDER) ||
      scopes.includes(PERMISSION_VIEW_WMS_TRANSFER_ORDER),
    canManageWmsRma: scopes.includes(PERMISSION_MANAGE_WMS_RMA),
    canManageWmsBill: scopes.includes(PERMISSION_MANAGE_WMS_BILL),
    canManageWmsQuotation: scopes.includes(PERMISSION_MANAGE_WMS_QUOTATION),
    canCreateMerchant: scopes.includes(PERMISSION_MANAGE_WMS_MERCHANT),
    canManageInventory: scopes.includes(PERMISSION_MANAGE_WMS_INVENTORY),
    canReleaseWmsOutbound: scopes.includes(PERMISSION_RELEASE_WMS_OUTBOUND),

    canViewPayment:
      scopes.includes(PERMISSION_VIEW_PAYMENT) || scopes.includes(PERMISSION_MANAGE_PAYMENT),
    canViewBilling:
      scopes.includes(PERMISSION_MANAGE_INVOICE) ||
      scopes.includes(PERMISSION_MANAGE_PAYMENT) ||
      scopes.includes(PERMISSION_VIEW_PAYMENT),
    canAccessWarehouseSetting:
      scopes.includes(PERMISSION_MANAGE_WMS) || scopes.includes(PERMISSION_MANAGE_WAREHOUSE_BIN),
    canViewProduct: scopes.includes(PERMISSION_VIEW_PRODUCT),
    canEditProductDimension: scopes.includes(PERMISSION_EDIT_PRODUCT_DIMENSION),
    canUploadProduct: scopes.includes(PERMISSION_UPLOAD_PRODUCT),
    canEditProductInfo: scopes.includes(PERMISSION_EDIT_PRODUCT_INFO),
  };
}

// export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
//   const { currentUser } = initialState || {};
//   return {
//     canAdmin: currentUser && currentUser.access === 'admin',
//   };
// }
