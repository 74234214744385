export default {
  'report.generate-report': 'Generate Reports',
  'report.report-type.title': 'Report(Please select report)',
  'report.report-type.label': 'Report',
  'report.report-type.required': 'Please select report',
  'report.group.product_price': 'Product Price',
  'report.product_price.summary': 'Product Price - Summary',
  'report.group.inventory_aging': 'Inventory Aging',
  'report.inventory.summary': 'Inventory',
  'report.inventory_aging.summary': 'Inventory - Aging',
  'report.marketpalce.label': 'Marketpalces',
  'report.marketpalce.placeholder': 'Please select marketpalces',
  'report.marketplace_category.label': 'Marketplace Category',
  'report.marketplace_category.placeholder': 'Please select Categories',
  'report.supplier_company.label': 'Suppliers',
  'report.supplier_company.placeholder': 'Please select supplier',
  'report.merchant.label': 'Merchants',
  'report.merchant.placeholder': 'Please select merchants',
  'report.warehouse.label': 'Warehouses',
  'report.warehouse.placeholder': 'Please select warehouses',
  'report.from-date.label': 'From Date',
  'report.to-date.label': 'To Date',
  'report.date.placeholder': 'Please select a date',
};
