export default {
  'currency.code': 'Currency Code',
  'currency.sign': 'Currency Sign',
  'currency.name': 'Name',
  'currency.create': 'Create Currency',
  'currency.create.fail': 'Fail to create currency',
  'currency.create.success': 'Currency has been succesfully created',
  'currency.update.fail': 'Fail to update currency',
  'currency.update': 'Update',
  'currency.update.success': 'Currency has been succesfully updated',
};
