export default {
  'warehouse_bin.name': '架位名稱',
  'warehouse_bin.external_warehouse_bin_code': '外部架位碼',
  'warehouse_bin.bin_property': '架位屬性',
  'warehouse_bin.rack_code': '貨架碼',
  'warehouse_bin.shelf_code': '貨架擱板碼',
  'warehouse_bin.bin_size': '空間大小',
  'warehouse_bin.bin_size_length': '長',
  'warehouse_bin.bin_size_width': '闊',
  'warehouse_bin.bin_size_height': '高',
  'warehouse_bin.create': '新增倉庫架位',
  'warehouse_bin.create.fail': '建立倉庫架位失敗！',
  'warehouse_bin.create.success': '成功建立倉庫架位！',
  'warehouse_bin.update.fail': '建立倉庫架位失敗！',
  'warehouse_bin.update.success': '成功更新倉庫架位！',
  'warehouse_bin.download_selected': '下載所選取的架位條碼',
  'warehouse_bin.download-selected-confirm': '你真的想下載想選取的倉庫條碼嗎？',
  'warehouse_bin.location_zone_code': '上架區域',
  'warehouse_bin.location_aisle_code': 'Aisle',
  'warehouse_bin.location_bay_code': 'Bay',
  'warehouse_bin.location_level_code': 'Level',
  'warehouse_bin.location_position_code': 'Position',
};
