export default {
  'product_supplier_cost.supplier': 'Supplier',
  'product_supplier_cost.product': 'Product',
  'product_supplier_cost.history': '{product_name} Supplier Cost Histories',
  'product_supplier_cost.show_history': 'Show Suppleir Cost Histories',
  'product_supplier_cost.form.please-select-supplier': 'Please Select Supplier',
  'product_supplier_cost.product_name': 'Product Name',
  'product_supplier_cost.search_product': 'Search Product',
  'product_supplier_cost.upload_product_costs': 'Upload Product Costs',
  'product_supplier_cost.supplier_costs_file': 'Supplier Costs File',
  'product_supplier_cost.product_code': 'Product Code',
  'product_supplier_cost.sku': 'SKU',
  'product_supplier_cost.supplier_item_code': 'Supplier Item Code',
  'product_supplier_cost.cost': 'Cost',
  'product_supplier_cost.other_cost': 'Other Cost',
  'product_supplier_cost.currency': 'Currency',
  'product_supplier_cost.rate_to_hkd': 'Rate To HKD',
  'product_supplier_cost.remark': 'Remark',
  'product_supplier_cost.create': 'Create Supplier Product Cost',
  'product_supplier_cost.create.fail': 'Fail to create supplier product cost',
  'product_supplier_cost.create.success': 'Supplier product cost has been succesfully created',
  'product_supplier_cost.update.fail': 'Fail to update product cost',
  'product_supplier_cost.update': 'Update',
  'product_supplier_cost.update.success': 'Supplier product cost has been succesfully updated',
  'product_supplier_cost.status': 'Status',
  'product_supplier_cost.un_listing_price': 'Unlisting Platform Product',
  'product_supplier_cost.disable-confirm':
    'Are you sure to disable this {product} cost in {supplier}?',
  'product_supplier_cost.enable-confirm':
    'Are you sure to enable this {product} cost in {supplier}?',
  'product_supplier_cost.change_other_supplier_cost_id': 'Change To Supplier',
  'product_supplier_cost.disable-auto-change-confirm':
    'Are you sure to disable this {product} cost in {supplier} and product listing will auto chagne to {other_supplier} ?',
};
