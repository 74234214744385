import type { Effect, Reducer } from 'umi';

import type { RmaOrderDataType } from './data';
import { getRmaOrderDetail } from './service';

export interface StateType {
  rmaOrder?: RmaOrderDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    get: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
    clear: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'rmaOrderModel',
  state: {
    rmaOrder: undefined,
  },
  effects: {
    *get({ payload }, { call, put }) {
      const response = yield call(getRmaOrderDetail, payload);
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        rmaOrder: action.payload,
      };
    },
    clear() {
      return {
        rmaOrder: undefined,
      };
    },
  },
};

export default Model;
