export default {
  'user.list.user-name': '用戶名',
  'user.list.user-short-name': '別名',
  'user.list.user-email': '郵件地址',
  'user.list.password': '密碼',
  'user.list.email-language': '電郵中所用的語言',
  'user.list.invite': '邀請',
  'user.list.resend-invitation-email': '重發邀請郵件',
  'user.list.resend-success': '重發成功',
  'user.list.confirm-password': '確認密碼',
  'user.list.role-label': '角色',
  'user.list.email-confirmed-status': '激活電郵',
  'user.list.batch-operation': '批量操作',
  'user.list.invite-user': '邀請用戶',
  'user.list.setting': '設置 -',
  'user.list.please-input-email': '請輸入電郵',
  'user.list.please-input-username': '請輸入用戶名',
  'user.list.please-input-user-short-name': '請輸入別名',
  'user.list.user-title': '稱謂',
  'user.list.user-title.mr': 'Mr',
  'user.list.user-title.ms': 'Ms',
  'user.list.user-title.mrs': 'Mrs',
  'user.list.user-job-title': '職位',
  'user.list.user-tel': '手機號碼',
  'user.list.project': '項目',
  'user.list.please-select-project': '請選擇項目',
  'user.list.Role': '角色',
  'user.list.role.view-permission': '檢查用戶權限',
  'user.list.view-permission': '用戶權限',
  'user.list.please-select-role': '請選擇角色',
  'user.list.please-select-functional-groups': '請選擇職能組',
  'user.list.identification-no': '識別號',
  'user.list.please-input-identification-no': '請輸入識別號',
  'user.list.status': '狀態',
  'user.list.default_module': '默認模塊',
  'user.list.please-input-tel': '請輸入手機號碼',
  'user.list.please-input-password-more-than': '請輸入至少六位字符密碼',
  'user.list.delete-confirm': '確定取消關聯該用戶 ？',
  'user.list.email-confirmed-status.active': '有效',
  'user.list.email-confirmed-status.inactive': '待激活',
  'user.status.inactive': '無效',
  'user.status.active': '有效',
  'user.tab.project-setting': '項目設置',
  'user.tab.preferences': '偏好設定',
  'user.list.exists-warning-title': '邀請用戶',
  'user.list.exists-warning-ok-button-text': '是，邀請',
};
