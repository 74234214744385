import type { Effect, Reducer } from 'umi';

import type { StockTakeTableListDataType } from './data';
import { getStockTakeDetails } from './service';

export interface StateType {
  stockTake?: StockTakeTableListDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getStockTake: Effect;
  };
  reducers: {
    saveStockTake: Reducer<StateType>;
    clearStockTake: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'stockTakeModel',
  state: {
    stockTake: undefined,
  },
  effects: {
    *getStockTake({ payload }, { call, put }) {
      const response = yield call(getStockTakeDetails, payload);
      yield put({
        type: 'saveStockTake',
        payload: response.data,
      });
    },
  },

  reducers: {
    saveStockTake(state, action) {
      return {
        ...state,
        stockTake: action.payload,
      };
    },
    clearStockTake() {
      return {
        stockTake: undefined,
      };
    },
  },
};

export default Model;
