export default {
  'inbound_orders.batch': 'Batch',
  'inbound_orders.warehouse': 'Warehouse',
  'inbound_orders.merchant': 'Merchant',
  'inbound_orders.inbound_type': 'Inbound Type',
  'inbound_orders.asn_code': 'ASN Code',
  'inbound_orders.skip_scan_serial_number': 'Skip Scan S/N',
  'inbound_orders.total_weight': 'Total Weight(KG)',
  'inbound_orders.total_volume': 'Total Volume(CBM)',
  'inbound_orders.total_box': 'Total No. of Boxes',
  'inbound_orders.total_pallet': 'Total No. of Pallet',
  'inbound_orders.total_cost': 'Total Cost',
  'inbound_orders.customer_order_no': 'Customer Order No.',
  'inbound_orders.inbound_method': 'Inbound Method',
  'inbound_orders.shipping_mode': 'Shipping Mode',
  'inbound_orders.freight_method': 'Freight Method',
  'inbound_orders.estimated_arrival_time': 'Estimated Arrival Time',
  'inbound_orders.channel_code': 'Serivce Channel',
  'inbound_orders.tack_nos': 'Tracking Nos',
  'inbound_orders.estimated_arrival_date_from': 'Est. Arr. Date From',
  'inbound_orders.estimated_arrival_date_to': 'Est. Arr. Date To',
  'inbound_orders.received_date': 'Received Date',
  'inbound_orders.received_date_from': 'Received Date From',
  'inbound_orders.received_date_to': 'Received Date To',
  'inbound_orders.po_number': 'PO No.',
  'inbound_orders.received_date_at': 'ASN Received Date',
  'inbound_orders.item.expiry_date': 'Expiry Date',
  'inbound_orders.create': 'Create Inbound Order',
  'inbound_orders.create.fail': 'Fail to create inbound',
  'inbound_orders.create.success': 'InboundOrder has been succesfully created',
  'inbound_orders.update.fail': 'Fail to update inbound',
  'inbound_orders.update': 'Update',
  'inbound_orders.update.success': 'Inbound Order has been succesfully updated',
  'inbound_orders.update_dimension.fail': 'Fail to update product dimension',
  'inbound_orders.form_item.title': 'Product details',
  'inbound_orders.carton_no': 'Carton No.',
  'inbound_orders.product': 'Product',
  'inbound_orders.search_product': 'Search Product',
  'inbound_orders.sku': 'SKU',
  'inbound_orders.product_name': 'Product Name',
  'inbound_orders.item.qty': 'Single Box Qty',
  'inbound_orders.item.box_qty': 'No. of Boxes',
  'inbound_orders.pallet_package': 'Pallet Packages',
  'inbound_orders.pallet_package.title': 'Pallet Details',
  'inbound_orders.pallet_package.length': 'Length',
  'inbound_orders.pallet_package.width': 'Width',
  'inbound_orders.pallet_package.height': 'Height',
  'inbound_orders.pallet_package.weight': 'Weight(KG)',
  'inbound_orders.pallet_no': 'Pallet No.',
  'inbound_orders.dimension_detail': 'Length*Width*Height(CM)	',
  'inbound_orders.pallet.box_qty': 'No. of Boxes',
  'inbound_orders.pallet.associated_box_number': 'Associated Box Number',
  'inbound_orders.upload_file_name': 'Upload File Name',
  'inbound_orders.upload_receiving_report': 'Upload Receiving Report',
  'inbound_orders.put_away.title': 'Put Away SKU {sku}',
  'inbound_orders.total_product_qty': 'Total Expected Qty',
  'inbound_orders.details.items': 'Item Details',
  'inbound_orders.details.sku': 'SKU Code',
  'inbound_orders.details.serial_number': 'Serial Number',
  'inbound_orders.details.serial_numbers': 'Serial Numbers',
  'inbound_orders.details.sorting_report': 'Sorting Report',
  'inbound_orders.details.sorting_items': 'Sorting Items',
  'inbound_orders.details.product_name': 'Product Name',
  'inbound_orders.details.product_category': 'Product Category',
  'inbound_orders.details.product_qty': 'Expected Qty',
  'inbound_orders.details.expected_qty': 'Expected Qty',
  'inbound_orders.details.product_actual_qty': 'Actual Arrival Qty',
  'inbound_orders.details.product_normal_qty': 'Actual Normal Qty',
  'inbound_orders.details.put_away_qty': 'Put Away Qty',
  'inbound_orders.details.production_date': 'MFG Date',
  'inbound_orders.details.expiry_date': 'Expiry Date',
  'inbound_orders.details.system_expire_date': 'System Expire Date',
  'inbound_orders.details.country_of_origin': 'Country Of Origin',
  'inbound_orders.details.product_damage_qty': 'Damage Qty',
  'inbound_orders.details.difference': 'Difference',
  'inbound_orders.details.product_shortage_qty': 'Arrival Shortage Qty',
  'inbound_orders.details.product_more_qty': 'Arrival Additional Qty',
  'inbound_orders.details.production_batch_code': 'Production Batch Code',
  'inbound_orders.details.pending_put_away_qty': 'Pending Put Away Qty',
  'inbound_orders.details.packing_list': 'Packing Details',
  'inbound_orders.details.pallet_no': 'Pallet No.',
  'inbound_orders.details.carton_no': 'Carton No.',
  'inbound_orders.details.print_normal_qty': 'Print Normal Qty',
  'inbound_orders.details.print_damage_qty': 'Print Damage Qty',
  'inbound_orders.details.month': 'Month',
  'inbound_orders.external_warehouse_bin_code': 'External Warehouse Bin',
  'inbound_orders.dispatch-inbound-confirm':
    'Are you sure you want to dispatch this {document_no}?',
  'inbound_orders.received-confirm': 'Are you sure you want to received the {document_no}?',
  'inbound_orders.update_title': 'Update ({merchant}) - {asn_number}',
  'inbound_orders.action.put_away': 'Put Away Now',
  'inbound_orders.search_by_sku': 'Search By SKU',
  'inbound_orders.action.update_dimension': 'Update Product Dimension',
  'inbound_orders.put_away.leave-confirm': 'Are you sure you want to leave this page?',
  'inbound_orders.upload_receiving_title': 'Upload Receiving Report',
  'inbound_orders.receiving_report_items': 'Receiving Report Items',
  'inbound_orders.details.receive_date': 'Receive Date',
  'inbound_orders.details.total_scanned_qty': 'Scanned Qty',
  'inbound_orders.confirm-receiving': 'Pending Confirm Upload Receiving Report to put away',
  'inbound_orders.search_pending_asn': 'Please Search ASN (Dispatched Or Pending Confirm)',
  'inbound_orders.scan_asn_code': 'Scan ASN Code',
  'inbound_orders.scan_sku_barcode': 'Scan SKU / Barcode',
  'inbound_orders.scan_barcode': 'Scan Barcode',
  'inbound_orders.scan_asn_code.no_data':
    'The {asn_code} is not valid for sorting, please verify the ASN number first',
  'inbound_orders.scan_asn_code.no_data_putaway': 'The {asn_code} has no information for put away',
  'inbound_orders.received-to-sort-tooltip': 'Receive the ASN, and then doing the sorting',
  'inbound_orders.received-to-sort': 'Received -> Sort',
  'inbound_orders.received-to-sort-confirm':
    'Are you sure you want to received this {document_no} to sort?',
  'inbound_orders.require_sorting_qty': 'Awaiting Sorting Qty',
  'inbound_orders.actual_sorting_qty': 'Actual Sorting Qty',
  'inbound_orders.sorting_item': 'Sorting Item',
  'inbound_orders.sorting_print_label': 'Seal Container Box',
  'inbound_orders.sorting.item_title': 'Sorting SKU / Bar Code {sku}',
  'inbound_orders.sorting.cancel': 'Cancel',
  'inbound_orders.sorting.submit': 'Submit',
  'inbound_orders.sorting.sorting_normal': 'Sorting Normal',
  'inbound_orders.sorting.sorting_damage': 'Sorting Damage',
  'inbound_orders.print.inbound_label_title': 'Print Inbound Carton Label - Sealed',
  'inbound_orders.print.item_title': 'Print Product 2D Barcode',
  'inbound_orders.details.print_bar_code': 'Print Bar Code',
  'inbound_orders.scan_bin': 'Scan W/H Bin',
  'inbound_sorting_reports.submitted_date_at': 'Submitted At',
  'inbound_sorting_reports.submitted_by': 'Submitted By',
  'inbound_sorting_reports.confirmed_date_at': 'Confirmed At',
  'inbound_sorting_reports.confirmed_by': 'Confirmed By',
  'inbound_sorting_reports.remark': 'Remark',
  'inbound_orders.show_sorting_items_title': '{document_no} Sorting Report Items',
  'inbound_sorting_reports.show-sorting-items': 'Show Sorting Items',
  'inbound_orders.scan_container_box_code': 'Scan Container Code',
  'inbound_orders.put_away_item': 'Put Away (Item)',
  'inbound_orders.put_away_container': 'Put Away (Container)',
  'inbound_orders.received-to-sort-modal-title': 'Registration for the ASN - {document_no}',
  'inbound_orders.driver_name': 'Driver Name',
  'inbound_orders.driver_identity_card': 'Driver Identity Card',
  'inbound_orders.driver_license_plate': 'Truck Plate',
  'inbound_orders.driver_phone': 'Driver Phone',
  'inbound_orders.ready_sorting_product': 'Product for Sorting',
  'inbound_orders.sorting.not_match_sku':
    'The input SKU - {scan_sku} does not matching with the SKU - {require_sku}',
  'inbound_orders.sorting.not_allow_overship':
    'The input SKU - {scan_sku} is overship, please check',
  'inbound_orders.confirm-sorting-items':
    'Are you sure you want to confirm the {document_no} sorting report, and send receiving report to customer?',
  'inbound_orders.scan_asn_code.success': 'Scan ASN succesfully',
  'inbound_orders.scan_container_code.success': 'Verification of the container box has been done!',
  'inbound_orders.details.length': 'Length',
  'inbound_orders.details.width': 'Width',
  'inbound_orders.details.height': 'Height',

  'inbound_orders.tab.asn': 'ASN',
  'inbound_orders.tab.sorting': 'Sorting',
  'inbound_orders.tab.put-away': 'Put Away',
  'inbound_orders.tab.sorting-report': 'Sorting Report',
  'inbound_orders.tab.upload-receiving': 'Upload Receiving',
  'inbound_orders.button.generate-receiving-report': 'Generate Receiving Report',
  'inbound_orders.button.generate-registration': 'Generate Registration',
  'inbound_orders.button.download-file': 'Download Files',
  'inbound_orders.button.excel': 'Excel',
  'inbound_orders.message.generate-registration-success': 'Generate registration file successfully',
  'inbound_orders.message.generate-registration-fail': 'Fail to generate registration file',
  'inbound_orders.sorting.sorted_items': 'Sorted Items',
  'inbound_orders.sorting.inbound-items': 'Items on - {document_no}',
  'inbound_orders.sorting.complete-sorting': 'Complete the sorting',
  'inbound_orders.sorting.un-complete-sorting': 'Reverse the completion',
  'inbound_orders.sorting.un-receive-product': 'Un-Recevie the product',
  'inbound_orders.sorting.current_box_scanned': 'Product Scanned (Box) - Subtotal',
  'inbound_orders.sorting.total_product_scanned': 'Product Scanned - Grand Total',
  'inbound_orders.put-away.pending-item-table.title': 'Pending Put Away - Items',
  'inbound_orders.put-away.pending-container-table.title': 'Pending Put Away - Containers',
  'inbound_orders.confirm-and-send-sorting-report-message':
    'Confirm and send the sorting report to customer successfully!',
  'inbound_orders.go-to.button': 'Go to - {asn_code}',
  'inbound_orders.sorting.message.please-scan-the-product': 'Please scan the product bar code',
  'inbound_orders.cancel-confirm': 'Are you sure to cancel the ASN - {document_no} inbound order?',
  'inbound_orders.export_to_excel': 'Export to Excel',
  'inbound_orders.update_tracking_info': 'Update Tracking Info',
  'inbound_orders.button.edit_tracking': 'Edit Tracking',
  'inbound_orders.select_carton': 'Select Carton No. List To Associate Pallet No.',
  'inbound_orders.confirm-sorting-confirm':
    'Are you sure you want to confirm this {document_no} sorting report?',
  'inbound_orders.confirm-sorting.button': 'Confirm Sorting',
  'inbound_orders.sorting-item.confirm.message': 'Confirming the sorting items...',
  'inbound_orders.sorting-item.confirm.fail': 'Fail to confirm the sorting!',
  'inbound_orders.sorting-item.confirm.tooltips': 'Confirm the sorting',
  'inbound_orders.putaway.success': 'Goods have been put away successfully',
  'inbound_orders.sorting.verifying_pin': 'Verifying Pin...',
  'inbound_orders.sorting.fail_to_do_pin_exchange': 'Fail to do Pin exchange',
  'inbound_orders.delete-container-sorting-confirm':
    'Are you sure you want to remove this container {container_box_code} sorting?',
  'inbound_orders.delete-container-sorting': 'Remove Container {container_box_code}  Sorting',
  'inbound_orders.scan.asn_code_not_match':
    'The {document_no} is not match for the pending sorting items, please check',
  'inbound_orders.total_scanned_box': 'Total Scanned',
};
