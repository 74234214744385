// localstorge name
export const TOKEN_NAME = 'access-token';

export const REFRESH_TOKEN_NAME = 'refresh-token';

export const TOKEN_PAYLOAD_NAME = 'token-payload';

export const CURRENT_USER = 'current-user';

export const LOCALE = 'umi_locale';

export const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10M

export const LOCALES = ['en-US', 'zh-TW'];

export const COURIER_EXPORT = 1;
export const COURIER_IMPORT = 2;
export const COURIER_HK_LOCAL = 3;

export const MODEL_TYPE_GATE_CHARGE = 'gate_charges';
export const MODEL_TYPE_TOPUP = 'topups';
export const MODEL_TYPE_CUSTOMER_PAYMENT_RECORD = 'customer_payment_records';

// Scopes (Permissions)
export const ADMIN = 'admin';
export const PERMISSION_MANAGE_SHIPMENT = 'manage_delivery_shipment';
export const PERMISSION_MANAGE_SERVICE = 'manage_service';

export const PERMISSION_MANAGE_ASN = 'manage_asn';
export const PERMISSION_SETUP_SERVICE = 'setup_service';
export const PERMISSION_GATE_CHARGE = 'create_and_edit_gate_charge';

// Customer
export const PERMISSION_MANAGE_LABEL = 'manage_label';
export const PERMISSION_MANAGE_PICKUP = 'manage_pickup';
export const PERMISSION_VIEW_QUOTE = 'view_quote';
export const PERMISSION_MANAGE_MARKETPLACE = 'manage_marketplace';
export const PERMISSION_MANAGE_PAYMENT = 'manage_payment';
export const PERMISSION_VIEW_PAYMENT = 'view_payment';
export const PERMISSION_MANAGE_INVOICE = 'manage_invoice';
export const PERMISSION_MANAGE_PRODUCT_PRICE = 'manage_product_price';

export const PERMISSION_VIEW_PRODUCT = 'view_product';
export const PERMISSION_EDIT_PRODUCT_DIMENSION = 'edit_product_dimension';
export const PERMISSION_UPLOAD_PRODUCT = 'upload_product';
export const PERMISSION_EDIT_PRODUCT_INFO = 'edit_product_info';

export const CUSTOMER_PERMISSION_EDIT_PRODUCT = 'edit_product'; // we haven't use this permission
export const CUSTOMER_PERMISSION_EDIT_INBOUND = 'edit_inbound';
export const CUSTOMER_PERMISSION_EDIT_OUTBOUND = 'edit_outbound';
export const CUSTOMER_PERMISSION_EDIT_OUTBOUND_WAREHOUSE_INFO = 'edit_outbound_warehouse_info';

export const CUSTOMER_PERMISSION_EDIT_WMS_RMA = 'edit_wms_rma';
export const CUSTOMER_PERMISSION_EDIT_MERCHANT = 'edit_merchant';
export const CUSTOMER_PERMISSION_VIEW_INVENTORY = 'view_inventory';
export const CUSTOMER_PERMISSION_VIEW_WMS_QUOTATION = 'view_wms_quotation';
export const CUSTOMER_PERMISSION_VIEW_WMS_BILL = 'view_wms_bill';
export const CUSTOMER_PERMISSION_DOWNLOAD_ALL_OUTBOUND_DOCUMENT = 'download_all_outbound_document';

export const PERMISSION_MANAGE_WMS = 'manage_wms';
export const PERMISSION_MANAGE_WAREHOUSE_BIN = 'manage_warehouse_bin';
export const PERMISSION_MANAGE_WMS_INBOUND = 'manage_wms_inbound';
export const PERMISSION_MANAGE_WMS_OUTBOUND = 'manage_wms_outbound';
export const PERMISSION_MANAGE_WMS_TRANSFER_ORDER = 'manage_wms_transfer';
export const PERMISSION_MANAGE_WMS_ADJUSTMENT = 'manage_wms_adjustment';

export const PERMISSION_RELEASE_CONTAINER = 'release_container';
export const PERMISSION_TOOLS_WMS_OFFLINE_INBOUND = 'wms_tools_offline_inbound';
export const PERMISSION_MANAGE_WMS_RMA = 'manage_wms_rma';
export const PERMISSION_MANAGE_WMS_MERCHANT = 'manage_wms_merchant';
export const PERMISSION_MANAGE_WMS_INVENTORY = 'manage_wms_inventory';
export const PERMISSION_MANAGE_WMS_BILL = 'manage_wms_bill';
export const PERMISSION_MANAGE_WMS_QUOTATION = 'manage_wms_quotation';
export const PERMISSION_RELEASE_WMS_OUTBOUND = 'release_wms_outbound';

export const PERMISSION_VIEW_WMS_INBOUND = 'view_wms_inbound';
export const PERMISSION_VIEW_WMS_OUTBOUND = 'view_wms_outbound';
export const PERMISSION_VIEW_WMS_MERCHANT = 'view_wms_merchant';
export const PERMISSION_VIEW_WMS_INVENTORY = 'view_wms_inventory';
export const PERMISSION_VIEW_WMS_TRANSFER_ORDER = 'view_wms_transfer_pick';
export const PERMISSION_VIEW_WMS_REPLENISHMENT = 'view_wms_replenishment';
export const PERMISSION_VIEW_WMS_RMA = 'view_wms_rma';
export const PERMISSION_VIEW_WMS_REPORT = 'view_wms_report';

export const PERMISSION_DELETE_FILE = 'delete_file';
export const PERMISSION_UPLOAD_INBOUND_RECEIVING = 'upload_inbound_receiving';
export const PERMISSION_UPLOAD_OUTBOUND_RECEIVING = 'upload_outbound_receiving';

export const COLORS = {
  success: '#52c41a',
  primary: '#6f42c1',
  error: '#f5222d',
  warning: '#faad14',
  orange: '#fa8c16',
  blue: '#20a8d8',
  preview: '#3e515b',
  green: '#239B56',
  pending: '#32CD32',
  approved: '#B67E06',
  rejected: '#aa0099',
  submitted: '#14B639',
  pinky: '#ffa3b5',
};

export const USER_TYPE_COLORS = {
  U: '#f5222d', //user
  C: '#52c41a', //contact
};

export const NORMAL_STATUS_COLORS = {
  0: '#f5222d', //error
  1: '#52c41a', //success
};

export const STOCK_TAKE_COMMAND_TYPE_COLORS = {
  0: '#20a8d8',
  1: '#FF6A29',
};

export const SUPPLIER_STATUS_COLORS = {
  0: '#f5222d', //error
  1: COLORS.success, //success
  10: COLORS.primary, //pending
  11: COLORS.rejected, //rejected
};

// Status Color
export const STATUS_COLORS = {
  1: '#20a8d8', // "Work In Progress"
  2: '#ffc107', // "Revision In Progress"
  3: '#32CD32', // "Ready for decision"
  4: '#aa0099', // "Rejected"
  5: '#6f42c1', // "Awaiting Approval"
  10: '#B67E06', // "Approved"
  15: '#14B639', // "Submitted"
  20: '#f86c6b', // "Supplier Rejected"
  25: '#32CD32', // "Ready For Delivery"
  27: '#20a8d8', // "Delivery In Progress"
  29: '#32CD32', // "Delivered"
  30: '#ffc107', // "Revision In Progress With DO"
  31: '#f86c6b', // "Approval In Progress With DO"
  32: '#6f42c1', // "Not Approved With DO"
  33: '#B67E06', // "Approved With DO"
  35: '#ffc107', // "Revision In Progress WithGR"
  36: '#f86c6b', // "Approval In Progress With GR"
  37: '#6f42c1', // "Not Approved With GR"
  38: '#B67E06', // "Approved With GR"
  40: '#108ee9', // "DN Created"
  50: '#1890FF', // "Converted"
  60: '#63c2de', // "Fully Delivered"
  70: '#63c2de', // "Completed"
  80: '#63c2de', // "Reconciled"
  81: '#B67E06', // "Being Reconciled"
  82: '#1890FF', // "Partially Reconciled"
  90: '#63c2de', // "Require Investigation"
  91: '#63c2de', // "Awaiting Settlement"
  92: '#63c2de', // "Settled"
  98: '#63c2de', // "Closed"
  99: '#f86c6b', // "Canceled"
};

export const STATUS_ORDER_COLORS = {
  0: '#f86c6b', // "error"
  1: '#2db7f5', // "Work In Progress"
  2: '#0256B4', // "wms pre order"
  3: '#32CD32', // "processing"
  4: '#aa0099', // "wms in progress"
  5: '#6f42c1', // "Awaiting Approval"
  6: '#14B639', // "shipped"
  7: '#FB8760',
  8: '#32CD32', // "Approved"
  9: '#6f42c1', // "warning"
  10: '#B67E06', // "Approved"
};

export const STATUS_DELIVERY_ORDER_COLORS = {
  1: '#2db7f5', // "Work In Progress"
  2: '#0256B4', // "wms pre order"
  3: '#6f42c1', // "processing"
  4: '#aa0099', // "wms in progress"
  5: '#464D04', // "Awaiting Approval"
  6: '#14B639', // "shipped"
  7: '#FB8760',
  8: '#aa0099',
  9: '#00A399',
  10: '#14B639',
  11: '#f86c6b',
};

export const STATUS_SHIPMENT_COLORS = {
  0: '#6f42c1', // "NO SYNC TO shipment"
  10: '#20a8d8', // "Work In Progress"
  11: '#f86c6b', // "custom tracking route"
  12: '#32CD32', // "custom tracking route"
  13: '#f86c6b', // "custom tracking route"
  14: '#6f42c1', // "custom tracking route"
  15: '#2db7f5', // "custom tracking route"
  16: '#2db7f5', // "custom tracking route"
  17: '#2db7f5', // "custom tracking route"
  18: '#fa8c16', // "custom tracking route"
  19: '#B67E06', // "custom tracking route"
  20: '#B67E06', //IN_TRANSIT
  30: '#32CD32', //OUT_FOR_DELIVERY
  40: '#f86c6b', //FAILED_ATTEMPT
  50: '#14B639', //DELIVERED
  60: '#B67E06', //AVAILABLE_FOR_PICKUP
  70: '#f86c6b', //EXCEPTION
  71: '#1890FF', //RETURN_SENDER
  72: '#B67E06', //LOST
  80: '#f86c6b', //EXPIRED
  90: '#B67E06', //PENDING
  '-1': '#f86c6b', //CANCELED
};

export const STATUS_ASN_COLORS = {
  0: '#32CD32', // "processing"
  1: '#2db7f5', // "CONFIRM"
  2: '#2db7f5', // "PARTIALLY_CONFIRM"
};

export const EXPENSE_STATUS_COLORS = {
  1: '#6f42c1',
  5: '#52c41a',
  99: '#f5222d',
};

export const DELIVERY_BOOKING_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  5: '#FF6A29',
  6: '#14B639',
  99: '#f5222d',
};

export const WMS_OUTBOUND_PICK_CONTAINER_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  5: '#FF6A29',
  6: '#14B639',
  99: '#f5222d',
};

export const DELIVERY_MANIFEST_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  5: '#FF6A29',
  6: '#14B639',
  99: '#f5222d',
};

export const DELIVERY_LASTMILE_STATUS_COLORS = {
  2: '#6f42c1',
  3: '#20a8d8',
  4: '#FF6A29',
  6: '#14B639',
  99: '#f5222d',
};

export const OUTBOUND_INVOICE_STATUS_COLORS = {
  1: '#6f42c1',
  6: '#52c41a',
  99: '#f5222d',
};

export const PRODUCT_PROFIT_JOB_STATUS_COLORS = {
  1: '#6f42c1',
  5: '#f5222d',
  6: '#52c41a',
};

export const WMS_INBOUND_ORDER_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  4: '#faad14',
  5: '#FF6A29',
  9: '#464d04',
  10: '#14B639',
  11: '#6f42c1',
  21: '#B67E06',
  22: '#f4680b',
  23: '#aa0099',
  24: '#a64917',
  25: '#52c41a',
  26: '#1890FF',
  29: '#63c2de',
  99: '#f5222d',
};

export const WMS_INBOUND_SORTING_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  4: '#faad14',
  5: '#FF6A29',
  6: '#1890FF',
  7: '#63c2de',
  9: '#aa0099',
  10: '#14B639',
  99: '#f5222d',
};

export const WMS_REPLENISHMENT_STATUS_COLORS = {
  1: '#6f42c1',
  2: '#e4ae0c',
  3: '#20a8d8',
  4: '#faad14',
  5: '#FF6A29',
  8: '#a64917',
  9: '#aa0099',
  10: '#14B639',
  99: '#f5222d',
};

export const WMS_REPLENISHMENT_TYPE_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  5: '#FF6A29',
  7: '#aa0099',
  9: '#497ab6',
  11: '#8cb578',
};

export const WMS_REPLENISHMENT_REQUEST_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  5: '#FF6A29',
  99: '#f5222d',
};

export const WMS_RMA_ORDER_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  4: '#faad14',
  5: '#FF6A29',
  8: '#aa0099',
  9: '#a64917',
  10: '#14B639',
  20: '#f86c6b',
  99: '#f5222d',
};

export const WMS_BATCH_ADJUSTMENT_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  4: '#faad14',
  5: '#FF6A29',
  8: '#aa0099',
  9: '#a64917',
  10: '#14B639',
  88: '#63c2de',
  99: '#f5222d',
};

export const WMS_WAREHOUSE_BILL_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  5: '#FF6A29',
  6: '#14B639',
  99: '#f5222d',
};

export const WMS_WAREHOUSE_CHARGE_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#20a8d8',
  6: '#00A399',
  9: '#f5222d',
  99: '#f5222d',
};

export const WMS_DISPATCH_REPORT_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#faad14',
  6: '#00A399',
  99: '#f5222d',
};

export const WMS_QUOTATION_STATUS_COLORS = {
  1: '#6f42c1',
  6: '#00A399',
  99: '#f5222d',
};

export const WMS_DELIVERY_NOTE_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#00068D',
  5: '#E06837',
  6: '#6C009E',
  7: '#FB8760',
  9: '#20a8d8',
  10: '#00A399',
  20: '#14B639',
  51: '#B67E06',
  52: '#1890FF',
  53: '#aa0099',
  54: '#a64917',
  88: '#63c2de',
  99: '#f5222d',
};

export const EXTERNAL_WMS_STATUS_COLORS = {
  1: '#6f42c1',
  6: '#FF6A29',
  9: '#aa0099',
};

export const EXTERNAL_WMS_BATCH_STATUS_COLORS = {
  1: '#6f42c1',
  5: '#20a8d8',
  10: '#14B639',
  19: '#aa0099',
  99: '#f86c6b',
};

export const EXTERNAL_WMS_TRANSFER_PICK_COLORS = {
  4: '#6f42c1',
  5: '#20a8d8',
  10: '#14B639',
  19: '#aa0099',
  99: '#f86c6b',
};

export const WMS_WAVE_ORDER_STATUS_COLORS = {
  1: '#6f42c1',
  3: '#FF6A29',
  6: '#00A399',
};

export const WMS_INVENTORY_BIN_DETAIL_STATUS_COLORS = {
  0: '#20a8d8',
  1: '#6f42c1',
  10: '#FF6A29',
  20: '#aa0099',
  70: '#f5222d',
};

export const TRANSFER_PICK_ORDER_STATUS_COLORS = {
  1: '#20a8d8', // Prepare
  2: '#03776c', //TransferPickOrderItemStatusPicked, this is item level actually
  3: '#592b03', // Pending Pick
  5: '#a64917', // Picking
  6: '#6f42c1', // Picking Completed
  9: '#464d04', // Partially PutAway
  10: '#14B639', // "PutAway"
  22: '#f4680b', // Partially Sorted
  23: '#aa0099', // Sorted
  26: '#1890FF', // Confirmed Sorting
  99: '#f86c6b', // "Canceled"
};

export const PROBLEMATIC_DN_COLOR = {
  0: '#FFFFFF',
  10: '#eefafb',
  88: '#f9ffd1',
  99: '#f86c6b',
};

export interface ISelectDefaultOption {
  optionValue: number;
  optionText: string;
}

export const OMS_DELIVERY_EXPORT = 1;

export const OMS_DELIVERY_IMPORT = 2;

export const REPORT_TYPE_ASN_INBOUND = 'ReportOmsAsnInbound';

export const JOR_WMS_UNKNOWN_SKU = 'unknown_sku';

export const BATCH_ADJUSTMENT_TYPE_STATUS = 1;
export const BATCH_ADJUSTMENT_TYPE_OWNER = 2;
export const BATCH_ADJUSTMENT_TYPE_VALID_DATE = 3;
export const BATCH_ADJUSTMENT_TYPE_BATCH = 4;
export const BATCH_ADJUSTMENT_TYPE_QUANTITY = 5;

export const WMS_FLOW_INBOUND_RECEIVE_TO_PUT_AWAY = 1;
export const WMS_FLOW_INBOUND_REQUIRE_INSPECTION = 3;
export const WMS_FLOW_INBOUND_REQUIRE_SORTING = 5;

export const WMS_INBOUND_SORTING_NORMAL = 1;
export const WMS_INBOUND_SORTING_DAMAGE = 3;
export const WMS_ZONE_PROPERTY_AMR = 6;
export const WMS_ZONE_PROPERTY_TEMP_STORAGE = 3;
export const WMS_ZONE_PROPERTY_NORMAL_ZONE = 1;

export const WMS_REPLENISHMENT_TYPE_ASN_TEMP_ZONE = 1;
export const WMS_REPLENISHMENT_TYPE_IMMEDIATE_RETURN = 5;
export const WMS_REPLENISHMENT_TYPE_YESTERDAY_ORDER = 9;
export const WMS_REPLENISHMENT_TYPE_CROSS_ZONE = 11;

export const WMS_STOCK_TAKE_TYPE_PRODUCT_SKU = 2;
export const WMS_STOCK_TAKE_TYPE_WAREHOUSE_BIN = 4;

export const ConfirmToPackSuccessPutCursorToContainer = 10;
export const ConfirmToPackSuccessPutCursorToEAN = 1;
export const ConfirmToPackFail = -1;

export const CONFIRM_TO_PACK_SUCCESS_PUT_CURSOR_TO_CONTAINER = 10;
export const CONFIRM_TO_PACK_SUCCESS_PUT_CURSOR_TO_EAN = 1;
export const CONFIRM_TO_PACK_FAIL = -1;

export const DeliveryNoteStatusRequestCancel = 88;
export const DeliveryNoteStatusCancelled = 99;
export const DeliveryNoteStatusPacked = 7;
export const DeliveryNoteStatusShippedStock = 10;
export const DeliveryNoteStatusDelivered = 20;
export const PickingProductTypeList: string[] = ['CN', 'Non-CN', 'MX', 'EXD'];
export const PickingProductTypeEXD = 'EXD';

export const WMS_OUTBOUND_PICK_TYPE_NORMAL = 1;
export const WMS_OUTBOUND_PICK_TYPE_AMR = 3;
export const WMS_OUTBOUND_PICK_TYPE_AMR_AND_NORMAL = 6;

export const WMS_DN_CLIENT_WEBHOOK_STATUS_DONE = 6;
export const WMS_DN_STATUS_SHIPPED = 10;

export const DELIVERY_DISPATCH_ERROR_CODE_WRONG_STATUS_CANCEL = '8001';
export const DELIVERY_DISPATCH_ERROR_CODE_WRONG_STATUS_SHIPPED = '8002';
export const DELIVERY_DISPATCH_ERROR_CODE_WRONG_STATUS_OTHERS = '8003';

export const getDNUrl = (deliveryNoteId: number) => {
  return '/wms/outbound/delivery-note/' + deliveryNoteId;
};

export const ADDRESS_TYPE_RECEIVER = 1;
export const ADDRESS_TYPE_SHIPPER = 2;

export const RequireStarStyle = {
  color: '#ff4d4f',
  marginRight: '4px',
  fontFamily: 'SimSun, sans-serif',
  fontSize: '14px',
};
