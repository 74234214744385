// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import ProjectOutlined from '@ant-design/icons/es/icons/ProjectOutlined';
import FileZipOutlined from '@ant-design/icons/es/icons/FileZipOutlined';
import TransactionOutlined from '@ant-design/icons/es/icons/TransactionOutlined';
import OneToOneOutlined from '@ant-design/icons/es/icons/OneToOneOutlined';
import InboxOutlined from '@ant-design/icons/es/icons/InboxOutlined';
import SendOutlined from '@ant-design/icons/es/icons/SendOutlined';
import UngroupOutlined from '@ant-design/icons/es/icons/UngroupOutlined';
import FolderOpenOutlined from '@ant-design/icons/es/icons/FolderOpenOutlined';
import PullRequestOutlined from '@ant-design/icons/es/icons/PullRequestOutlined';
import ScheduleOutlined from '@ant-design/icons/es/icons/ScheduleOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import ProfileOutlined from '@ant-design/icons/es/icons/ProfileOutlined';
import SolutionOutlined from '@ant-design/icons/es/icons/SolutionOutlined';
import DollarOutlined from '@ant-design/icons/es/icons/DollarOutlined';
import BlockOutlined from '@ant-design/icons/es/icons/BlockOutlined';
import LayoutOutlined from '@ant-design/icons/es/icons/LayoutOutlined';
import ScanOutlined from '@ant-design/icons/es/icons/ScanOutlined';
import ShoppingCartOutlined from '@ant-design/icons/es/icons/ShoppingCartOutlined';
import SelectOutlined from '@ant-design/icons/es/icons/SelectOutlined';
import SyncOutlined from '@ant-design/icons/es/icons/SyncOutlined';
import AppstoreOutlined from '@ant-design/icons/es/icons/AppstoreOutlined';
import ToolOutlined from '@ant-design/icons/es/icons/ToolOutlined';
import RetweetOutlined from '@ant-design/icons/es/icons/RetweetOutlined';
import DatabaseOutlined from '@ant-design/icons/es/icons/DatabaseOutlined';
import FileTextOutlined from '@ant-design/icons/es/icons/FileTextOutlined';
import HomeOutlined from '@ant-design/icons/es/icons/HomeOutlined';
import BankOutlined from '@ant-design/icons/es/icons/BankOutlined';
import CodeSandboxOutlined from '@ant-design/icons/es/icons/CodeSandboxOutlined';
import CarOutlined from '@ant-design/icons/es/icons/CarOutlined';
import UserSwitchOutlined from '@ant-design/icons/es/icons/UserSwitchOutlined';
import UsergroupAddOutlined from '@ant-design/icons/es/icons/UsergroupAddOutlined';
import ControlOutlined from '@ant-design/icons/es/icons/ControlOutlined'
  export default {
    SmileOutlined,
ProjectOutlined,
FileZipOutlined,
TransactionOutlined,
OneToOneOutlined,
InboxOutlined,
SendOutlined,
UngroupOutlined,
FolderOpenOutlined,
PullRequestOutlined,
ScheduleOutlined,
UserOutlined,
ProfileOutlined,
SolutionOutlined,
DollarOutlined,
BlockOutlined,
LayoutOutlined,
ScanOutlined,
ShoppingCartOutlined,
SelectOutlined,
SyncOutlined,
AppstoreOutlined,
ToolOutlined,
RetweetOutlined,
DatabaseOutlined,
FileTextOutlined,
HomeOutlined,
BankOutlined,
CodeSandboxOutlined,
CarOutlined,
UserSwitchOutlined,
UsergroupAddOutlined,
ControlOutlined
  }