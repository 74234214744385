import { request } from 'umi';
import type {
  FileTableListParams,
  CreateFileDataType,
  UpdateFileDataType,
} from '@/models/wms/file.d';

const { JOR_WMS_FILE_URL, JOR_A_WMS_FILE_URL } = API;

export async function queryJorWmsFile(params: FileTableListParams | undefined) {
  return request(JOR_WMS_FILE_URL, {
    method: 'GET',
    params,
  });
}

export async function queryJorWmsFileTypes(params: { model_type: string }) {
  return request(`${JOR_WMS_FILE_URL}/types`, {
    method: 'GET',
    params,
  });
}

// export async function getJorWmsFileLimit(params: { model_id: number; model_type: string }) {
//   return request(`${JOR_WMS_FILE_URL}/limit`, {
//     method: 'GET',

//     params,
//   });
// }

export async function getJorWmsFile(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_WMS_FILE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createJorWmsFile(params: CreateFileDataType) {
  return request(JOR_WMS_FILE_URL, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateJorWmsFile(params: UpdateFileDataType) {
  const { id } = params;
  return request(`${JOR_WMS_FILE_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function removeJorWmsFile(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_WMS_FILE_URL}/${id}`, {
    method: 'DELETE',
  });
}

export async function downloadJorWmsFile(params: { id: string }) {
  return request(`${JOR_WMS_FILE_URL}/${params.id}/download`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function previewJorWmsFile(params: { id: string }) {
  const res = await request(`${JOR_WMS_FILE_URL}/${params.id}/download`, {
    responseType: 'blob',
  });
  return res;
}

export async function queryJorAdminWmsFile(params: FileTableListParams | undefined) {
  return request(JOR_A_WMS_FILE_URL, {
    method: 'GET',
    params,
  });
}
