export default {
  'delivery-bookings.id': 'ID',
  'delivery-bookings.title': 'Booking List',
  'delivery-bookings.delivery_booking_no': 'Booking No.',
  'delivery-bookings.booking_type': 'Type',
  'delivery-bookings.pickup_address': 'Pickup Address',
  'delivery-bookings.delivery_address': 'Delivery Address',
  'delivery-bookings.pickup_delivery_address': 'Pickup / Delivery Address',
  'delivery-bookings.company_name': 'Company Name',
  'delivery-bookings.country_code': 'Country Code',
  'delivery-bookings.city': 'City',
  'delivery-bookings.state': 'State',
  'delivery-bookings.contact_person_name': 'Contact Person',
  'delivery-bookings.contact_phone': 'Contact Phone',
  'delivery-bookings.delivery_contact_person': 'Delivery Contact Person',
  'delivery-bookings.delivery_contact_phone': 'Delivery Contact Phone',
  'delivery-bookings.postal_code': 'Postal Code',
  'delivery-bookings.request_items': 'Request Booking Packages',
  'delivery-bookings.request_collection_date': 'Delivery / Collection Date',
  'delivery-bookings.request_collection_time': 'Ready Time',
  'delivery-bookings.pickup_note': 'Pickup Note',
  'delivery-bookings.show_request_items': 'Show Request Booking Packages',
  'delivery-bookings.shipment_scan_date': 'Scanned Date',
  'delivery-bookings.scanned_packages': 'Scanned Packages',
  'delivery-bookings.action': 'Action',
  'delivery-bookings.status': 'Status',
  'delivery-bookings.message.booking_type': 'Please Choose Pickup Or Delivery',
  'delivery-bookings.message.address': 'Please enter pickup address',
  'delivery-bookings.message.contact_phone': 'Please enter contact phone',
  'delivery-bookings.message.contact_person_name': 'Please enter contact',
  'delivery-bookings.message.batch_id': 'Please select available batch id',
  'delivery-bookings.message.request_collection_date': 'Please select collection date',
  'delivery-bookings.message.request_collection_time': 'Please select available collection time',
  'delivery-bookings.batch.title': 'Create Booking Appointment',
  'delivery-bookings.type.pickup': 'Request Pickup',
  'delivery-bookings.type.delivery': 'Delivery to Warehouse',
  'delivery-bookings.update': 'Update Booking Appointment',
  'delivery-bookings.update.success': 'Pickup schedule has been succesfully updated ',
  'delivery-bookings.create.fail': 'Fail to create pickup schedule',
  'delivery-bookings.create.success': 'Pickup schedule has been succesfully created',
  'delivery-bookings.update.fail': 'Fail to update pickup schedule',
  'delivery-bookings.form_item.title': 'Booking Packages',
  'delivery-bookings.scan_booking_no': 'Scan Booking No.',
  'delivery-bookings.details.reference_no': 'Reference No.',
  'delivery-bookings.details.shipment_number': 'Tracking No.',
  'delivery-bookings.details.tracking_no': 'Tracking No.',
  'delivery-bookings.details.sr_tracking_no': 'SR Tracking No.',
  'delivery-bookings.details.remark': 'Remark',
  'delivery-bookings.details.shipped_date': 'Shipped Date',
  'delivery-bookings.details.shipped_by': 'Shipped By',
  'delivery-bookings.detail.cancel-confirm': 'Are you sure to cancel this {document_no} package?',
  'delivery-bookings.received-confirm': 'Are you sure you want to received this {document_no}',
  'delivery-bookings.cancel-confirm': 'Are you sure to cancel this {document_no} booking order?',
  'delivery-bookings.upload_items': 'Upload Box Items',
  'delivery-bookings.export_to_excel': 'Export your data to excel',
  'delivery-bookings.download': 'Download',
  'delivery-bookings.show_request_item_title': '{document_no} Booking Packages',
  'delivery-bookings.scan_delivery_booking_no': 'Scan Booking No.',
  'delivery-bookings.details.courier_service_code': 'Courier Serivice Code',
  'delivery-bookings.prepare-confirm':
    'Are you sure you want to prepare this booking {document_no}',
  'delivery-bookings.dispatch-confirm':
    'Are you sure you want to dispatch this booking {document_no}',
  'delivery-bookings.scan.success': 'Scan booking No. has been succesfully',
  'delivery-bookings.scan_booking_no.no_data':
    'The booking No. {booking_no} has no data to pack, please check it',
  'delivery-bookings.item.title': 'Booking Packages',
  'delivery-bookings.scan_tracking_no': 'Scan Tracking No.',
  'delivery-bookings.scan_tracking_no.success': 'Scan Tracking No. succesfully',
  'delivery-orders.manifest_status': 'Manifest Status',
  'delivery-orders.action': 'Action',
  'delivery-orders.all-shipments.title': 'All Shipments',
  'delivery-orders.manual-shipment.title': 'Manual Shipments',
  'delivery-orders.require-add-manifest.title': 'Require Add Manifest Shipment',
  'delivery-orders.button.create_track_and_trace':
    'Create shipment tracking, system will auto get the shipment tracking details',
  'delivery-orders.button.download_waybill': 'Download Waybill',
  'delivery-orders.button.download_lastmile_waybill': 'Download Lastmile PDF',
  'delivery-orders.button.download_invoice': 'Download Invoice',
  'delivery-orders.button.return_waybill': 'Create Return Shipment Waybill',
  'delivery-orders.require-assign-shipment.title': 'Require Assign Shipments',
  'delivery-orders.assign_shipment_status': 'Assign Status',
  'delivery-orders.button.retry_send_manifest': 'Retry To Send Manifest',
  'delivery-orders.retry-add-manifest-confirm':
    'Are you sure to retry this {document_no} to add mainfest?',
  'delivery-orders.require-lastmile.title': 'Require Uplaod Lastmie Zip Or Tracking No.',
  'delivery-orders.last_mile_status': 'Lastmile Status',
  'delivery-orders.upload-lastmile': 'Upload Lastmile',
  'delivery-orders.upload-lastmile-zip': 'Upload Lastmile Zip Labels',
  'delivery-orders.upload-lastmile-tracking': 'Upload Lastmile Tracking No.',
  'delivery-orders.assign-selected-shipment': 'Assign Selected Shipment',
  'delivery-orders.upload-manual-shipments': 'Upload Manual Shipments',
  'delivery-orders.manual-shipments': 'Manual Shipments',
  'delivery-orders.collection_date': 'Collection Date',
  'delivery-orders.collection_time': 'Collection Time',
  'delivery_booings.download': 'Download',
  'delivery_booings.export_manifest_to_excel': 'Export Manifest To Excel',
};
