export default {
  'courier-services.create': 'Create Service',
  'courier-services.service_code': 'Service Code',
  'courier-services.courier': 'Courier',
  'courier-services.account_number': 'API Account',
  'courier-services.courier_api_code': 'Courier Api',
  'courier-services.service_channel': 'Service Channel',
  'courier-services.courier_platform_service_code': 'Courier Platform Service',
  'courier-services.country': 'Country',
  'courier-services.destination_country': 'Destination Country',
  'courier-services.import': 'Import',
  'courier-services.export': 'Export',
  'courier-services.hk_local': 'HK Local',
  'courier-services.export_import': 'Export Or Import',
  'courier-services.weight_break_from': 'Weight Break From',
  'courier-services.weight_break_to': 'Weight Break To',
  'courier-services.status': 'Status',
  'courier-services.status.inactive': 'Inactive',
  'courier-services.status.active': 'Active',
  'courier-services.new': 'New Service',
  'courier-services.edit': 'Edit Service',
  'courier-services.detail': 'Service Details',
  'courier-services.delivery_shipper': 'Shipper',
  'courier-services.delete-confirm': 'Are you sure you want to delete this services?',
  'courier-services.disable-confirm': 'Are you sure you want to disable this services?',
  'courier-services.enable-confirm': 'Are you sure you want to enable this services?',
  'courier-services.disable.success': 'Service has been successfully disabled',
  'courier-services.enable.success': 'Service has been successfully enabled',
  'courier-services.disable.fail': 'Fail to disable service',
  'courier-services.enable.fail': 'Fail to enable service',
  'courier-services.menuMap.basic': 'General Info',
  'courier-services.menuMap.customer': 'Customers',
  'courier-services.menuMap.account': 'API Accounts',
  'courier-services.menuMap.cost': 'Cost Setting',
  'courier-services.customers': 'Customers',
  'courier-services.action.add-new-customer': 'Add New Customer',
  'courier-services.delete-company.warning': 'Are you sure to delete this company?',
  'courier-services.message.country': 'Please select Country',
  'courier-services.message.destination_country': 'Please select destination country',
  'courier-services.message.cost_currency_code': 'Please select currency',
  'courier-services.message.courier_rate_type': 'Please select service rate type for the rate card',
  'courier-services.service_type': 'Service Type',
  'courier-services.basic.update': 'Update',
  'company-services.company_name': 'Company Name',
  'company-services.program_name': 'Program Name',
  'company-services.currency_code': 'Currency Code',
  'company-services.valid_till': 'Valid Till',
  'company-services.markup': 'Markup',
  'courier-services.create.success': 'Service has been succesfully created',
  'courier-services.update.fail': 'Fail to update Service',
  'courier-services.update': 'Update',
  'courier-services.update.success': 'Service has been succesfully updated',
  'courier-services.courier_rate_type': 'Courier Rate Type',
  'courier-services.cost_currency_code': 'Cost Currency',
  'courier-services.max_weight': 'Max Weight',
  'courier-services.max_height': 'Max Height',
  'courier-services.max_width': 'Max Width',
  'courier-services.max_length': 'Max Length',
  'courier-services.ddp_surcharge': 'DDP Surcharge',
  'courier-services.fuel_surcharge_percent': 'Fuel Surcharge',
  'courier-services.pod': 'Proof Of Delivery',
  'courier-services.lead_time': 'Lead Time',
  'courier-services.min_shipping_time': 'Min Shipping Time',
  'courier-services.max_shipping_time': 'Max Shipping Time',
  'courier-services.rating': 'Rating',
  'courier-services.pickup_mode': 'Pickup Mode',
  'courier-services.message.pickup_mode': 'Please Select Pickup Mode',
  'courier-services.free_pickup': 'Free Pickup',
  'courier-services.dropoff_conditional_pickup': 'Drop-off or *Conditional Pickup',
  'courier-services.peak_season_surcharge': 'Peak Season Surcharge',
  'courier-services.dimension_denominator': 'Dimension Denominator',
};
