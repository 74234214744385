import type { Effect, Reducer } from 'umi';

import type {
  InventoryAdjustmentTableList,
  InventoryBatchAdjustmentDataType,
  StockTakeTableListDataType,
} from './data';
import {
  getInventoryBatchAdjustmentDetail,
  getStockTakeDetails,
  getInventoryAdjustmentDetail,
} from './service';

export interface StateType {
  stockTake?: StockTakeTableListDataType;
  inventoryAdjustment?: InventoryAdjustmentTableList;
  batchAdjustment?: InventoryBatchAdjustmentDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getStockTake: Effect;
    getInventoryAdjustment: Effect;
    getBatchAdjustment: Effect;
  };
  reducers: {
    saveStockTake: Reducer<StateType>;
    saveInventoryAdjustment: Reducer<StateType>;
    saveBatchAdjustment: Reducer<StateType>;
    clearStockTake: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'wmsInventoryModel',
  state: {
    stockTake: undefined,
    inventoryAdjustment: undefined,
    batchAdjustment: undefined,
  },
  effects: {
    *getStockTake({ payload }, { call, put }) {
      const response = yield call(getStockTakeDetails, payload);
      yield put({
        type: 'saveStockTake',
        payload: response.data,
      });
    },
    *getInventoryAdjustment({ payload }, { call, put }) {
      const response = yield call(getInventoryAdjustmentDetail, payload);
      yield put({
        type: 'saveInventoryAdjustment',
        payload: response.data,
      });
    },
    *getBatchAdjustment({ payload }, { call, put }) {
      const response = yield call(getInventoryBatchAdjustmentDetail, payload);
      yield put({
        type: 'saveBatchAdjustment',
        payload: response.data,
      });
    },
  },

  reducers: {
    saveStockTake(state, action) {
      return {
        ...state,
        stockTake: action.payload,
      };
    },
    saveInventoryAdjustment(state, action) {
      return {
        ...state,
        inventoryAdjustment: action.payload,
      };
    },
    saveBatchAdjustment(state, action) {
      return {
        ...state,
        batchAdjustment: action.payload,
      };
    },
    clearStockTake() {
      return {
        stockTake: undefined,
        inventoryAdjustment: undefined,
        batchAdjustment: undefined,
      };
    },
  },
};

export default Model;
