import type { Effect, Reducer } from 'umi';

import type { ContactTableListItem } from '../supplier-contact/data';
import type { SupplierBankTableListItem } from '../supplier-bank/data';

import { getSuppleirContacts, getSuppleirBanks } from './service';

export interface StateType {
  contacts?: ContactTableListItem[];
  banks?: SupplierBankTableListItem[];
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getContacts: Effect;
    getSupplierBanks: Effect;
  };
  reducers: {
    saveContacts: Reducer<StateType>;
    saveSupplierBanks: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'supplierModel',

  state: {
    contacts: [],
    banks: [],
  },

  effects: {
    *getContacts({ payload }, { call, put }) {
      const response = yield call(getSuppleirContacts, payload);
      yield put({
        type: 'saveContacts',
        payload: response.data,
      });
    },
    *getSupplierBanks({ payload }, { call, put }) {
      const response = yield call(getSuppleirBanks, payload);
      yield put({
        type: 'saveSupplierBanks',
        payload: response.data,
      });
    },
  },

  reducers: {
    saveContacts(state, action) {
      return {
        ...state,
        contacts: action.payload,
      };
    },
    saveSupplierBanks(state, action) {
      return {
        ...state,
        banks: action.payload,
      };
    },
  },
};

export default Model;
