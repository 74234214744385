export default {
  'warehouse_bin.name': 'Bin Name',
  'warehouse_bin.external_warehouse_bin_code': 'External Bin Name',
  'warehouse_bin.bin_property': 'Bin Property',
  'warehouse_bin.rack_code': 'Rack Code',
  'warehouse_bin.shelf_code': 'Shelf Code',
  'warehouse_bin.bin_size': 'Size',
  'warehouse_bin.bin_size_length': 'Length',
  'warehouse_bin.bin_size_width': 'Width',
  'warehouse_bin.bin_size_height': 'Height',
  'warehouse_bin.create': 'Create Warehouse bin',
  'warehouse_bin.create.fail': 'Fail to create warehouse bin',
  'warehouse_bin.create.success': 'Warehouse bin has been succesfully created',
  'warehouse_bin.update.fail': 'Fail to update warehouse bin',
  'warehouse_bin.update.success': 'Warehouse bin has been succesfully updated',
  'warehouse_bin.download_selected': 'Downlaod selected',
  'warehouse_bin.download-selected-confirm':
    'Are you sure you want to download selected warehouse bin barcode?',
  'warehouse_bin.location_zone_code': 'Put Away Zone',
  'warehouse_bin.location_aisle_code': 'Aisle',
  'warehouse_bin.location_bay_code': 'Bay',
  'warehouse_bin.location_level_code': 'Level',
  'warehouse_bin.location_position_code': 'Position',
};
