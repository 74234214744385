export default {
  'inventories.search_by_product_type': 'View By Product Type',
  'inventories.search_by_zone_product_type': 'View By Product Type with Zone',
  'inventories.warehouse_zone_code': 'Warehouse Zone Code',
  'inventories.product': 'Product',
  'inventories.product_sku': 'SKU',
  'inventories.product_total_qty': 'Total Qty',
  'inventories.on_hand_stock': 'On Hand',
  'inventories.can_order_stock': 'Can Order',
  'inventories.can_allocate_stock': 'Available',
  'inventories.ordered_stock': 'New Ordered',
  'inventories.inspect_stock': 'Inspect',
  'inventories.hold_stock': 'Holding',
  'inventories.damage_stock': 'Damage',
  'inventories.inbound_asn_stock': 'ASN Stock',
  'inventories.outbound_dn_stock': 'DN Stock',
  'inventories.pending_put_away_qty': 'Pending Put Away Qty',
  'inventories.put_away_qty': 'Put Away Qty',
  'inventories.pre_pick_stock': 'PrePick Qty',
  'inventories.picked_stock': 'Picked Qty',
  'inventories.pre_replenishment_qty': 'Pre-Replenishment Qty (To AMR)',
  'inventories.back_order_stock': 'Back Order Stock',
  'inventories.download.warning_report': 'Download Dispatched Warning',
  'inventories.download.warning_report.title': 'Download Inventory Dispatch DN Warning Report',
  'inventories.remark': 'Remark',
  'inventories.status': 'Status',
  'inventories.create': 'Create Product',
  'inventories.create.success': 'Product has been succesfully created',
  'inventories.update.fail': 'Fail to update product',
  'inventories.update': 'Update',
  'inventories.update.success': 'Product has been succesfully updated',
  'stocktakes.stock_take_name': 'Stock Take Name',
  'stocktakes.stock_take_code': 'Stock Take No.',
  'stocktakes.type': 'Type',
  'stocktakes.stock_take_command_type': 'Stock Take CMD Type',
  'stocktakes.creation_date': 'Creation Date',
  'stocktakes.workstation_no': 'Workstation No.',
  'stocktakes.first_print_datetime': 'First Print Time',
  'stocktakes.last_print_datetime': 'Last Print Time',
  'stocktakes.last_upload_time': 'Last Upload Time',
  'stocktakes.confirm_result_time': 'Confirm Adj. Time',
  'stocktakes.inventory_adjustment_code': 'Adjustment No.',
  'stocktakes.start_datetime': 'Start DateTime',
  'stocktakes.end_datetime': 'End DateTime',
  'stocktakes.created_by_username': 'Created By',
  'stocktakes.completed_by_username': 'Completed By',
  'stocktakes.uploaded_by_username': 'Uploaded By',
  'stocktakes.confirm_result_by_username': 'Confirm Adj. By',
  'stocktakes.random_stocktake_amount': 'Random Stock Take Qty',
  'stocktakes.remark': 'Remark',
  'stocktakes.create': 'Create Stock Take',
  'stocktakes.create.placeholder.bin': 'bin (wildcast by *)',
  'stocktakes.create.tab.search': 'Searching',
  'stocktakes.create.tab.items': 'Stocktake Items ({st_item_count})',
  'stocktakes.create.button.copyto': 'Add to Draft',
  'stocktakes.create.button.unselect-zero-can-allocate': '[Unselect zero "Can Allocate Qty"]',
  'stocktakes.create.button.clear-draft': '[Clear Draft]',
  'stocktakes.create.button.create-st': 'Create ST',
  'stocktakes.create.warning.no-item-insert': 'Please select items that require stocktake first',
  'stocktakes.create.warning.pls-input-aisle-range': 'Please input aisle range',
  'stocktakes.create.warning.pls-input-bay-range': 'Please input bay range',
  'stocktakes.create.message.saved-to-draft': '{diff_count} record(s) added into draft',
  'stocktakes.upload.button.upload-st-result': 'Upload ST Result',
  'stocktakes.upload.title.upload-st-result': 'Upload Stocktake Result',
  'stocktakes.upload.warning.please-select-file-to-upload': 'Please select excel file first',
  'stocktakes.upload.button.download-result': 'Download Result',
  'stocktakes.upload.button.confirm-adjustment': 'Confirm Adjustment',
  'stocktakes.upload.button.complete-stocktake': 'Complete Stocktake',
  'stocktakes.upload.confirm.upload-with-cfm-complete':
    'Are you sure you want to complete this stocktake?',
  'stocktakes.upload.confirm.upload-with-cfm-adjust':
    'Are you sure you want to perform stocktake inventory adjustment according to this stocktake excel form?',
  'stocktakes.label.warehouse_bin_zone': 'Zone',
  'stocktakes.label.warehouse_bin_aisle_code': 'Aisle',
  'stocktakes.label.warehouse_bin_bay_code': 'Bay',
  'stocktakes.label.warehouse_bin_level_code': 'Level',
  'stocktakes.label.warehouse_bin_position_code': 'Position',
  'stocktakes.update': 'Update Stock Take',
  'stocktakes.informed_stocktake': 'Informed Stock Take',
  'stocktakes.blind_stocktake': 'Blind Stock Take',
  'stocktakes.create_item_by_sku.title':
    'Stocktake by SKU(When entering multiple SKU codes at one time, use, to separate them, such as sku001, sku002.)',
  'stocktakes.create_item_by_bin.title':
    'Stocktake by shelf-bin(When multiple shelf bin codes are inputted at one time, use, to separate them, such as B000001F1A, C000002F1A.)',
  'stocktakes.stock_take_items': 'Stock Take Items',
  'stocktakes.adjustments': 'Adjustments',
  'stocktakes.adjustment_items': 'Adjustment Items',
  'stocktakes.complete-confirm':
    'Are you sure you want to complete the stock take order - {document_no}?',
  'stocktakes.cancel-confirm':
    'Are you sure you want to cancel the stock take order - {document_no}?',
  'stocktakes.details.item_no': 'Item No.',
  'stocktakes.details.sku': 'SKU',
  'stocktakes.details.product_name': 'Product Name',
  'stocktakes.details.system_amount': 'System Amount',
  'stocktakes.details.stocktake_amount': 'Stocktake Amount',
  'stocktakes.details.packing_spec': 'Packing Spec',
  'stocktakes.details.production_date': 'Production Date',
  'stocktakes.details.expiry_date': 'Expiry Date',
  'stocktakes.details.shelf_code': 'Shelf Code',
  'stocktakes.details.shelf_bin_code': 'Shelf Bin Code',
  'stocktakes.details.operator': 'Operator',
  'stocktakes.details.sku_level': 'SKU Level',
  'stocktakes.details.amount': 'Amount',
  'inventory-adjustment.id': 'Id',
  'inventory-adjustment.create': 'Create Inventory Adjustment',
  'inventory-adjustment.create.success': 'Create inventory adjustment successfully',
  'inventory-adjustment.create.fail': 'Fail to create inventory adjustment',
  'inventory-adjustment.add_adjusted_sku_details': 'Add Adjusted SKU Details',
  'inventory-adjustment.out_adjustment_code': 'Adjustment No.',
  'inventory-adjustment.merchant': 'Merchant',
  'inventory-adjustment.warehouse': 'Warehouse',
  'inventory-adjustment.virtual_warehouse': 'Virtual Warehouse',
  'inventory-adjustment.warehouse_zone': 'Warehouse Zone',
  'inventory-adjustment.on_hand_stock': 'On Hand Stock',
  'inventory-adjustment.item.no': 'Item No.',
  'inventory-adjustment.item.id': 'Item Id',
  'inventory-adjustment.product_name': 'Product Name',
  'inventory-adjustment.inventory_qty': 'Inventory Qty',
  'inventory-adjustment.can_allocate_stock': 'Can Allocate Qty',
  'inventory-adjustment.require_adjust_qty': 'Adjust Qty',
  'inventory-adjustment.adjusted_qty': 'Adjusted Qty',
  'inventory-adjustment.adjust_qty': 'Adjust Qty',
  'inventory-adjustment.system_amount': ' System Amount (before adjustment)',
  'inventory-adjustment.adjusted_system_amount': ' System Amount (after adjustment)',
  'inventory-adjustment.require_adjust_qty.tooltip':
    'If you need to add one piece, please enter 1. If you need to subtract one item, please enter - 1',
  'inventory-adjustment.adjustment_reason': 'Adjustment Reason',
  'inventory-adjustment.adjustment_reason_remark': 'Adjustment Reason Remark',
  'inventory-adjustment.production_date': 'MFG Date',
  'inventory-adjustment.expiry_date': 'Exp Date',
  'inventory-adjustment.system_expire_date': 'System Exp Date',
  'inventory-adjustment.production_batch_code': 'Production Batch Code',
  'inventory-adjustment.warehouse_zone_code': 'Zone Code',
  'inventory-adjustment.warehouse_bin_code': 'Bin Code',
  'inventory-adjustment.completed_date_at': 'Completed Date',
  'inventory-adjustment.completed_by': 'Completed By',
  'inventory-adjustment.no_amr_inventory_adjustment': 'No AMR Inventory Adjustments',
  'inventory-adjustment.amr_inventory_adjustment': 'AMR Inventory Adjustments',
  'inventory-adjustment.status': 'Status',
  'inventory-adjustment.wms_provider_code': 'WMS Provider Code',
  'inventory-adjustment.creation_date': 'Creation Date',
  'inventory-adjustment.remark': 'Remark',
  'inventory-adjustment.adjustment_code': 'Adjustment Code',
  'inventory-adjustment.stock_take_code': 'Stock Take Code',
  'inventory-adjustment.workstation_no': 'Workstation No.',
  'inventory-adjustment.pending_adjust_amount': 'Pending Adjust Amount',
  'inventory-adjustment.adjusted_amount': 'Adjusted Amount',
  'inventory-adjustment.adjust_amount': 'Adjust Amount',
  'inventory-adjustment.operator': 'Operator',
  'inventory-adjustment.no_item_warning': 'Please add adjutment items first!',
  'inventory-adjustment.button.complete': 'Complete Adjustment',
  'inventory-adjustment.complete-confirm':
    'Are you sure you want to complete the inventory adjustment - {document_no}?',
  'inventory-adjustment.cancel-confirm':
    'Are you sure you want to cancel the inventory adjustment - {document_no}?',
  'stockout_adjustment_item.amount': 'Qty',
  'stockout_adjustment_item.sku_level': 'SKU Level',
  'stockout_adjustment_item.production_date': 'Production Date',
  'stockout_adjustment_item.expiry_date': 'Expiry Date',

  'inventory-batch-adjusment.batch_adjustment_code': 'Batch Adjustment No.',
  'inventory-batch-adjusment.batch_adjustment_type': 'Batch Adjustment Type',
  'inventory-batch-adjusment.distribution_system': 'Distribution System',
  'inventory-batch-adjusment.manually_designated_bin': 'Manually Designated Bin',
  'inventory-batch-adjusment.remark': 'Remark',
  'inventory-batch-adjust.status': 'Status',
  'inventory-batch-adjust-item.product_sku': 'Product SKU',
  'inventory-batch-adjust-item.plan_adjustment_num': 'Plan Adjustment Qty',
  'inventory-batch-adjust-item.actual_adjustment_num': 'Actual Adjustment Qty',
  'inventory-batch-adjust-item.merchant_code': 'Merchant Code',
  'inventory-batch-adjust-item.system_expire_date': 'System Expiry Date',
  'inventory-batch-adjust-item.asn_code': 'ASN Code',
  'inventory-batch-adjust-item.picking_product_type': 'Picking Product Type',
  'inventory-batch-adjust-item.target_system_expire_date': 'Target System Expiry Date',
  'inventory-batch-adjust-item.target_owner_code': 'Target Owner Code',
  'inventory-batch-adjust-item.target_picking_product_type': 'Target Picking Product Type',
  'inventory-batch-adjust-item.repush-selected-confirm':
    'Are you sure you want to Re-Push selected adjustment item to external WMS?',
  'inventory-batch-adjust-item.repush_selected': 'Re-Push Selected',
  'inventory-batch-adjust-item.api_owner_code': 'API Owner Code',
  'inventory-batch-adjust-item.adjusted_qty': 'Adjusted Qty',
  'inventory-batch-adjust-item.exceptions': 'Exceptions',
  'inventory-batch-adjust-item.production_date': 'Production Date',
  'inventory-batch-adjust-item.expiration_date': 'Expiration Date',
  'inventory.tab.inventory': 'Inventory',
  'inventory.tab.inventory-by-product-type': 'INV - Product Type',
  'inventory.tab.inventory-by-bin': 'INV - Bin',
  'inventory.tab.stock-take': 'Stock Take',
  'inventory.tab.inventory-adjustment': 'INV - Adjustment',
  'inventory.tab.inventory-batch-adjustment': 'INV Attr. Adjustment',
  'inventory.tab.temp-inventory': 'Temp Inventory',
  'inventory.tab.bin-lock-setting': 'Bin Lock Setting',
  'inventory.tab.bin-lock-setting.title': 'Bin Lock Setting',

  'inventory.tab.inventory.title': 'Overall Inventory',
  'inventory.tab.inventory-by-product-type.title': 'View Inventory by Product Type',
  'inventory.tab.inventory-by-bin.title': 'View Inventory by Bin',
  'inventory.tab.stock-take.title': 'Stock Take',
  'inventory.tab.inventory-adjustment.title': 'Inventory Adjustment',
  'inventory.tab.inventory-batch-adjustment.title': 'Inventory Attribute Adjustment',
  'inventory.tab.temp-inventory.title': 'Temp Zone Stock (confirmed sorting pending putaway)',
  'inventory.move-bin.fail': 'Bin movement has been fail!',

  'inventories.in_transit_stock': 'In-transit Stock',
  'inventories.allocated_stock': 'Allocated Stock',
  'inventories.show-allocated': 'Show Allocated Details',
  'inventories.delivery_note': 'DN No.',
  'inventories.expected_asn_code': 'Expected ASN',
  'inventories.allocated_qty': 'Allocated Qty',
  'inventories.allocated_title': 'Allocated {merchant} ({sku})',
  'inventories.create_stock_take.fail': 'Fail to create a new stock take',
  'inventory.remove.this.line': 'Delete this line?',
  'inventory.adjustment.please-put-adjusted-qty': 'Please put the adjusted Qty',
  'inventory.adjustment.please-select-adjustment-reason-code':
    'Please select an adjustment reason!',
  'inventory.adjustment.duplication': 'The item has been added before!',
};
