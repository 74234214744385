export default {
  'subsidiary.primary_name': 'Primary Name',
  'subsidiary.secondary_name': 'Secondary Name',
  'subsidiary.invoice_name': 'Invoice Name',
  'subsidiary.address': 'Address',
  'subsidiary.postal_address': 'Postal Address',
  'subsidiary.fax': 'Fax',
  'subsidiary.mobile': 'Mobile',
  'subsidiary.website': 'Website',
  'subsidiary.email': 'Email',
  'subsidiary.contact_person': 'Contact Person',
  'subsidiary.bank_name': 'Bank Name',
  'subsidiary.bank_swift_bic': 'Bank SwiftBIC',
  'subsidiary.bank_account': 'Bank Account',
  'subsidiary.chop': 'Chop',
  'subsidiary.logo': 'Logo',
  'subsidiary.br': 'BR',
  'subsidiary.delete-confirm': 'Are you sure to delete this subsidiary?',
  'role.name': 'Name',
  'role.role_type': 'Role Type',
  'role.role_permission': 'Permissions',
  'role.delete-confirm': 'Are you sure to delete this role?',
  'role.name.warning': 'Please input a Role Name',
  'role.role_type.warning': 'Please select Role Type',
  'user.email': 'Email',
  'user.name': 'Display Name',
  'user.user_role': 'User Role',
  'user.user_subsidiary': 'User Subsidiary',
  'user.list.please-select-subsidiary': 'Please select Subsidiary',
  'user.password': 'Password',
};
