export default {
  'replenishment_orders.menu.picking': '揀貨',
  'replenishment_orders.replenishment_code': '補貨批次單號',
  'replenishment_orders.scan_replenishment_code': '掃描補貨批次單號',
  'replenishment_orders.wms_receipt_code': '補貨單號',
  'replenishment_orders.scan_container_box_code': '掃描箱號',
  'replenishment_orders.container_box_or_dn_code': '箱號/出貨單號',
  'replenishment_orders.from_warehouse_zone': '從 - 區域',
  'replenishment_orders.from_warehouse_bin': '從 - 架位',
  'replenishment_orders.to_warehouse_zone': '到-區域',
  'replenishment_orders.to_zone_property': '區域屬性',
  'replenishment_orders.details.pallets': '補貨箱',
  'replenishment_orders.details.items': '補貨產品(按箱)',
  'replenishment_orders.details.request-items': '請求補貨單產品',
  'replenishment_orders.scan_sku_barcode': '掃描 SKU',
  'replenishment_orders.replenishment_type': '補貨類別',
  'replenishment_orders.product_container_title': 'SKU - {product_sku} 箱',
  'replenishment_orders.product_type_title': 'SKU - {product_sku} 揀貨類別',
  'replenishment_orders.menu.picking.tooltip': '揀選產品以補貨',
  'replenishment_orders.details.sku_type_amount': '多少個 SKU',
  'replenishment_orders.details.planned_receive_qty': '計劃補貨數量',
  'replenishment_orders.details.intransit_qty': '接收數量',
  'replenishment_orders.details.put_away_date_at': '上架日期',
  'replenishment_orders.details.request_qty': '請求的數量',
  'replenishment_orders.details.allocated_qty': '配貨數量',
  'replenishment_orders.details.replenishment_date': '補貨日期',
  'replenishment_orders.scan_amr_zone': '掃描 AMR 區域碼',
  'replenishment_orders.create-replenishment-request-item-confirm':
    '真的要選擇以上的貨品補貨到 AMR 嗎?',
  'replenishment_orders.create-replenishment-request-item-confirm-and-create':
    '真的用以上的產品立刻建立一個新的補貨單嗎？',
  'replenishment_orders.remove-container-confirm': '真的想移取這個箱 - {container_box_code} 嗎?',
  'replenishment_orders.details.delivery_note_code': '出貨單號 (DN)',
  'replenishment_orders.print_picked_item_pdf': '列印揀貨產品 PDF',
  'replenishment_orders.details.max_request_qty': '最大請求數量',
  'replenishment_orders.movement.success': '移動區域成功',
  'replenishment_orders.title.replenishment_order': '補貨單',
  'replenishment_orders.title.move_temp_zone': '從臨時區域移庫',
  'replenishment_orders.title.create_replenishment_order_from_normal_zone.title': '從正貨區補貨',
  'replenishment_orders.title.create_replenishment_order_from_normal_zone': '新增補貨產品',
  'replenishment_orders.title.immediate_return.title': '立刻返回已取消的訂單到 AMR',
  'replenishment_orders.title.immediate_return': '回單到 AMR',
  'replenishment_orders.scan.checking.success': '檢查出貨單成功',

  'replenishment.tab.receive_to_amr.title': 'AMR 區域接收',
  'replenishment.tab.receive_to_amr': 'AMR 區域接收',
  'replenishment.tab.requested_item.title': '請求新的補貨的單',
  'replenishment.tab.requested_item': '請求新的補貨的單',
  'replenishment.tab.products': '產品',
  'replenishment.to_amr_zone': '到 AMR 區域',
  'replenishment.pending_movement_table_title': '待移區',
  'replenishment.container_box_scanned_duplicate': '{container_box} 已經掃描過了',
  'replenishment_orders.create.success': '已成功加入所選產品來準備補貨',
  'replenishment_orders.return_cancel_dn_table.title': '返回已取消的 DN 到 AMR',
  'replenishment_orders.return_dn_duplicate_scan': '{dn_number} 已經掃描過了',
  'replenishment_orders.picking_item.table.title': '揀撰的產品',
  'replenishment_orders.picking.success': '揀貨成功',
  'replenishment_orders.scan.container.success': '驗証箱號成功',
  'replenishment_orders.amr.awaiting_receive.title': '待接收箱子',
  'replenishment_orders.submit_receiving_to_amr.success': '接收箱子成功',
  'replenishment_orders.amr.select_item_to_replenish': '建立補貨單中...',
  'replenishment_orders.amr.request_replenishment_in_different_zone': '產品存放於不同的倉庫區域',
  'replenishment_orders.amr.request_item_to_replenish_fail': '選取產品來補貨失敗',
  'replenishment_orders.cancel-confirm': '真的想取消這個補貨單 - {document_no} 嗎?',
  'replenishment_orders.allocate-confirm': '真的想於補貨單 - {document_no} 配貨嗎?',
  'replenishment_orders.release-confirm':
    '真的想於釋放於補貨單 - {document_no} 中還沒有揀貨的產品嗎?',
  'replenishment_orders.cancellation.fail': '取消補貨單失敗',
  'replenishment_orders.allocation.fail': '配貨失敗',
  'replenishment_orders.release.fail': '釋放產品失敗',
  'replenishment_orders.request_item_picked': '新增補貨單',
  'replenishment_orders.request-item-picked-confirm': '真的要建立一個新的補貨單嗎？',
  'replenishment_orders.return.success': '已成功發送回貨單',
  'replenishment_orders.create.all_valid_sku_is_added':
    '這個產品 SKU 的所有類別都已全部增加在下面的列表中，如果想更改請求數量，請先在下表移除該產品',
  'replenishment_orders.details.request_qty.is_required': '補貨的請求數量是必填的',
  'replenishment_orders.details.request_qty.more_than_zero': '補貨的請求數量必須大於 0',
  'replenishment.remove-requested-item': '你真的要移取所選的補貨產品嗎？',
  'button.replenishment.allocate': '配貨',
  'button.replenishment.release': '釋放',
  'button.replenishment.request_item_create_only': '移到準備補貨產品',
  'button.replenishment.immedate_create_replenishment_order': '立刻建立新的補貨單',
  'replenishment_orders.allocation.info.label': '配貨訊息',
  'replenishment_orders.possible.blocking.allocation.dn.label': '可能鎖住箱子的 DN',
  'replenishment_orders.number_of_skus_label':
    '共有 {number_of_skus} SKU (不同的產品類別示為另一個SKU)',
};
