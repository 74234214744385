import { request } from 'umi';
import type { CreateFormValueType } from './components/CreateForm';
import type { WarehouseBillTableListParams } from './data';

const { JOR_A_WMS_WAREHOUSE_BILL_URL } = API;

export async function getWarehouseBills(params: WarehouseBillTableListParams | undefined) {
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getWarehouseBillDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getWarehouseBillOptions() {
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/options`, {
    method: 'GET',
  });
}

export async function createWarehouseBill(params: CreateFormValueType) {
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function cancelWarehouseBill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function pushWarehouseRentBillJob(params: { id: number; action: string }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/push/job`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function completeRequireNote(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/require/note`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function completeWarehouseBill(params: { id: number; note: string }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/complete`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function confirmWarehouseBill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/confirm`, {
    method: 'POST',
  });
}

export async function completedWarehouseBill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/complete`, {
    method: 'POST',
  });
}

// export async function downloadWarehouseBillPDF(params: { id: number }) {
//   const { id } = params;
//   return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/download/pdf`, {
//     method: 'GET',
//     responseType: 'blob',
//   });
// }

export async function downloadWarehouseBillReport(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/download/bill/report`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadWarehouseBillErrorSkuReport(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${id}/download/error/sku/report`, {
    method: 'GET',
    getResponse: true,
    responseType: 'blob',
  });
}

export async function getWarehouseBillInboundChargeItems(
  billId: number,
  params: WarehouseBillTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${billId}/inbound/items`, {
    method: 'GET',
    params,
  });
}

export async function getWarehouseBillOutboundChargeItems(
  billId: number,
  params: WarehouseBillTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${billId}/outbound/items`, {
    method: 'GET',
    params,
  });
}

export async function getWarehouseBillRentBillItems(
  billId: number,
  params: WarehouseBillTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_WAREHOUSE_BILL_URL}/${billId}/rent/items`, {
    method: 'GET',
    params,
  });
}
