export default {
  'binlocks.item.no': '#',
  'binlocks.request_code': '設定#',
  'binlocks.request_name': '設定名稱',
  'binlocks.remark': '備註',
  'binlocks.created_by_username': '創建人',
  'binlocks.activated_at': '啟用於',
  'binlocks.activated_by_username': '啟用人',
  'binlocks.closed_at': '關閉於',
  'binlocks.closed_by_username': '關閉人',
  'binlocks.creation_date': '建立日期',
  'binlocks.create': '新增架位鎖設定',
  'binlocks.create.placeholder.bin': '架位 [通配符(*)搜尋]',
  'binlocks.create.tab.search': '搜尋',
  'binlocks.create.tab.items': '架位鎖項目 ({lock_item_count})',
  'binlocks.create.button.copyto': '加入草稿',
  'binlocks.create.button.clear-draft': '[清除草稿]',
  'binlocks.create.button.create-binlock': '建立架位鎖設定',
  'binlocks.create.warning.no-item-insert': '請先選擇需要*上鎖*的架位',
  'binlocks.create.warning.pls-input-aisle-range': '請輸入通道範圍',
  'binlocks.create.warning.pls-input-bay-range': '請輸入間隔範圍',
  'binlocks.create.message.saved-to-draft': '{diff_count} 記錄已新增至草稿',
  'binlocks.warehouse_zone_code': '倉區',
  'binlocks.warehouse_bin_code': '架位',
  'binlocks.label.warehouse_bin_zone': '區',
  'binlocks.label.warehouse_bin_aisle_code': '通道',
  'binlocks.label.warehouse_bin_bay_code': '間隔',
  'binlocks.label.warehouse_bin_level_code': '層',
  'binlocks.label.warehouse_bin_position_code': '位',
  'binlocks.update': '更新',
  'binlocks.activate-confirm': '您確定要啟用此架位鎖設定嗎？',
  'binlocks.close-confirm': '您確定要關閉此架位鎖設定嗎？',
  'binlocks.placeholder.search-by-bin': '架位（僅搜尋「準備/已啟動」狀態下的請求）',
  'binlocks.warning.search-by-bin.min-character': '搜尋最少4個字符',
};
