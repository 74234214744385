export default {
  'pickup-requests.id': 'ID',
  'pickup-requests.title': 'Pickup Request List',
  'pickup-requests.batch_id': 'Batch ID',
  'pickup-requests.batch_request_number': 'Batch Number',
  'pickup-requests.asn_number': 'ASN Number',
  'pickup-requests.delivery_type': 'Type',
  'pickup-requests.pickup_address': 'Pickup Address',
  'pickup-requests.delivery_address': 'Delivery Address',
  'pickup-requests.pickup_delivery_address': 'Pickup / Delivery Address',
  'pickup-requests.company_name': 'Company Name',
  'pickup-requests.country_code': 'Country Code',
  'pickup-requests.city': 'City',
  'pickup-requests.state': 'State',
  'pickup-requests.contact_person_name': 'Contact Person',
  'pickup-requests.contact_phone': 'Contact Phone',
  'pickup-requests.delivery_contact_person': 'Delivery Contact Person',
  'pickup-requests.delivery_contact_phone': 'Delivery Contact Phone',
  'pickup-requests.postal_code': 'Postal Code',
  'pickup-requests.request_inbound_num': 'Request Inbound Number',
  'pickup-requests.request_packages': 'Request Packages',
  'pickup-requests.request_collection_date': 'Delivery / Collection Date',
  'pickup-requests.request_collection_time': 'Ready Time',
  'pickup-requests.pickup_note': 'Pickup Note',
  'pickup-requests.shipment_scan_date': 'Scanned Date',
  'pickup-requests.inbound_num': 'Inbound Number',
  'pickup-requests.action': 'Action',
  'pickup-requests.message.address': 'Please enter pickup address',
  'pickup-requests.message.delivery_type': 'Please choose Pickup Or Delivery',
  'pickup-requests.message.batch_id': 'Please select available batch id',
  'pickup-requests.message.request_collection_date': 'Please select collection date',
  'pickup-requests.message.request_collection_time': 'Please select available collection time',
  'pickup-requests.batch.title': 'Create Schedule Collection',
  'pickup-requests.type.pickup': 'Request LME Pickup',
  'pickup-requests.type.delivery': 'Delivery to Warehouse',
  'pickup-requests.update': 'Update Pickup Schedule',
  'pickup-requests.update.success': 'Pickup schedule has been succesfully updated ',
  'pickup-requests.create.fail': 'Fail to create pickup schedule',
  'pickup-requests.create.success': 'Pickup schedule has been succesfully created',
  'pickup-requests.update.fail': 'Fail to update pickup schedule',
  'pickup-requests.cancel-confirm': 'Are you sure to cancel this request?',
};
