import { request } from 'umi';
import type { InboundOrderItemFormDataType, InboundOrderTableListParams } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';
import type { UpdateTrackingFormValueType } from './components/UpdateTrackingForm';

const { JOR_WMS_INBOUND_ORDER_URL } = API;

export async function getInboundOrders(params: InboundOrderTableListParams | undefined) {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function exportInboundOrders(params: InboundOrderTableListParams | undefined) {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/export`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}

export async function getInboundOrderOptions() {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function getInboundOrderSerialNumbers(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/serial/numbers`, {
    method: 'GET',
  });
}

export async function getInboundOrderDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getInboundOrderGroupItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/group/items`, {
    method: 'GET',
  });
}

export async function getInboundOrderPackingList(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/packing/list`, {
    method: 'GET',
  });
}

export async function getInboundOrderBoxItems(params: { id: string }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/box/items`, {
    method: 'GET',
  });
}

export async function getInboundOrderPalletBoxes(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/pallet/boxes`, {
    method: 'GET',
  });
}

export async function createInboundOrder(params: CreateFormValueType) {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateInboundOrder(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function updateInboundOrderTracking(params: UpdateTrackingFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/tracking`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function createInboundOrderItems(id: number, params: InboundOrderItemFormDataType) {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function dispatchInboundOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/dispatch`, {
    method: 'POST',
  });
}

export async function cancelInboundOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/cancel/order`, {
    method: 'POST',
  });
}

export async function confirmInboundSortingReport(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/confirm/sorting`, {
    method: 'POST',
  });
}

export async function getInboundAvailablePutawayItems(params: { id: number; sku: string }) {
  const { id, sku } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/available/putaway/${sku}`, {
    method: 'GET',
  });
}

export async function getInboundAllSortingItemList(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/sorting/report/items`, {
    method: 'GET',
  });
}

export async function getInboundSortingGroupItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/sorting/report/group`, {
    method: 'GET',
  });
}

export async function downloadInboundOrderPDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadInboundOrderPod(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/download/pod`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadInboundBoxTemplate() {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/download/box/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function uploadToCreateInboundOrderItems(id: number, formData: FormData) {
  return request(`${JOR_WMS_INBOUND_ORDER_URL}/${id}/upload/boxes`, {
    method: 'POST',
    data: formData,
  });
}
