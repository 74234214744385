export default {
  'pages.layouts.userLayout.title': 'LM EXP - Ship',
  'pages.login.accountLogin.tab': '登入',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password（admin/ant.design)',
  'pages.login.failure': '登入失敗!',
  'pages.login.username.placeholder': '請輸入你的登入電郵',
  'pages.login.username.required': '請輸入你的登入電郵',
  'pages.login.password.placeholder': '請輸入你的密碼',
  'pages.login.password.required': '請輸入你的密碼',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': '登入',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',

  'pages.searchTable.chosen': '揀選了',
  'pages.searchTable.item': '項目',
};
