export default {
  'warehouse_bin_size.name': 'Bin Size Name',
  'warehouse_bin_size.length': 'Bin Length',
  'warehouse_bin_size.width': 'Bin Width',
  'warehouse_bin_size.height': 'Bin Height',
  'warehouse_bin_size.create': 'Create Bin Size',
  'warehouse_bin_size.create.fail': 'Fail to create bin size',
  'warehouse_bin_size.create.success': 'Bin size has been succesfully created',
  'warehouse_bin_size.update.fail': 'Fail to update bin size',
  'warehouse_bin_size.update.success': 'Bin size has been succesfully updated',
};
