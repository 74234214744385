export default {
  'marketplace_category.marketplace': 'Marketplace',
  'marketplace_category.name': 'Category Name',
  'marketplace_category.description': 'Description',
  'marketplace_category.commission': 'Commission',
  'marketplace_category.refurbished_commission': 'Refurbished Commission',
  'marketplace_category.parent': 'Parent Category',
  'marketplace_category.remark': 'Remark',
  'marketplace_category.status': 'Status',
  'marketplace_category.create': 'Create Marketplace Category',
  'marketplace_category.create.fail': 'Fail to create marketplace category',
  'marketplace_category.create.success': 'Marketplace Category has been succesfully created',
  'marketplace_category.update.fail': 'Fail to update marketplace category',
  'marketplace_category.update': 'Update',
  'marketplace_category.update.success': 'Marketplace Category has been succesfully updated',
};
