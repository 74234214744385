import { request } from 'umi';
import type {
  CreateReplenishmentFormType,
  ReplenishmentOrderTableListParams,
  ReturnToCreateReplenishmentFormType,
} from './data';
import type { PickingReplenishmentFormType } from './picking';
import type { ReceivedReplenishmentFormType } from './received';

const {
  JOR_A_WMS_REPLENISHMENT_ORDER_URL,
  JOR_A_WMS_AMR_REPLENISHMENT_URL,
  JOR_A_WMS_WAREHOUSE_TROLLEY_URL,
  JOR_A_WMS_DELIVERY_NOTE_URL,
  JOR_A_WMS_REPLENISHMENT_REQUEST_ITEM_URL,
} = API;

export async function getReplenishmentOrders(
  params: ReplenishmentOrderTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getReplenishmentOrderOptions() {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function getReplenishmentOrderDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getReplenishmentOrderPallets(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/${id}/pallets`, {
    method: 'GET',
  });
}

export async function getReplenishmentOrderItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/${id}/items`, {
    method: 'GET',
  });
}

export async function getReplenishmentRequestItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/request/items/detail/${id}`, {
    method: 'GET',
  });
}

export async function createReplenishmentOrder(params: CreateReplenishmentFormType) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkPickingReplenishmentCode(code: string) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/check/picking/${code}`, {
    method: 'GET',
  });
}

export async function scanContainerToPickingReplenishment(params: {
  container_box_code: string;
  replenishment_order_id: number;
}) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/scan/pick/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function scanContainerToReceivedReplenishment(containerCode: string) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/received/container/${containerCode}`, {
    method: 'GET',
  });
}

export async function submitReplenishmentReceivedContainer(params: ReceivedReplenishmentFormType) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/received/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

// export async function scanContainerToSubmitPicking(params: PickingReplenishmentFormType) {
//   return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/picking`, {
//     method: 'POST',
//     data: {
//       ...params,
//     },
//   });
// }

export async function submitPickingRequestItem(params: PickingReplenishmentFormType) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/picking/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function downloadReplenishmentOrderPDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function cancelReplenishmentOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/${id}/cancel/order`, {
    method: 'POST',
  });
}

export async function allocateReplenishmentOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/allocate/${id}`, {
    method: 'POST',
  });
}

export async function releaseReplenishmentOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/${id}/release/order`, {
    method: 'POST',
  });
}

export async function cancelReplenishmentOrderPallet(params: { palletId: number }) {
  const { palletId } = params;
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/pallet/${palletId}/cancel`, {
    method: 'POST',
  });
}

export async function getAmrReplenishmentPallets(
  params: ReplenishmentOrderTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_AMR_REPLENISHMENT_URL}/pallets`, {
    method: 'GET',
    params,
  });
}

export async function getAmrReplenishmentItems(
  params: ReplenishmentOrderTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_AMR_REPLENISHMENT_URL}/items`, {
    method: 'GET',
    params,
  });
}

export async function getAmrReplenishmentOptions() {
  return request(`${JOR_A_WMS_AMR_REPLENISHMENT_URL}/options`, {
    method: 'GET',
  });
}

export async function checkWarehouseTrolleyCode(params: { trolley_code: string }) {
  return request(`${JOR_A_WMS_WAREHOUSE_TROLLEY_URL}/check/code`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function returnPendingCancelToAmrReplenishment(
  params: ReturnToCreateReplenishmentFormType,
) {
  return request(`${JOR_A_WMS_REPLENISHMENT_ORDER_URL}/return/create`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkPendingCancelOutboundProducts(code_or_tracking_no: string) {
  return request(`${JOR_A_WMS_DELIVERY_NOTE_URL}/check/pending/cancel/${code_or_tracking_no}`, {
    method: 'GET',
  });
}

export async function getAmrReplenishemntRequestItems(
  params: ReplenishmentOrderTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_REPLENISHMENT_REQUEST_ITEM_URL}/item/with/no/replenishment/order`, {
    method: 'GET',
    params,
  });
}

export async function postPickReplenishemntRequestItems(params: {
  ids: number[];
  warehouse_zone_id: number;
}) {
  return request(`${JOR_A_WMS_REPLENISHMENT_REQUEST_ITEM_URL}/pick/request`, {
    method: 'POST',
    data: {
      ...params,
    },
    getResponse: true,
    responseType: 'blob',
  });
}

export async function downloadPickedRequestItems(id: number) {
  return request(`${JOR_A_WMS_REPLENISHMENT_REQUEST_ITEM_URL}/pick/${id}/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function removeReplenishmentRequestItem(ids: number[]) {
  return request(`${JOR_A_WMS_REPLENISHMENT_REQUEST_ITEM_URL}/remove/item`, {
    method: 'POST',
    data: {
      id: ids,
    },
  });
}
