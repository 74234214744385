export default {
  'inventory-batch-adjusment.create': 'Create Inventory Batch Adjustment',
  'inventory-batch-adjusment.auto_designated': 'Auto Designated',
  'inventory-batch-adjusment.manually_designated': 'Manually Designated',
  'inventory-batch-adjusment.update_title': 'Update {document_no}',
  'inventory-batch-adjusment.details.item_no': 'Item No.',
  'inventory-batch-adjusment.details.merchant': 'Merchant',
  'inventory-batch-adjusment.details.product_sku': 'Product SKU',
  'inventory-batch-adjusment.details.product_name': 'Product Name',
  'inventory-batch-adjusment.details.batch_manage_type': 'Batch Manage Type',
  'inventory-batch-adjusment.details.sku_level': 'SKU Level',
  'inventory-batch-adjusment.details.production_date': 'Production Date',
  'inventory-batch-adjusment.details.expiry_date': 'Expiry Date',
  'inventory-batch-adjusment.details.input_date': 'Input Date',
  'inventory-batch-adjusment.details.bin_code': 'Bin Code',
  'inventory-batch-adjusment.details.adjustment_num': 'Adjustment Number',
  'inventory-batch-adjusment.details.target_merchant': 'Target Merchant',
  'inventory-batch-adjusment.details.target_sku_level': 'Targe SKU Level',
  'inventory-batch-adjusment.details.target_production_date': 'Target Production Date',
  'inventory-batch-adjusment.details.target_expiry_date': 'Target Expiry Date',
  'inventory-batch-adjusment.details.target_input_date': 'Target Input Date',
  'inventory-batch-adjusment.details.remark': 'Remark',
  'inventory-batch-adjusment.details.create': 'Create Batch Adjustment Item',
  'inventory-batch-adjusment.details.delete-confirm':
    'Are you sure to delete this adjustment item?',
};
