export default {
  'binlocks.item.no': '#',
  'binlocks.request_code': 'Request Code',
  'binlocks.request_name': 'Request Name',
  'binlocks.remark': 'Remark',
  'binlocks.created_by_username': 'Created By',
  'binlocks.activated_at': 'Activated At',
  'binlocks.activated_by_username': 'Activated By',
  'binlocks.closed_at': 'Close At',
  'binlocks.closed_by_username': 'Closed By',
  'binlocks.creation_date': 'Creation Date',
  'binlocks.create': 'Create Bin Locks',
  'binlocks.create.placeholder.bin': 'Bin (wildcast by *)',
  'binlocks.create.tab.search': 'Searching',
  'binlocks.create.tab.items': 'Bin Lock Items ({lock_item_count})',
  'binlocks.create.button.copyto': 'Add to Draft',
  'binlocks.create.button.clear-draft': '[Clear Draft]',
  'binlocks.create.button.create-binlock': 'Create Bin Locks',
  'binlocks.create.warning.no-item-insert': 'Please select bins that require to lock first',
  'binlocks.create.warning.pls-input-aisle-range': 'Please input aisle range',
  'binlocks.create.warning.pls-input-bay-range': 'Please input bay range',
  'binlocks.create.message.saved-to-draft': '{diff_count} record(s) added into draft',
  'binlocks.warehouse_zone_code': 'Whs. Zone',
  'binlocks.warehouse_bin_code': 'Bin Code',
  'binlocks.label.warehouse_bin_zone': 'Bin Zone',
  'binlocks.label.warehouse_bin_aisle_code': 'Aisle',
  'binlocks.label.warehouse_bin_bay_code': 'Bay',
  'binlocks.label.warehouse_bin_level_code': 'Level',
  'binlocks.label.warehouse_bin_position_code': 'Position',
  'binlocks.update': 'Update',
  'binlocks.activate-confirm': 'Are you sure you want to activate this bin lock setting?',
  'binlocks.close-confirm': 'Are you sure you want to close this bin lock setting?',
  'binlocks.placeholder.search-by-bin':
    'Bin Code (Search request in Prepare/Activated status only)',
  'binlocks.warning.search-by-bin.min-character': 'Minimum 4 characters for search',
};
