export default {
  'inbound_orders.batch': '批次',
  'inbound_orders.warehouse': '倉庫',
  'inbound_orders.merchant': '貨主',
  'inbound_orders.inbound_type': '入庫類別',
  'inbound_orders.asn_code': 'ASN 號碼',
  'inbound_orders.skip_scan_serial_number': '跳過掃描 S/N',
  'inbound_orders.total_weight': '總重量 (KG)',
  'inbound_orders.total_volume': '總體積',
  'inbound_orders.total_cost': '總價格',
  'inbound_orders.total_box': '總箱數',
  'inbound_orders.total_pallet': '總板數',
  'inbound_orders.customer_order_no': '客戶訂單號',

  'inbound_orders.inbound_method': '入庫方式',
  'inbound_orders.shipping_mode': '運送模式',
  'inbound_orders.freight_method': '航運方式',
  'inbound_orders.estimated_arrival_time': '預計送達日期',
  'inbound_orders.channel_code': '服務商',
  'inbound_orders.tack_nos': '追蹤號',
  'inbound_orders.estimated_arrival_date_from': '預計到達日期 - 從',
  'inbound_orders.estimated_arrival_date_to': '預計到達日期 - 至',
  'inbound_orders.received_date': '收貨日期',
  'inbound_orders.received_date_from': '收貨日期 - 從',
  'inbound_orders.received_date_to': '收貨日期 - 至',
  'inbound_orders.po_number': '採購單號',
  'inbound_orders.received_date_at': '入庫單收貨日期',
  'inbound_orders.item.expiry_date': '過期日',
  'inbound_orders.create': '建立入庫單',
  'inbound_orders.create.fail': '建立入庫單失敗',
  'inbound_orders.create.success': '入庫單建立成功',
  'inbound_orders.update.fail': '更新入庫單失敗',
  'inbound_orders.update': '更新',
  'inbound_orders.update.success': '更新入庫單成功',
  'inbound_orders.update_dimension.fail': '更新三邊碼失敗',
  'inbound_orders.form_item.title': '產品資料',
  'inbound_orders.carton_no': '箱號',
  'inbound_orders.product': '產品',
  'inbound_orders.search_product': '搜尋產品',
  'inbound_orders.product_name': '產品名稱',
  'inbound_orders.item.qty': '箱內的產品量',
  'inbound_orders.item.box_qty': '箱數',
  'inbound_orders.pallet_package': '卡板',
  'inbound_orders.pallet_package.title': '卡板資料',
  'inbound_orders.pallet_package.length': '長',
  'inbound_orders.pallet_package.width': '闊',
  'inbound_orders.pallet_package.height': '高',
  'inbound_orders.pallet_package.weight': '重量(KG)',
  'inbound_orders.pallet_no': '卡板號',
  'inbound_orders.dimension_detail': '長x闊x高(CM)	',
  'inbound_orders.pallet.box_qty': '箱數',
  'inbound_orders.pallet.associated_box_number': '對應的箱號',
  'inbound_orders.upload_file_name': '上傳檔案名稱',
  'inbound_orders.upload_receiving_report': '上傳收貨報告',
  'inbound_orders.put_away.title': '上架的 SKU {sku}',
  'inbound_orders.total_product_qty': '總預期數量',
  'inbound_orders.details.items': '貨物資料',
  'inbound_orders.details.sku': '產品號碼 SKU',
  'inbound_orders.details.serial_number': '序列號 S/N',
  'inbound_orders.details.serial_numbers': '序列號 S/N(s)',
  'inbound_orders.details.sorting_report': '分揀報告',
  'inbound_orders.details.sorting_items': '分揀箱',
  'inbound_orders.details.product_name': '產品名稱',
  'inbound_orders.details.product_category': '產品類別',
  'inbound_orders.details.product_qty': '預期數量',
  'inbound_orders.details.expected_qty': '預期數量',
  'inbound_orders.details.product_actual_qty': '實數數量',
  'inbound_orders.details.product_normal_qty': '好貨數量',
  'inbound_orders.details.put_away_qty': '上架數量',
  'inbound_orders.details.production_date': '生產日期',
  'inbound_orders.details.expiry_date': '到期日期',
  'inbound_orders.details.system_expire_date': '系統假定的過期日',
  'inbound_orders.details.country_of_origin': '產地 (COO)',
  'inbound_orders.details.product_damage_qty': '壞貨數量',
  'inbound_orders.details.difference': '相差',
  'inbound_orders.details.product_shortage_qty': '少收數量',
  'inbound_orders.details.product_more_qty': '多收數量',
  'inbound_orders.details.production_batch_code': '批次號',
  'inbound_orders.details.pending_put_away_qty': '待上架數量',
  'inbound_orders.details.packing_list': '包裝資料',
  'inbound_orders.details.pallet_no': '板數',
  'inbound_orders.details.carton_no': '箱數',
  'inbound_orders.details.print_normal_qty': '列印正貨數量',
  'inbound_orders.details.print_damage_qty': '列印壞貨數量',
  'inbound_orders.details.month': '月',

  'inbound_orders.external_warehouse_bin_code': '外部 WMS 架位',
  'inbound_orders.dispatch-inbound-confirm': '真的想發出這個 ASN - {document_no}?',
  'inbound_orders.received-confirm': '真的想確認收取這個單號 - {document_no}?',
  'inbound_orders.update_title': '更新 ({merchant}) 的 {asn_number} ',
  'inbound_orders.action.put_away': '現在上架',
  'inbound_orders.search_by_sku': '以 SKU 來搜尋',
  'inbound_orders.action.update_dimension': '更新產品三邊碼',
  'inbound_orders.put_away.leave-confirm': '真的要離開這個頁面嗎？',
  'inbound_orders.upload_receiving_title': '上傳收貨結果',
  'inbound_orders.receiving_report_items': '收貨報告',
  'inbound_orders.details.receive_date': '收貨日期',
  'inbound_orders.details.total_scanned_qty': '已分揀數量',
  'inbound_orders.confirm-receiving': 'Pending Confirm Upload Receiving Report to put away',
  'inbound_orders.search_pending_asn': 'Please Search ASN (Dispatched Or Pending Confirm)',
  'inbound_orders.scan_asn_code': '掃描 ASN 號碼',
  'inbound_orders.scan_sku_barcode': '掃描 SKU 或 其他條碼',
  'inbound_orders.scan_barcode': '掃描條碼',
  'inbound_orders.scan_asn_code.no_data': '這個 {asn_code} 不該做分揀，請檢查你揀選的 ASN',
  'inbound_orders.scan_asn_code.no_data_putaway': '入庫單 - {asn_code} 沒有可上架的資料',
  'inbound_orders.received-to-sort-tooltip': '先收貨再分揀',
  'inbound_orders.received-to-sort': '收貨 -> 分揀',
  'inbound_orders.received-to-sort-confirm': '真的要收取這個入庫單 - {document_no} 以進行分揀?',
  'inbound_orders.require_sorting_qty': '待分揀數量',
  'inbound_orders.actual_sorting_qty': '已分揀數量',
  'inbound_orders.sorting_item': '分揀',
  'inbound_orders.sorting_print_label': '封箱',
  'inbound_orders.sorting.item_title': '分揀 SKU / Barcode {sku}',
  'inbound_orders.sorting.cancel': '取消',
  'inbound_orders.sorting.submit': '發送',
  'inbound_orders.sorting.sorting_normal': '分揀 - 好貨',
  'inbound_orders.sorting.sorting_damage': '分揀 - 壞貨',
  'inbound_orders.print.inbound_label_title': '列印封箱標籤',
  'inbound_orders.print.item_title': '列印產品 SKU 條',
  'inbound_orders.details.print_bar_code': '列印條碼',
  'inbound_orders.scan_bin': '掃描架位',
  'inbound_sorting_reports.submitted_date_at': '發送日期',
  'inbound_sorting_reports.submitted_by': '發送人',
  'inbound_sorting_reports.confirmed_date_at': '確認日期',
  'inbound_sorting_reports.confirmed_by': '確認人',
  'inbound_sorting_reports.remark': '備註',
  'inbound_orders.show_sorting_items_title': '{document_no} 分揀報告',
  'inbound_sorting_reports.show-sorting-items': '顯示分揀報告',
  'inbound_orders.scan_container_box_code': '掃描箱號',
  'inbound_orders.put_away_item': '上架 (單件)',
  'inbound_orders.put_away_container': '上架 (箱)',
  'inbound_orders.received-to-sort-modal-title': '登記收貨資料 ASN - {document_no}',
  'inbound_orders.driver_name': '司機名稱',
  'inbound_orders.driver_identity_card': '司機身份證號號',
  'inbound_orders.driver_license_plate': '車牌',
  'inbound_orders.driver_phone': '司機電話',
  'inbound_orders.ready_sorting_product': '分揀的產品',
  'inbound_orders.sorting.not_match_sku':
    '你所掃描的 SKU - {scan_sku} 與所需要的 SKU - {require_sku} 對不上',
  'inbound_orders.sorting.not_allow_overship': '你所掃描的 SKU - {scan_sku} 不允許多收, 請查看',
  'inbound_orders.confirm-sorting-items': '要確認 ASN - {document_no} 的分揀報表並發送給客戶嗎?',
  'inbound_orders.scan_asn_code.success': '成功驗證 ASN',
  'inbound_orders.scan_container_code.success': '檢測箱號完成',
  'inbound_orders.details.length': '長',
  'inbound_orders.details.width': '闊',
  'inbound_orders.details.height': '高',

  'inbound_orders.tab.asn': '入庫清單 (ASN)',
  'inbound_orders.tab.sorting': '分揀',
  'inbound_orders.tab.put-away': '上架',
  'inbound_orders.tab.sorting-report': '分揀報告',
  'inbound_orders.tab.upload-receiving': '上傳收貨結果',
  'inbound_orders.button.generate-receiving-report': '生成收貨報告',
  'inbound_orders.button.generate-registration': '生成登記表',
  'inbound_orders.button.download-file': '下載檔案',
  'inbound_orders.button.excel': 'Excel 報表',
  'inbound_orders.message.generate-registration-success': '成功生成登記表',
  'inbound_orders.message.generate-registration-fail': '生成登記表失敗',
  'inbound_orders.sorting.sorted_items': '已分揀的產品',
  'inbound_orders.sorting.inbound-items': '{document_no} 產品列表',
  'inbound_orders.sorting.complete-sorting': '完成分揀',
  'inbound_orders.sorting.un-complete-sorting': '撤銷完成了的分揀',
  'inbound_orders.sorting.un-receive-product': '未到貨',
  'inbound_orders.sorting.current_box_scanned': '箱子中已掃描的數量 - Subtotal',
  'inbound_orders.sorting.total_product_scanned': '產品已掃描的總數量 - Grand Total',
  'inbound_orders.put-away.pending-item-table.title': '待上架 - 單件',
  'inbound_orders.put-away.pending-container-table.title': '待上架 - 箱',
  'inbound_orders.confirm-and-send-sorting-report-message': '已將分揀報告發送到客戶!',
  'inbound_orders.go-to.button': '轉到 - {asn_code} 頁面',
  'inbound_orders.sorting.message.please-scan-the-product': '請掃描產品條碼',

  'inbound_orders.cancel-confirm': '真的要取消 ASN - {document_no} 嗎?',
  'inbound_orders.export_to_excel': '匯出至 Excel',
  'inbound_orders.update_tracking_info': '更新追蹤訊息',
  'inbound_orders.button.edit_tracking': '修改追蹤訊息',
  'inbound_orders.select_carton': '選取箱號去關聯板號',
  'inbound_orders.confirm-sorting-confirm': '要確認 ASN - {document_no} 的分揀報表?',
  'inbound_orders.confirm-sorting.button': '確認分揀',
  'inbound_orders.sorting-item.confirm.message': '正在確認分揀 ...',
  'inbound_orders.sorting-item.confirm.fail': '確認分揀失敗',
  'inbound_orders.sorting-item.confirm.tooltips': '確認分揀',
  'inbound_orders.putaway.success': '已成功上架',
  'inbound_orders.sorting.verifying_pin': '驗証 Pin 碼中...',
  'inbound_orders.sorting.fail_to_do_pin_exchange': 'Pin 碼轉換失敗',
  'inbound_orders.delete-container-sorting-confirm':
    '你真的想要移除這個已經分揀的 {container_box_code} 箱子嗎?',
  'inbound_orders.delete-container-sorting': '移除箱子 {container_box_code}, 重新分揀',
  'inbound_orders.scan.asn_code_not_match':
    '{document_no} 與待分揀的入庫單號不一致,請重新掃描入庫單號',
  'inbound_orders.total_scanned_box': '總掃描箱數',
};
