export default {
  'user.sub-contractor': 'Sub Contractor',
  'user.name': 'Name',
  'user.short-name': 'Initial',
  'user.email': 'Email',
  'user.email-address': 'Email Address',
  'user.initial': 'Initial',
  'user.job_title': 'Job Title',
  'user.office_tel': 'Office Tel.',
  'user.mobile_tel': 'Mobile Tel.',
  'user.password': 'Password',
  'user.email-language': 'Invitation Email Language',
  'user.invite': 'Invite',
  'user.resend-invitation-email': 'Resend Invitation Email',
  'user.resend-success': 'Resend Success',
  'user.email-confirmed-status': 'Email Confirmed',
  'user.confirm-password': 'Confirm Password',
  'user.role-label': 'Roles',
  'user.invite-user': 'Invite User',
  'user.setting': 'Setting for -',
  'user.please-input-email': 'Please Input Email',
  'user.please-input-username': 'Please Input UserName',
  'user.please-input-user-short-name': 'Please Input Initial',
  'user.user-title': 'Title',
  'user.user-title.mr': 'Mr',
  'user.user-title.ms': 'Ms',
  'user.user-title.mrs': 'Mrs',
  'user.user-job-title': 'Job Title',
  'user.telephone': 'Telephone',
  'user.mobile-phone': 'Mobile Phone',
  'user.office-telephone': 'Office Telephone',
  'user.project': 'Project',
  'user.please-select-project': 'Please Select Project',
  'user.Role': 'Role',
  'user.client': 'Client',
  'user.role.view-permission': 'Check User Permissions',
  'user.view-permission': 'User Permissions',
  'user.please-select-role': 'Please Select Role',
  'user.please-select-functional-groups': 'Please Select Functional Groups',
  'user.identification-no': 'Identification No.',
  'user.status': 'Status',
  'user.please-input-tel': 'Please Input Office Tel.',
  'user.please-input-mobile-tel': 'Please Input Mobile Tel.',
  'user.please-input-identification-no': 'Please Input Identification No.',
  'user.please-input-password-more-than': 'Please Input Password at least 6 character',
  'user.delete-confirm': 'Are you sure to disconnect this user?',
  'user.email-confirmed-status.active': 'Confirmed',
  'user.email-confirmed-status.inactive': 'Pending',
  'user.status.inactive': 'Inactive',
  'user.status.active': 'Active',
  'user.tab.project-setting': 'Project Settings',
  'user.exists-warning-title': 'Invite user',
  'user.exists-warning-ok-button-text': 'Yes, Invite',
  'user.suppliers': 'Suppliers',
  'user.create.fail': 'Fail to create user',
  'user.create.success': 'User has been successfully invited',
  'user.update.fail': 'Fail to update user',
  'user.update.success': 'User has been successfully updated',
};
