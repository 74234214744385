export default {
  'warehouses.name': 'Name',
  'warehouses.warehouse_code': 'Warehouse Code',
  'warehouses.district': 'District',
  'warehouses.country': 'Country',
  'warehouses.contact_person': 'Contact Person',
  'warehouses.contact': 'Contact',
  'warehouses.address': 'Address',
  'warehouses.email': 'E-mail',
  'warehouses.create': 'Create Warehouse',
  'warehouses.create.fail': 'Fail to create warehouse',
  'warehouses.create.success': 'Warehouse has been succesfully created',
  'warehouses.update.fail': 'Fail to update warehouse',
  'warehouses.update.success': 'Warehouse has been succesfully updated',
  'warehouses.cut_off_time': 'Cut-Off Time (Cancel)',
  'warehouses.menuMap.basic': 'General Info',
  'warehouses.menuMap.provider': 'WMS Provider',
  'warehouses.action.add-new-provider': 'Add New WMS Provider',
  'warehouses.menuMap.provider-user': 'WMS Provider Users',
  'warehouses.menuMap.container': 'WMS Containers',
  'warehouses.menuMap.carrier': 'WMS Carriers',
  'warehouses.action.add-new-user': 'Add New WMS Provider User',
  'warehouses.delete-user.warning': 'Are you sure to delete this WMS provider user?',
  'warehouses.action.add-new-carrier': 'Add New WMS Carrier',
  'warehouses.delete-carrier.warning': 'Are you sure to delete this WMS carrier?',
  'warehouses.action.add-new-container': 'Add New WMS Container',
  'warehouses.delete-container.warning': 'Are you sure to delete this WMS container?',
  'warehouses.basic.update': 'Update',
  'warehouses.basic.add_provider': 'Add Provider',
  'warehouses.wms_provider': 'WMS Provider',
  'warehouses.wms_provider_code': 'WMS Provider Code',
  'warehouses.provider.primary_name': 'Primary Name',
  'warehouses.provider.api_warehouse_code': 'API Warehouse Code',
  'warehouses.warehouse_zone_code': 'Zone Code',
  'warehouses.provider.api_owner_code': 'API Owner Code',
  'warehouses.provider.api_user_id': 'API User Id',
  'warehouses.provider.api_user_key': 'API User Key',
  'warehouses.provider.api_url': 'API URL',
  'warehouses.provider.webhook_secret': 'Webhook Secrect',
  'warehouses.provider_user.user_name': 'User Name',
  'warehouses.provider_user.password': 'Password',
  'warehouses.provider_user.status': 'Status',
  'warehouses.container.container_code': 'Container Code',
  'warehouses.container.use_type': 'Use Type',
  'warehouses.container.occupy_status': 'Occupy Status',
  'warehouses.container.container_type': 'Container Type',
  'warehouses.container.remark': 'Remark',
  'warehouses.container.status': 'Status',
  'warehouses.container.download_selected': 'Downlaod selected',
  'warehouses.container.download-selected-confirm':
    'Are you sure you want to download selected wms container barcode?',
  'warehouses.carrier.carrier_code': 'Carrier Code',
  'warehouses.carrier.carrier_name': 'Carrier Name',
  'warehouses.carrier.warehouse_carrier_abbr': 'Warehouse Carrier Abbr',
  'warehouses.carrier.carrier_abbr': 'Carrier Abbr',
  'warehouses.carrier.contact_person': 'Contact Person',
  'warehouses.carrier.fax': 'Fax',
  'warehouses.carrier.email': 'E-mail',
  'warehouses.carrier.contact_tel': 'Contact Tel',
  'warehouses.carrier.country': 'Country',
  'warehouses.carrier.province': 'State',
  'warehouses.carrier.city': 'City',
  'warehouses.carrier.addr': 'Address',
  'warehouses.carrier.zip_code': 'Postal Code',
  'warehouses.carrier.require_console': 'Require Console',
  'warehouses.carrier.console_max_weight': 'Console Max Weight(G)',
  'warehouses.carrier.picking_remark': 'Picking Remark',
  'warehouses.carrier.packed_remark': 'Packed Remark',
  'warehouses.carrier.dispatched_remark': 'Dispatched Remark',
  'warehouses.carrier.remark': 'Remark',
  'warehouses.carrier.status': 'Status',
  'warehouses.carrier-cutoff.edit-title': 'Edit WMS Carrier Cut Off',
  'warehouses.carrier-cutoff.edit-btn-tips': 'Edit Cut Off',
  'warehouses.carrier-cutoff.invalid-cut-off-format': 'Only allow HH:MM or 1-6 characters long',
  'warehouses.carrier-cutoff.duplicated-cut-off': 'Duplicated Cut Off',
  'warehouses.carrier-cutoff.cut-off-placeholder': 'Cut Off',
};
