export default {
  'tools.menu.offline_inbound': 'Offline Inbound',
  'tools.menu.offline_inbound.tooltip': 'Print Offline Inbound Carton Label',
  'tools.print_contianer.print_inbound_box': 'Print Inbound Box',
  'tools.print_contianer.print_outbound_box': 'Print Outbound Box',
  'tools.print_contianer.generate_qty': 'Generate Qty',
  'tools.print_contianer.generate-container': 'Generate Container Code',
  'tools.print_contianer.only_one_sku': '1 Carton 1 SKU',
  'tools.print_contianer.unsealed_inbound_box': 'Un-used Inbound Box',
  'tools.print_contianer.sealed_inbound_box': 'Used Inbound Box',
  'tools.print_trolley.generate_qty': 'Generate Qty',
  'tools.print_trolley.generate-trolley': 'Generate Trolley Code',
  'tools.please-input': 'Please Input ',
  'tools.please-select': 'Please Select ',
  'tools.print_label': 'Print Label',
  'tools.offline_inbound.title': 'Print Offline Inbound Label',
  'tools.offline_inbound.box_id': 'Box ID',
  'tools.offline_inbound.use_type': 'Use Type',
  'tools.offline_inbound.warehouse': 'Warehouse',
  'tools.offline_inbound.customer_order_no': 'PO No.',
  'tools.offline_inbound.product': 'Product',
  'tools.offline_inbound.product_category': 'Product Category',
  'tools.offline_inbound.production_batch_code': 'Production Batch Code',
  'tools.offline_inbound.production_date': 'MFG Date',
  'tools.offline_inbound.expiry_date': 'Expiry Date',
  'tools.offline_inbound.product_qty': 'Expected Qty',
  'tools.offline_inbound.scan_barcode': 'Scan Barcode',
  'tools.use_type_normal': 'Normal',
  'tools.use_type_damage': 'Damage',
  'tools.offline-inbound.box_id': 'Box ID',
  'tools.offline_inbound.month': 'Month',
  'tools.offline_inbound.net_weight': 'Net Weight',
  'tools.offline_inbound.gross_weight': 'Gross Weight',
  'tools.offline_inbound.gross_weight_valid': 'Gross Weight require more than 0',
  'tools.offline_inbound.require_add_product':
    'There is not found product match for Barcode - {bar_code}, please check',
  'tools.offline_inbound.length': 'Length',
  'tools.offline_inbound.width': 'Width',
  'tools.offline_inbound.height': 'Height',
  'tools.offline_inbound.valid_month': 'Shelf Life(Month)',
  'tools.print_contianer.print_container_code': 'Print Container Code',
  'tools.download-selected-container-confirm':
    'Are you sure you want to download the selected container(s) barcode?',
  'tools.download-selected-container-button': 'Download the selected container(s) barcode',
};
