export default {
  'pick_pack_vas_flow.platform_code': 'Platform Code',
  'pick_pack_vas_flow.platform_name': 'Platform Name',
  'pick_pack_vas_flow.step_one_name': 'Step 1 Dispaly Name',
  'pick_pack_vas_flow.step_two_name': 'Step 2 Dispaly Name',
  'pick_pack_vas_flow.step_three_name': 'Step 3 Dispaly Name',
  'pick_pack_vas_flow.step_four_name': 'Step 4 Dispaly Name',
  'pick_pack_vas_flow.step_five_name': 'Step 5 Dispaly Name',
  'pick_pack_vas_flow.vas_name': 'VAS Dispaly Name',
  'pick_pack_vas_flow.vas_label_name': 'VAS Dispaly Name (Include Label)',
  'pick_pack_vas_flow.create': 'Create Pick & Pack VAS',
  'pick_pack_vas_flow.create.fail': 'Fail to create Pick & Pack VAS',
  'pick_pack_vas_flow.create.success': 'Pick & Pack VAS has been succesfully created',
  'pick_pack_vas_flow.update.fail': 'Fail to update warehouse bin',
  'pick_pack_vas_flow.update': 'Update {company_name} Pick & Pack VAS',
  'pick_pack_vas_flow.update.success': 'Pick & Pack VAS has been succesfully updated',
};
