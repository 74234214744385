import { request } from 'umi';
import type { DeliveryBookingTableListParams } from './data';

const { LME_A_OMS_DELIVERY_BOOKING_URL, LME_A_OMS_DELIVERY_ORDER_URL } = API;

export async function queryDeliveryBooking(params: DeliveryBookingTableListParams | undefined) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getDeliveryBookingOptions() {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/options`, {
    method: 'GET',
  });
}

export async function getDeliveryBookingDetail(params: { id: number }) {
  const { id } = params;
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/${id}`, {
    method: 'GET',
  });
}

export async function cancelDeliveryBooking(id: number) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/cancel/${id}`, {
    method: 'POST',
  });
}

export async function cancelDeliveryBookingItem(id: number, itemId: number) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/cancel/${id}/item/${itemId}`, {
    method: 'POST',
  });
}

export async function prepareDeliveryBooking(id: number) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/prepare/${id}`, {
    method: 'POST',
  });
}

export async function prepareDeliveryBookingList(params: { delivery_booking_ids: number[] }) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/prepare-bookings`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function scanBookingNoToPrepare(bookingNo: string) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/scan/${bookingNo}/to-prepare`, {
    method: 'GET',
  });
}

export async function getByDeliveryBookingNoToScan(bookingNo: string) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/scan/${bookingNo}/to-relabel`, {
    method: 'GET',
  });
}

export async function scanTrackingNoToDispatch(trackingNo: string) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/scan/${trackingNo}/to-dispatch`, {
    method: 'GET',
  });
}

export async function scanAndRelabelDeliveryBookingItem(srTrackingNo: string) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/scan-item/${srTrackingNo}`, {
    method: 'POST',
  });
}

export async function dispatchDeliveryBooking(id: number) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/dispatch/${id}`, {
    method: 'POST',
  });
}

export async function dispatchDeliveryBookingItems(params: {
  delivery_booking_item_ids: number[];
}) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/dispatch-items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function downloadDeliveryBookingNote(id: number) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/${id}/download-pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getDeliveryBookingItems(
  bookingId: number,
  params: DeliveryBookingTableListParams | undefined,
) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/${bookingId}/items`, {
    method: 'GET',
    params,
  });
}

export async function queryBookingRequireManifest(
  params: DeliveryBookingTableListParams | undefined,
) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/require-add-manifest`, {
    method: 'GET',
    params,
  });
}

export async function adminDownloadBookingCourierManifest(bookingId: number) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/${bookingId}/download-courier-manifest`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function queryBookingyRequireLastmile(
  params: DeliveryBookingTableListParams | undefined,
) {
  return request(`${LME_A_OMS_DELIVERY_BOOKING_URL}/require-lastmile`, {
    method: 'GET',
    params,
  });
}

export async function getDeliveryOrderOptions() {
  return request(`${LME_A_OMS_DELIVERY_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function retryDeliveryManifest(deliveryOrderId: number) {
  return request(`${LME_A_OMS_DELIVERY_ORDER_URL}/retry-send-manifest/${deliveryOrderId}`, {
    method: 'POST',
  });
}
