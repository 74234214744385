import { request } from 'umi';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';
import type {
  AvailableBookingTableListParams,
  DeliveryBookingItemFormDataType,
  DeliveryBookingTableListParams,
} from './data';

const { LME_OMS_DELIVERY_BOOKING_URL } = API;

export async function queryDeliveryBooking(params: DeliveryBookingTableListParams | undefined) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getDeliveryBookingOptions() {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/options`, {
    method: 'GET',
  });
}

export async function getDeliveryBookingDetail(params: { id: number }) {
  const { id } = params;
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}`, {
    method: 'GET',
  });
}

export async function queryDeliveryBookingOptions(id: number) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}/edit`, {
    method: 'GET',
  });
}

export async function createDeliveryBooking(params: CreateFormValueType) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateDeliveryBooking(params: UpdateFormValueType) {
  const { id, ...rest } = params;
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...rest,
    },
  });
}

export async function cancelDeliveryBooking(id: number) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/cancel/${id}`, {
    method: 'POST',
  });
}

export async function downloadDeliveryBookingNote(id: number) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}/download-pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getDeliveryBookingItems(
  bookingId: number,
  params: DeliveryBookingTableListParams | undefined,
) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${bookingId}/items`, {
    method: 'GET',
    params,
  });
}

export async function uploadDeliveryBookingItems(id: number, formData: FormData) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}/upload-items`, {
    method: 'POST',
    data: formData,
  });
}

export async function downloadDeliveryBookingTemplate() {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/download/booking-item-template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function createDeliveryBookingItems(
  id: number,
  params: DeliveryBookingItemFormDataType,
) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}/items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function searchAvailableBookingDeliveryOrder(
  params: AvailableBookingTableListParams | undefined,
) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/available-booking`, {
    method: 'GET',
    params,
  });
}

export async function deleteDeliveryBookingItem(id: number, itemId: number) {
  return request(`${LME_OMS_DELIVERY_BOOKING_URL}/${id}/delete-item/${itemId}`, {
    method: 'POST',
  });
}
