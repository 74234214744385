export default {
  'delivery_notes.batch': 'Batch',
  'delivery_notes.warehouse': 'Warehouse',
  'delivery_notes.merchant_name': 'Merchant Name',
  'delivery_notes.delivery_note_code': 'DN No.',
  'delivery_notes.total_weight': 'Total Weight(KG)',
  'delivery_notes.total_volume': 'Total Volume(CBM)',
  'delivery_notes.total_cost': 'Total Cost',
  'delivery_notes.reference_no': 'Reference No.',
  'delivery_notes.back_order': 'Back Order',
  'delivery_notes.require_courier_invoice': 'Require Invoice',
  'delivery_notes.status': 'Status',
  'delivery_notes.upload_waybill_title': 'Upload {document_no} Waybill File',
  'delivery_notes.upload_waybill': 'Upload Waybill',
  'delivery_notes.download_waybill': 'Download Waybill',
  'delivery_notes.upload_invoice_title': 'Upload {document_no} Invoice File',
  'delivery_notes.upload_invoice': 'Upload Invoice',
  'delivery_notes.download_invoice': 'Download Invoice',
  'delivery_notes.create': 'Create Outbound Order',
  'delivery_notes.create.fail': 'Fail to create outbound order',
  'delivery_notes.create.success': 'Outbound Order has been succesfully created',
  'delivery_notes.update.fail': 'Fail to update outbound order',
  'delivery_notes.update': 'Update',
  'delivery_notes.update.success': 'Outbound Order has been succesfully updated',
  'delivery_notes.form_item.title': 'Item Details',
  'delivery_notes.product': 'Product',
  'delivery_notes.search_product': 'Search Product',
  'delivery_notes.item.no': 'No.',
  'delivery_notes.sku': 'SKU Code',
  'delivery_notes.product_name': 'Product Name',
  'delivery_notes.item.qty': 'Item Qty',
  'delivery_notes.item.order_qty': 'Order Qty',
  'delivery_notes.item.pre_pick_qty': 'PrePick Qty',
  'delivery_notes.item.picked_qty': 'Picked Qty',
  'delivery_notes.item.declared_price': 'Declared Value',
  'delivery_notes.item.currency': 'Currency',
  'delivery_notes.release-selected-confirm':
    'Are you sure you want to release selected DN to picking list?',
  'delivery_notes.release_selected': 'Release Selected',
  'delivery_notes.confirm_to_new': 'Confirm To New',
  'delivery_notes.confirm-to-new-confirm':
    'Are you sure you want to confirm this {document_no} to new order?',
  'delivery_notes.release_to_picklist': 'Release To Picklist',
  'delivery_notes.release-confirm':
    'Are you sure you want to release this {document_no} to picking list?',
  'delivery_notes.confirm_picked': 'Confirm Picked',
  'delivery_notes.confirm-picked-confirm':
    'Are you sure you want to confirm this {document_no} picked?',
  'delivery_notes.dispatch_shipping': 'Dispatch Shipping',
  'delivery_notes.dispatch-shipping-confirm':
    'Are you sure you want to dispatch this {document_no} shpping?',
  'delivery_notes.cancel-confirm': 'Are you sure to cancel this {document_no} delivery note?',
  'delivery_notes.return': 'Return',
  'delivery_notes.return-confirm': 'Are you sure to return this {document_no} delivery note?',
  'delivery_notes.download-selected-confrim': 'Are you sure to download selected outbound orders?',
  'delivery_notes.download_selected': 'Download Selected',
  'delivery_notes.export_to_excel': 'Export your data to excel',
  'delivery_notes.download': 'Download',
  'delivery_notes.show_tracking': 'Show Tracking',
  'delivery_notes.asn': 'ASN',
  'delivery_notes.delivery_info': 'Delivery Info',
  'delivery_notes.delivery_company': 'Company Name',
  'delivery_notes.delivery_name': 'Contact Name',
  'delivery_notes.delivery_email': 'Contact Email',
  'delivery_notes.address': 'Address',
  'delivery_notes.address1': 'Address1',
  'delivery_notes.address2': 'Address2',
  'delivery_notes.country': 'Country',
  'delivery_notes.city': 'City',
  'delivery_notes.state': 'State',
  'delivery_notes.postal': 'Postal',
  'delivery_notes.phone': 'Phone',
  'delivery_notes.item.request_asn_code': 'Request ASN / RMA',
};
