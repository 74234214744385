import { request } from 'umi';
import type { SupplierTableListParams } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';

const { LME_PRODUCT_SUPPLIER_URL } = API;

export async function getSuppliers(params: SupplierTableListParams | undefined) {
  return request(`${LME_PRODUCT_SUPPLIER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getAllActiveSuppliers() {
  return request(`${LME_PRODUCT_SUPPLIER_URL}/all/active`, {
    method: 'GET',
  });
}

export async function getSupplierDetail(id: number) {
  return request(`${LME_PRODUCT_SUPPLIER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createSupplier(params: CreateFormValueType) {
  return request(`${LME_PRODUCT_SUPPLIER_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateSupplier(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${LME_PRODUCT_SUPPLIER_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function getSuppleirContacts(params: { id: number }) {
  const { id } = params;
  return request(`${LME_PRODUCT_SUPPLIER_URL}/${id}/contacts`, {
    method: 'GET',
    params,
  });
}

export async function getSuppleirBanks(params: { id: number }) {
  const { id } = params;
  return request(`${LME_PRODUCT_SUPPLIER_URL}/${id}/banks`, {
    method: 'GET',
    params,
  });
}
