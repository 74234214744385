export default {
  'account.settings.menuMap.basic': '基本設置',
  'account.settings.menuMap.password': '更改密碼',
  'account.settings.menuMap.company': '公司資料',
  'account.settings.menuMap.signature': '簽名',
  'account.settings.basic.email': '電郵',
  'account.settings.basic.displayname': '用戶名',
  'account.settings.basic.displayname-message': '請輸入用戶名',
  'account.settings.basic.short-message': '請輸入簡簽',
  'account.settings.basic.first-name': '名字',
  'account.settings.basic.last-name': '姓氏',
  'account.settings.basic.job_title': '職位',
  'account.settings.basic.office_tel': '電話',
  'account.settings.basic.tel': '電話',
  'account.settings.basic.tel-message': '請輸入電話',
  'account.settings.basic.job-title': '請輸入職位',
  'account.settings.basic.transaction-email': '交易電子郵件和更新',
  'account.settings.basic.update': '更新信息',
  'account.settings.password.old-password': '現在的密碼',
  'account.settings.password.old-password-message': '請輸入現在的密碼',
  'account.settings.password.new-password': '新密碼',
  'account.settings.password.confirm-password': '確認密碼',
  'account.settings.password.update': '更新密碼',
  'account.settings.signature.update': '更新',
  'account.settings.signature.edit': '編輯',
  'account.settings.signature.clear': '清空',
  'account.settings.signature.please-input': '請簽名',
  'account.settings.signature.full-signature-title': '簽名',
  'account.settings.signature.short-signature-title': '簡式簽名',
  'account.settings.signature.empty-ignature': '尚未簽名',
  'account.settings.menuMap.notification': '電子郵件通知',
  'company.primary_name': '名稱',
  'company.secondary_name': '次名稱',
  'company.chop': '公司印章',
  'company.logo': '公司Logo',
  'company.address': '地址',
  'company.tel': '固定電話',
  'company.br': 'BR',

  'company.please_enter.primary_name': '請輸入公司的主要名稱',
  'company.please_enter.secondary_name': '請輸入公司的副名',
  'company.please_enter.address': '請輸入公司地址',
};
