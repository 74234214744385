import type { Effect, Reducer } from 'umi';

import type { BinLockTableListDataType } from './data';
import { getBinLockDetails } from './service';

export interface StateType {
  binLock?: BinLockTableListDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    getBinLockDetails: Effect;
  };
  reducers: {
    saveBinLock: Reducer<StateType>;
    clearBinLock: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'wmsBinLockModel',
  state: {
    binLock: undefined,
  },
  effects: {
    *getBinLockDetails({ payload }, { call, put }) {
      const response = yield call(getBinLockDetails, payload);
      yield put({
        type: 'saveBinLock',
        payload: response.data,
      });
    },
  },

  reducers: {
    saveBinLock(state, action) {
      return {
        ...state,
        binLock: action.payload,
      };
    },
    clearBinLock() {
      return {
        binLock: undefined,
      };
    },
  },
};

export default Model;
