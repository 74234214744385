import { queryDeliveryLabelStatistic } from '@/services/label';
import type { Effect, Reducer } from 'umi';

export interface StateType {
  requiredOptions?: RequiredLabelOptions;
  selectedQuote?: SelectQuoteFormDataType;
  deliveryStatistic?: {
    total_pending: number;
    total_rejected: number;
  };
}

export interface SelectQuoteFormDataType {
  ship_from: string;
  warehouse_id: string;
  service_code: string;
  delivery_country_code: string;
  delivery_state_code: string;
  postal_code: string;
  service_type: string;
  battery_type: string;
  incoterm: string;
  insurance: number;
  weight: number;
  length: number;
  width: number;
  height: number;
  customs_currency: string;
  customs_value?: number;
}

export interface RequiredLabelOptions {
  exportImport: number;
  declaredCurrency: string;
  maxPackageNumber: number;
  wmsMerchantCode: string;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    setRequiredOptions: Effect;
    setSelectedQuote: Effect;
    getDeliveryStatistic: Effect;
  };
  reducers: {
    saveRequiredOption: Reducer<StateType>;
    saveSelectedQuote: Reducer<StateType>;
    saveDeliveryStatistic: Reducer<StateType>;
    clear: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'labelModel',

  state: {
    requiredOptions: undefined,
    selectedQuote: undefined,
    deliveryStatistic: undefined,
  },

  effects: {
    *setRequiredOptions({ payload }, { put }) {
      yield put({
        type: 'saveRequiredOption',
        payload,
      });
    },
    *getDeliveryStatistic({ payload }, { call, put }) {
      const response = yield call(queryDeliveryLabelStatistic, payload);
      yield put({
        type: 'saveDeliveryStatistic',
        payload: response.data,
      });
    },
    *setSelectedQuote({ payload, callback }, { put }) {
      yield put({
        type: 'saveSelectedQuote',
        payload,
      });
      if (callback) callback();
    },
  },

  reducers: {
    saveRequiredOption(state, action) {
      return {
        ...state,
        requiredOptions: {
          ...state?.requiredOptions,
          ...action.payload,
        },
      };
    },
    saveDeliveryStatistic(state, action) {
      return {
        ...state,
        deliveryStatistic: action.payload,
      };
    },
    clear(state) {
      return {
        selectedQuote: undefined,
        requiredOptions: undefined,
        deliveryStatistic: undefined,
        wmsMerchantCode: undefined,
      };
    },
    saveSelectedQuote(state, action) {
      return {
        ...state,
        selectedQuote: action.payload,
      };
    },
  },
};

export default Model;
