export default {
  'warehouse_zone.warehouse_zone_name': '區域名稱',
  'warehouse_zone.warehouse_zone_code': '區域碼',
  'warehouse_zone.zone_property': '區域屬性',
  'warehouse_zone.zone_type': '區域類型',
  'warehouse_zone.picking_priority': '揀貨優先級',
  'warehouse_zone.create': '新增倉庫區域',
  'warehouse_zone.create.fail': '建立倉庫區域失敗',
  'warehouse_zone.create.success': '倉庫區域已成功建立',
  'warehouse_zone.update.fail': '更新倉庫區失敗',
  'warehouse_zone.update.success': '成功更新倉庫區域',
  'warehouse_zone.download_selected': '下載所選條碼',
  'warehouse_zone.download-selected-confirm': '你真的想下載所選取的倉庫區域條碼嗎?',
};
