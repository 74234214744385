export default {
  'calculator_profit_job.batch_no': 'Batch No.',
  'calculator_profit_job.marketplace_name': 'Marketplace Name',
  'calculator_profit_job.payment_gateway': 'Payment Gateway',
  'calculator_profit_job.marketplace_category_name': 'Marketplace Category Name',
  'calculator_profit_job.supplier_name': 'Supplier Name',
  'calculator_profit_job.supplier-cost-history':
    'Batch {batch_no} Uploaded Supplier Cost Histories',
  'calculator_profit_job.show_upload_details': 'Show Batch Upload Supplier Costs',
  'calculator_profit_job.event_type': 'Event Type',
  'calculator_profit_job.impacted_product_list': 'Batch {batch_no} Impacted Product List',
  'calculator_profit_job.show_impacted_product_list': 'Show Impacted Product List',
  'calculator_profit_job.from': 'From',
  'calculator_profit_job.to': 'To',
  'calculator_profit_job.from_commission': 'From Commission',
  'calculator_profit_job.to_commission': 'To Commission',
  'calculator_profit_job.status': 'Status',
};
