// @ts-nocheck
import {
  createIntl,
  IntlShape,
  MessageDescriptor,
} from '/var/www/chunsing-app-ui/node_modules/@umijs/plugin-locale/node_modules/react-intl';
import { ApplyPluginsType } from 'umi';
import { event, LANG_CHANGE_EVENT } from './locale';
// @ts-ignore
import warning from '/var/www/chunsing-app-ui/node_modules/@umijs/plugin-locale/node_modules/warning/warning.js';

import { plugin } from '../core/plugin';

export {
  createIntl,
};
export {
  FormattedDate,
  FormattedDateParts,
  FormattedDisplayName,
  FormattedHTMLMessage,
  FormattedList,
  FormattedMessage,
  FormattedNumber,
  FormattedNumberParts,
  FormattedPlural,
  FormattedRelativeTime,
  FormattedTime,
  FormattedTimeParts,
  IntlContext,
  IntlProvider,
  RawIntlProvider,
  createIntlCache,
  defineMessages,
  injectIntl,
  useIntl,
} from '/var/www/chunsing-app-ui/node_modules/@umijs/plugin-locale/node_modules/react-intl';

let g_intl: IntlShape;

const useLocalStorage = true;

import enUS0 from 'antd/es/locale/en_US';
import lang_enUS0 from "/var/www/chunsing-app-ui/src/locales/en-US.ts";
import lang_enUS1 from "/var/www/chunsing-app-ui/src/pages/admin-service/courier-services/locales/en-US.ts";
import lang_enUS2 from "/var/www/chunsing-app-ui/src/pages/admin-service/customer-rates/locales/en-US.ts";
import lang_enUS3 from "/var/www/chunsing-app-ui/src/pages/admin-shipment/delivery-booking/locales/en-US.ts";
import lang_enUS4 from "/var/www/chunsing-app-ui/src/pages/admin-shipment/shipment-tracking/locales/en-US.ts";
import lang_enUS5 from "/var/www/chunsing-app-ui/src/pages/courier-services/locales/en-US.ts";
import lang_enUS6 from "/var/www/chunsing-app-ui/src/pages/delivery-address-book/locales/en-US.ts";
import lang_enUS7 from "/var/www/chunsing-app-ui/src/pages/delivery-booking/locales/en-US.ts";
import lang_enUS8 from "/var/www/chunsing-app-ui/src/pages/delivery-note/locales/en-US.ts";
import lang_enUS9 from "/var/www/chunsing-app-ui/src/pages/inbound/locales/en-US.ts";
import lang_enUS10 from "/var/www/chunsing-app-ui/src/pages/inventory-batch-adjustment/locales/en-US.ts";
import lang_enUS11 from "/var/www/chunsing-app-ui/src/pages/invoice/locales/en-US.ts";
import lang_enUS12 from "/var/www/chunsing-app-ui/src/pages/marketplace/marketplace-category/locales/en-US.ts";
import lang_enUS13 from "/var/www/chunsing-app-ui/src/pages/marketplace/marketplace-payment/locales/en-US.ts";
import lang_enUS14 from "/var/www/chunsing-app-ui/src/pages/marketplace/order-fulfillment/locales/en-US.ts";
import lang_enUS15 from "/var/www/chunsing-app-ui/src/pages/marketplace/stores/locales/en-US.ts";
import lang_enUS16 from "/var/www/chunsing-app-ui/src/pages/merchant/locales/en-US.ts";
import lang_enUS17 from "/var/www/chunsing-app-ui/src/pages/outbound-console/locales/en-US.ts";
import lang_enUS18 from "/var/www/chunsing-app-ui/src/pages/payments/customer-payment-record/locales/en-US.ts";
import lang_enUS19 from "/var/www/chunsing-app-ui/src/pages/payments/topup/locales/en-US.ts";
import lang_enUS20 from "/var/www/chunsing-app-ui/src/pages/pickup-request/locales/en-US.ts";
import lang_enUS21 from "/var/www/chunsing-app-ui/src/pages/product-manage/calculator-profit-job/locales/en-US.ts";
import lang_enUS22 from "/var/www/chunsing-app-ui/src/pages/product-manage/currency/locales/en-US.ts";
import lang_enUS23 from "/var/www/chunsing-app-ui/src/pages/product-manage/exchange-rate/locales/en-US.ts";
import lang_enUS24 from "/var/www/chunsing-app-ui/src/pages/product-manage/product-category/locales/en-US.ts";
import lang_enUS25 from "/var/www/chunsing-app-ui/src/pages/product-manage/product-price/locales/en-US.ts";
import lang_enUS26 from "/var/www/chunsing-app-ui/src/pages/product-manage/product-supplier-cost/locales/en-US.ts";
import lang_enUS27 from "/var/www/chunsing-app-ui/src/pages/product-manage/products/locales/en-US.ts";
import lang_enUS28 from "/var/www/chunsing-app-ui/src/pages/product-manage/report/locales/en-US.ts";
import lang_enUS29 from "/var/www/chunsing-app-ui/src/pages/product-manage/suppliers/locales/en-US.ts";
import lang_enUS30 from "/var/www/chunsing-app-ui/src/pages/profile/settings/locales/en-US.ts";
import lang_enUS31 from "/var/www/chunsing-app-ui/src/pages/rma-order/locales/en-US.ts";
import lang_enUS32 from "/var/www/chunsing-app-ui/src/pages/settings/courier-platform-methods/locales/en-US.ts";
import lang_enUS33 from "/var/www/chunsing-app-ui/src/pages/settings/couriers/locales/en-US.ts";
import lang_enUS34 from "/var/www/chunsing-app-ui/src/pages/settings/customer-rates/locales/en-US.ts";
import lang_enUS35 from "/var/www/chunsing-app-ui/src/pages/settings/customer-spot-rates/locales/en-US.ts";
import lang_enUS36 from "/var/www/chunsing-app-ui/src/pages/settings/delivery-shippers/locales/en-US.ts";
import lang_enUS37 from "/var/www/chunsing-app-ui/src/pages/settings/service-costs/locales/en-US.ts";
import lang_enUS38 from "/var/www/chunsing-app-ui/src/pages/settings/service-spot-costs/locales/en-US.ts";
import lang_enUS39 from "/var/www/chunsing-app-ui/src/pages/settings/suppliers/locales/en-US.ts";
import lang_enUS40 from "/var/www/chunsing-app-ui/src/pages/settings/warehouses/locales/en-US.ts";
import lang_enUS41 from "/var/www/chunsing-app-ui/src/pages/stocktake/locales/en-US.ts";
import lang_enUS42 from "/var/www/chunsing-app-ui/src/pages/track-trace/locales/en-US.ts";
import lang_enUS43 from "/var/www/chunsing-app-ui/src/pages/User/login/locales/en-US.ts";
import lang_enUS44 from "/var/www/chunsing-app-ui/src/pages/warehouse-bill/locales/en-US.ts";
import lang_enUS45 from "/var/www/chunsing-app-ui/src/pages/warehouse-rent-bill-item/locales/en-US.ts";
import lang_enUS46 from "/var/www/chunsing-app-ui/src/pages/warehouse-rent-bill/locales/en-US.ts";
import lang_enUS47 from "/var/www/chunsing-app-ui/src/pages/warehouses/advance-shipment-notice/locales/en-US.ts";
import lang_enUS48 from "/var/www/chunsing-app-ui/src/pages/warehouses/gate-charge/locales/en-US.ts";
import lang_enUS49 from "/var/www/chunsing-app-ui/src/pages/warehouses/warehouse-scan/locales/en-US.ts";
import lang_enUS50 from "/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/inbound-charge/locales/en-US.ts";
import lang_enUS51 from "/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/locales/en-US.ts";
import lang_enUS52 from "/var/www/chunsing-app-ui/src/pages/wms/admin/client-webhook/locales/en-US.ts";
import lang_enUS53 from "/var/www/chunsing-app-ui/src/pages/wms/admin/company-wms-quotation/locales/en-US.ts";
import lang_enUS54 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-bin-size/locales/en-US.ts";
import lang_enUS55 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-bin/locales/en-US.ts";
import lang_enUS56 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-rent-bill-item/locales/en-US.ts";
import lang_enUS57 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-zone/locales/en-US.ts";
import lang_enUS58 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouses/locales/en-US.ts";
import lang_enUS59 from "/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/locales/en-US.ts";
import lang_enUS60 from "/var/www/chunsing-app-ui/src/pages/wms/bin-lock-setting/locales/en-US.ts";
import lang_enUS61 from "/var/www/chunsing-app-ui/src/pages/wms/dashboard/locales/en-US.ts";
import lang_enUS62 from "/var/www/chunsing-app-ui/src/pages/wms/drivers/locales/en-US.ts";
import lang_enUS63 from "/var/www/chunsing-app-ui/src/pages/wms/inbound/locales/en-US.ts";
import lang_enUS64 from "/var/www/chunsing-app-ui/src/pages/wms/inventory-bin/locales/en-US.ts";
import lang_enUS65 from "/var/www/chunsing-app-ui/src/pages/wms/inventory-cycle-count/locales/en-US.ts";
import lang_enUS66 from "/var/www/chunsing-app-ui/src/pages/wms/inventory/locales/en-US.ts";
import lang_enUS67 from "/var/www/chunsing-app-ui/src/pages/wms/locales/en-US.ts";
import lang_enUS68 from "/var/www/chunsing-app-ui/src/pages/wms/merchant/locales/en-US.ts";
import lang_enUS69 from "/var/www/chunsing-app-ui/src/pages/wms/outbound/locales/en-US.ts";
import lang_enUS70 from "/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/marketplace-pick-pack-vas/locales/en-US.ts";
import lang_enUS71 from "/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/marketplace/locales/en-US.ts";
import lang_enUS72 from "/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/pick-pack-vas-flow/locales/en-US.ts";
import lang_enUS73 from "/var/www/chunsing-app-ui/src/pages/wms/report/locales/en-US.ts";
import lang_enUS74 from "/var/www/chunsing-app-ui/src/pages/wms/rma-order/locales/en-US.ts";
import lang_enUS75 from "/var/www/chunsing-app-ui/src/pages/wms/tools/locales/en-US.ts";
import lang_enUS76 from "/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/locales/en-US.ts";
import lang_enUS77 from "/var/www/chunsing-app-ui/src/pages/wms/virtual-warehouse/locales/en-US.ts";
import zhTW0 from 'antd/es/locale/zh_TW';
import lang_zhTW0 from "/var/www/chunsing-app-ui/src/locales/zh-TW.ts";
import lang_zhTW1 from "/var/www/chunsing-app-ui/src/pages/admin-service/courier-services/locales/zh-TW.ts";
import lang_zhTW2 from "/var/www/chunsing-app-ui/src/pages/admin-service/customer-rates/locales/zh-TW.ts";
import lang_zhTW3 from "/var/www/chunsing-app-ui/src/pages/admin-shipment/delivery-booking/locales/zh-TW.ts";
import lang_zhTW4 from "/var/www/chunsing-app-ui/src/pages/admin-shipment/shipment-tracking/locales/zh-TW.ts";
import lang_zhTW5 from "/var/www/chunsing-app-ui/src/pages/courier-services/locales/zh-TW.ts";
import lang_zhTW6 from "/var/www/chunsing-app-ui/src/pages/delivery-address-book/locales/zh-TW.ts";
import lang_zhTW7 from "/var/www/chunsing-app-ui/src/pages/delivery-booking/locales/zh-TW.ts";
import lang_zhTW8 from "/var/www/chunsing-app-ui/src/pages/delivery-note/locales/zh-TW.ts";
import lang_zhTW9 from "/var/www/chunsing-app-ui/src/pages/inbound/locales/zh-TW.ts";
import lang_zhTW10 from "/var/www/chunsing-app-ui/src/pages/inventory-batch-adjustment/locales/zh-TW.ts";
import lang_zhTW11 from "/var/www/chunsing-app-ui/src/pages/invoice/locales/zh-TW.ts";
import lang_zhTW12 from "/var/www/chunsing-app-ui/src/pages/marketplace/marketplace-category/locales/zh-TW.ts";
import lang_zhTW13 from "/var/www/chunsing-app-ui/src/pages/marketplace/marketplace-payment/locales/zh-TW.ts";
import lang_zhTW14 from "/var/www/chunsing-app-ui/src/pages/marketplace/order-fulfillment/locales/zh-TW.ts";
import lang_zhTW15 from "/var/www/chunsing-app-ui/src/pages/marketplace/stores/locales/zh-TW.ts";
import lang_zhTW16 from "/var/www/chunsing-app-ui/src/pages/merchant/locales/zh-TW.ts";
import lang_zhTW17 from "/var/www/chunsing-app-ui/src/pages/outbound-console/locales/zh-TW.ts";
import lang_zhTW18 from "/var/www/chunsing-app-ui/src/pages/payments/customer-payment-record/locales/zh-TW.ts";
import lang_zhTW19 from "/var/www/chunsing-app-ui/src/pages/payments/topup/locales/zh-TW.ts";
import lang_zhTW20 from "/var/www/chunsing-app-ui/src/pages/pickup-request/locales/zh-TW.ts";
import lang_zhTW21 from "/var/www/chunsing-app-ui/src/pages/product-manage/calculator-profit-job/locales/zh-TW.ts";
import lang_zhTW22 from "/var/www/chunsing-app-ui/src/pages/product-manage/currency/locales/zh-TW.ts";
import lang_zhTW23 from "/var/www/chunsing-app-ui/src/pages/product-manage/exchange-rate/locales/zh-TW.ts";
import lang_zhTW24 from "/var/www/chunsing-app-ui/src/pages/product-manage/product-category/locales/zh-TW.ts";
import lang_zhTW25 from "/var/www/chunsing-app-ui/src/pages/product-manage/product-price/locales/zh-TW.ts";
import lang_zhTW26 from "/var/www/chunsing-app-ui/src/pages/product-manage/product-supplier-cost/locales/zh-TW.ts";
import lang_zhTW27 from "/var/www/chunsing-app-ui/src/pages/product-manage/products/locales/zh-TW.ts";
import lang_zhTW28 from "/var/www/chunsing-app-ui/src/pages/product-manage/report/locales/zh-TW.ts";
import lang_zhTW29 from "/var/www/chunsing-app-ui/src/pages/product-manage/suppliers/locales/zh-TW.ts";
import lang_zhTW30 from "/var/www/chunsing-app-ui/src/pages/profile/settings/locales/zh-TW.ts";
import lang_zhTW31 from "/var/www/chunsing-app-ui/src/pages/rma-order/locales/zh-TW.ts";
import lang_zhTW32 from "/var/www/chunsing-app-ui/src/pages/settings/courier-platform-methods/locales/zh-TW.ts";
import lang_zhTW33 from "/var/www/chunsing-app-ui/src/pages/settings/couriers/locales/zh-TW.ts";
import lang_zhTW34 from "/var/www/chunsing-app-ui/src/pages/settings/customer-rates/locales/zh-TW.ts";
import lang_zhTW35 from "/var/www/chunsing-app-ui/src/pages/settings/customer-spot-rates/locales/zh-TW.ts";
import lang_zhTW36 from "/var/www/chunsing-app-ui/src/pages/settings/delivery-shippers/locales/zh-TW.ts";
import lang_zhTW37 from "/var/www/chunsing-app-ui/src/pages/settings/service-costs/locales/zh-TW.ts";
import lang_zhTW38 from "/var/www/chunsing-app-ui/src/pages/settings/service-spot-costs/locales/zh-TW.ts";
import lang_zhTW39 from "/var/www/chunsing-app-ui/src/pages/settings/suppliers/locales/zh-TW.ts";
import lang_zhTW40 from "/var/www/chunsing-app-ui/src/pages/settings/warehouses/locales/zh-TW.ts";
import lang_zhTW41 from "/var/www/chunsing-app-ui/src/pages/stocktake/locales/zh-TW.ts";
import lang_zhTW42 from "/var/www/chunsing-app-ui/src/pages/track-trace/locales/zh-TW.ts";
import lang_zhTW43 from "/var/www/chunsing-app-ui/src/pages/User/login/locales/zh-TW.ts";
import lang_zhTW44 from "/var/www/chunsing-app-ui/src/pages/warehouse-bill/locales/zh-TW.ts";
import lang_zhTW45 from "/var/www/chunsing-app-ui/src/pages/warehouse-rent-bill-item/locales/zh-TW.ts";
import lang_zhTW46 from "/var/www/chunsing-app-ui/src/pages/warehouse-rent-bill/locales/zh-TW.ts";
import lang_zhTW47 from "/var/www/chunsing-app-ui/src/pages/warehouses/advance-shipment-notice/locales/zh-TW.ts";
import lang_zhTW48 from "/var/www/chunsing-app-ui/src/pages/warehouses/gate-charge/locales/zh-TW.ts";
import lang_zhTW49 from "/var/www/chunsing-app-ui/src/pages/warehouses/warehouse-scan/locales/zh-TW.ts";
import lang_zhTW50 from "/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/inbound-charge/locales/zh-TW.ts";
import lang_zhTW51 from "/var/www/chunsing-app-ui/src/pages/wms/admin/bill-manage/locales/zh-TW.ts";
import lang_zhTW52 from "/var/www/chunsing-app-ui/src/pages/wms/admin/client-webhook/locales/zh-TW.ts";
import lang_zhTW53 from "/var/www/chunsing-app-ui/src/pages/wms/admin/company-wms-quotation/locales/zh-TW.ts";
import lang_zhTW54 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-bin-size/locales/zh-TW.ts";
import lang_zhTW55 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-bin/locales/zh-TW.ts";
import lang_zhTW56 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-rent-bill-item/locales/zh-TW.ts";
import lang_zhTW57 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouse-zone/locales/zh-TW.ts";
import lang_zhTW58 from "/var/www/chunsing-app-ui/src/pages/wms/admin/warehouses/locales/zh-TW.ts";
import lang_zhTW59 from "/var/www/chunsing-app-ui/src/pages/wms/amr-replenishment/locales/zh-TW.ts";
import lang_zhTW60 from "/var/www/chunsing-app-ui/src/pages/wms/bin-lock-setting/locales/zh-TW.ts";
import lang_zhTW61 from "/var/www/chunsing-app-ui/src/pages/wms/dashboard/locales/zh-TW.ts";
import lang_zhTW62 from "/var/www/chunsing-app-ui/src/pages/wms/drivers/locales/zh-TW.ts";
import lang_zhTW63 from "/var/www/chunsing-app-ui/src/pages/wms/inbound/locales/zh-TW.ts";
import lang_zhTW64 from "/var/www/chunsing-app-ui/src/pages/wms/inventory-bin/locales/zh-TW.ts";
import lang_zhTW65 from "/var/www/chunsing-app-ui/src/pages/wms/inventory-cycle-count/locales/zh-TW.ts";
import lang_zhTW66 from "/var/www/chunsing-app-ui/src/pages/wms/inventory/locales/zh-TW.ts";
import lang_zhTW67 from "/var/www/chunsing-app-ui/src/pages/wms/locales/zh-TW.ts";
import lang_zhTW68 from "/var/www/chunsing-app-ui/src/pages/wms/merchant/locales/zh-TW.ts";
import lang_zhTW69 from "/var/www/chunsing-app-ui/src/pages/wms/outbound/locales/zh-TW.ts";
import lang_zhTW70 from "/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/marketplace-pick-pack-vas/locales/zh-TW.ts";
import lang_zhTW71 from "/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/marketplace/locales/zh-TW.ts";
import lang_zhTW72 from "/var/www/chunsing-app-ui/src/pages/wms/pick-pack-vas/pick-pack-vas-flow/locales/zh-TW.ts";
import lang_zhTW73 from "/var/www/chunsing-app-ui/src/pages/wms/report/locales/zh-TW.ts";
import lang_zhTW74 from "/var/www/chunsing-app-ui/src/pages/wms/rma-order/locales/zh-TW.ts";
import lang_zhTW75 from "/var/www/chunsing-app-ui/src/pages/wms/tools/locales/zh-TW.ts";
import lang_zhTW76 from "/var/www/chunsing-app-ui/src/pages/wms/transfer-pick-order/locales/zh-TW.ts";
import lang_zhTW77 from "/var/www/chunsing-app-ui/src/pages/wms/virtual-warehouse/locales/zh-TW.ts";

export const localeInfo: {[key: string]: any} = {
  'en-US': {
    messages: {
      ...lang_enUS0,...lang_enUS1,...lang_enUS2,...lang_enUS3,...lang_enUS4,...lang_enUS5,...lang_enUS6,...lang_enUS7,...lang_enUS8,...lang_enUS9,...lang_enUS10,...lang_enUS11,...lang_enUS12,...lang_enUS13,...lang_enUS14,...lang_enUS15,...lang_enUS16,...lang_enUS17,...lang_enUS18,...lang_enUS19,...lang_enUS20,...lang_enUS21,...lang_enUS22,...lang_enUS23,...lang_enUS24,...lang_enUS25,...lang_enUS26,...lang_enUS27,...lang_enUS28,...lang_enUS29,...lang_enUS30,...lang_enUS31,...lang_enUS32,...lang_enUS33,...lang_enUS34,...lang_enUS35,...lang_enUS36,...lang_enUS37,...lang_enUS38,...lang_enUS39,...lang_enUS40,...lang_enUS41,...lang_enUS42,...lang_enUS43,...lang_enUS44,...lang_enUS45,...lang_enUS46,...lang_enUS47,...lang_enUS48,...lang_enUS49,...lang_enUS50,...lang_enUS51,...lang_enUS52,...lang_enUS53,...lang_enUS54,...lang_enUS55,...lang_enUS56,...lang_enUS57,...lang_enUS58,...lang_enUS59,...lang_enUS60,...lang_enUS61,...lang_enUS62,...lang_enUS63,...lang_enUS64,...lang_enUS65,...lang_enUS66,...lang_enUS67,...lang_enUS68,...lang_enUS69,...lang_enUS70,...lang_enUS71,...lang_enUS72,...lang_enUS73,...lang_enUS74,...lang_enUS75,...lang_enUS76,...lang_enUS77,
    },
    locale: 'en-US',
    antd: {
      ...enUS0,
    },
    momentLocale: '',
  },
  'zh-TW': {
    messages: {
      ...lang_zhTW0,...lang_zhTW1,...lang_zhTW2,...lang_zhTW3,...lang_zhTW4,...lang_zhTW5,...lang_zhTW6,...lang_zhTW7,...lang_zhTW8,...lang_zhTW9,...lang_zhTW10,...lang_zhTW11,...lang_zhTW12,...lang_zhTW13,...lang_zhTW14,...lang_zhTW15,...lang_zhTW16,...lang_zhTW17,...lang_zhTW18,...lang_zhTW19,...lang_zhTW20,...lang_zhTW21,...lang_zhTW22,...lang_zhTW23,...lang_zhTW24,...lang_zhTW25,...lang_zhTW26,...lang_zhTW27,...lang_zhTW28,...lang_zhTW29,...lang_zhTW30,...lang_zhTW31,...lang_zhTW32,...lang_zhTW33,...lang_zhTW34,...lang_zhTW35,...lang_zhTW36,...lang_zhTW37,...lang_zhTW38,...lang_zhTW39,...lang_zhTW40,...lang_zhTW41,...lang_zhTW42,...lang_zhTW43,...lang_zhTW44,...lang_zhTW45,...lang_zhTW46,...lang_zhTW47,...lang_zhTW48,...lang_zhTW49,...lang_zhTW50,...lang_zhTW51,...lang_zhTW52,...lang_zhTW53,...lang_zhTW54,...lang_zhTW55,...lang_zhTW56,...lang_zhTW57,...lang_zhTW58,...lang_zhTW59,...lang_zhTW60,...lang_zhTW61,...lang_zhTW62,...lang_zhTW63,...lang_zhTW64,...lang_zhTW65,...lang_zhTW66,...lang_zhTW67,...lang_zhTW68,...lang_zhTW69,...lang_zhTW70,...lang_zhTW71,...lang_zhTW72,...lang_zhTW73,...lang_zhTW74,...lang_zhTW75,...lang_zhTW76,...lang_zhTW77,
    },
    locale: 'zh-TW',
    antd: {
      ...zhTW0,
    },
    momentLocale: 'zh-tw',
  },
};

/**
 * 增加一个新的国际化语言
 * @param name 语言的 key
 * @param messages 对应的枚举对象
 * @param extraLocale momentLocale, antd 国际化
 */
export const addLocale = (
  name: string,
  messages: Object,
  extraLocales: {
    momentLocale:string;
    antd:string
  },
) => {
  if (!name) {
    return;
  }
  // 可以合并
  const mergeMessages = localeInfo[name]?.messages
    ? Object.assign({}, localeInfo[name].messages, messages)
    : messages;


  const { momentLocale, antd } = extraLocales || {};
  const locale = name.split('-')?.join('-')
  localeInfo[name] = {
    messages: mergeMessages,
    locale,
    momentLocale: momentLocale,
    antd,
  };
   // 如果这是的 name 和当前的locale 相同需要重新设置一下，不然更新不了
  if (locale === getLocale()) {
    event.emit(LANG_CHANGE_EVENT, locale);
  }
};

/**
 * 获取当前的 intl 对象，可以在 node 中使用
 * @param locale 需要切换的语言类型
 * @param changeIntl 是否不使用 g_intl
 * @returns IntlShape
 */
export const getIntl = (locale?: string, changeIntl?: boolean) => {
  // 如果全局的 g_intl 存在，且不是 setIntl 调用
  if (g_intl && !changeIntl && !locale) {
    return g_intl;
  }
  // 如果存在于 localeInfo 中
  if (locale&&localeInfo[locale]) {
    return createIntl(localeInfo[locale]);
  }
  // 不存在需要一个报错提醒
  warning(
    !locale||!!localeInfo[locale],
    `The current popular language does not exist, please check the locales folder!`,
  );
  // 使用 zh-CN
  if (localeInfo["zh-TW"]) return createIntl(localeInfo["zh-TW"]);

  // 如果还没有，返回一个空的
  return createIntl({
    locale: "zh-TW",
    messages: {},
  });
};

/**
 * 切换全局的 intl 的设置
 * @param locale 语言的key
 */
export const setIntl = (locale: string) => {
  g_intl = getIntl(locale, true);
};

/**
 * 获取当前选择的语言
 * @returns string
 */
export const getLocale = () => {
  const runtimeLocale = plugin.applyPlugins({
    key: 'locale',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  // runtime getLocale for user define
  if (typeof runtimeLocale?.getLocale === 'function') {
    return runtimeLocale.getLocale();
  }
  // please clear localStorage if you change the baseSeparator config
  // because changing will break the app
  const lang =
    typeof localStorage !== 'undefined' && useLocalStorage
      ? window.localStorage.getItem('umi_locale')
      : '';
  // support baseNavigator, default true
  let browserLang;
  return lang || browserLang || "zh-TW";
};


/**
 * 获取当前选择的方向
 * @returns string
 */
export const getDirection = () => {
  const lang = getLocale();
  // array with all prefixs for rtl langueges ex: ar-EG , he-IL
  const rtlLangs = ['he', 'ar', 'fa', 'ku']
  const direction =  rtlLangs.filter(lng => lang.startsWith(lng)).length ? 'rtl' : 'ltr';
  return direction;
};

/**
 * 切换语言
 * @param lang 语言的 key
 * @param realReload 是否刷新页面，默认刷新
 * @returns string
 */
export const setLocale = (lang: string, realReload: boolean = true) => {
  const runtimeLocale = plugin.applyPlugins({
    key: 'locale',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });

  const updater = () => {
    if (getLocale() !== lang) {
      if (typeof window.localStorage !== 'undefined' && useLocalStorage) {
        window.localStorage.setItem('umi_locale', lang || '');
      }
      setIntl(lang);
      if (realReload) {
        window.location.reload();
      } else {
        event.emit(LANG_CHANGE_EVENT, lang);
        // chrome 不支持这个事件。所以人肉触发一下
        if (window.dispatchEvent) {
          const event = new Event('languagechange');
          window.dispatchEvent(event);
        }
      }
    }
  }

  if (typeof runtimeLocale?.setLocale === 'function') {
    runtimeLocale.setLocale({
      lang,
      realReload,
      updater: updater,
    });
    return;
  }

  updater();
};

let firstWaring = true;

/**
 * intl.formatMessage 的语法糖
 * @deprecated 使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl
 * @param descriptor { id : string, defaultMessage : string }
 * @param values { [key:string] : string }
 * @returns string
 */
export const formatMessage: IntlShape['formatMessage'] = (
  descriptor: MessageDescriptor,
  values: any,
) => {
  if (firstWaring) {
    warning(
      false,
      `Using this API will cause automatic refresh when switching languages, please use useIntl or injectIntl.

使用此 api 会造成切换语言的时候无法自动刷新，请使用 useIntl 或 injectIntl。

http://j.mp/37Fkd5Q
      `,
    );
    firstWaring = false;
  }
  return g_intl.formatMessage(descriptor, values);
};

/**
 * 获取语言列表
 * @returns string[]
 */
export const getAllLocales = () => Object.keys(localeInfo);
