import { request } from 'umi';
import type { OutboundConsoleTableListParams } from './data';
import type { UpdateFormValueType } from './components/UpdateForm';

const { JOR_WMS_OUTBOUND_CONSOLE_URL } = API;

export async function getOutboundConsoles(params: OutboundConsoleTableListParams | undefined) {
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}`, {
    method: 'GET',
    params,
  });
}

export async function exportOutboundConsoles(params: OutboundConsoleTableListParams | undefined) {
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/export`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}
export async function exportSelectedOutboundConsoles(params: { ids: number[] }) {
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/export/selected`, {
    method: 'POST',
    data: {
      ...params,
    },
    responseType: 'blob',
  });
}

export async function getOutboundConsoleOptions() {
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/options`, {
    method: 'GET',
  });
}

export async function getOutboundConsoleDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getOutboundConsoleOrders(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/${id}/orders`, {
    method: 'GET',
  });
}

export async function updateOutboundConsole(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function downloadJorWmsOutboundConsoleWaybill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/${id}/download/waybill`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsOutboundConsoleWInvoice(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_OUTBOUND_CONSOLE_URL}/${id}/download/invoice`, {
    method: 'GET',
    responseType: 'blob',
  });
}
