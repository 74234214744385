import { request } from 'umi';
import type { CalculatorProductProfitParam, ProductPriceTableListParams } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';

const { LME_PRODUCT_PRICE_URL } = API;

export async function getProductPrices(params: ProductPriceTableListParams | undefined) {
  return request(`${LME_PRODUCT_PRICE_URL}`, {
    method: 'GET',
    params,
  });
}
export async function getProductPriceCloneOptionByPriceId(id: number) {
  return request(`${LME_PRODUCT_PRICE_URL}/${id}/clone/create`, {
    method: 'GET',
  });
}

export async function getProductPriceEditOption(id: number) {
  return request(`${LME_PRODUCT_PRICE_URL}/${id}/edit`, {
    method: 'GET',
  });
}

export async function gerProductPriceByProductId(params: { id: number }) {
  const { id } = params;
  return request(`${LME_PRODUCT_PRICE_URL}/product/${id}`, {
    method: 'GET',
  });
}

export async function getProductPriceCreateOption() {
  return request(`${LME_PRODUCT_PRICE_URL}/create`, {
    method: 'GET',
  });
}

export async function getAllActiveProductPrices() {
  return request(`${LME_PRODUCT_PRICE_URL}/all/active`, {
    method: 'GET',
  });
}

export async function getProductPriceDetail(id: number) {
  return request(`${LME_PRODUCT_PRICE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function createProductPrice(params: CreateFormValueType) {
  return request(`${LME_PRODUCT_PRICE_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function calculatorProductProfit(params: CalculatorProductProfitParam) {
  return request(`${LME_PRODUCT_PRICE_URL}/calculator`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateProductPrice(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${LME_PRODUCT_PRICE_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function getProductPriceHistory(id: number) {
  return request(`${LME_PRODUCT_PRICE_URL}/${id}/history`, {
    method: 'GET',
  });
}

export async function handleDisableProductPrice(id: number) {
  return request(`${LME_PRODUCT_PRICE_URL}/${id}/disable`, {
    method: 'POST',
  });
}
export async function handleEnableProductPrice(id: number) {
  return request(`${LME_PRODUCT_PRICE_URL}/${id}/enable`, {
    method: 'POST',
  });
}
