import type { Effect, Reducer } from 'umi';

import type { OutboundConsoleDataType } from './data';
import { getOutboundConsoleDetail } from './service';

export interface StateType {
  outboundConsole?: OutboundConsoleDataType;
}

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    get: Effect;
  };
  reducers: {
    save: Reducer<StateType>;
    clear: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'outboundConsoleModel',
  state: {
    outboundConsole: undefined,
  },
  effects: {
    *get({ payload }, { call, put }) {
      const response = yield call(getOutboundConsoleDetail, payload);
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        outboundConsole: action.payload,
      };
    },
    clear() {
      return {
        outboundConsole: undefined,
      };
    },
  },
};

export default Model;
