export default {
  'label.create-payment': '新增付款紀錄',
  'label.external-transaction-no': '其他參考編號',
  'label.amount': '總額',
  'label.invoices': '發票',
  'title.invoices': '{document_no} 的發票',
  'label.number-of-invoices': '共有發票: {invoice_count}',
  'action.button.clear-selection': '重新選擇',
  'label.selected-invoices': '已選取 {number} 發票',
  'title.please-select-invoice': '請選取想付款的發票',
  'label.remark': '備註',
  'title.review-and-create': '查看並建立付款紀錄',
  'edit.payment': '修改付款紀錄',
  'action.button.review-and-create': '查看並新增紀錄',
  'error.multiple-currencies-is-not-allow': '選取的發票只能有一種貨幣!',
  'action.creating-payment': '正在新增付款紀錄',
  'action.updating-payment': '正在更新付款紀錄',
  'payment-proof-has-been-uploaded': '已有一個付款紀錄的檔案',
  'payment-record-proof': '付款單據',
  'upload-payment-proof-reminder': '請上傳你的付款單據',
  'button.show-invoices': '顯示發票',
  'cancel-cus-payment-warning': '真的要取消這個付款紀錄嗎?',
};
