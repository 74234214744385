import { DefaultFooter } from '@ant-design/pro-layout';
import defaultSettings from '@/../config/defaultSettings';

export default () => (
  <DefaultFooter
    copyright={defaultSettings.copyright}
    links={[
      {
        title: ``,
        href: '#',
        key: `footerLink`,
      },
    ]}
  />
);
