import { request } from 'umi';
import type { DeliveryNoteItemFormDataType, DeliveryNoteTableListParams } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';

const { JOR_WMS_DELIVERY_NOTE_URL } = API;

export async function getDeliveryNotes(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}`, {
    method: 'GET',
    params,
  });
}

export async function exportDeliveryNotes(params: DeliveryNoteTableListParams | undefined) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/export`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}
export async function exportSelectedDeliveryNotes(params: { ids: number[] }) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/export/selected`, {
    method: 'POST',
    data: {
      ...params,
    },
    responseType: 'blob',
  });
}

export async function getDeliveryNoteOptions() {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/options`, {
    method: 'GET',
  });
}

export async function getDeliveryNoteDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getDeliveryNoteItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}/items`, {
    method: 'GET',
  });
}

export async function createDeliveryNote(params: CreateFormValueType) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createDeliveryNoteItems(id: number, params: DeliveryNoteItemFormDataType) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}/items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateDeliveryNote(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function confirmToNewDeliveryNote(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}/confirm/order`, {
    method: 'POST',
  });
}

export async function confirmToNewDeliveryNotes(params: { ids: number[] }) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/confirm/order`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function cancelDeliveryNote(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}/cancel/order`, {
    method: 'POST',
  });
}

export async function searchOutboundDeliveryNotes(
  params: { search: string; merchant_id?: number } | undefined,
) {
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/search/outbound`, {
    method: 'GET',
    params,
  });
}

export async function getDeliveryNoteShippedSerialNumbers(params: { deliveryNoteId: number }) {
  const { deliveryNoteId } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${deliveryNoteId}/shipped/serial/numbers`, {
    method: 'GET',
  });
}

export async function downloadJorWmsDeliveryNoteWaybill(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}/download/waybill`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadJorWmsDeliveryNoteWInvoice(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_DELIVERY_NOTE_URL}/${id}/download/invoice`, {
    method: 'GET',
    responseType: 'blob',
  });
}
