export default {
  'inventory_bins.inventory_bin': '架位庫存列表',
  'inventory_bins.inventory_bin_detail': '架位庫存批次屬性詳情',
  'inventory_bins.warehouse': 'Warehouse',
  'inventory_bins.product': 'Product',
  'inventory_bins.product_sku': 'SKU',
  'inventory_bins.product_total_qty': 'Total Qty',
  'inventory_bins.warehouse_zone': 'Warehouse Zone',
  'inventory_bins.warehouse_bin': 'Warehouse Bin',
  'inventory_bins.item.no': 'Item No.',
  'inventory_bins.product_name': 'Product Name',
  'inventory_bins.bin_property': '架位類型',
  'inventory_bins.on_hand_stock': 'On Hand Qty',
  'inventory_bins.container_box_code': '箱號',
  'inventory_bins.production_date': '生產日期',
  'inventory_bins.expiry_date': '過期日期',
  'inventory_bins.split_qty': '拆分數量',
  'inventory_bins.system_expire_date': 'System Expire Date',
  'inventory_bins.picking_product_type': '貨品類別定義',
  'inventory_bins.production_batch_code': '批次號',
  'inventory_bins.current_bin_stock': 'Total Qty (Without Packed)',
  'inventory_bins.pre_pick_qty': 'PrePick Qty',
  'inventory_bins.pick_qty': 'Pick Qty',
  'inventory_bins.picked_qty': '已揀貨數量',
  'inventory_bins.transaction_code': 'Transaction Code',
  'inventory_bins.create': 'Create Product',
  'inventory_bins.create.fail': 'Fail to create product',
  'inventory_bins.create.success': 'Product has been succesfully created',
  'inventory_bins.update.fail': 'Fail to update product',
  'inventory_bins.update': 'Update',
  'inventory_bins.update.success': 'Product has been succesfully updated',
  'inventory_bins.move-to-bin': 'Move To Other Bin',
  'inventory_bins.move_bin.title': 'Move Bin {warehouse_bin} SKU - {sku}',
  'inventory_bins.split_bin_detail.title': '拆分 {container_box_code} 產品號碼 SKU - {sku}',
  'inventory_bins.external_warehouse_bin_code': 'External Warehouse Bin Code',
  'inventory_bins.move_qty': '移動數量',
  'inventory_bins.move_from_serial_number': 'Move From Serial Number',
  'inventory_bins.move_to_serial_number': 'Move To Serial Number',
  'inventory_bins.merchant': 'Merchant',
  'inventory_bins.move_to_bin_name': 'Move To Bin Name',
  'inventory_bins.can_allocate_stock': 'Available Stock',
  'inventory_bins.inbound_batch_no': '內部批次',
  'inventory_bins.asn_code': 'ASN',
  'inventory_bins.available_stock': 'Available Stock',
  'inventory_bins.ean_or_upc_code': 'EAN/UPC Code',
  'inventory_bins.move_bin.leave-confirm': 'Are you sure want to leave this page?',
  'inventory_bins.split-container': '拆分箱子',
  'inventory_bins.move-container': '移動箱子',
  'inventory_bins.move_inv_container.title': '移動箱子',
  'inventory_bins.can_move_qty': '可移庫數量',
  'inventory_bins.add_move_sku_details': '添加移動的箱子',
  'inventory_bins.move_container.confirm_bin_title': '確認移動箱子',
  'inventory_bins.can_allow_split_stock': '可拆分數量',
  'inventory_bins.button.print-split-container': '打印箱子Label',
  'inventory_bins.no_item_warning': '請先添加需要移動箱子的產品',
  'inventory_bins.please-select-item': '請先選擇需要移動的SKU',
  'inventory_bins.duplicate-select-item': '所選產品已經添加過了',
  'inventory_bins.show-zero-row': '顯示 0 庫存數據',
};
