import { Settings as LayoutSettings } from '@ant-design/pro-layout';
import moment from 'moment';

type DefaultSettings = LayoutSettings & {
  lme_title: string;
};

const Settings: DefaultSettings & {
  pwa?: boolean;
  logo?: string;
  copyright: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  // primaryColor: '#0088cc',
  primaryColor: '#2F54EB',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'SHIPRACER',
  lme_title: 'SHIPRACER',
  pwa: false,
  logo: 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
  copyright: `${moment(new Date()).format('YYYY')} Shipracer Development Team`,
  iconfontUrl: '',
};

export default Settings;
