import { request } from 'umi';
import type {
  NoteTableListParams,
  CreateNoteDataType,
  UpdateNoteDataType,
} from '@/models/finance/note.d';

const { LME_FINANCE_NOTE_URL } = API;

export async function queryFinanceNote(params: NoteTableListParams | undefined) {
  return request(LME_FINANCE_NOTE_URL, {
    method: 'GET',
    params,
  });
}

export async function getFinanceNote(params: { id: string }) {
  const { id } = params;
  return request(`${LME_FINANCE_NOTE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function queryFinanceParentNote(params: NoteTableListParams | undefined) {
  return request(`${LME_FINANCE_NOTE_URL}/parent`, {
    method: 'GET',
    params,
  });
}

export async function createFinanceNote(params: CreateNoteDataType) {
  return request(LME_FINANCE_NOTE_URL, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateFinanceNote(params: UpdateNoteDataType) {
  const { id } = params;
  return request(`${LME_FINANCE_NOTE_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}

export async function removeFinanceNote(params: { id: string }) {
  const { id } = params;
  return request(`${LME_FINANCE_NOTE_URL}/${id}`, {
    method: 'DELETE',
  });
}
