export default {
  'couriers.name': 'Name',
  'couriers.max_weight': 'Max Weight',
  'couriers.max_length': 'Max Length',
  'couriers.max_width': 'Max Width',
  'couriers.max_height': 'Max Height',
  'couriers.ddp_surcharge': 'DDP Surcharge',
  'couriers.fuel_surcharge': 'Fuel Surcharge',
  'couriers.pod': 'Proof Of Delivery',
  'couriers.lead_time': 'Lead Time',
  'couriers.currency': 'Currency',
  'couriers.incoterm_type': 'Incoterm',
  'couriers.status': 'Status',
  'couriers.supplier': 'Supplier',
  'couriers.supplier_contact': 'Supplier Contact',
  'couriers.supplier_email': 'Supplier E-mail',
  'couriers.create': 'Create Courier',
  'couriers.create.fail': 'Fail to create courier',
  'couriers.create.success': 'Courier has been succesfully created',
  'couriers.update.fail': 'Fail to update courier',
  'couriers.update': 'Update',
  'couriers.update.success': 'Courier has been succesfully updated',
};
