import { request } from 'umi';
import type { WarehouseBillTableListParams } from './data';

const { JOR_WMS_WAREHOUSE_BILL_URL } = API;

export async function getWarehouseBills(params: WarehouseBillTableListParams | undefined) {
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getWarehouseBillDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getWarehouseBillOptions() {
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}/options`, {
    method: 'GET',
  });
}

export async function downloadWarehouseBillReport(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}/${id}/download/bill/report`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getWarehouseBillInboundChargeItems(
  billId: number,
  params: WarehouseBillTableListParams | undefined,
) {
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}/${billId}/inbound/items`, {
    method: 'GET',
    params,
  });
}

export async function getWarehouseBillOutboundChargeItems(
  billId: number,
  params: WarehouseBillTableListParams | undefined,
) {
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}/${billId}/outbound/items`, {
    method: 'GET',
    params,
  });
}

export async function getWarehouseBillRentBillItems(
  billId: number,
  params: WarehouseBillTableListParams | undefined,
) {
  return request(`${JOR_WMS_WAREHOUSE_BILL_URL}/${billId}/rent/items`, {
    method: 'GET',
    params,
  });
}
