export default {
  'inbound_orders.batch': 'Batch',
  'inbound_orders.warehouse': 'Warehouse',
  'inbound_orders.virtual_warehouse': 'Virtual Warehouse',
  'inbound_orders.merchant': 'Merchant',
  'inbound_orders.inbound_type': 'Inbound Type',
  'inbound_orders.asn_code': 'ASN Code',
  'inbound_orders.skip_scan_serial_number': 'Skip Scan S/N',
  'inbound_orders.total_weight': 'Total Weight(KG)',
  'inbound_orders.total_volume': 'Total Volume(CBM)',
  'inbound_orders.total_box': 'Total Box',
  'inbound_orders.total_pallet': 'Total Pallet',
  'inbound_orders.total_cost': 'Total Cost',
  'inbound_orders.customer_order_no': 'Customer Order No.',
  'inbound_orders.inbound_method': 'Inbound Method',
  'inbound_orders.shipping_mode': 'Shipping Mode',
  'inbound_orders.freight_method': 'Freight Method',
  'inbound_orders.estimated_arrival_time': 'Estimated Arrival Time',
  'inbound_orders.channel_code': 'Serivce Channel',
  'inbound_orders.tack_nos': 'Tracking Nos',
  'inbound_orders.status': 'Status',
  'inbound_orders.create': 'Create Inbound Order',
  'inbound_orders.create.fail': 'Fail to create product',
  'inbound_orders.create.success': 'InboundOrder has been succesfully created',
  'inbound_orders.update.fail': 'Fail to update product',
  'inbound_orders.update': 'Update',
  'inbound_orders.update.success': 'Inbound Order has been succesfully updated',

  'inbound_orders.form_item.title': 'Product details ',
  'inbound_orders.carton_no': 'Carton No.',
  'inbound_orders.product': 'Product',
  'inbound_orders.search_product': 'Search Product',
  'inbound_orders.sku': 'SKU',
  'inbound_orders.product_name': 'Product Name',
  'inbound_orders.item.qty': 'Single Box Qty',
  'inbound_orders.item.box_qty': 'Box Qty',
  'inbound_orders.pallet_package': 'Pallet Packages',
  'inbound_orders.pallet_package.title': 'Pallet Details',
  'inbound_orders.pallet_package.length': 'Length',
  'inbound_orders.pallet_package.width': 'Width',
  'inbound_orders.pallet_package.height': 'Height',
  'inbound_orders.pallet_package.weight': 'Weight(KG)',
  'inbound_orders.pallet_no': 'Pallet No.	',
  'inbound_orders.dimension_detail': 'Length*Width*Height(CM)	',
  'inbound_orders.pallet.box_qty': 'Box Qty',
  'inbound_orders.pallet.associated_box_number': 'Associated Box Number',

  'inbound_orders.put_away.title': 'Put Away {sku}',
  'inbound_orders.details.items': 'Item Details',
  'inbound_orders.details.sku': 'SKU Code',
  'inbound_orders.details.product_name': 'Product Name',
  'inbound_orders.details.product_qty': 'Expected Qty',
  'inbound_orders.details.product_actual_qty': 'Arrival Qty',
  'inbound_orders.details.put_away_qty': 'Put Away Qty',
  'inbound_orders.external_warehouse_bin_code': 'External Warehouse Bin Code',
  'inbound_orders.details.pending_put_away_qty': 'Pending Put Away Qty',
  'inbound_orders.details.packing_list': 'Packing Details',
  'inbound_orders.details.pallet_no': 'Pallet No.',
  'inbound_orders.details.carton_no': 'Carton No.',
  'inbound_orders.dispatch-inbound-confirm': 'Are you sure you want to dispatch this {document_no}',
  'inbound_orders.received-confirm': 'Are you sure you want to received this {document_no}',
  'inbound_orders.update_title': 'Update ({merchant}) {asn_number} ',
  'inbound_orders.cancel-confirm': 'Are you sure to cancel this {document_no} inbound order?',
  'inbound_orders.upload_items': 'Upload Box Items',
  'inbound_orders.export_to_excel': 'Export your data to excel',
  'inbound_orders.download': 'Download',
  'inbound_orders.update_tracking_info': 'Update Tracking Info',
  'inbound_orders.button.edit_tracking': 'Edit Tracking',
  'inbound_orders.select_carton': 'Select Carton No. List To Associate Pallet No.',
};
