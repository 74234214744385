import React from 'react';
import styles from './index.less';

const EnvironmentReminder: React.FC<{}> = () => {
  // APP_ENV: local/dev/uat/production
  // const APP_ENV: string = window.env.app_env || 'local';
  const CustomHeader: string = window.env.custom_header;

  return CustomHeader && CustomHeader?.length ? (
    <span className={`${styles.reminder}`}>{CustomHeader}</span>
  ) : null;
};

export default EnvironmentReminder;
