export default {
  'warehouse_rent_bill.merchant': 'Merchant',
  'warehouse_rent_bill.bill_code': 'Bill Code',
  'warehouse_rent_bill.total_amount': 'Total Amount',
  'warehouse_rent_bill.bill_start_date': 'Bill Start Date',
  'warehouse_rent_bill.bill_end_date': 'Bill End Date',
  'warehouse_rent_bill.remark': 'Remark',
  'warehouse_rent_bill.submitted_at': 'Submitted At',
  'warehouse_rent_bill.submitted_by': 'Submitted By',
  'warehouse_rent_bill.status': 'Status',
};
