export default {
  'rma_orders.item.received_qty': 'Received Qty',
  'rma_orders.item.put_away_qty': 'Put Away Qty',
  'rma_orders.item.product_images': 'Product Images',
  'rma_orders.item.warehouse_bin': 'Warehouse Bin',
  'rma_orders.details.pending_received_qty': 'Pending Received Qty',
  'rma_orders.details.serial_number': 'Serial Number',
  'rma_orders.details.received_qty': 'Received Qty',
  'rma_orders.details.unknown_product_name': ' Unknown Product Name',
  'rma_orders.item.status': 'Status',
  'rma_orders.received.title': 'Inspection SKU {sku}',
  'rma_orders.action.receive': 'Receive',
  'rma_orders.action.inspect': 'Inspect',
  'rma_orders.put_away.title': 'Put Away SKU {sku}',
  'rma_orders.action.put_away': 'Put Away',
  'rma_orders.details.ean_or_upc_code': 'EAN / UPC Code',
  'rma_orders.request_collection_date': 'Collection Date',
  'rma_orders.received.leave-confirm': 'Are you sure you want to leave this page?',
  'rma_orders.tabs.inpspect_to_putaway': 'Inspect & PutAway',
  'rma_orders.tabs.received_to_inspect': 'Received (Pending To Inspect)',
};
