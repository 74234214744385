import { request } from 'umi';
import type { UserPickOutboundLogItem } from '../outbound/data';
import type {
  InventoryBatchAdjustmentTableListParams,
  InventoryTableListParams,
  StockOutAdjustmentItemTableListParams,
  StockTakeItemTableListParams,
  StockTakeTableListDataTypeParams,
  CheckInventoryBinDetails,
  WebhookInventoryAdjustmentTableListParams,
} from './data';
import type { CreateInvAdjustmentFormValueType } from './components/inventory-adjustment/CreateItemForm';

const {
  JOR_A_WMS_INVENTORY_URL,
  JOR_A_WMS_INVENTORY_BIN_URL,
  JOR_A_WMS_INVENTORY_ZONE_URL,
  JOR_A_WMS_STOCK_TAKE_URL,
  JOR_A_WMS_INVENTORY_BATCH_ADJUSTMENT_URL,
  JOR_A_WMS_INVENTORY_ADJUSTMENT_URL,
  JOR_A_WMS_WEBHOOK_INVENTORY_ADJUSTMENT_URL,
} = API;

export async function getInventories(params: InventoryTableListParams | undefined) {
  return request(`${JOR_A_WMS_INVENTORY_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getAllowanceInventories(params: InventoryTableListParams | undefined) {
  return request(`${JOR_A_WMS_INVENTORY_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getInventoryAllowanceByProductType(
  params: InventoryTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/allowance/product/type`, {
    method: 'GET',
    params,
  });
}

export async function getAllowanceInventoryZoneByProductType(
  params: InventoryTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_ZONE_URL}/allowance/product/type`, {
    method: 'GET',
    params,
  });
}

export async function getAllowanceTempZoneInventories(
  params: InventoryTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_ZONE_URL}/temp/stock`, {
    method: 'GET',
    params,
  });
}

export async function getInventoryByZoneProductType(params: InventoryTableListParams | undefined) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/zone/product/type`, {
    method: 'GET',
    params,
  });
}

export async function getInventoryDetail(id: number) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/${id}`, {
    method: 'GET',
  });
}

export async function checkInvAvailablePickBySerialNumber(params: {
  serial_number: string;
  picklist_no: string;
  update_user_pack_log?: { delivery_note_code: string; picked_items: UserPickOutboundLogItem[] };
}) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/available/pick/item`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInventoryAvailablePickContainer(params: {
  delivery_note_code: string;
  container_box_code: string;
}) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/available/pick/container`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInventoryAvailablePickPackProduct(params: {
  scanned_number: string;
  picklist_no: string;
  container_box_code?: string;
  update_user_pack_log?: { delivery_note_code: string; picked_items: UserPickOutboundLogItem[] };
}) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/available/pickpack/product`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInventoryAvailablePackProduct(params: {
  scanned_number: string;
  delivery_note_code: string;
  update_user_pack_log?: { delivery_note_code: string; picked_items: UserPickOutboundLogItem[] };
}) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/available/pack/product`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function exportToWarehouseDispatchedWarning(warehouseId: number) {
  return request(`${JOR_A_WMS_INVENTORY_URL}/export/warehouse/${warehouseId}/dispatched/warning`, {
    method: 'GET',
    responseType: 'blob',
    getResponse: true,
  });
}

export async function getStockTakes(params: StockTakeTableListDataTypeParams | undefined) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}`, {
    method: 'GET',
    params,
  });
}

export async function downloadStockTakePdf(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadStockTakeExcel(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${id}/download/excel`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadStockTakeResultExcel(params: {
  stocktake_id: number;
  result_file_path: string;
}) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/download/result`, {
    method: 'POST',
    data: {
      ...params,
    },
    responseType: 'blob',
  });
}

export async function completeStockTakeResultIfNoVariance(params: {
  stocktake_id: number;
  result_file_path: string;
}) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/complete/result`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function adminCompleteStockTake(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${id}/complete`, {
    method: 'POST',
  });
}

export async function adminCancelStockTake(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function getStockTakeOptions() {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/options`, {
    method: 'GET',
  });
}

export async function getStockTakeDetails(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getStockTakeItems(
  stock_take_id: number,
  params: StockTakeItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${stock_take_id}/items`, {
    method: 'GET',
    params,
  });
}

export async function createStockTake(params: any) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function uploadStocktakeResult(formData: FormData) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/upload/result`, {
    method: 'POST',
    data: formData,
  });
}

export async function getStockTakeAdjustments(
  stock_take_id: number,
  params: StockTakeItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${stock_take_id}/adjustments`, {
    method: 'GET',
    params,
  });
}

export async function getStockTakeAdjustmentItems(
  stock_take_id: number,
  params: StockTakeItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_STOCK_TAKE_URL}/${stock_take_id}/adjustment/items`, {
    method: 'GET',
    params,
  });
}

// // no called
// export async function addStockTakeAdjustmentItem(params: AddAdjustmentItemFormValueType) {
//   return request(`${JOR_A_WMS_STOCK_TAKE_URL}/add/adjustment/item`, {
//     method: 'POST',
//     data: {
//       ...params,
//     },
//   });
// }

export async function checkInventoryBinAllowAdjustment(params: CheckInventoryBinDetails) {
  return request(`${JOR_A_WMS_INVENTORY_BIN_URL}/check/allow/adjustment`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInventoryBinAllowStockTake(params: CheckInventoryBinDetails) {
  return request(`${JOR_A_WMS_INVENTORY_BIN_URL}/check/allow/stock/take`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInventoryBinAllowTransfer(params: CheckInventoryBinDetails) {
  return request(`${JOR_A_WMS_INVENTORY_BIN_URL}/check/allow/transfer`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function checkInventoryBinAllowContainerMove(params: CheckInventoryBinDetails) {
  return request(`${JOR_A_WMS_INVENTORY_BIN_URL}/check/allow/container/move`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getInventoryAdjustments(
  params: StockOutAdjustmentItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getInventoryAdjustmentOptions() {
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/options`, {
    method: 'GET',
  });
}

export async function getInventoryAdjustmentDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getInventoryAdjustmentItems(
  id: number,
  params: StockOutAdjustmentItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function createInventoryAdjustment(params: CreateInvAdjustmentFormValueType) {
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function adminCompleteInventoryAdjustment(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/${id}/complete`, {
    method: 'POST',
  });
}

export async function adminCancelInventoryAdjustment(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/${id}/cancel`, {
    method: 'POST',
  });
}

export async function getInventoryAdjustmentItem(
  params: StockOutAdjustmentItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/items`, {
    method: 'GET',
    params,
  });
}

export async function downloadInventoryAdjustmentPdf(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INVENTORY_ADJUSTMENT_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getInventoryBatchAdjustment(
  params: InventoryBatchAdjustmentTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getInventoryBatchAdjustmentDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function rePushInventoryBatchAdjustmentItems(params: {
  batch_adjustment_id: number;
  item_ids: number[];
}) {
  return request(`${JOR_A_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/item/repush`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function getInventoryBatchAdjustmentOptions() {
  return request(`${JOR_A_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/options`, {
    method: 'GET',
  });
}

export async function getInventoryBatchAdjustmentItems(
  id: number,
  params: StockOutAdjustmentItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_INVENTORY_BATCH_ADJUSTMENT_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function getWebhookInventoryAdjustment(
  params: WebhookInventoryAdjustmentTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_WEBHOOK_INVENTORY_ADJUSTMENT_URL}`, {
    method: 'GET',
    params,
  });
}

export async function getWebhookInventoryAdjustmentDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_A_WMS_WEBHOOK_INVENTORY_ADJUSTMENT_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getWebhookInventoryAdjustmentOptions() {
  return request(`${JOR_A_WMS_WEBHOOK_INVENTORY_ADJUSTMENT_URL}/options`, {
    method: 'GET',
  });
}

export async function getWebhookInventoryAdjustmentItems(
  id: number,
  params: StockOutAdjustmentItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_WEBHOOK_INVENTORY_ADJUSTMENT_URL}/${id}/items`, {
    method: 'GET',
    params,
  });
}

export async function queryWebhookInventoryAdjustmentItems(
  params: StockOutAdjustmentItemTableListParams | undefined,
) {
  return request(`${JOR_A_WMS_WEBHOOK_INVENTORY_ADJUSTMENT_URL}/search/items`, {
    method: 'GET',
    params,
  });
}
