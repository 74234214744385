import { useCallback, useState } from 'react';

type UserLoading = {
  globalLoading: boolean;
};

export default function useUserLoadingModel() {
  const [userLoading, handleSetUserLoading] = useState<UserLoading>({ globalLoading: false });

  const setUserLoading = useCallback((userLoadingState: UserLoading) => {
    handleSetUserLoading(userLoadingState);
  }, []);

  return {
    userLoading,
    setUserLoading,
  };
}
