// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import ProjectOutlined from '@ant-design/icons/ProjectOutlined';
import FileZipOutlined from '@ant-design/icons/FileZipOutlined';
import TransactionOutlined from '@ant-design/icons/TransactionOutlined';
import OneToOneOutlined from '@ant-design/icons/OneToOneOutlined';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import SendOutlined from '@ant-design/icons/SendOutlined';
import UngroupOutlined from '@ant-design/icons/UngroupOutlined';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';
import PullRequestOutlined from '@ant-design/icons/PullRequestOutlined';
import ScheduleOutlined from '@ant-design/icons/ScheduleOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import LayoutOutlined from '@ant-design/icons/LayoutOutlined';
import ScanOutlined from '@ant-design/icons/ScanOutlined';
import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';
import SelectOutlined from '@ant-design/icons/SelectOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import RetweetOutlined from '@ant-design/icons/RetweetOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import BankOutlined from '@ant-design/icons/BankOutlined';
import CodeSandboxOutlined from '@ant-design/icons/CodeSandboxOutlined';
import CarOutlined from '@ant-design/icons/CarOutlined';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined';
import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined'

export default {
  SmileOutlined,
ProjectOutlined,
FileZipOutlined,
TransactionOutlined,
OneToOneOutlined,
InboxOutlined,
SendOutlined,
UngroupOutlined,
FolderOpenOutlined,
PullRequestOutlined,
ScheduleOutlined,
UserOutlined,
ProfileOutlined,
SolutionOutlined,
DollarOutlined,
BlockOutlined,
LayoutOutlined,
ScanOutlined,
ShoppingCartOutlined,
SelectOutlined,
SyncOutlined,
AppstoreOutlined,
ToolOutlined,
RetweetOutlined,
DatabaseOutlined,
FileTextOutlined,
HomeOutlined,
BankOutlined,
CodeSandboxOutlined,
CarOutlined,
UserSwitchOutlined,
UsergroupAddOutlined,
ControlOutlined
}
    