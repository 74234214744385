export default {
  'outbound_consoles.upload_waybill_title': 'Upload {document_no} Waybill File',
  'outbound_consoles.upload_waybill': 'Upload Waybill',
  'outbound_consoles.download_waybill': 'Download Waybill',
  'outbound_consoles.upload_invoice_title': 'Upload {document_no} Invoice File',
  'outbound_consoles.upload_invoice': 'Upload Invoice',
  'outbound_consoles.download_invoice': 'Download Invoice',
  'outbound_consoles.update.fail': 'Fail to update outbound order',
  'outbound_consoles.update': 'Update',
  'outbound_consoles.update.success': 'Outbound Order has been succesfully updated',
  'outbound_consoles.cancel-confirm': 'Are you sure to cancel this {document_no} delivery note?',
  'outbound_consoles.download-selected-confrim':
    'Are you sure to download selected outbound orders?',
  'outbound_consoles.download_selected': 'Download Selected',
  'outbound_consoles.export_to_excel': 'Export your data to excel',
  'outbound_consoles.download': 'Download',
  'outbound-consoles.update': 'Update Outbound Console {document_no}',
  'outbound_consoles.oms_courier_service': 'Shipracer Courier Service',
};
