export default {
  'warehouses.name': '倉庫名稱',
  'warehouses.warehouse_code': '倉庫編碼',
  'warehouses.district': '地區',
  'warehouses.country': '國家',
  'warehouses.contact_person': '聯絡人',
  'warehouses.contact': '聯絡',
  'warehouses.address': '地址',
  'warehouses.email': '電郵',
  'warehouses.create': '新增倉庫',
  'warehouses.create.fail': '建立新倉庫失敗',
  'warehouses.create.success': '成功建立新倉庫',
  'warehouses.update.fail': '更新建立新倉庫失敗',
  'warehouses.update.success': '成功更新倉庫',
  'warehouses.cut_off_time': '截單時間',
  'warehouses.menuMap.basic': 'General Info',
  'warehouses.menuMap.provider': 'WMS Provider',
  'warehouses.action.add-new-provider': 'Add New WMS Provider',
  'warehouses.menuMap.provider-user': 'WMS Provider Users',
  'warehouses.menuMap.container': 'WMS Containers',
  'warehouses.menuMap.carrier': 'WMS Carriers',
  'warehouses.action.add-new-user': 'Add New WMS Provider User',
  'warehouses.delete-user.warning': 'Are you sure to delete this WMS provider user?',
  'warehouses.action.add-new-carrier': 'Add New WMS Carrier',
  'warehouses.delete-carrier.warning': 'Are you sure to delete this WMS carrier?',
  'warehouses.action.add-new-container': 'Add New WMS Container',
  'warehouses.delete-container.warning': 'Are you sure to delete this WMS container?',
  'warehouses.basic.update': 'Update',
  'warehouses.basic.add_provider': 'Add Provider',
  'warehouses.wms_provider': 'WMS Provider',
  'warehouses.wms_provider_code': 'WMS Provider Code',
  'warehouses.provider.primary_name': 'Primary Name',
  'warehouses.warehouse_zone_code': 'Zone Code',
  'warehouses.provider.api_warehouse_code': 'API Warehouse Code',
  'warehouses.provider.api_owner_code': 'API Owner Code',
  'warehouses.provider.api_user_id': 'API User Id',
  'warehouses.provider.api_user_key': 'API User Key',
  'warehouses.provider.api_url': 'API URL',
  'warehouses.provider.webhook_secret': 'Webhook Secrect',
  'warehouses.provider_user.user_name': 'User Name',
  'warehouses.provider_user.password': 'Password',
  'warehouses.provider_user.status': 'Status',
  'warehouses.container.container_code': '箱號',
  'warehouses.container.use_type': 'Use Type',
  'warehouses.container.occupy_status': 'Occupy Status',
  'warehouses.container.container_type': 'Container Type',
  'warehouses.container.remark': 'Remark',
  'warehouses.container.status': 'Status',
  'warehouses.container.download_selected': 'Downlaod selected',
  'warehouses.container.download-selected-confirm':
    'Are you sure you want to download selected wms container barcode?',
  'warehouses.carrier.carrier_code': 'Carrier Code',
  'warehouses.carrier.carrier_name': 'Carrier Name',
  'warehouses.carrier.carrier_abbr': 'Carrier Abbr',
  'warehouses.carrier.warehouse_carrier_abbr': 'Warehouse Carrier Abbr',
  'warehouses.carrier.contact_person': 'Contact Person',
  'warehouses.carrier.contact_tel': 'Contact Tel',
  'warehouses.carrier.fax': 'Fax',
  'warehouses.carrier.email': 'E-mail',
  'warehouses.carrier.country': 'Country',
  'warehouses.carrier.province': 'State',
  'warehouses.carrier.city': 'City',
  'warehouses.carrier.addr': 'Address',
  'warehouses.carrier.zip_code': 'Postal Code',
  'warehouses.carrier.require_console': 'Require Console',
  'warehouses.carrier.console_max_weight': 'Console Max Weight(G)',
  'warehouses.carrier.picking_remark': 'Picking Remark',
  'warehouses.carrier.packed_remark': 'Packed Remark',
  'warehouses.carrier.dispatched_remark': 'Dispatched Remark',
  'warehouses.carrier.remark': 'Remark',
  'warehouses.carrier.status': 'Status',
  'warehouses.carrier-cutoff.edit-title': '修改截單時間',
  'warehouses.carrier-cutoff.edit-btn-tips': '修改截單時間',
  'warehouses.carrier-cutoff.invalid-cut-off-format': '僅允許 HH:MM 或 1-6 個字元長',
  'warehouses.carrier-cutoff.duplicated-cut-off': '重複的截單時間',
  'warehouses.carrier-cutoff.cut-off-placeholder': '截單時間',
};
