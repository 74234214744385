export default {
  'suppliers.primary_name': 'Primary Name',
  'suppliers.secondary_name': 'Secondary Name',
  'suppliers.payee_bank_name': 'Payee Bank Name',
  'suppliers.payee_bank_swiftbic': 'Payee Bank Swiftbic',
  'suppliers.payee_bank_account_name': 'Payee Bank Account Name',
  'suppliers.address': 'Address',
  'suppliers.tel': 'TEL',
  'suppliers.mobile': 'Mobile',
  'suppliers.email': 'E-mail',
  'suppliers.contact_person': 'Contact Person',
  'suppliers.postal_address': 'Postal Address',
  'suppliers.status': 'Status',
  'suppliers.create': 'Create Supplier',
  'suppliers.create.fail': 'Fail to create supplier',
  'suppliers.create.success': 'Supplier has been succesfully created',
  'suppliers.update.fail': 'Fail to update supplier',
  'suppliers.update': 'Update',
  'suppliers.update.success': 'Supplier has been succesfully updated',
};
