export default {
  'wms_report.generate-report': 'Generate Reports',
  'wms_report.report-type.title': 'Report(Please select report)',
  'wms_report.report-type.label': 'Report',
  'wms_report.report-type.required': 'Please select report',
  'wms_report.group.inbound_sorted_item': 'Inbound Sorted Container',
  'wms_report.inbound_sorted_item.summary': 'Inbound Sorted Container - Summary (within 31 days)',
  'wms_report.inbound_pending.summary': 'Pending ASN Summary',
  'wms_report.group.outbound': 'Outbound',
  'wms_report.outbound.summary': 'Outbound - Summary (within 7 days)',
  'wms_report.group.outbound_pick_item': 'Outbound Pick Item',
  // 'wms_report.outbound_pick_item.summary': 'Outbound Pick Item - Summary',
  'wms_report.daily_allocation.summary': 'Daily Allocation - Summary',
  'wms_report.monthly_billing_outbound.summary': 'Monthly Billing Outbound - Summary',
  'wms_report.daily_transfer_report.summary': 'Daily Transfer Order - Summary',
  'wms_report.replenishment_cross_zone.summary': 'Replen. Cross Zone - Summary (within 7 days)',
  'wms_report.group.inventory_bin_detail': 'INV - Bin Detail',
  'wms_report.inventory_bin_detail.summary': 'INV - Bin Detail - Summary',
  'wms_report.group.inventory_adjustment': 'INV - Adjustment',
  'wms_report.inventory_adjustment.summary': 'INV - Adjustment - Summary (within 31 days)',
  'wms_report.user_packed_item': 'User Packed Report',
  'wms_report.user_packed_item.summary': 'User Packed Report - Summary (within 1 days)',
  'wms_report.merchant.label': 'Merchants',
  'wms_report.merchant.placeholder': 'Please select merchants',
  'wms_report.warehouse.label': 'Warehouses',
  'wms_report.report_date.label': 'Report Date',
  'wms_report.warehouse.placeholder': 'Please select warehouses',
  'wms_report.from-date.label': 'From Date',
  'wms_report.to-date.label': 'To Date',
  'wms_report.packed-date.label': 'Packed Date',
  'wms_report.date.placeholder': 'Please select a date',
  'wms_report.from-received-date.label': 'From Received Date',
  'wms_report.to-received-date.label': 'To Received Date',
  'wms_report.received_date.placeholder': 'Please select a received date',
  'wms_report.packed_date.placeholder': 'Please select a packed date',
  'wms_report.skus.label': 'SKU(s)',
  'wms_report.skus.max-warning': 'Max. {max_cnt} SKU(s) only',
  'wms_report.skus.copy-and-paste-textarea-placeholder':
    'Max. {max_cnt} SKU(s) only (When entering multiple SKU codes at one time, use "," or line break to separate them, such as sku001, sku002.) ',
  'wms_report.inventory_transaction.internal': 'Inventory Transaction Report (Internal)',
  'wms_report.inventory_transaction.simple': 'Inventory Transaction Report',
  'wms_report.inventory_transaction.relocate': 'Inventory Relocation Report',
  'wms_report.internal_sku_dimension_rpt': 'SKU Dimension Report (Internal)',
};
