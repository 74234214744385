import { request } from 'umi';
import type { RmaOrderItemFormDataType, RmaOrderTableListParams } from './data';
import type { CreateFormValueType } from './components/CreateForm';
import type { UpdateFormValueType } from './components/UpdateForm';
import type { CreateRmaWaybillFormValueType } from './components/CreateRmaWaybillForm';

import type { RmaProductFileTableListParams } from '../wms/rma-order/data';

const { JOR_WMS_RMA_ORDER_URL, JOR_WMS_RMA_PRODUCT_FILE_URL } = API;

export async function getRmaOrders(params: RmaOrderTableListParams | undefined) {
  return request(`${JOR_WMS_RMA_ORDER_URL}`, {
    method: 'GET',
    params,
  });
}

export async function exportRmaOrders(params: RmaOrderTableListParams | undefined) {
  return request(`${JOR_WMS_RMA_ORDER_URL}/export`, {
    method: 'GET',
    params,
    responseType: 'blob',
  });
}

export async function getRmaOrderOptions() {
  return request(`${JOR_WMS_RMA_ORDER_URL}/options`, {
    method: 'GET',
  });
}

export async function getRmaOrderDetail(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}`, {
    method: 'GET',
  });
}

export async function getRmaOrderItems(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/items`, {
    method: 'GET',
  });
}

export async function getRmaOrderItemProducts(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/item/products`, {
    method: 'GET',
  });
}

export async function queryRmaProductFiles(params: RmaProductFileTableListParams | undefined) {
  return request(JOR_WMS_RMA_PRODUCT_FILE_URL, {
    method: 'GET',
    params,
  });
}

export async function createRmaOrder(params: CreateFormValueType) {
  return request(`${JOR_WMS_RMA_ORDER_URL}`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function updateRmaOrder(params: UpdateFormValueType) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}`, {
    method: 'PUT',
    data: {
      ...params,
    },
  });
}
export async function createRmaOrderWaybill(id: number, params: CreateRmaWaybillFormValueType) {
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/create/label`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function createRmaOrderItems(id: number, params: RmaOrderItemFormDataType) {
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/items`, {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

export async function dispatchRmaOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/dispatch`, {
    method: 'POST',
  });
}

export async function cancelRmaOrder(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/cancel/order`, {
    method: 'POST',
  });
}

export async function downloadRmaOrderPDF(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/download/pdf`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadRmaOrderPod(params: { id: number }) {
  const { id } = params;
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/download/pod`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function downloadRmaItemTemplate() {
  return request(`${JOR_WMS_RMA_ORDER_URL}/download/item/template`, {
    method: 'GET',
    responseType: 'blob',
  });
}

export async function uploadToCreateRmaOrderItems(id: number, formData: FormData) {
  return request(`${JOR_WMS_RMA_ORDER_URL}/${id}/upload/items`, {
    method: 'POST',
    data: formData,
  });
}
