export default {
  'delivery_address_books.address_type': 'Address Type',
  'delivery_address_books.delivery_name': 'Delivery Name',
  'delivery_address_books.email': 'E-mail',
  'delivery_address_books.company': 'Company',
  'delivery_address_books.address': 'Address',
  'delivery_address_books.address_line_1': 'Address Line 1',
  'delivery_address_books.address_line_2': 'Address Line 2',
  'delivery_address_books.address_line_3': 'Address Line 3',
  'delivery_address_books.district': 'District',
  'delivery_address_books.city': 'City',
  'delivery_address_books.state': 'State',
  'delivery_address_books.postal': 'Postal',
  'delivery_address_books.country': 'Country',
  'delivery_address_books.phone': 'Phone',
  'delivery_address_books.ext_number': 'EXT.',
  'delivery_address_books.default': 'Default.',
  'delivery_address_books.status': 'Status',
  'delivery_address_books.create': 'Create Address Book',
  'delivery_address_books.create.fail': 'Fail to create merchant',
  'delivery_address_books.create.success': 'Address Book has been succesfully created',
  'delivery_address_books.update.fail': 'Fail to update merchant',
  'delivery_address_books.update': 'Update',
  'delivery_address_books.tabs.shipper': 'Shipper',
  'delivery_address_books.tabs.receiver': 'Receiver',
  'delivery_address_books.update.success': 'Address Book has been succesfully updated',
  'delivery_address_books.delete-confirm': 'Are you sure you want to delete this address book?',
  'delivery_address_books.delete.success': 'Address Book has been succesfully deleted',
};
