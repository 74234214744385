export default {
  'remark.title': '文檔備註',
  'remark.title.edit': '設置文檔備註',
  'remark.title.custom': '自定義文檔備註',
  'remark.btn.custom': '自定義',
  'remark.carry-next': '轉到下一個文檔',
  'remark.carry-all': '攜帶所有備註',
  'remark.transfer.source-title': '備註模板列表',
  'remark.transfer.target-title': '已選擇',
  'remark.search.title.placeholder': '請輸入搜尋內容',
  'remark.search.document.placeholder': '請選擇備註標籤',
};
